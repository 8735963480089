export const a1 = [
    { id:10001, word:'a',translate:'a',checked:false,blocked:false },
    { id:10002, word:'about',translate:'около',checked:false,blocked:false },
    { id:10003, word:'above',translate:'над',checked:false,blocked:false },
    { id:10004, word:'across',translate:'через',checked:false,blocked:false },
    { id:10005, word:'action',translate:'действие',checked:false,blocked:false },
    { id:10006, word:'activity',translate:'активность',checked:false,blocked:false },
    { id:10007, word:'actor',translate:'актёр',checked:false,blocked:false },
    { id:10008, word:'actress',translate:'актриса',checked:false,blocked:false },
    { id:10009, word:'add',translate:'добавить',checked:false,blocked:false },
    { id:10010, word:'address',translate:'адрес',checked:false,blocked:false },
    { id:10011, word:'adult',translate:'взрослый',checked:false,blocked:false },
    { id:10012, word:'advice',translate:'совет',checked:false,blocked:false },
    { id:10013, word:'afraid',translate:'бояться',checked:false,blocked:false },
    { id:10014, word:'after',translate:'после',checked:false,blocked:false },
    { id:10015, word:'afternoon',translate:'после обеда',checked:false,blocked:false },
    { id:10016, word:'again',translate:'снова',checked:false,blocked:false },
    { id:10017, word:'age',translate:'возраст',checked:false,blocked:false },
    { id:10018, word:'ago',translate:'назад',checked:false,blocked:false },
    { id:10019, word:'agree',translate:'соглашаться',checked:false,blocked:false },
    { id:10020, word:'air',translate:'воздух',checked:false,blocked:false },
    { id:10021, word:'airport',translate:'аэропорт',checked:false,blocked:false },
    { id:10022, word:'all',translate:'все',checked:false,blocked:false },
    { id:10023, word:'also',translate:'также',checked:false,blocked:false },
    { id:10024, word:'always',translate:'всегда',checked:false,blocked:false },
    { id:10025, word:'amazing',translate:'удивительно',checked:false,blocked:false },
    { id:10026, word:'and',translate:'и',checked:false,blocked:false },
    { id:10027, word:'angry',translate:'сердитый',checked:false,blocked:false },
    { id:10028, word:'animal',translate:'животное',checked:false,blocked:false },
    { id:10029, word:'another',translate:'ещё',checked:false,blocked:false },
    { id:10030, word:'answer',translate:'ответ',checked:false,blocked:false },
    { id:10031, word:'any',translate:'любой',checked:false,blocked:false },
    { id:10032, word:'anyone',translate:'любой',checked:false,blocked:false },
    { id:10033, word:'anything',translate:'что-нибудь',checked:false,blocked:false },
    { id:10034, word:'apartment',translate:'квартира',checked:false,blocked:false },
    { id:10035, word:'apple',translate:'яблоко',checked:false,blocked:false },
    { id:10036, word:'April',translate:'апрель',checked:false,blocked:false },
    { id:10037, word:'area',translate:'область',checked:false,blocked:false },
    { id:10038, word:'arm',translate:'рука',checked:false,blocked:false },
    { id:10039, word:'around',translate:'вокруг',checked:false,blocked:false },
    { id:10040, word:'arrive',translate:'прибыть',checked:false,blocked:false },
    { id:10041, word:'art',translate:'искусство',checked:false,blocked:false },
    { id:10042, word:'article',translate:'статья',checked:false,blocked:false },
    { id:10043, word:'artist',translate:'художник',checked:false,blocked:false },
    { id:10044, word:'as',translate:'как',checked:false,blocked:false },
    { id:10045, word:'ask',translate:'спросить',checked:false,blocked:false },
    { id:10046, word:'at',translate:'на',checked:false,blocked:false },
    { id:10047, word:'August',translate:'Август',checked:false,blocked:false },
    { id:10048, word:'aunt',translate:'тетя',checked:false,blocked:false },
    { id:10049, word:'autumn',translate:'осень',checked:false,blocked:false },
    { id:10050, word:'away',translate:'прочь',checked:false,blocked:false },
    { id:10051, word:'baby',translate:'ребенок',checked:false,blocked:false },
    { id:10052, word:'back',translate:'назад',checked:false,blocked:false },
    { id:10053, word:'bad',translate:'плохой',checked:false,blocked:false },
    { id:10054, word:'bag',translate:'сумка',checked:false,blocked:false },
    { id:10055, word:'ball',translate:'мяч',checked:false,blocked:false },
    { id:10056, word:'banana',translate:'банан',checked:false,blocked:false },
    { id:10057, word:'band',translate:'группа',checked:false,blocked:false },
    { id:10058, word:'bank',translate:'банк',checked:false,blocked:false },
    { id:10059, word:'bath',translate:'ванна',checked:false,blocked:false },
    { id:10060, word:'bathroom',translate:'ванная',checked:false,blocked:false },
    { id:10061, word:'be',translate:'быть',checked:false,blocked:false },
    { id:10062, word:'beach',translate:'пляж',checked:false,blocked:false },
    { id:10063, word:'beautiful',translate:'красивый',checked:false,blocked:false },
    { id:10064, word:'because',translate:'потому что',checked:false,blocked:false },
    { id:10065, word:'become',translate:'стать',checked:false,blocked:false },
    { id:10066, word:'bed',translate:'кровать',checked:false,blocked:false },
    { id:10067, word:'bedroom',translate:'спальня',checked:false,blocked:false },
    { id:10068, word:'beer',translate:'пиво',checked:false,blocked:false },
    { id:10069, word:'before',translate:'до',checked:false,blocked:false },
    { id:10070, word:'begin',translate:'начать',checked:false,blocked:false },
    { id:10071, word:'beginning',translate:'начало',checked:false,blocked:false },
    { id:10072, word:'behind',translate:'за',checked:false,blocked:false },
    { id:10073, word:'believe',translate:'верить',checked:false,blocked:false },
    { id:10074, word:'below',translate:'ниже',checked:false,blocked:false },
    { id:10075, word:'best',translate:'лучший',checked:false,blocked:false },
    { id:10076, word:'better',translate:'лучше',checked:false,blocked:false },
    { id:10077, word:'between',translate:'между',checked:false,blocked:false },
    { id:10078, word:'bicycle',translate:'велосипед',checked:false,blocked:false },
    { id:10079, word:'big',translate:'большой',checked:false,blocked:false },
    { id:10080, word:'bike',translate:'велосипед',checked:false,blocked:false },
    { id:10081, word:'bill',translate:'билл',checked:false,blocked:false },
    { id:10082, word:'bird',translate:'птица',checked:false,blocked:false },
    { id:10083, word:'birthday',translate:'день рождения',checked:false,blocked:false },
    { id:10084, word:'black',translate:'чёрный',checked:false,blocked:false },
    { id:10085, word:'blog',translate:'блог',checked:false,blocked:false },
    { id:10086, word:'blonde',translate:'блондинка',checked:false,blocked:false },
    { id:10087, word:'blue',translate:'синий',checked:false,blocked:false },
    { id:10088, word:'boat',translate:'лодка',checked:false,blocked:false },
    { id:10089, word:'body',translate:'тело',checked:false,blocked:false },
    { id:10090, word:'book',translate:'книга',checked:false,blocked:false },
    { id:10091, word:'boot',translate:'багажник',checked:false,blocked:false },
    { id:10092, word:'bored',translate:'скучно',checked:false,blocked:false },
    { id:10093, word:'boring',translate:'скучный',checked:false,blocked:false },
    { id:10094, word:'born',translate:'рожденный',checked:false,blocked:false },
    { id:10095, word:'both',translate:'оба',checked:false,blocked:false },
    { id:10096, word:'bottle',translate:'бутылка',checked:false,blocked:false },
    { id:10097, word:'box',translate:'коробка',checked:false,blocked:false },
    { id:10098, word:'boy',translate:'мальчик',checked:false,blocked:false },
    { id:10099, word:'boyfriend',translate:'парень',checked:false,blocked:false },
    { id:10100, word:'bread',translate:'хлеб',checked:false,blocked:false },
    { id:10101, word:'break',translate:'перерыв',checked:false,blocked:false },
    { id:10102, word:'breakfast',translate:'завтрак',checked:false,blocked:false },
    { id:10103, word:'bring',translate:'принести',checked:false,blocked:false },
    { id:10104, word:'brother',translate:'брат',checked:false,blocked:false },
    { id:10105, word:'brown',translate:'коричневый',checked:false,blocked:false },
    { id:10106, word:'build',translate:'построить',checked:false,blocked:false },
    { id:10107, word:'building',translate:'здание',checked:false,blocked:false },
    { id:10108, word:'bus',translate:'автобус',checked:false,blocked:false },
    { id:10109, word:'business',translate:'бизнес',checked:false,blocked:false },
    { id:10110, word:'busy',translate:'занят',checked:false,blocked:false },
    { id:10111, word:'but',translate:'но',checked:false,blocked:false },
    { id:10112, word:'butter',translate:'масло',checked:false,blocked:false },
    { id:10113, word:'buy',translate:'купить',checked:false,blocked:false },
    { id:10114, word:'by',translate:'по',checked:false,blocked:false },
    { id:10115, word:'bye',translate:'пока',checked:false,blocked:false },
    { id:10116, word:'cafe',translate:'кафе',checked:false,blocked:false },
    { id:10117, word:'cake',translate:'торт',checked:false,blocked:false },
    { id:10118, word:'call',translate:'звонок',checked:false,blocked:false },
    { id:10119, word:'camera',translate:'камера',checked:false,blocked:false },
    { id:10120, word:'can',translate:'можно',checked:false,blocked:false },
    { id:10121, word:'cannot',translate:'нельзя',checked:false,blocked:false },
    { id:10122, word:'capital',translate:'столица',checked:false,blocked:false },
    { id:10123, word:'car',translate:'автомобиль',checked:false,blocked:false },
    { id:10124, word:'card',translate:'карта',checked:false,blocked:false },
    { id:10125, word:'career',translate:'карьера',checked:false,blocked:false },
    { id:10126, word:'carrot',translate:'морковь',checked:false,blocked:false },
    { id:10127, word:'carry',translate:'переноска',checked:false,blocked:false },
    { id:10128, word:'cat',translate:'кот',checked:false,blocked:false },
    { id:10130, word:'cent',translate:'цент',checked:false,blocked:false },
    { id:10131, word:'centre',translate:'центр',checked:false,blocked:false },
    { id:10132, word:'century',translate:'век',checked:false,blocked:false },
    { id:10133, word:'chair',translate:'кресло',checked:false,blocked:false },
    { id:10134, word:'change',translate:'изменение',checked:false,blocked:false },
    { id:10135, word:'chart',translate:'график',checked:false,blocked:false },
    { id:10136, word:'cheap',translate:'дешево',checked:false,blocked:false },
    { id:10137, word:'check',translate:'проверка',checked:false,blocked:false },
    { id:10138, word:'cheese',translate:'сыр',checked:false,blocked:false },
    { id:10139, word:'chicken',translate:'курица',checked:false,blocked:false },
    { id:10140, word:'child',translate:'ребенок',checked:false,blocked:false },
    { id:10141, word:'chocolate',translate:'шоколад',checked:false,blocked:false },
    { id:10142, word:'choose',translate:'выбрать',checked:false,blocked:false },
    { id:10143, word:'cinema',translate:'кинотеатр',checked:false,blocked:false },
    { id:10144, word:'city',translate:'город',checked:false,blocked:false },
    { id:10145, word:'class',translate:'класс',checked:false,blocked:false },
    { id:10146, word:'classroom',translate:'класс',checked:false,blocked:false },
    { id:10147, word:'clean',translate:'чистить',checked:false,blocked:false },
    { id:10148, word:'climb',translate:'восхождение',checked:false,blocked:false },
    { id:10149, word:'clock',translate:'часы',checked:false,blocked:false },
    { id:10150, word:'close',translate:'закрывать',checked:false,blocked:false },
    { id:10151, word:'clothes',translate:'одежда',checked:false,blocked:false },
    { id:10152, word:'club',translate:'клуб',checked:false,blocked:false },
    { id:10153, word:'coat',translate:'пальто',checked:false,blocked:false },
    { id:10154, word:'coffee',translate:'кофе',checked:false,blocked:false },
    { id:10155, word:'cold',translate:'холод',checked:false,blocked:false },
    { id:10156, word:'college',translate:'колледж',checked:false,blocked:false },
    { id:10157, word:'colour',translate:'цвет',checked:false,blocked:false },
    { id:10158, word:'come',translate:'прийти',checked:false,blocked:false },
    { id:10159, word:'common',translate:'общий',checked:false,blocked:false },
    { id:10160, word:'company',translate:'компания',checked:false,blocked:false },
    { id:10161, word:'compare',translate:'сравнить',checked:false,blocked:false },
    { id:10162, word:'complete',translate:'полный',checked:false,blocked:false },
    { id:10163, word:'computer',translate:'компьютер',checked:false,blocked:false },
    { id:10164, word:'concert',translate:'концерт',checked:false,blocked:false },
    { id:10165, word:'conversation',translate:'разговор',checked:false,blocked:false },
    { id:10166, word:'cook',translate:'готовить',checked:false,blocked:false },
    { id:10167, word:'cooking',translate:'приготовление',checked:false,blocked:false },
    { id:10168, word:'cool',translate:'прохладный',checked:false,blocked:false },
    { id:10169, word:'correct',translate:'правильно',checked:false,blocked:false },
    { id:10170, word:'cost',translate:'стоимость',checked:false,blocked:false },
    { id:10171, word:'could',translate:'можно',checked:false,blocked:false },
    { id:10172, word:'country',translate:'страна',checked:false,blocked:false },
    { id:10173, word:'course',translate:'курс',checked:false,blocked:false },
    { id:10174, word:'cousin',translate:'двоюродный брат',checked:false,blocked:false },
    { id:10175, word:'cow',translate:'корова',checked:false,blocked:false },
    { id:10176, word:'cream',translate:'крем',checked:false,blocked:false },
    { id:10177, word:'create',translate:'создать',checked:false,blocked:false },
    { id:10178, word:'culture',translate:'культура',checked:false,blocked:false },
    { id:10179, word:'cup',translate:'чашка',checked:false,blocked:false },
    { id:10180, word:'customer',translate:'клиент',checked:false,blocked:false },
    { id:10181, word:'cut',translate:'вырезать',checked:false,blocked:false },
    { id:10182, word:'dad',translate:'отец',checked:false,blocked:false },
    { id:10183, word:'dance',translate:'танец',checked:false,blocked:false },
    { id:10184, word:'dancer',translate:'танцор',checked:false,blocked:false },
    { id:10185, word:'dancing',translate:'танец',checked:false,blocked:false },
    { id:10186, word:'dangerous',translate:'опасный',checked:false,blocked:false },
    { id:10187, word:'dark',translate:'темнота',checked:false,blocked:false },
    { id:10188, word:'date',translate:'свидание',checked:false,blocked:false },
    { id:10189, word:'daughter',translate:'дочь',checked:false,blocked:false },
    { id:10190, word:'day',translate:'день',checked:false,blocked:false },
    { id:10191, word:'dear',translate:'дорогая',checked:false,blocked:false },
    { id:10192, word:'December',translate:'декабрь',checked:false,blocked:false },
    { id:10193, word:'decide',translate:'решить',checked:false,blocked:false },
    { id:10194, word:'delicious',translate:'вкусно',checked:false,blocked:false },
    { id:10195, word:'describe',translate:'описать',checked:false,blocked:false },
    { id:10196, word:'description',translate:'описание',checked:false,blocked:false },
    { id:10197, word:'design',translate:'дизайн',checked:false,blocked:false },
    { id:10198, word:'desk',translate:'стол',checked:false,blocked:false },
    { id:10199, word:'detail',translate:'подробно',checked:false,blocked:false },
    { id:10200, word:'dialogue',translate:'диалог',checked:false,blocked:false },
    { id:10201, word:'dictionary',translate:'словарь',checked:false,blocked:false },
    { id:10202, word:'die',translate:'умереть',checked:false,blocked:false },
    { id:10203, word:'diet',translate:'диета',checked:false,blocked:false },
    { id:10204, word:'difference',translate:'разница',checked:false,blocked:false },
    { id:10205, word:'different',translate:'разное',checked:false,blocked:false },
    { id:10206, word:'difficult',translate:'трудный',checked:false,blocked:false },
    { id:10207, word:'dinner',translate:'ужин',checked:false,blocked:false },
    { id:10208, word:'dirty',translate:'грязный',checked:false,blocked:false },
    { id:10209, word:'discuss',translate:'обсудить',checked:false,blocked:false },
    { id:10210, word:'dish',translate:'блюдо',checked:false,blocked:false },
    { id:10211, word:'do',translate:'делать',checked:false,blocked:false },
    { id:10212, word:'doctor',translate:'врач',checked:false,blocked:false },
    { id:10213, word:'dog',translate:'собака',checked:false,blocked:false },
    { id:10214, word:'dollar',translate:'доллар',checked:false,blocked:false },
    { id:10215, word:'door',translate:'дверь',checked:false,blocked:false },
    { id:10216, word:'down',translate:'вниз',checked:false,blocked:false },
    { id:10217, word:'downstairs',translate:'вниз',checked:false,blocked:false },
    { id:10218, word:'draw',translate:'нарисовать',checked:false,blocked:false },
    { id:10219, word:'dress',translate:'платье',checked:false,blocked:false },
    { id:10220, word:'drink',translate:'пить',checked:false,blocked:false },
    { id:10221, word:'drive',translate:'водить',checked:false,blocked:false },
    { id:10222, word:'driver',translate:'водитель',checked:false,blocked:false },
    { id:10223, word:'during',translate:'во время',checked:false,blocked:false },
    { id:10225, word:'each',translate:'каждый',checked:false,blocked:false },
    { id:10226, word:'ear',translate:'ухо',checked:false,blocked:false },
    { id:10227, word:'early',translate:'рано',checked:false,blocked:false },
    { id:10228, word:'east',translate:'восток',checked:false,blocked:false },
    { id:10229, word:'easy',translate:'легко',checked:false,blocked:false },
    { id:10230, word:'eat',translate:'есть',checked:false,blocked:false },
    { id:10231, word:'egg',translate:'яйцо',checked:false,blocked:false },
    { id:10232, word:'eight',translate:'восемь',checked:false,blocked:false },
    { id:10233, word:'eighteen',translate:'восемнадцать',checked:false,blocked:false },
    { id:10234, word:'eighty',translate:'восемьдесят',checked:false,blocked:false },
    { id:10235, word:'elephant',translate:'слон',checked:false,blocked:false },
    { id:10236, word:'eleven',translate:'одиннадцать',checked:false,blocked:false },
    { id:10237, word:'else',translate:'еще',checked:false,blocked:false },
    { id:10238, word:'email',translate:'email',checked:false,blocked:false },
    { id:10239, word:'end',translate:'конец',checked:false,blocked:false },
    { id:10240, word:'enjoy',translate:'наслаждайтесь',checked:false,blocked:false },
    { id:10241, word:'enough',translate:'достаточно',checked:false,blocked:false },
    { id:10242, word:'euro',translate:'евро',checked:false,blocked:false },
    { id:10243, word:'even',translate:'даже',checked:false,blocked:false },
    { id:10244, word:'evening',translate:'вечер',checked:false,blocked:false },
    { id:10245, word:'event',translate:'мероприятие',checked:false,blocked:false },
    { id:10246, word:'ever',translate:'когда-либо',checked:false,blocked:false },
    { id:10247, word:'every',translate:'каждый',checked:false,blocked:false },
    { id:10248, word:'everybody',translate:'все',checked:false,blocked:false },
    { id:10249, word:'everyone',translate:'все',checked:false,blocked:false },
    { id:10250, word:'everything',translate:'всё',checked:false,blocked:false },
    { id:10251, word:'exam',translate:'экзамен',checked:false,blocked:false },
    { id:10252, word:'example',translate:'пример',checked:false,blocked:false },
    { id:10253, word:'excited',translate:'возбужденный',checked:false,blocked:false },
    { id:10254, word:'exciting',translate:'захватывающий',checked:false,blocked:false },
    { id:10255, word:'exercise',translate:'упражнение',checked:false,blocked:false },
    { id:10256, word:'expensive',translate:'дорого',checked:false,blocked:false },
    { id:10257, word:'explain',translate:'объяснить',checked:false,blocked:false },
    { id:10258, word:'extra',translate:'дополнительный',checked:false,blocked:false },
    { id:10259, word:'eye',translate:'глаз',checked:false,blocked:false },
    { id:10260, word:'face',translate:'лицо',checked:false,blocked:false },
    { id:10261, word:'fact',translate:'факт',checked:false,blocked:false },
    { id:10262, word:'fall',translate:'падение',checked:false,blocked:false },
    { id:10263, word:'false',translate:'ЛОЖЬ',checked:false,blocked:false },
    { id:10264, word:'family',translate:'семья',checked:false,blocked:false },
    { id:10265, word:'famous',translate:'знаменитость',checked:false,blocked:false },
    { id:10266, word:'fantastic',translate:'фантастика',checked:false,blocked:false },
    { id:10267, word:'far',translate:'далеко',checked:false,blocked:false },
    { id:10268, word:'farm',translate:'ферма',checked:false,blocked:false },
    { id:10269, word:'farmer',translate:'фермер',checked:false,blocked:false },
    { id:10270, word:'fast',translate:'быстро',checked:false,blocked:false },
    { id:10271, word:'fat',translate:'толстый',checked:false,blocked:false },
    { id:10272, word:'father',translate:'отец',checked:false,blocked:false },
    { id:10273, word:'favourite',translate:'любимый',checked:false,blocked:false },
    { id:10274, word:'February',translate:'февраль',checked:false,blocked:false },
    { id:10275, word:'feel',translate:'чувствовать себя',checked:false,blocked:false },
    { id:10276, word:'feeling',translate:'чувство',checked:false,blocked:false },
    { id:10277, word:'festival',translate:'фестиваль',checked:false,blocked:false },
    { id:10278, word:'few',translate:'несколько',checked:false,blocked:false },
    { id:10279, word:'fifteen',translate:'пятнадцать',checked:false,blocked:false },
    { id:10280, word:'fifth',translate:'пятый',checked:false,blocked:false },
    { id:10281, word:'fifty',translate:'пятьдесят',checked:false,blocked:false },
    { id:10282, word:'fill',translate:'заполнить',checked:false,blocked:false },
    { id:10283, word:'film',translate:'фильм',checked:false,blocked:false },
    { id:10284, word:'final',translate:'финал',checked:false,blocked:false },
    { id:10285, word:'find',translate:'найти',checked:false,blocked:false },
    { id:10286, word:'fine',translate:'штраф',checked:false,blocked:false },
    { id:10287, word:'finish',translate:'закончить',checked:false,blocked:false },
    { id:10288, word:'fire',translate:'огонь',checked:false,blocked:false },
    { id:10289, word:'first',translate:'первый',checked:false,blocked:false },
    { id:10290, word:'fish',translate:'рыба',checked:false,blocked:false },
    { id:10291, word:'five',translate:'пять',checked:false,blocked:false },
    { id:10292, word:'flat',translate:'квартира',checked:false,blocked:false },
    { id:10293, word:'flight',translate:'рейс',checked:false,blocked:false },
    { id:10294, word:'floor',translate:'пол',checked:false,blocked:false },
    { id:10295, word:'flower',translate:'цветок',checked:false,blocked:false },
    { id:10296, word:'fly',translate:'летать',checked:false,blocked:false },
    { id:10297, word:'follow',translate:'следовать',checked:false,blocked:false },
    { id:10298, word:'food',translate:'еда',checked:false,blocked:false },
    { id:10299, word:'foot',translate:'нога',checked:false,blocked:false },
    { id:10300, word:'football',translate:'футбол',checked:false,blocked:false },
    { id:10301, word:'for',translate:'для',checked:false,blocked:false },
    { id:10302, word:'forget',translate:'забыть',checked:false,blocked:false },
    { id:10303, word:'form',translate:'форма',checked:false,blocked:false },
    { id:10304, word:'forty',translate:'сорок',checked:false,blocked:false },
    { id:10305, word:'four',translate:'четыре',checked:false,blocked:false },
    { id:10306, word:'fourteen',translate:'четырнадцать',checked:false,blocked:false },
    { id:10307, word:'fourth',translate:'четвертый',checked:false,blocked:false },
    { id:10308, word:'free',translate:'бесплатно',checked:false,blocked:false },
    { id:10309, word:'Friday',translate:'Пятница',checked:false,blocked:false },
    { id:10310, word:'friend',translate:'друг',checked:false,blocked:false },
    { id:10311, word:'friendly',translate:'дружеский',checked:false,blocked:false },
    { id:10312, word:'from',translate:'из',checked:false,blocked:false },
    { id:10313, word:'front',translate:'фронт',checked:false,blocked:false },
    { id:10314, word:'fruit',translate:'фрукты',checked:false,blocked:false },
    { id:10315, word:'full',translate:'полный',checked:false,blocked:false },
    { id:10316, word:'fun',translate:'веселье',checked:false,blocked:false },
    { id:10317, word:'funny',translate:'смешно',checked:false,blocked:false },
    { id:10318, word:'future',translate:'будущее',checked:false,blocked:false },
    { id:10319, word:'game',translate:'игра',checked:false,blocked:false },
    { id:10320, word:'garden',translate:'сад',checked:false,blocked:false },
    { id:10321, word:'geography',translate:'география',checked:false,blocked:false },
    { id:10322, word:'get',translate:'получить',checked:false,blocked:false },
    { id:10323, word:'girl',translate:'девушка',checked:false,blocked:false },
    { id:10324, word:'girlfriend',translate:'девушка',checked:false,blocked:false },
    { id:10325, word:'give',translate:'дать',checked:false,blocked:false },
    { id:10326, word:'glass',translate:'стекло',checked:false,blocked:false },
    { id:10327, word:'go',translate:'перейти',checked:false,blocked:false },
    { id:10328, word:'good',translate:'хорошо',checked:false,blocked:false },
    { id:10329, word:'goodbye',translate:'прощай',checked:false,blocked:false },
    { id:10330, word:'grandfather',translate:'дедушка',checked:false,blocked:false },
    { id:10331, word:'grandmother',translate:'бабушка',checked:false,blocked:false },
    { id:10332, word:'grandparent',translate:'дедушка и бабушка',checked:false,blocked:false },
    { id:10333, word:'great',translate:'великий',checked:false,blocked:false },
    { id:10334, word:'green',translate:'зеленый',checked:false,blocked:false },
    { id:10335, word:'grey',translate:'серый',checked:false,blocked:false },
    { id:10336, word:'group',translate:'группа',checked:false,blocked:false },
    { id:10337, word:'grow',translate:'расти',checked:false,blocked:false },
    { id:10338, word:'guess',translate:'угадать',checked:false,blocked:false },
    { id:10339, word:'guitar',translate:'гитара',checked:false,blocked:false },
    { id:10340, word:'gym',translate:'спортзал',checked:false,blocked:false },
    { id:10341, word:'hair',translate:'волосы',checked:false,blocked:false },
    { id:10342, word:'half',translate:'половина',checked:false,blocked:false },
    { id:10343, word:'hand',translate:'рука',checked:false,blocked:false },
    { id:10344, word:'happen',translate:'случиться',checked:false,blocked:false },
    { id:10345, word:'happy',translate:'счастье',checked:false,blocked:false },
    { id:10346, word:'hard',translate:'твердый',checked:false,blocked:false },
    { id:10347, word:'hat',translate:'шляпа',checked:false,blocked:false },
    { id:10348, word:'hate',translate:'ненавидеть',checked:false,blocked:false },
    { id:10349, word:'have',translate:'иметь',checked:false,blocked:false },
    { id:10350, word:'have',translate:'иметь',checked:false,blocked:false },
    { id:10351, word:'he',translate:'он',checked:false,blocked:false },
    { id:10352, word:'head',translate:'голова',checked:false,blocked:false },
    { id:10353, word:'health',translate:'здоровье',checked:false,blocked:false },
    { id:10354, word:'healthy',translate:'здоровый',checked:false,blocked:false },
    { id:10355, word:'hear',translate:'слышать',checked:false,blocked:false },
    { id:10356, word:'hello',translate:'здравствуйте',checked:false,blocked:false },
    { id:10357, word:'help',translate:'помощь',checked:false,blocked:false },
    { id:10358, word:'her',translate:'она',checked:false,blocked:false },
    { id:10359, word:'here',translate:'здесь',checked:false,blocked:false },
    { id:10360, word:'hey',translate:'Привет',checked:false,blocked:false },
    { id:10361, word:'hi',translate:'привет',checked:false,blocked:false },
    { id:10362, word:'high',translate:'высоко',checked:false,blocked:false },
    { id:10363, word:'him',translate:'он',checked:false,blocked:false },
    { id:10364, word:'his',translate:'его',checked:false,blocked:false },
    { id:10365, word:'history',translate:'история',checked:false,blocked:false },
    { id:10366, word:'hobby',translate:'хобби',checked:false,blocked:false },
    { id:10367, word:'holiday',translate:'праздник',checked:false,blocked:false },
    { id:10368, word:'home',translate:'дом',checked:false,blocked:false },
    { id:10369, word:'homework',translate:'домашняя работа',checked:false,blocked:false },
    { id:10370, word:'hope',translate:'надежда',checked:false,blocked:false },
    { id:10371, word:'horse',translate:'лошадь',checked:false,blocked:false },
    { id:10372, word:'hospital',translate:'больница',checked:false,blocked:false },
    { id:10373, word:'hot',translate:'горячий',checked:false,blocked:false },
    { id:10374, word:'hotel',translate:'отель',checked:false,blocked:false },
    { id:10375, word:'hour',translate:'час',checked:false,blocked:false },
    { id:10376, word:'house',translate:'дом',checked:false,blocked:false },
    { id:10377, word:'how',translate:'как',checked:false,blocked:false },
    { id:10378, word:'however',translate:'однако',checked:false,blocked:false },
    { id:10379, word:'hundred',translate:'сто',checked:false,blocked:false },
    { id:10380, word:'hungry',translate:'голодный',checked:false,blocked:false },
    { id:10381, word:'husband',translate:'муж',checked:false,blocked:false },
    { id:10382, word:'I',translate:'я',checked:false,blocked:false },
    { id:10383, word:'ice',translate:'лед',checked:false,blocked:false },
    { id:10385, word:'idea',translate:'идея',checked:false,blocked:false },
    { id:10386, word:'if',translate:'если',checked:false,blocked:false },
    { id:10387, word:'imagine',translate:'представить',checked:false,blocked:false },
    { id:10388, word:'important',translate:'важно',checked:false,blocked:false },
    { id:10389, word:'improve',translate:'улучшить',checked:false,blocked:false },
    { id:10390, word:'in',translate:'в',checked:false,blocked:false },
    { id:10391, word:'include',translate:'включать',checked:false,blocked:false },
    { id:10392, word:'information',translate:'информация',checked:false,blocked:false },
    { id:10393, word:'interest',translate:'интерес',checked:false,blocked:false },
    { id:10394, word:'interested',translate:'интересный',checked:false,blocked:false },
    { id:10395, word:'interesting',translate:'интересный',checked:false,blocked:false },
    { id:10396, word:'internet',translate:'интернет',checked:false,blocked:false },
    { id:10397, word:'interview',translate:'интервью',checked:false,blocked:false },
    { id:10398, word:'into',translate:'в',checked:false,blocked:false },
    { id:10399, word:'introduce',translate:'представить',checked:false,blocked:false },
    { id:10400, word:'island',translate:'остров',checked:false,blocked:false },
    { id:10401, word:'it',translate:'это',checked:false,blocked:false },
    { id:10402, word:'its',translate:'его',checked:false,blocked:false },
    { id:10403, word:'jacket',translate:'куртка',checked:false,blocked:false },
    { id:10404, word:'January',translate:'Январь',checked:false,blocked:false },
    { id:10405, word:'jeans',translate:'джинсы',checked:false,blocked:false },
    { id:10406, word:'job',translate:'работа',checked:false,blocked:false },
    { id:10407, word:'join',translate:'присоединиться',checked:false,blocked:false },
    { id:10408, word:'journey',translate:'путешествие',checked:false,blocked:false },
    { id:10409, word:'juice',translate:'сок',checked:false,blocked:false },
    { id:10410, word:'July',translate:'Июль',checked:false,blocked:false },
    { id:10411, word:'June',translate:'июнь',checked:false,blocked:false },
    { id:10412, word:'just',translate:'просто',checked:false,blocked:false },
    { id:10413, word:'keep',translate:'держать',checked:false,blocked:false },
    { id:10414, word:'key',translate:'ключ',checked:false,blocked:false },
    { id:10415, word:'kilometre',translate:'километр',checked:false,blocked:false },
    { id:10416, word:'kind',translate:'добрый',checked:false,blocked:false },
    { id:10417, word:'kitchen',translate:'кухня',checked:false,blocked:false },
    { id:10418, word:'know',translate:'знать',checked:false,blocked:false },
    { id:10419, word:'land',translate:'земля',checked:false,blocked:false },
    { id:10420, word:'language',translate:'язык',checked:false,blocked:false },
    { id:10421, word:'large',translate:'большой',checked:false,blocked:false },
    { id:10422, word:'last',translate:'последний',checked:false,blocked:false },
    { id:10423, word:'late',translate:'поздний',checked:false,blocked:false },
    { id:10424, word:'later',translate:'позже',checked:false,blocked:false },
    { id:10425, word:'laugh',translate:'смеяться',checked:false,blocked:false },
    { id:10426, word:'learn',translate:'учиться',checked:false,blocked:false },
    { id:10427, word:'leave',translate:'оставить',checked:false,blocked:false },
    { id:10428, word:'left',translate:'левый',checked:false,blocked:false },
    { id:10429, word:'leg',translate:'нога',checked:false,blocked:false },
    { id:10430, word:'lesson',translate:'урок',checked:false,blocked:false },
    { id:10431, word:'let',translate:'позволить',checked:false,blocked:false },
    { id:10432, word:'letter',translate:'письмо',checked:false,blocked:false },
    { id:10433, word:'library',translate:'библиотека',checked:false,blocked:false },
    { id:10434, word:'lie',translate:'ложь',checked:false,blocked:false },
    { id:10435, word:'life',translate:'жизнь',checked:false,blocked:false },
    { id:10436, word:'light',translate:'свет',checked:false,blocked:false },
    { id:10437, word:'like',translate:'как',checked:false,blocked:false },
    { id:10438, word:'like',translate:'как',checked:false,blocked:false },
    { id:10439, word:'line',translate:'линия',checked:false,blocked:false },
    { id:10440, word:'lion',translate:'лев',checked:false,blocked:false },
    { id:10441, word:'list',translate:'список',checked:false,blocked:false },
    { id:10442, word:'listen',translate:'слушать',checked:false,blocked:false },
    { id:10443, word:'little',translate:'маленький',checked:false,blocked:false },
    { id:10444, word:'live1',translate:'жить1',checked:false,blocked:false },
    { id:10445, word:'local',translate:'местный',checked:false,blocked:false },
    { id:10446, word:'long',translate:'длинный',checked:false,blocked:false },
    { id:10447, word:'look',translate:'смотреть',checked:false,blocked:false },
    { id:10448, word:'lose',translate:'потерять',checked:false,blocked:false },
    { id:10449, word:'lot',translate:'много',checked:false,blocked:false },
    { id:10450, word:'love',translate:'любовь',checked:false,blocked:false },
    { id:10451, word:'lunch',translate:'обед',checked:false,blocked:false },
    { id:10452, word:'machine',translate:'машина',checked:false,blocked:false },
    { id:10453, word:'magazine',translate:'журнал',checked:false,blocked:false },
    { id:10454, word:'main',translate:'главная',checked:false,blocked:false },
    { id:10455, word:'make',translate:'сделать',checked:false,blocked:false },
    { id:10456, word:'man',translate:'человек',checked:false,blocked:false },
    { id:10457, word:'many',translate:'много',checked:false,blocked:false },
    { id:10458, word:'map',translate:'карта',checked:false,blocked:false },
    { id:10459, word:'March',translate:'март',checked:false,blocked:false },
    { id:10460, word:'market',translate:'рынок',checked:false,blocked:false },
    { id:10461, word:'married',translate:'женат',checked:false,blocked:false },
    { id:10462, word:'match',translate:'матч',checked:false,blocked:false },
    { id:10463, word:'May',translate:'май',checked:false,blocked:false },
    { id:10464, word:'maybe',translate:'возможно',checked:false,blocked:false },
    { id:10465, word:'me',translate:'я',checked:false,blocked:false },
    { id:10466, word:'meal',translate:'еда',checked:false,blocked:false },
    { id:10467, word:'mean',translate:'значение',checked:false,blocked:false },
    { id:10468, word:'meaning',translate:'значение',checked:false,blocked:false },
    { id:10469, word:'meat',translate:'мясо',checked:false,blocked:false },
    { id:10470, word:'meet',translate:'встретиться',checked:false,blocked:false },
    { id:10471, word:'meeting',translate:'встреча',checked:false,blocked:false },
    { id:10472, word:'member',translate:'член',checked:false,blocked:false },
    { id:10473, word:'menu',translate:'меню',checked:false,blocked:false },
    { id:10474, word:'message',translate:'сообщение',checked:false,blocked:false },
    { id:10475, word:'metre',translate:'метр',checked:false,blocked:false },
    { id:10476, word:'midnight',translate:'полночь',checked:false,blocked:false },
    { id:10477, word:'mile',translate:'миля',checked:false,blocked:false },
    { id:10478, word:'milk',translate:'молоко',checked:false,blocked:false },
    { id:10479, word:'million',translate:'миллион',checked:false,blocked:false },
    { id:10480, word:'minute',translate:'минута',checked:false,blocked:false },
    { id:10481, word:'miss',translate:'мисс',checked:false,blocked:false },
    { id:10482, word:'mistake',translate:'ошибка',checked:false,blocked:false },
    { id:10483, word:'model',translate:'модель',checked:false,blocked:false },
    { id:10484, word:'modern',translate:'современный',checked:false,blocked:false },
    { id:10485, word:'moment',translate:'момент',checked:false,blocked:false },
    { id:10486, word:'Monday',translate:'Понедельник',checked:false,blocked:false },
    { id:10487, word:'money',translate:'деньги',checked:false,blocked:false },
    { id:10488, word:'month',translate:'месяц',checked:false,blocked:false },
    { id:10489, word:'more',translate:'больше',checked:false,blocked:false },
    { id:10490, word:'morning',translate:'утро',checked:false,blocked:false },
    { id:10491, word:'most',translate:'самый',checked:false,blocked:false },
    { id:10492, word:'mother',translate:'мать',checked:false,blocked:false },
    { id:10493, word:'mountain',translate:'гора',checked:false,blocked:false },
    { id:10494, word:'mouse',translate:'мышь',checked:false,blocked:false },
    { id:10495, word:'mouth',translate:'рот',checked:false,blocked:false },
    { id:10496, word:'move',translate:'переезд',checked:false,blocked:false },
    { id:10497, word:'movie',translate:'фильм',checked:false,blocked:false },
    { id:10498, word:'much',translate:'много',checked:false,blocked:false },
    { id:10499, word:'mum',translate:'мама',checked:false,blocked:false },
    { id:10500, word:'museum',translate:'музей',checked:false,blocked:false },
    { id:10501, word:'music',translate:'музыка',checked:false,blocked:false },
    { id:10502, word:'must',translate:'надо',checked:false,blocked:false },
    { id:10503, word:'my',translate:'мой',checked:false,blocked:false },
    { id:10504, word:'name',translate:'имя',checked:false,blocked:false },
    { id:10505, word:'natural',translate:'природа',checked:false,blocked:false },
    { id:10506, word:'near',translate:'рядом',checked:false,blocked:false },
    { id:10507, word:'need',translate:'нужно',checked:false,blocked:false },
    { id:10508, word:'negative',translate:'отрицательный',checked:false,blocked:false },
    { id:10509, word:'neighbour',translate:'сосед',checked:false,blocked:false },
    { id:10510, word:'never',translate:'никогда',checked:false,blocked:false },
    { id:10511, word:'new',translate:'новый',checked:false,blocked:false },
    { id:10512, word:'news',translate:'новости',checked:false,blocked:false },
    { id:10513, word:'newspaper',translate:'газета',checked:false,blocked:false },
    { id:10514, word:'next',translate:'следующий',checked:false,blocked:false },
    { id:10515, word:'next',translate:'следующий',checked:false,blocked:false },
    { id:10516, word:'nice',translate:'мило',checked:false,blocked:false },
    { id:10517, word:'night',translate:'ночь',checked:false,blocked:false },
    { id:10518, word:'nine',translate:'девять',checked:false,blocked:false },
    { id:10519, word:'nineteen',translate:'девятнадцать',checked:false,blocked:false },
    { id:10520, word:'ninety',translate:'девяносто',checked:false,blocked:false },
    { id:10521, word:'no',translate:'нет',checked:false,blocked:false },
    { id:10523, word:'nobody',translate:'никто',checked:false,blocked:false },
    { id:10524, word:'north',translate:'север',checked:false,blocked:false },
    { id:10525, word:'nose',translate:'нос',checked:false,blocked:false },
    { id:10526, word:'not',translate:'не',checked:false,blocked:false },
    { id:10527, word:'note',translate:'нота',checked:false,blocked:false },
    { id:10528, word:'nothing',translate:'ничего',checked:false,blocked:false },
    { id:10529, word:'November',translate:'ноябрь',checked:false,blocked:false },
    { id:10530, word:'now',translate:'сейчас',checked:false,blocked:false },
    { id:10531, word:'number',translate:'номер',checked:false,blocked:false },
    { id:10532, word:'nurse',translate:'медсестра',checked:false,blocked:false },
    { id:10533, word:'object',translate:'объект',checked:false,blocked:false },
    { id:10535, word:'October',translate:'октябрь',checked:false,blocked:false },
    { id:10536, word:'of',translate:'из',checked:false,blocked:false },
    { id:10537, word:'off',translate:'выходной',checked:false,blocked:false },
    { id:10538, word:'office',translate:'офис',checked:false,blocked:false },
    { id:10539, word:'often',translate:'часто',checked:false,blocked:false },
    { id:10540, word:'oh',translate:'о',checked:false,blocked:false },
    { id:10542, word:'old',translate:'старый',checked:false,blocked:false },
    { id:10543, word:'on',translate:'на',checked:false,blocked:false },
    { id:10544, word:'once',translate:'однажды',checked:false,blocked:false },
    { id:10545, word:'one',translate:'один',checked:false,blocked:false },
    { id:10546, word:'onion',translate:'лук',checked:false,blocked:false },
    { id:10547, word:'online',translate:'онлайн',checked:false,blocked:false },
    { id:10548, word:'only',translate:'только',checked:false,blocked:false },
    { id:10549, word:'open',translate:'открыть',checked:false,blocked:false },
    { id:10550, word:'opinion',translate:'мнение',checked:false,blocked:false },
    { id:10551, word:'opposite',translate:'противоположное',checked:false,blocked:false },
    { id:10552, word:'or',translate:'или',checked:false,blocked:false },
    { id:10553, word:'orange',translate:'оранжевый',checked:false,blocked:false },
    { id:10554, word:'order',translate:'приказ',checked:false,blocked:false },
    { id:10555, word:'other',translate:'другой',checked:false,blocked:false },
    { id:10556, word:'our',translate:'наш',checked:false,blocked:false },
    { id:10557, word:'out',translate:'снаружи',checked:false,blocked:false },
    { id:10558, word:'outside',translate:'снаружи',checked:false,blocked:false },
    { id:10559, word:'over',translate:'через',checked:false,blocked:false },
    { id:10560, word:'own',translate:'собственный',checked:false,blocked:false },
    { id:10561, word:'page',translate:'страница',checked:false,blocked:false },
    { id:10562, word:'paint',translate:'краска',checked:false,blocked:false },
    { id:10563, word:'painting',translate:'рисование',checked:false,blocked:false },
    { id:10564, word:'pair',translate:'пара',checked:false,blocked:false },
    { id:10565, word:'paper',translate:'бумага',checked:false,blocked:false },
    { id:10566, word:'paragraph',translate:'параграф',checked:false,blocked:false },
    { id:10567, word:'parent',translate:'родитель',checked:false,blocked:false },
    { id:10568, word:'park',translate:'парк',checked:false,blocked:false },
    { id:10569, word:'part',translate:'часть',checked:false,blocked:false },
    { id:10570, word:'partner',translate:'партнер',checked:false,blocked:false },
    { id:10571, word:'party',translate:'партия',checked:false,blocked:false },
    { id:10572, word:'passport',translate:'паспорт',checked:false,blocked:false },
    { id:10573, word:'past',translate:'прошлое',checked:false,blocked:false },
    { id:10574, word:'pay',translate:'платить',checked:false,blocked:false },
    { id:10575, word:'pen',translate:'ручка',checked:false,blocked:false },
    { id:10576, word:'pencil',translate:'карандаш',checked:false,blocked:false },
    { id:10577, word:'people',translate:'люди',checked:false,blocked:false },
    { id:10578, word:'pepper',translate:'перец',checked:false,blocked:false },
    { id:10579, word:'perfect',translate:'идеальный',checked:false,blocked:false },
    { id:10580, word:'period',translate:'период',checked:false,blocked:false },
    { id:10581, word:'person',translate:'человек',checked:false,blocked:false },
    { id:10582, word:'personal',translate:'персональный',checked:false,blocked:false },
    { id:10583, word:'phone',translate:'телефон',checked:false,blocked:false },
    { id:10584, word:'photo',translate:'фото',checked:false,blocked:false },
    { id:10585, word:'photograph',translate:'фотография',checked:false,blocked:false },
    { id:10586, word:'phrase',translate:'фраза',checked:false,blocked:false },
    { id:10587, word:'piano',translate:'фортепиано',checked:false,blocked:false },
    { id:10588, word:'picture',translate:'фотография',checked:false,blocked:false },
    { id:10589, word:'piece',translate:'фрагмент',checked:false,blocked:false },
    { id:10590, word:'pig',translate:'свинья',checked:false,blocked:false },
    { id:10591, word:'pink',translate:'розовый',checked:false,blocked:false },
    { id:10592, word:'place',translate:'место',checked:false,blocked:false },
    { id:10593, word:'plan',translate:'план',checked:false,blocked:false },
    { id:10594, word:'plane',translate:'самолет',checked:false,blocked:false },
    { id:10595, word:'plant',translate:'растение',checked:false,blocked:false },
    { id:10596, word:'play',translate:'играть',checked:false,blocked:false },
    { id:10597, word:'player',translate:'игрок',checked:false,blocked:false },
    { id:10598, word:'please',translate:'пожалуйста',checked:false,blocked:false },
    { id:10599, word:'point',translate:'точка',checked:false,blocked:false },
    { id:10600, word:'police',translate:'полиция',checked:false,blocked:false },
    { id:10601, word:'policeman',translate:'полицейский',checked:false,blocked:false },
    { id:10602, word:'pool',translate:'бассейн',checked:false,blocked:false },
    { id:10603, word:'poor',translate:'бедный',checked:false,blocked:false },
    { id:10604, word:'popular',translate:'популярный',checked:false,blocked:false },
    { id:10605, word:'positive',translate:'положительный',checked:false,blocked:false },
    { id:10606, word:'possible',translate:'возможно',checked:false,blocked:false },
    { id:10607, word:'post',translate:'пост',checked:false,blocked:false },
    { id:10608, word:'potato',translate:'картофель',checked:false,blocked:false },
    { id:10609, word:'pound',translate:'фунт',checked:false,blocked:false },
    { id:10610, word:'practice',translate:'практиковать',checked:false,blocked:false },
    { id:10611, word:'practise',translate:'практиковать',checked:false,blocked:false },
    { id:10612, word:'prefer',translate:'предпочесть',checked:false,blocked:false },
    { id:10613, word:'prepare',translate:'подготовиться',checked:false,blocked:false },
    { id:10614, word:'present',translate:'представить',checked:false,blocked:false },
    { id:10615, word:'pretty',translate:'красивый',checked:false,blocked:false },
    { id:10616, word:'price',translate:'цена',checked:false,blocked:false },
    { id:10617, word:'probably',translate:'возможно',checked:false,blocked:false },
    { id:10618, word:'problem',translate:'проблема',checked:false,blocked:false },
    { id:10619, word:'product',translate:'продукт',checked:false,blocked:false },
    { id:10620, word:'programme',translate:'программа',checked:false,blocked:false },
    { id:10621, word:'project',translate:'проект',checked:false,blocked:false },
    { id:10622, word:'purple',translate:'фиолетовый',checked:false,blocked:false },
    { id:10623, word:'put',translate:'поставить',checked:false,blocked:false },
    { id:10624, word:'quarter',translate:'квартал',checked:false,blocked:false },
    { id:10625, word:'question',translate:'вопрос',checked:false,blocked:false },
    { id:10626, word:'quick',translate:'быстро',checked:false,blocked:false },
    { id:10627, word:'quickly',translate:'быстро',checked:false,blocked:false },
    { id:10628, word:'quiet',translate:'тихо',checked:false,blocked:false },
    { id:10629, word:'quite',translate:'довольно',checked:false,blocked:false },
    { id:10630, word:'radio',translate:'радио',checked:false,blocked:false },
    { id:10631, word:'rain',translate:'дождь',checked:false,blocked:false },
    { id:10632, word:'read',translate:'читать',checked:false,blocked:false },
    { id:10633, word:'reader',translate:'читатель',checked:false,blocked:false },
    { id:10634, word:'reading',translate:'чтение',checked:false,blocked:false },
    { id:10635, word:'ready',translate:'готово',checked:false,blocked:false },
    { id:10636, word:'real',translate:'настоящий',checked:false,blocked:false },
    { id:10637, word:'really',translate:'действительно',checked:false,blocked:false },
    { id:10638, word:'reason',translate:'причина',checked:false,blocked:false },
    { id:10639, word:'red',translate:'красный',checked:false,blocked:false },
    { id:10640, word:'relax',translate:'расслабиться',checked:false,blocked:false },
    { id:10641, word:'remember',translate:'помнить',checked:false,blocked:false },
    { id:10642, word:'repeat',translate:'повторить',checked:false,blocked:false },
    { id:10643, word:'report',translate:'отчет',checked:false,blocked:false },
    { id:10644, word:'restaurant',translate:'ресторан',checked:false,blocked:false },
    { id:10645, word:'result',translate:'результат',checked:false,blocked:false },
    { id:10646, word:'return',translate:'возврат',checked:false,blocked:false },
    { id:10647, word:'rice',translate:'рис',checked:false,blocked:false },
    { id:10648, word:'rich',translate:'богатый',checked:false,blocked:false },
    { id:10649, word:'ride',translate:'поездка',checked:false,blocked:false },
    { id:10650, word:'right',translate:'право',checked:false,blocked:false },
    { id:10651, word:'river',translate:'река',checked:false,blocked:false },
    { id:10652, word:'road',translate:'дорога',checked:false,blocked:false },
    { id:10653, word:'room',translate:'комната',checked:false,blocked:false },
    { id:10654, word:'routine',translate:'рутина',checked:false,blocked:false },
    { id:10655, word:'rule',translate:'правило',checked:false,blocked:false },
    { id:10656, word:'run',translate:'бежать',checked:false,blocked:false },
    { id:10657, word:'sad',translate:'грусть',checked:false,blocked:false },
    { id:10658, word:'salad',translate:'салат',checked:false,blocked:false },
    { id:10659, word:'salt',translate:'соль',checked:false,blocked:false },
    { id:10660, word:'same',translate:'тот же',checked:false,blocked:false },
    { id:10661, word:'sandwich',translate:'бутерброд',checked:false,blocked:false },
    { id:10662, word:'Saturday',translate:'Суббота',checked:false,blocked:false },
    { id:10663, word:'say',translate:'сказать',checked:false,blocked:false },
    { id:10664, word:'school',translate:'школа',checked:false,blocked:false },
    { id:10665, word:'science',translate:'наука',checked:false,blocked:false },
    { id:10666, word:'scientist',translate:'ученый',checked:false,blocked:false },
    { id:10667, word:'sea',translate:'море',checked:false,blocked:false },
    { id:10668, word:'second',translate:'второй',checked:false,blocked:false },
    { id:10669, word:'number',translate:'число',checked:false,blocked:false },
    { id:10671, word:'section',translate:'раздел',checked:false,blocked:false },
    { id:10672, word:'see',translate:'смотреть',checked:false,blocked:false },
    { id:10673, word:'sell',translate:'продать',checked:false,blocked:false },
    { id:10674, word:'send',translate:'отправить',checked:false,blocked:false },
    { id:10675, word:'sentence',translate:'предложение',checked:false,blocked:false },
    { id:10676, word:'September',translate:'Сентябрь',checked:false,blocked:false },
    { id:10677, word:'seven',translate:'семь',checked:false,blocked:false },
    { id:10678, word:'seventeen',translate:'семнадцать',checked:false,blocked:false },
    { id:10679, word:'seventy',translate:'семьдесят',checked:false,blocked:false },
    { id:10680, word:'share',translate:'поделиться',checked:false,blocked:false },
    { id:10681, word:'she',translate:'она',checked:false,blocked:false },
    { id:10682, word:'sheep',translate:'овца',checked:false,blocked:false },
    { id:10683, word:'shirt',translate:'рубашка',checked:false,blocked:false },
    { id:10684, word:'shoe',translate:'обувь',checked:false,blocked:false },
    { id:10685, word:'shop',translate:'магазин',checked:false,blocked:false },
    { id:10686, word:'shopping',translate:'магазин',checked:false,blocked:false },
    { id:10687, word:'short',translate:'короткая',checked:false,blocked:false },
    { id:10688, word:'should',translate:'следует',checked:false,blocked:false },
    { id:10689, word:'show',translate:'показать',checked:false,blocked:false },
    { id:10690, word:'shower',translate:'душ',checked:false,blocked:false },
    { id:10691, word:'sick',translate:'больной',checked:false,blocked:false },
    { id:10692, word:'similar',translate:'подобный',checked:false,blocked:false },
    { id:10693, word:'sing',translate:'петь',checked:false,blocked:false },
    { id:10694, word:'singer',translate:'певец',checked:false,blocked:false },
    { id:10695, word:'sister',translate:'сестра',checked:false,blocked:false },
    { id:10696, word:'sit',translate:'сидеть',checked:false,blocked:false },
    { id:10697, word:'situation',translate:'ситуация',checked:false,blocked:false },
    { id:10698, word:'six',translate:'шесть',checked:false,blocked:false },
    { id:10699, word:'sixteen',translate:'шестнадцать',checked:false,blocked:false },
    { id:10700, word:'sixty',translate:'шестьдесят',checked:false,blocked:false },
    { id:10701, word:'skill',translate:'навык',checked:false,blocked:false },
    { id:10702, word:'skirt',translate:'юбка',checked:false,blocked:false },
    { id:10703, word:'sleep',translate:'сон',checked:false,blocked:false },
    { id:10704, word:'slow',translate:'медленный',checked:false,blocked:false },
    { id:10705, word:'small',translate:'маленький',checked:false,blocked:false },
    { id:10706, word:'snake',translate:'змея',checked:false,blocked:false },
    { id:10707, word:'snow',translate:'снег',checked:false,blocked:false },
    { id:10708, word:'so',translate:'так',checked:false,blocked:false },
    { id:10709, word:'some',translate:'некоторые',checked:false,blocked:false },
    { id:10710, word:'somebody',translate:'кто-то',checked:false,blocked:false },
    { id:10711, word:'someone',translate:'кто-то',checked:false,blocked:false },
    { id:10712, word:'something',translate:'что-то',checked:false,blocked:false },
    { id:10713, word:'sometimes',translate:'иногда',checked:false,blocked:false },
    { id:10714, word:'son',translate:'сын',checked:false,blocked:false },
    { id:10715, word:'song',translate:'песня',checked:false,blocked:false },
    { id:10716, word:'soon',translate:'скоро',checked:false,blocked:false },
    { id:10717, word:'sorry',translate:'простите',checked:false,blocked:false },
    { id:10718, word:'sound',translate:'звук',checked:false,blocked:false },
    { id:10719, word:'soup',translate:'суп',checked:false,blocked:false },
    { id:10720, word:'south',translate:'юг',checked:false,blocked:false },
    { id:10721, word:'space',translate:'космос',checked:false,blocked:false },
    { id:10722, word:'speak',translate:'говорить',checked:false,blocked:false },
    { id:10723, word:'special',translate:'специальный',checked:false,blocked:false },
    { id:10724, word:'spell',translate:'заклинание',checked:false,blocked:false },
    { id:10725, word:'spelling',translate:'правописание',checked:false,blocked:false },
    { id:10726, word:'spend',translate:'тратить',checked:false,blocked:false },
    { id:10727, word:'sport',translate:'спорт',checked:false,blocked:false },
    { id:10728, word:'spring',translate:'весна',checked:false,blocked:false },
    { id:10729, word:'stand',translate:'стоять',checked:false,blocked:false },
    { id:10730, word:'star',translate:'звезда',checked:false,blocked:false },
    { id:10731, word:'start',translate:'начать',checked:false,blocked:false },
    { id:10732, word:'statement',translate:'заявление',checked:false,blocked:false },
    { id:10733, word:'station',translate:'станция',checked:false,blocked:false },
    { id:10734, word:'stay',translate:'оставаться',checked:false,blocked:false },
    { id:10735, word:'still',translate:'все еще',checked:false,blocked:false },
    { id:10736, word:'stop',translate:'остановка',checked:false,blocked:false },
    { id:10737, word:'story',translate:'история',checked:false,blocked:false },
    { id:10738, word:'street',translate:'улица',checked:false,blocked:false },
    { id:10739, word:'strong',translate:'сильный',checked:false,blocked:false },
    { id:10740, word:'student',translate:'студент',checked:false,blocked:false },
    { id:10741, word:'study',translate:'учеба',checked:false,blocked:false },
    { id:10742, word:'style',translate:'стиль',checked:false,blocked:false },
    { id:10743, word:'subject',translate:'предмет',checked:false,blocked:false },
    { id:10744, word:'success',translate:'успех',checked:false,blocked:false },
    { id:10745, word:'sugar',translate:'сахар',checked:false,blocked:false },
    { id:10746, word:'summer',translate:'лето',checked:false,blocked:false },
    { id:10747, word:'sun',translate:'солнце',checked:false,blocked:false },
    { id:10748, word:'Sunday',translate:'воскресенье',checked:false,blocked:false },
    { id:10749, word:'supermarket',translate:'супермаркет',checked:false,blocked:false },
    { id:10750, word:'sure',translate:'конечно',checked:false,blocked:false },
    { id:10751, word:'sweater',translate:'свитер',checked:false,blocked:false },
    { id:10752, word:'swim',translate:'плавать',checked:false,blocked:false },
    { id:10753, word:'swimming',translate:'плавание',checked:false,blocked:false },
    { id:10754, word:'table',translate:'стол',checked:false,blocked:false },
    { id:10755, word:'take',translate:'взять',checked:false,blocked:false },
    { id:10756, word:'talk',translate:'говорить',checked:false,blocked:false },
    { id:10757, word:'tall',translate:'высокий',checked:false,blocked:false },
    { id:10758, word:'taxi',translate:'такси',checked:false,blocked:false },
    { id:10759, word:'tea',translate:'чай',checked:false,blocked:false },
    { id:10760, word:'teach',translate:'преподавать',checked:false,blocked:false },
    { id:10761, word:'teacher',translate:'учитель',checked:false,blocked:false },
    { id:10762, word:'team',translate:'команда',checked:false,blocked:false },
    { id:10763, word:'teenager',translate:'подросток',checked:false,blocked:false },
    { id:10764, word:'telephone',translate:'телефон',checked:false,blocked:false },
    { id:10765, word:'television',translate:'телевизор',checked:false,blocked:false },
    { id:10766, word:'tell',translate:'рассказать',checked:false,blocked:false },
    { id:10767, word:'ten',translate:'десять',checked:false,blocked:false },
    { id:10768, word:'tennis',translate:'теннис',checked:false,blocked:false },
    { id:10769, word:'terrible',translate:'ужас',checked:false,blocked:false },
    { id:10770, word:'test',translate:'тест',checked:false,blocked:false },
    { id:10771, word:'text',translate:'текст',checked:false,blocked:false },
    { id:10772, word:'than',translate:'чем',checked:false,blocked:false },
    { id:10773, word:'thank',translate:'спасибо',checked:false,blocked:false },
    { id:10774, word:'thanks',translate:'спасибо',checked:false,blocked:false },
    { id:10775, word:'that',translate:'это',checked:false,blocked:false },
    { id:10776, word:'the',translate:'сайт',checked:false,blocked:false },
    { id:10777, word:'theatre',translate:'театр',checked:false,blocked:false },
    { id:10778, word:'their',translate:'их',checked:false,blocked:false },
    { id:10779, word:'them',translate:'им',checked:false,blocked:false },
    { id:10780, word:'then',translate:'тогда',checked:false,blocked:false },
    { id:10781, word:'there',translate:'там',checked:false,blocked:false },
    { id:10782, word:'they',translate:'они',checked:false,blocked:false },
    { id:10783, word:'thing',translate:'вещь',checked:false,blocked:false },
    { id:10784, word:'think',translate:'думать',checked:false,blocked:false },
    { id:10785, word:'third',translate:'третий',checked:false,blocked:false },
    { id:10786, word:'thirsty',translate:'жаждущий',checked:false,blocked:false },
    { id:10787, word:'thirteen',translate:'тринадцать',checked:false,blocked:false },
    { id:10788, word:'thirty',translate:'тридцать',checked:false,blocked:false },
    { id:10789, word:'this',translate:'это',checked:false,blocked:false },
    { id:10790, word:'thousand',translate:'тысяча',checked:false,blocked:false },
    { id:10791, word:'three',translate:'три',checked:false,blocked:false },
    { id:10792, word:'through',translate:'через',checked:false,blocked:false },
    { id:10793, word:'Thursday',translate:'Четверг',checked:false,blocked:false },
    { id:10794, word:'ticket',translate:'билет',checked:false,blocked:false },
    { id:10795, word:'time',translate:'время',checked:false,blocked:false },
    { id:10796, word:'tired',translate:'усталый',checked:false,blocked:false },
    { id:10797, word:'title',translate:'название',checked:false,blocked:false },
    { id:10798, word:'to',translate:'на',checked:false,blocked:false },
    { id:10799, word:'today',translate:'сегодня',checked:false,blocked:false },
    { id:10800, word:'together',translate:'вместе',checked:false,blocked:false },
    { id:10801, word:'toilet',translate:'туалет',checked:false,blocked:false },
    { id:10802, word:'tomato',translate:'помидор',checked:false,blocked:false },
    { id:10803, word:'tomorrow',translate:'завтра',checked:false,blocked:false },
    { id:10804, word:'tonight',translate:'сегодня',checked:false,blocked:false },
    { id:10805, word:'too',translate:'слишком',checked:false,blocked:false },
    { id:10806, word:'tooth',translate:'зуб',checked:false,blocked:false },
    { id:10807, word:'topic',translate:'тема',checked:false,blocked:false },
    { id:10808, word:'tourist',translate:'турист',checked:false,blocked:false },
    { id:10809, word:'town',translate:'город',checked:false,blocked:false },
    { id:10810, word:'traffic',translate:'движение',checked:false,blocked:false },
    { id:10811, word:'train',translate:'поезд',checked:false,blocked:false },
    { id:10812, word:'travel',translate:'путешествие',checked:false,blocked:false },
    { id:10813, word:'tree',translate:'дерево',checked:false,blocked:false },
    { id:10814, word:'trip',translate:'поездка',checked:false,blocked:false },
    { id:10815, word:'trousers',translate:'брюки',checked:false,blocked:false },
    { id:10816, word:'true',translate:'правда',checked:false,blocked:false },
    { id:10817, word:'try',translate:'попробовать',checked:false,blocked:false },
    { id:10818, word:'T-shirt',translate:'Футболка',checked:false,blocked:false },
    { id:10819, word:'Tuesday',translate:'Вторник',checked:false,blocked:false },
    { id:10820, word:'turn',translate:'повернуть',checked:false,blocked:false },
    { id:10821, word:'TV',translate:'ТЕЛЕВИЗОР',checked:false,blocked:false },
    { id:10822, word:'twelve',translate:'двенадцать',checked:false,blocked:false },
    { id:10823, word:'twenty',translate:'двадцать',checked:false,blocked:false },
    { id:10824, word:'twice',translate:'дважды',checked:false,blocked:false },
    { id:10825, word:'two',translate:'два',checked:false,blocked:false },
    { id:10826, word:'type',translate:'тип',checked:false,blocked:false },
    { id:10827, word:'umbrella',translate:'зонтик',checked:false,blocked:false },
    { id:10828, word:'uncle',translate:'дядя',checked:false,blocked:false },
    { id:10829, word:'under',translate:'под',checked:false,blocked:false },
    { id:10830, word:'understand',translate:'понимать',checked:false,blocked:false },
    { id:10831, word:'university',translate:'университет',checked:false,blocked:false },
    { id:10832, word:'until',translate:'до',checked:false,blocked:false },
    { id:10833, word:'up',translate:'вверх',checked:false,blocked:false },
    { id:10834, word:'upstairs',translate:'наверху',checked:false,blocked:false },
    { id:10835, word:'us',translate:'мы',checked:false,blocked:false },
    { id:10836, word:'use',translate:'использовать',checked:false,blocked:false },
    { id:10837, word:'useful',translate:'полезно',checked:false,blocked:false },
    { id:10838, word:'usually',translate:'обычно',checked:false,blocked:false },
    { id:10839, word:'vacation',translate:'отпуск',checked:false,blocked:false },
    { id:10840, word:'vegetable',translate:'овощ',checked:false,blocked:false },
    { id:10841, word:'very',translate:'очень',checked:false,blocked:false },
    { id:10842, word:'video',translate:'видео',checked:false,blocked:false },
    { id:10843, word:'village',translate:'деревня',checked:false,blocked:false },
    { id:10844, word:'visit',translate:'посетить',checked:false,blocked:false },
    { id:10845, word:'visitor',translate:'посетитель',checked:false,blocked:false },
    { id:10846, word:'wait',translate:'ждать',checked:false,blocked:false },
    { id:10847, word:'waiter',translate:'официант',checked:false,blocked:false },
    { id:10848, word:'wake',translate:'проснуться',checked:false,blocked:false },
    { id:10849, word:'walk',translate:'ходить',checked:false,blocked:false },
    { id:10850, word:'wall',translate:'стена',checked:false,blocked:false },
    { id:10851, word:'want',translate:'хотеть',checked:false,blocked:false },
    { id:10852, word:'warm',translate:'теплый',checked:false,blocked:false },
    { id:10853, word:'wash',translate:'мыть',checked:false,blocked:false },
    { id:10854, word:'watch',translate:'смотреть',checked:false,blocked:false },
    { id:10855, word:'water',translate:'вода',checked:false,blocked:false },
    { id:10856, word:'way',translate:'способ',checked:false,blocked:false },
    { id:10857, word:'we',translate:'мы',checked:false,blocked:false },
    { id:10858, word:'wear',translate:'носить',checked:false,blocked:false },
    { id:10859, word:'weather',translate:'погода',checked:false,blocked:false },
    { id:10860, word:'website',translate:'сайт',checked:false,blocked:false },
    { id:10861, word:'Wednesday',translate:'Среда',checked:false,blocked:false },
    { id:10862, word:'week',translate:'неделя',checked:false,blocked:false },
    { id:10863, word:'weekend',translate:'выходные',checked:false,blocked:false },
    { id:10864, word:'welcome',translate:'добро пожаловать',checked:false,blocked:false },
    { id:10865, word:'well',translate:'хорошо',checked:false,blocked:false },
    { id:10866, word:'west',translate:'запад',checked:false,blocked:false },
    { id:10867, word:'what',translate:'что',checked:false,blocked:false },
    { id:10868, word:'when',translate:'когда',checked:false,blocked:false },
    { id:10869, word:'where',translate:'где',checked:false,blocked:false },
    { id:10870, word:'which',translate:'что',checked:false,blocked:false },
    { id:10871, word:'white',translate:'белый',checked:false,blocked:false },
    { id:10872, word:'who',translate:'кто',checked:false,blocked:false },
    { id:10873, word:'why',translate:'почему',checked:false,blocked:false },
    { id:10874, word:'wife',translate:'жена',checked:false,blocked:false },
    { id:10875, word:'will',translate:'будет',checked:false,blocked:false },
    { id:10876, word:'win',translate:'победа',checked:false,blocked:false },
    { id:10877, word:'window',translate:'окно',checked:false,blocked:false },
    { id:10878, word:'wine',translate:'вино',checked:false,blocked:false },
    { id:10879, word:'winter',translate:'зима',checked:false,blocked:false },
    { id:10880, word:'with',translate:'с',checked:false,blocked:false },
    { id:10881, word:'without',translate:'без',checked:false,blocked:false },
    { id:10882, word:'woman',translate:'женщина',checked:false,blocked:false },
    { id:10883, word:'wonderful',translate:'замечательно',checked:false,blocked:false },
    { id:10884, word:'word',translate:'слово',checked:false,blocked:false },
    { id:10885, word:'work',translate:'работа',checked:false,blocked:false },
    { id:10886, word:'worker',translate:'рабочий',checked:false,blocked:false },
    { id:10887, word:'world',translate:'мир',checked:false,blocked:false },
    { id:10888, word:'would',translate:'бы',checked:false,blocked:false },
    { id:10889, word:'write',translate:'писать',checked:false,blocked:false },
    { id:10890, word:'writer',translate:'писатель',checked:false,blocked:false },
    { id:10891, word:'writing',translate:'письмо',checked:false,blocked:false },
    { id:10892, word:'wrong',translate:'неправильно',checked:false,blocked:false },
    { id:10893, word:'yeah',translate:'да',checked:false,blocked:false },
    { id:10894, word:'year',translate:'год',checked:false,blocked:false },
    { id:10895, word:'yellow',translate:'желтый',checked:false,blocked:false },
    { id:10896, word:'yes',translate:'да',checked:false,blocked:false },
    { id:10897, word:'yesterday',translate:'вчера',checked:false,blocked:false },
    { id:10898, word:'you',translate:'вы',checked:false,blocked:false },
    { id:10899, word:'young',translate:'молодой',checked:false,blocked:false },
    { id:10900, word:'your',translate:'ваш',checked:false,blocked:false },
    { id:10901, word:'yourself',translate:'себя',checked:false,blocked:false }
  ];


export const a2 = [
    { id:20001, word:'ability',translate:'способность',checked:false,blocked:false },
    { id:20002, word:'able',translate:'способный',checked:false,blocked:false },
    { id:20003, word:'abroad',translate:'за границей',checked:false,blocked:false },
    { id:20004, word:'accept',translate:'принимать',checked:false,blocked:false },
    { id:20005, word:'accident',translate:'авария',checked:false,blocked:false },
    { id:20006, word:'according',translate:'согласно',checked:false,blocked:false },
    { id:20007, word:'achieve',translate:'достичь',checked:false,blocked:false },
    { id:20008, word:'act',translate:'действовать',checked:false,blocked:false },
    { id:20009, word:'active',translate:'активный',checked:false,blocked:false },
    { id:20010, word:'actually',translate:'на самом деле',checked:false,blocked:false },
    { id:20011, word:'adult',translate:'взрослый',checked:false,blocked:false },
    { id:20012, word:'advantage',translate:'преимущество',checked:false,blocked:false },
    { id:20013, word:'adventure',translate:'приключение',checked:false,blocked:false },
    { id:20014, word:'advertise',translate:'рекламировать',checked:false,blocked:false },
    { id:20015, word:'advertisement',translate:'реклама',checked:false,blocked:false },
    { id:20016, word:'advertising',translate:'реклама',checked:false,blocked:false },
    { id:20017, word:'affect',translate:'повлиять',checked:false,blocked:false },
    { id:20018, word:'after',translate:'после',checked:false,blocked:false },
    { id:20019, word:'against',translate:'против',checked:false,blocked:false },
    { id:20020, word:'ah',translate:'ах',checked:false,blocked:false },
    { id:20021, word:'airline',translate:'авиакомпания',checked:false,blocked:false },
    { id:20022, word:'alive',translate:'живой',checked:false,blocked:false },
    { id:20023, word:'all',translate:'все',checked:false,blocked:false },
    { id:20024, word:'all',translate:'все',checked:false,blocked:false },
    { id:20025, word:'allow',translate:'разрешить',checked:false,blocked:false },
    { id:20026, word:'almost',translate:'почти',checked:false,blocked:false },
    { id:20027, word:'alone',translate:'один',checked:false,blocked:false },
    { id:20028, word:'along',translate:'вдоль',checked:false,blocked:false },
    { id:20029, word:'already',translate:'уже',checked:false,blocked:false },
    { id:20030, word:'alternative',translate:'альтернатива',checked:false,blocked:false },
    { id:20031, word:'although',translate:'хотя',checked:false,blocked:false },
    { id:20032, word:'among',translate:'среди',checked:false,blocked:false },
    { id:20033, word:'amount',translate:'количество',checked:false,blocked:false },
    { id:20034, word:'ancient',translate:'древний',checked:false,blocked:false },
    { id:20035, word:'ankle',translate:'лодыжка',checked:false,blocked:false },
    { id:20036, word:'any',translate:'любой',checked:false,blocked:false },
    { id:20037, word:'anybody',translate:'любой',checked:false,blocked:false },
    { id:20038, word:'any',translate:'любой',checked:false,blocked:false },
    { id:20039, word:'anyway',translate:'в любом случае',checked:false,blocked:false },
    { id:20040, word:'anywhere',translate:'где угодно',checked:false,blocked:false },
    { id:20041, word:'app',translate:'приложение',checked:false,blocked:false },
    { id:20042, word:'appear',translate:'появляться',checked:false,blocked:false },
    { id:20043, word:'appearance',translate:'появление',checked:false,blocked:false },
    { id:20044, word:'apply',translate:'применять',checked:false,blocked:false },
    { id:20045, word:'architect',translate:'архитектор',checked:false,blocked:false },
    { id:20046, word:'architecture',translate:'архитектура',checked:false,blocked:false },
    { id:20047, word:'argue',translate:'спорить',checked:false,blocked:false },
    { id:20048, word:'argument',translate:'аргумент',checked:false,blocked:false },
    { id:20049, word:'army',translate:'армия',checked:false,blocked:false },
    { id:20050, word:'arrange',translate:'организовать',checked:false,blocked:false },
    { id:20051, word:'arrangement',translate:'расположение',checked:false,blocked:false },
    { id:20052, word:'as',translate:'как',checked:false,blocked:false },
    { id:20053, word:'asleep',translate:'спать',checked:false,blocked:false },
    { id:20054, word:'assistant',translate:'помощник',checked:false,blocked:false },
    { id:20055, word:'athlete',translate:'спортсмен',checked:false,blocked:false },
    { id:20056, word:'attack',translate:'атака',checked:false,blocked:false },
    { id:20057, word:'attend',translate:'посещать',checked:false,blocked:false },
    { id:20058, word:'attention',translate:'внимание',checked:false,blocked:false },
    { id:20059, word:'attractive',translate:'привлекательный',checked:false,blocked:false },
    { id:20060, word:'audience',translate:'аудитория',checked:false,blocked:false },
    { id:20061, word:'author',translate:'автор',checked:false,blocked:false },
    { id:20062, word:'available',translate:'доступный',checked:false,blocked:false },
    { id:20063, word:'average',translate:'средний',checked:false,blocked:false },
    { id:20064, word:'avoid',translate:'избегать',checked:false,blocked:false },
    { id:20065, word:'award',translate:'награда',checked:false,blocked:false },
    { id:20066, word:'awful',translate:'ужасно',checked:false,blocked:false },
    { id:20067, word:'back',translate:'назад',checked:false,blocked:false },
    { id:20068, word:'background',translate:'фон',checked:false,blocked:false },
    { id:20069, word:'badly',translate:'плохо',checked:false,blocked:false },
    { id:20070, word:'bar',translate:'бар',checked:false,blocked:false },
    { id:20071, word:'baseball',translate:'бейсбол',checked:false,blocked:false },
    { id:20072, word:'based',translate:'основа',checked:false,blocked:false },
    { id:20073, word:'basketball',translate:'баскетбол',checked:false,blocked:false },
    { id:20074, word:'bean',translate:'боб',checked:false,blocked:false },
    { id:20075, word:'bear',translate:'медведь',checked:false,blocked:false },
    { id:20076, word:'beat',translate:'бить',checked:false,blocked:false },
    { id:20077, word:'beef',translate:'говядина',checked:false,blocked:false },
    { id:20078, word:'before',translate:'до',checked:false,blocked:false },
    { id:20079, word:'behave',translate:'вести себя',checked:false,blocked:false },
    { id:20080, word:'behaviour',translate:'поведение',checked:false,blocked:false },
    { id:20081, word:'belong',translate:'принадлежать',checked:false,blocked:false },
    { id:20082, word:'belt',translate:'ремень',checked:false,blocked:false },
    { id:20083, word:'benefit',translate:'выгода',checked:false,blocked:false },
    { id:20084, word:'best',translate:'лучший',checked:false,blocked:false },
    { id:20085, word:'better',translate:'лучше',checked:false,blocked:false },
    { id:20086, word:'between',translate:'между',checked:false,blocked:false },
    { id:20087, word:'billion',translate:'миллиард',checked:false,blocked:false },
    { id:20088, word:'bin',translate:'бином',checked:false,blocked:false },
    { id:20089, word:'biology',translate:'биология',checked:false,blocked:false },
    { id:20090, word:'birth',translate:'рождение',checked:false,blocked:false },
    { id:20091, word:'biscuit',translate:'бисквит',checked:false,blocked:false },
    { id:20092, word:'bit',translate:'бит',checked:false,blocked:false },
    { id:20093, word:'blank',translate:'пустое место',checked:false,blocked:false },
    { id:20094, word:'blood',translate:'кровь',checked:false,blocked:false },
    { id:20095, word:'blow',translate:'удар',checked:false,blocked:false },
    { id:20096, word:'board',translate:'доска',checked:false,blocked:false },
    { id:20097, word:'boil',translate:'кипятить',checked:false,blocked:false },
    { id:20098, word:'bone',translate:'кость',checked:false,blocked:false },
    { id:20099, word:'book',translate:'книга',checked:false,blocked:false },
    { id:20100, word:'borrow',translate:'одолжить',checked:false,blocked:false },
    { id:20101, word:'boss',translate:'начальник',checked:false,blocked:false },
    { id:20102, word:'bottom',translate:'дно',checked:false,blocked:false },
    { id:20103, word:'bowl',translate:'миска',checked:false,blocked:false },
    { id:20104, word:'brain',translate:'мозг',checked:false,blocked:false },
    { id:20105, word:'bridge',translate:'мост',checked:false,blocked:false },
    { id:20106, word:'bright',translate:'яркий',checked:false,blocked:false },
    { id:20107, word:'brilliant',translate:'яркий',checked:false,blocked:false },
    { id:20108, word:'broken',translate:'сломанный',checked:false,blocked:false },
    { id:20109, word:'brush',translate:'кисть',checked:false,blocked:false },
    { id:20110, word:'burn',translate:'ожог',checked:false,blocked:false },
    { id:20111, word:'businessman',translate:'бизнесмен',checked:false,blocked:false },
    { id:20112, word:'button',translate:'кнопка',checked:false,blocked:false },
    { id:20113, word:'camp',translate:'лагерь',checked:false,blocked:false },
    { id:20114, word:'camping',translate:'кемпинг',checked:false,blocked:false },
    { id:20115, word:'care',translate:'уход',checked:false,blocked:false },
    { id:20116, word:'careful',translate:'осторожно',checked:false,blocked:false },
    { id:20117, word:'carefully',translate:'осторожно',checked:false,blocked:false },
    { id:20118, word:'carpet',translate:'ковер',checked:false,blocked:false },
    { id:20119, word:'cartoon',translate:'мультфильм',checked:false,blocked:false },
    { id:20120, word:'case',translate:'кейс',checked:false,blocked:false },
    { id:20121, word:'cash',translate:'наличные',checked:false,blocked:false },
    { id:20122, word:'castle',translate:'замок',checked:false,blocked:false },
    { id:20123, word:'catch',translate:'поймать',checked:false,blocked:false },
    { id:20124, word:'cause',translate:'причина',checked:false,blocked:false },
    { id:20125, word:'celebrate',translate:'праздновать',checked:false,blocked:false },
    { id:20126, word:'celebrity',translate:'знаменитость',checked:false,blocked:false },
    { id:20127, word:'certain',translate:'определенно',checked:false,blocked:false },
    { id:20128, word:'certainly',translate:'конечно',checked:false,blocked:false },
    { id:20129, word:'chance',translate:'шанс',checked:false,blocked:false },
    { id:20130, word:'character',translate:'персонаж',checked:false,blocked:false },
    { id:20131, word:'charity',translate:'благотворительность',checked:false,blocked:false },
    { id:20132, word:'chat',translate:'чат',checked:false,blocked:false },
    { id:20133, word:'check',translate:'проверить',checked:false,blocked:false },
    { id:20134, word:'chef',translate:'шеф-повар',checked:false,blocked:false },
    { id:20135, word:'chemistry',translate:'химия',checked:false,blocked:false },
    { id:20136, word:'chip',translate:'чип',checked:false,blocked:false },
    { id:20137, word:'choice',translate:'выбор',checked:false,blocked:false },
    { id:20138, word:'church',translate:'церковь',checked:false,blocked:false },
    { id:20139, word:'cigarette',translate:'сигарета',checked:false,blocked:false },
    { id:20140, word:'circle',translate:'круг',checked:false,blocked:false },
    { id:20141, word:'classical',translate:'классика',checked:false,blocked:false },
    { id:20142, word:'clear',translate:'ясно',checked:false,blocked:false },
    { id:20143, word:'clearly',translate:'ясно',checked:false,blocked:false },
    { id:20144, word:'clever',translate:'умный',checked:false,blocked:false },
    { id:20145, word:'climate',translate:'климат',checked:false,blocked:false },
    { id:20146, word:'close',translate:'близко',checked:false,blocked:false },
    { id:20148, word:'closed',translate:'закрытый',checked:false,blocked:false },
    { id:20149, word:'clothing',translate:'одежда',checked:false,blocked:false },
    { id:20150, word:'cloud',translate:'облако',checked:false,blocked:false },
    { id:20151, word:'coach',translate:'тренер',checked:false,blocked:false },
    { id:20152, word:'coast',translate:'побережье',checked:false,blocked:false },
    { id:20153, word:'code',translate:'код',checked:false,blocked:false },
    { id:20154, word:'colleague',translate:'коллега',checked:false,blocked:false },
    { id:20155, word:'collect',translate:'собирать',checked:false,blocked:false },
    { id:20156, word:'column',translate:'колонка',checked:false,blocked:false },
    { id:20157, word:'comedy',translate:'комедия',checked:false,blocked:false },
    { id:20158, word:'comfortable',translate:'удобно',checked:false,blocked:false },
    { id:20159, word:'comment',translate:'комментарий',checked:false,blocked:false },
    { id:20160, word:'communicate',translate:'общаться',checked:false,blocked:false },
    { id:20161, word:'community',translate:'сообщество',checked:false,blocked:false },
    { id:20162, word:'compete',translate:'соревноваться',checked:false,blocked:false },
    { id:20163, word:'competition',translate:'конкуренция',checked:false,blocked:false },
    { id:20164, word:'complain',translate:'жаловаться',checked:false,blocked:false },
    { id:20165, word:'completely',translate:'полностью',checked:false,blocked:false },
    { id:20166, word:'condition',translate:'условие',checked:false,blocked:false },
    { id:20167, word:'conference',translate:'конференция',checked:false,blocked:false },
    { id:20168, word:'connect',translate:'подключиться',checked:false,blocked:false },
    { id:20169, word:'connected',translate:'связанный',checked:false,blocked:false },
    { id:20170, word:'consider',translate:'рассматривать',checked:false,blocked:false },
    { id:20171, word:'contain',translate:'содержать',checked:false,blocked:false },
    { id:20172, word:'context',translate:'контекст',checked:false,blocked:false },
    { id:20173, word:'continent',translate:'континент',checked:false,blocked:false },
    { id:20174, word:'continue',translate:'продолжать',checked:false,blocked:false },
    { id:20175, word:'control',translate:'контролировать',checked:false,blocked:false },
    { id:20176, word:'cook',translate:'готовить',checked:false,blocked:false },
    { id:20177, word:'cooker',translate:'плита',checked:false,blocked:false },
    { id:20178, word:'copy',translate:'копия',checked:false,blocked:false },
    { id:20179, word:'corner',translate:'угол',checked:false,blocked:false },
    { id:20180, word:'correctly',translate:'правильно',checked:false,blocked:false },
    { id:20181, word:'count',translate:'считать',checked:false,blocked:false },
    { id:20182, word:'couple',translate:'пара',checked:false,blocked:false },
    { id:20183, word:'cover',translate:'обложка',checked:false,blocked:false },
    { id:20184, word:'crazy',translate:'сумасшествие',checked:false,blocked:false },
    { id:20185, word:'creative',translate:'креатив',checked:false,blocked:false },
    { id:20186, word:'credit',translate:'кредит',checked:false,blocked:false },
    { id:20187, word:'crime',translate:'преступление',checked:false,blocked:false },
    { id:20188, word:'criminal',translate:'преступник',checked:false,blocked:false },
    { id:20189, word:'cross',translate:'крест',checked:false,blocked:false },
    { id:20190, word:'crowd',translate:'толпа',checked:false,blocked:false },
    { id:20191, word:'crowded',translate:'толпа',checked:false,blocked:false },
    { id:20192, word:'cry',translate:'плакать',checked:false,blocked:false },
    { id:20193, word:'cupboard',translate:'шкаф',checked:false,blocked:false },
    { id:20194, word:'curly',translate:'кудрявый',checked:false,blocked:false },
    { id:20195, word:'cycle',translate:'цикл',checked:false,blocked:false },
    { id:20196, word:'daily',translate:'день',checked:false,blocked:false },
    { id:20197, word:'danger',translate:'опасность',checked:false,blocked:false },
    { id:20198, word:'dark',translate:'темнота',checked:false,blocked:false },
    { id:20199, word:'data',translate:'данные',checked:false,blocked:false },
    { id:20200, word:'dead',translate:'мертвый',checked:false,blocked:false },
    { id:20201, word:'deal',translate:'сделка',checked:false,blocked:false },
    { id:20202, word:'dear',translate:'дорогой',checked:false,blocked:false },
    { id:20203, word:'death',translate:'смерть',checked:false,blocked:false },
    { id:20204, word:'decision',translate:'решение',checked:false,blocked:false },
    { id:20205, word:'deep',translate:'глубоко',checked:false,blocked:false },
    { id:20206, word:'definitely',translate:'определенно',checked:false,blocked:false },
    { id:20207, word:'degree',translate:'степень',checked:false,blocked:false },
    { id:20208, word:'dentist',translate:'стоматолог',checked:false,blocked:false },
    { id:20209, word:'department',translate:'отдел',checked:false,blocked:false },
    { id:20210, word:'depend',translate:'зависеть',checked:false,blocked:false },
    { id:20211, word:'desert',translate:'пустыня',checked:false,blocked:false },
    { id:20212, word:'designer',translate:'дизайнер',checked:false,blocked:false },
    { id:20213, word:'destroy',translate:'уничтожить',checked:false,blocked:false },
    { id:20214, word:'detective',translate:'детектив',checked:false,blocked:false },
    { id:20215, word:'develop',translate:'разработка',checked:false,blocked:false },
    { id:20216, word:'device',translate:'устройство',checked:false,blocked:false },
    { id:20217, word:'diary',translate:'дневник',checked:false,blocked:false },
    { id:20218, word:'differently',translate:'по-другому',checked:false,blocked:false },
    { id:20219, word:'digital',translate:'цифровой',checked:false,blocked:false },
    { id:20220, word:'direct',translate:'прямой',checked:false,blocked:false },
    { id:20221, word:'direction',translate:'направление',checked:false,blocked:false },
    { id:20222, word:'director',translate:'директор',checked:false,blocked:false },
    { id:20223, word:'disagree',translate:'не соглашаться',checked:false,blocked:false },
    { id:20224, word:'disappear',translate:'исчезнуть',checked:false,blocked:false },
    { id:20225, word:'disaster',translate:'катастрофа',checked:false,blocked:false },
    { id:20226, word:'discover',translate:'открытие',checked:false,blocked:false },
    { id:20227, word:'discovery',translate:'открытие',checked:false,blocked:false },
    { id:20228, word:'discussion',translate:'обсуждение',checked:false,blocked:false },
    { id:20229, word:'disease',translate:'болезнь',checked:false,blocked:false },
    { id:20230, word:'distance',translate:'расстояние',checked:false,blocked:false },
    { id:20231, word:'divorced',translate:'разведен',checked:false,blocked:false },
    { id:20232, word:'document',translate:'документ',checked:false,blocked:false },
    { id:20233, word:'double',translate:'двойник',checked:false,blocked:false },
    { id:20234, word:'download',translate:'скачать',checked:false,blocked:false },
    { id:20235, word:'downstairs',translate:'внизу',checked:false,blocked:false },
    { id:20236, word:'drama',translate:'драма',checked:false,blocked:false },
    { id:20237, word:'drawing',translate:'рисунок',checked:false,blocked:false },
    { id:20238, word:'dream',translate:'мечта',checked:false,blocked:false },
    { id:20239, word:'drive',translate:'ехать',checked:false,blocked:false },
    { id:20240, word:'driving',translate:'вождение',checked:false,blocked:false },
    { id:20241, word:'drop',translate:'капля',checked:false,blocked:false },
    { id:20242, word:'drug',translate:'наркотик',checked:false,blocked:false },
    { id:20243, word:'dry',translate:'сушить',checked:false,blocked:false },
    { id:20244, word:'earn',translate:'заработать',checked:false,blocked:false },
    { id:20245, word:'earth',translate:'земля',checked:false,blocked:false },
    { id:20246, word:'easily',translate:'легко',checked:false,blocked:false },
    { id:20247, word:'education',translate:'образование',checked:false,blocked:false },
    { id:20248, word:'effect',translate:'эффект',checked:false,blocked:false },
    { id:20249, word:'either',translate:'или',checked:false,blocked:false },
    { id:20250, word:'electric',translate:'электрический',checked:false,blocked:false },
    { id:20251, word:'electrical',translate:'электрический',checked:false,blocked:false },
    { id:20252, word:'electricity',translate:'электричество',checked:false,blocked:false },
    { id:20253, word:'electronic',translate:'электронный',checked:false,blocked:false },
    { id:20254, word:'employ',translate:'нанимать',checked:false,blocked:false },
    { id:20255, word:'employee',translate:'работник',checked:false,blocked:false },
    { id:20256, word:'employer',translate:'работодатель',checked:false,blocked:false },
    { id:20257, word:'empty',translate:'пустой',checked:false,blocked:false },
    { id:20258, word:'ending',translate:'окончание',checked:false,blocked:false },
    { id:20259, word:'energy',translate:'энергия',checked:false,blocked:false },
    { id:20260, word:'engine',translate:'двигатель',checked:false,blocked:false },
    { id:20261, word:'engineer',translate:'инженер',checked:false,blocked:false },
    { id:20262, word:'enormous',translate:'огромный',checked:false,blocked:false },
    { id:20263, word:'enter',translate:'войти',checked:false,blocked:false },
    { id:20264, word:'environment',translate:'среда',checked:false,blocked:false },
    { id:20265, word:'equipment',translate:'оборудование',checked:false,blocked:false },
    { id:20266, word:'error',translate:'ошибка',checked:false,blocked:false },
    { id:20267, word:'especially',translate:'особенно',checked:false,blocked:false },
    { id:20268, word:'essay',translate:'эссе',checked:false,blocked:false },
    { id:20269, word:'everyday',translate:'повседневность',checked:false,blocked:false },
    { id:20270, word:'everywhere',translate:'везде',checked:false,blocked:false },
    { id:20271, word:'evidence',translate:'доказательства',checked:false,blocked:false },
    { id:20272, word:'exact',translate:'точно',checked:false,blocked:false },
    { id:20273, word:'exactly',translate:'точно',checked:false,blocked:false },
    { id:20274, word:'excellent',translate:'превосходно',checked:false,blocked:false },
    { id:20275, word:'except',translate:'кроме',checked:false,blocked:false },
    { id:20276, word:'exist',translate:'существовать',checked:false,blocked:false },
    { id:20277, word:'expect',translate:'ожидать',checked:false,blocked:false },
    { id:20278, word:'experience',translate:'опыт',checked:false,blocked:false },
    { id:20279, word:'experiment',translate:'экспериментировать',checked:false,blocked:false },
    { id:20280, word:'expert',translate:'эксперт',checked:false,blocked:false },
    { id:20281, word:'explanation',translate:'объяснение',checked:false,blocked:false },
    { id:20282, word:'express',translate:'выразить',checked:false,blocked:false },
    { id:20283, word:'expression',translate:'выражение',checked:false,blocked:false },
    { id:20284, word:'extreme',translate:'экстремальный',checked:false,blocked:false },
    { id:20285, word:'extremely',translate:'чрезвычайно',checked:false,blocked:false },
    { id:20286, word:'factor',translate:'фактор',checked:false,blocked:false },
    { id:20287, word:'factory',translate:'завод',checked:false,blocked:false },
    { id:20288, word:'fail',translate:'провал',checked:false,blocked:false },
    { id:20289, word:'fair',translate:'ярмарка',checked:false,blocked:false },
    { id:20290, word:'fall',translate:'падение',checked:false,blocked:false },
    { id:20291, word:'fan',translate:'вентилятор',checked:false,blocked:false },
    { id:20292, word:'farm',translate:'ферма',checked:false,blocked:false },
    { id:20293, word:'farming',translate:'фермерство',checked:false,blocked:false },
    { id:20294, word:'fashion',translate:'мода',checked:false,blocked:false },
    { id:20295, word:'fat',translate:'жир',checked:false,blocked:false },
    { id:20296, word:'fear',translate:'страх',checked:false,blocked:false },
    { id:20297, word:'feature',translate:'особенность',checked:false,blocked:false },
    { id:20298, word:'feed',translate:'корм',checked:false,blocked:false },
    { id:20299, word:'female',translate:'женщина',checked:false,blocked:false },
    { id:20300, word:'fiction',translate:'художественная литература',checked:false,blocked:false },
    { id:20301, word:'field',translate:'поле',checked:false,blocked:false },
    { id:20302, word:'fight',translate:'бой',checked:false,blocked:false },
    { id:20303, word:'figure',translate:'фигура',checked:false,blocked:false },
    { id:20304, word:'film',translate:'фильм',checked:false,blocked:false },
    { id:20305, word:'final',translate:'финал',checked:false,blocked:false },
    { id:20306, word:'finally',translate:'наконец',checked:false,blocked:false },
    { id:20307, word:'finger',translate:'палец',checked:false,blocked:false },
    { id:20308, word:'finish',translate:'закончить',checked:false,blocked:false },
    { id:20310, word:'firstly',translate:'в первую очередь',checked:false,blocked:false },
    { id:20311, word:'fish',translate:'рыба',checked:false,blocked:false },
    { id:20312, word:'fishing',translate:'рыбалка',checked:false,blocked:false },
    { id:20313, word:'fit',translate:'подогнать',checked:false,blocked:false },
    { id:20314, word:'fix',translate:'исправить',checked:false,blocked:false },
    { id:20315, word:'flat',translate:'плоский',checked:false,blocked:false },
    { id:20316, word:'flu',translate:'грипп',checked:false,blocked:false },
    { id:20317, word:'fly',translate:'муха',checked:false,blocked:false },
    { id:20318, word:'flying',translate:'летать',checked:false,blocked:false },
    { id:20319, word:'focus',translate:'фокус',checked:false,blocked:false },
    { id:20320, word:'following',translate:'следовать',checked:false,blocked:false },
    { id:20321, word:'foreign',translate:'иностранный',checked:false,blocked:false },
    { id:20322, word:'forest',translate:'лес',checked:false,blocked:false },
    { id:20323, word:'fork',translate:'вилка',checked:false,blocked:false },
    { id:20324, word:'formal',translate:'официальный',checked:false,blocked:false },
    { id:20325, word:'fortunately',translate:'к счастью',checked:false,blocked:false },
    { id:20326, word:'forward',translate:'вперёд',checked:false,blocked:false },
    { id:20327, word:'free',translate:'свободный',checked:false,blocked:false },
    { id:20328, word:'fresh',translate:'свежий',checked:false,blocked:false },
    { id:20329, word:'fridge',translate:'холодильник',checked:false,blocked:false },
    { id:20330, word:'frog',translate:'лягушка',checked:false,blocked:false },
    { id:20331, word:'fun',translate:'веселье',checked:false,blocked:false },
    { id:20332, word:'furniture',translate:'мебель',checked:false,blocked:false },
    { id:20333, word:'further',translate:'далее',checked:false,blocked:false },
    { id:20334, word:'future',translate:'будущее',checked:false,blocked:false },
    { id:20335, word:'gallery',translate:'галерея',checked:false,blocked:false },
    { id:20336, word:'gap',translate:'пробел',checked:false,blocked:false },
    { id:20337, word:'gas',translate:'газ',checked:false,blocked:false },
    { id:20338, word:'gate',translate:'ворота',checked:false,blocked:false },
    { id:20339, word:'general',translate:'генерал',checked:false,blocked:false },
    { id:20340, word:'gift',translate:'подарок',checked:false,blocked:false },
    { id:20341, word:'goal',translate:'ворота',checked:false,blocked:false },
    { id:20342, word:'god',translate:'бог',checked:false,blocked:false },
    { id:20343, word:'gold',translate:'золото',checked:false,blocked:false },
    { id:20344, word:'golf',translate:'гольф',checked:false,blocked:false },
    { id:20345, word:'good',translate:'добро',checked:false,blocked:false },
    { id:20346, word:'government',translate:'правительство',checked:false,blocked:false },
    { id:20347, word:'grass',translate:'трава',checked:false,blocked:false },
    { id:20348, word:'greet',translate:'приветствовать',checked:false,blocked:false },
    { id:20349, word:'ground',translate:'земля',checked:false,blocked:false },
    { id:20350, word:'guest',translate:'гость',checked:false,blocked:false },
    { id:20351, word:'guide',translate:'гид',checked:false,blocked:false },
    { id:20352, word:'gun',translate:'пистолет',checked:false,blocked:false },
    { id:20353, word:'guy',translate:'парень',checked:false,blocked:false },
    { id:20354, word:'habit',translate:'привычка',checked:false,blocked:false },
    { id:20355, word:'half',translate:'половина',checked:false,blocked:false },
    { id:20356, word:'hall',translate:'зал',checked:false,blocked:false },
    { id:20357, word:'happily',translate:'с радостью',checked:false,blocked:false },
    { id:20358, word:'have',translate:'есть',checked:false,blocked:false },
    { id:20359, word:'headache',translate:'головная боль',checked:false,blocked:false },
    { id:20360, word:'heart',translate:'сердце',checked:false,blocked:false },
    { id:20361, word:'heat',translate:'тепло',checked:false,blocked:false },
    { id:20362, word:'heavy',translate:'тяжесть',checked:false,blocked:false },
    { id:20363, word:'height',translate:'высота',checked:false,blocked:false },
    { id:20364, word:'helpful',translate:'помощь',checked:false,blocked:false },
    { id:20365, word:'hero',translate:'герой',checked:false,blocked:false },
    { id:20366, word:'hers',translate:'ее',checked:false,blocked:false },
    { id:20367, word:'herself',translate:'сама',checked:false,blocked:false },
    { id:20368, word:'hide',translate:'скрыть',checked:false,blocked:false },
    { id:20369, word:'high',translate:'высокий',checked:false,blocked:false },
    { id:20370, word:'hill',translate:'холм',checked:false,blocked:false },
    { id:20371, word:'himself',translate:'сам',checked:false,blocked:false },
    { id:20372, word:'his',translate:'его',checked:false,blocked:false },
    { id:20373, word:'hit',translate:'хит',checked:false,blocked:false },
    { id:20374, word:'hockey',translate:'хоккей',checked:false,blocked:false },
    { id:20375, word:'hold',translate:'держать',checked:false,blocked:false },
    { id:20376, word:'hole',translate:'отверстие',checked:false,blocked:false },
    { id:20377, word:'home',translate:'дом',checked:false,blocked:false },
    { id:20378, word:'hope',translate:'надежда',checked:false,blocked:false },
    { id:20379, word:'huge',translate:'огромный',checked:false,blocked:false },
    { id:20380, word:'human',translate:'человек',checked:false,blocked:false },
    { id:20381, word:'hurt',translate:'боль',checked:false,blocked:false },
    { id:20382, word:'ideal',translate:'идеал',checked:false,blocked:false },
    { id:20383, word:'identify',translate:'определить',checked:false,blocked:false },
    { id:20384, word:'ill',translate:'болезнь',checked:false,blocked:false },
    { id:20385, word:'illness',translate:'болезнь',checked:false,blocked:false },
    { id:20386, word:'image',translate:'образ',checked:false,blocked:false },
    { id:20387, word:'immediately',translate:'немедленно',checked:false,blocked:false },
    { id:20388, word:'impossible',translate:'невозможно',checked:false,blocked:false },
    { id:20389, word:'included',translate:'включено',checked:false,blocked:false },
    { id:20390, word:'including',translate:'включая',checked:false,blocked:false },
    { id:20391, word:'increase',translate:'увеличить',checked:false,blocked:false },
    { id:20392, word:'incredible',translate:'невероятно',checked:false,blocked:false },
    { id:20393, word:'independent',translate:'независимый',checked:false,blocked:false },
    { id:20394, word:'individual',translate:'индивидуум',checked:false,blocked:false },
    { id:20395, word:'industry',translate:'промышленность',checked:false,blocked:false },
    { id:20396, word:'informal',translate:'неформальный',checked:false,blocked:false },
    { id:20397, word:'injury',translate:'травма',checked:false,blocked:false },
    { id:20398, word:'insect',translate:'насекомое',checked:false,blocked:false },
    { id:20399, word:'inside',translate:'внутри',checked:false,blocked:false },
    { id:20400, word:'instead',translate:'вместо',checked:false,blocked:false },
    { id:20401, word:'instruction',translate:'инструкция',checked:false,blocked:false },
    { id:20402, word:'instructor',translate:'инструктор',checked:false,blocked:false },
    { id:20403, word:'instrument',translate:'прибор',checked:false,blocked:false },
    { id:20404, word:'intelligent',translate:'интеллектуальный',checked:false,blocked:false },
    { id:20405, word:'international',translate:'международный',checked:false,blocked:false },
    { id:20406, word:'introduction',translate:'введение',checked:false,blocked:false },
    { id:20407, word:'invent',translate:'изобрести',checked:false,blocked:false },
    { id:20408, word:'invention',translate:'изобретение',checked:false,blocked:false },
    { id:20409, word:'invitation',translate:'приглашение',checked:false,blocked:false },
    { id:20410, word:'invite',translate:'пригласить',checked:false,blocked:false },
    { id:20411, word:'involve',translate:'привлечь',checked:false,blocked:false },
    { id:20412, word:'item',translate:'предмет',checked:false,blocked:false },
    { id:20413, word:'itself',translate:'сам',checked:false,blocked:false },
    { id:20414, word:'jam',translate:'джем',checked:false,blocked:false },
    { id:20415, word:'jazz',translate:'джаз',checked:false,blocked:false },
    { id:20416, word:'jewellery',translate:'ювелирные изделия',checked:false,blocked:false },
    { id:20417, word:'joke',translate:'шутка',checked:false,blocked:false },
    { id:20418, word:'journalist',translate:'журналист',checked:false,blocked:false },
    { id:20419, word:'jump',translate:'прыжок',checked:false,blocked:false },
    { id:20420, word:'kid',translate:'ребенок',checked:false,blocked:false },
    { id:20421, word:'kill',translate:'убить',checked:false,blocked:false },
    { id:20422, word:'king',translate:'король',checked:false,blocked:false },
    { id:20423, word:'knee',translate:'колено',checked:false,blocked:false },
    { id:20424, word:'knife',translate:'нож',checked:false,blocked:false },
    { id:20425, word:'knock',translate:'стук',checked:false,blocked:false },
    { id:20426, word:'knowledge',translate:'знание',checked:false,blocked:false },
    { id:20427, word:'lab',translate:'лаборатория',checked:false,blocked:false },
    { id:20428, word:'lady',translate:'леди',checked:false,blocked:false },
    { id:20429, word:'lake',translate:'озеро',checked:false,blocked:false },
    { id:20430, word:'lamp',translate:'лампа',checked:false,blocked:false },
    { id:20431, word:'land',translate:'земля',checked:false,blocked:false },
    { id:20432, word:'laptop',translate:'ноутбук',checked:false,blocked:false },
    { id:20433, word:'last',translate:'последний',checked:false,blocked:false },
    { id:20434, word:'later',translate:'позже',checked:false,blocked:false },
    { id:20435, word:'laughter',translate:'смех',checked:false,blocked:false },
    { id:20436, word:'law',translate:'закон',checked:false,blocked:false },
    { id:20437, word:'lawyer',translate:'адвокат',checked:false,blocked:false },
    { id:20438, word:'lazy',translate:'ленивый',checked:false,blocked:false },
    { id:20439, word:'lead',translate:'вести',checked:false,blocked:false },
    { id:20440, word:'leader',translate:'лидер',checked:false,blocked:false },
    { id:20441, word:'learning',translate:'обучение',checked:false,blocked:false },
    { id:20442, word:'least',translate:'наименее',checked:false,blocked:false },
    { id:20443, word:'lecture',translate:'лекция',checked:false,blocked:false },
    { id:20444, word:'lemon',translate:'лимон',checked:false,blocked:false },
    { id:20445, word:'lend',translate:'одолжить',checked:false,blocked:false },
    { id:20446, word:'less',translate:'меньше',checked:false,blocked:false },
    { id:20447, word:'level',translate:'уровень',checked:false,blocked:false },
    { id:20448, word:'lifestyle',translate:'образ жизни',checked:false,blocked:false },
    { id:20449, word:'lift',translate:'поднять',checked:false,blocked:false },
    { id:20450, word:'light',translate:'свет',checked:false,blocked:false },
    { id:20451, word:'light',translate:'свет',checked:false,blocked:false },
    { id:20452, word:'likely',translate:'вероятно',checked:false,blocked:false },
    { id:20453, word:'link',translate:'ссылка',checked:false,blocked:false },
    { id:20454, word:'listener',translate:'слушатель',checked:false,blocked:false },
    { id:20455, word:'little',translate:'маленький',checked:false,blocked:false },
    { id:20456, word:'lock',translate:'замок',checked:false,blocked:false },
    { id:20457, word:'look',translate:'смотреть',checked:false,blocked:false },
    { id:20458, word:'lorry',translate:'грузовик',checked:false,blocked:false },
    { id:20459, word:'lost',translate:'потерянный',checked:false,blocked:false },
    { id:20460, word:'loud',translate:'громко',checked:false,blocked:false },
    { id:20461, word:'loudly',translate:'громко',checked:false,blocked:false },
    { id:20462, word:'lovely',translate:'прекрасный',checked:false,blocked:false },
    { id:20463, word:'low',translate:'низкий',checked:false,blocked:false },
    { id:20464, word:'luck',translate:'удача',checked:false,blocked:false },
    { id:20465, word:'lucky',translate:'удача',checked:false,blocked:false },
    { id:20466, word:'mail',translate:'почта',checked:false,blocked:false },
    { id:20467, word:'major',translate:'главная',checked:false,blocked:false },
    { id:20468, word:'male',translate:'мужчина',checked:false,blocked:false },
    { id:20469, word:'manage',translate:'управлять',checked:false,blocked:false },
    { id:20470, word:'manager',translate:'менеджер',checked:false,blocked:false },
    { id:20471, word:'manner',translate:'манера',checked:false,blocked:false },
    { id:20472, word:'mark',translate:'отметить',checked:false,blocked:false },
    { id:20473, word:'marry',translate:'жениться',checked:false,blocked:false },
    { id:20474, word:'material',translate:'материал',checked:false,blocked:false },
    { id:20475, word:'mathematics',translate:'математика',checked:false,blocked:false },
    { id:20476, word:'maths',translate:'математика',checked:false,blocked:false },
    { id:20477, word:'matter',translate:'материя',checked:false,blocked:false },
    { id:20478, word:'may',translate:'май',checked:false,blocked:false },
    { id:20479, word:'media',translate:'СМИ',checked:false,blocked:false },
    { id:20480, word:'medical',translate:'медицина',checked:false,blocked:false },
    { id:20481, word:'medicine',translate:'медицина',checked:false,blocked:false },
    { id:20482, word:'memory',translate:'память',checked:false,blocked:false },
    { id:20483, word:'mention',translate:'упоминание',checked:false,blocked:false },
    { id:20484, word:'metal',translate:'металл',checked:false,blocked:false },
    { id:20485, word:'method',translate:'метод',checked:false,blocked:false },
    { id:20486, word:'middle',translate:'середина',checked:false,blocked:false },
    { id:20487, word:'might',translate:'может',checked:false,blocked:false },
    { id:20488, word:'mind',translate:'ум',checked:false,blocked:false },
    { id:20489, word:'mine',translate:'шахта',checked:false,blocked:false },
    { id:20490, word:'mirror',translate:'зеркало',checked:false,blocked:false },
    { id:20491, word:'missing',translate:'пропавший',checked:false,blocked:false },
    { id:20492, word:'mobile',translate:'мобильный',checked:false,blocked:false },
    { id:20493, word:'monkey',translate:'обезьяна',checked:false,blocked:false },
    { id:20494, word:'moon',translate:'луна',checked:false,blocked:false },
    { id:20495, word:'mostly',translate:'в основном',checked:false,blocked:false },
    { id:20496, word:'motorcycle',translate:'мотоцикл',checked:false,blocked:false },
    { id:20497, word:'movement',translate:'движение',checked:false,blocked:false },
    { id:20498, word:'musical',translate:'музыка',checked:false,blocked:false },
    { id:20499, word:'musician',translate:'музыкант',checked:false,blocked:false },
    { id:20500, word:'myself',translate:'сам',checked:false,blocked:false },
    { id:20501, word:'narrow',translate:'узкий',checked:false,blocked:false },
    { id:20502, word:'national',translate:'национальный',checked:false,blocked:false },
    { id:20503, word:'nature',translate:'природа',checked:false,blocked:false },
    { id:20504, word:'nearly',translate:'почти',checked:false,blocked:false },
    { id:20505, word:'necessary',translate:'необходимый',checked:false,blocked:false },
    { id:20506, word:'neck',translate:'шея',checked:false,blocked:false },
    { id:20507, word:'need',translate:'нужно',checked:false,blocked:false },
    { id:20508, word:'neither',translate:'ни',checked:false,blocked:false },
    { id:20509, word:'nervous',translate:'нервная',checked:false,blocked:false },
    { id:20510, word:'network',translate:'сеть',checked:false,blocked:false },
    { id:20511, word:'noise',translate:'шум',checked:false,blocked:false },
    { id:20512, word:'noisy',translate:'шум',checked:false,blocked:false },
    { id:20513, word:'none',translate:'нет',checked:false,blocked:false },
    { id:20514, word:'normal',translate:'нормально',checked:false,blocked:false },
    { id:20515, word:'normally',translate:'обычно',checked:false,blocked:false },
    { id:20516, word:'notice',translate:'заметить',checked:false,blocked:false },
    { id:20517, word:'novel',translate:'новинка',checked:false,blocked:false },
    { id:20518, word:'nowhere',translate:'нигде',checked:false,blocked:false },
    { id:20519, word:'number',translate:'номер',checked:false,blocked:false },
    { id:20520, word:'nut',translate:'орех',checked:false,blocked:false },
    { id:20521, word:'ocean',translate:'океан',checked:false,blocked:false },
    { id:20522, word:'offer',translate:'предложение',checked:false,blocked:false },
    { id:20523, word:'officer',translate:'офицер',checked:false,blocked:false },
    { id:20524, word:'oil',translate:'нефть',checked:false,blocked:false },
    { id:20525, word:'onto',translate:'на',checked:false,blocked:false },
    { id:20526, word:'opportunity',translate:'возможность',checked:false,blocked:false },
    { id:20527, word:'option',translate:'опцион',checked:false,blocked:false },
    { id:20528, word:'ordinary',translate:'обычный',checked:false,blocked:false },
    { id:20529, word:'organization',translate:'организация',checked:false,blocked:false },
    { id:20530, word:'organize',translate:'организовать',checked:false,blocked:false },
    { id:20531, word:'original',translate:'оригинал',checked:false,blocked:false },
    { id:20532, word:'ourselves',translate:'сами',checked:false,blocked:false },
    { id:20533, word:'outside',translate:'снаружи',checked:false,blocked:false },
    { id:20534, word:'oven',translate:'печь',checked:false,blocked:false },
    { id:20535, word:'own',translate:'собственный',checked:false,blocked:false },
    { id:20536, word:'owner',translate:'владелец',checked:false,blocked:false },
    { id:20537, word:'pack',translate:'упаковка',checked:false,blocked:false },
    { id:20538, word:'pain',translate:'боль',checked:false,blocked:false },
    { id:20539, word:'painter',translate:'художник',checked:false,blocked:false },
    { id:20540, word:'palace',translate:'дворец',checked:false,blocked:false },
    { id:20541, word:'pants',translate:'брюки',checked:false,blocked:false },
    { id:20542, word:'parking',translate:'парковка',checked:false,blocked:false },
    { id:20543, word:'particular',translate:'конкретный',checked:false,blocked:false },
    { id:20544, word:'pass',translate:'пропуск',checked:false,blocked:false },
    { id:20545, word:'passenger',translate:'пассажир',checked:false,blocked:false },
    { id:20546, word:'past',translate:'прошлое',checked:false,blocked:false },
    { id:20547, word:'patient',translate:'пациент',checked:false,blocked:false },
    { id:20548, word:'pattern',translate:'шаблон',checked:false,blocked:false },
    { id:20549, word:'pay',translate:'платить',checked:false,blocked:false },
    { id:20550, word:'peace',translate:'мир',checked:false,blocked:false },
    { id:20551, word:'penny',translate:'пенни',checked:false,blocked:false },
    { id:20552, word:'per',translate:'за',checked:false,blocked:false },
    { id:20553, word:'per',translate:'на',checked:false,blocked:false },
    { id:20554, word:'perform',translate:'выполнить',checked:false,blocked:false },
    { id:20555, word:'perhaps',translate:'возможно',checked:false,blocked:false },
    { id:20556, word:'permission',translate:'разрешение',checked:false,blocked:false },
    { id:20557, word:'personality',translate:'личность',checked:false,blocked:false },
    { id:20558, word:'pet',translate:'домашнее животное',checked:false,blocked:false },
    { id:20559, word:'petrol',translate:'бензин',checked:false,blocked:false },
    { id:20560, word:'photograph',translate:'фотография',checked:false,blocked:false },
    { id:20561, word:'physical',translate:'физика',checked:false,blocked:false },
    { id:20562, word:'physics',translate:'физика',checked:false,blocked:false },
    { id:20563, word:'pick',translate:'выбрать',checked:false,blocked:false },
    { id:20564, word:'pilot',translate:'пилот',checked:false,blocked:false },
    { id:20565, word:'planet',translate:'планета',checked:false,blocked:false },
    { id:20566, word:'plant',translate:'завод',checked:false,blocked:false },
    { id:20567, word:'plastic',translate:'пластик',checked:false,blocked:false },
    { id:20568, word:'plate',translate:'пластина',checked:false,blocked:false },
    { id:20569, word:'platform',translate:'платформа',checked:false,blocked:false },
    { id:20570, word:'please',translate:'пожалуйста',checked:false,blocked:false },
    { id:20571, word:'pleased',translate:'доволен',checked:false,blocked:false },
    { id:20572, word:'pocket',translate:'карман',checked:false,blocked:false },
    { id:20573, word:'polite',translate:'вежливый',checked:false,blocked:false },
    { id:20574, word:'pollution',translate:'загрязнение',checked:false,blocked:false },
    { id:20575, word:'pop',translate:'население',checked:false,blocked:false },
    { id:20576, word:'population',translate:'население',checked:false,blocked:false },
    { id:20577, word:'position',translate:'должность',checked:false,blocked:false },
    { id:20578, word:'possession',translate:'владение',checked:false,blocked:false },
    { id:20579, word:'possibility',translate:'возможность',checked:false,blocked:false },
    { id:20580, word:'poster',translate:'плакат',checked:false,blocked:false },
    { id:20581, word:'power',translate:'власть',checked:false,blocked:false },
    { id:20582, word:'predict',translate:'предсказать',checked:false,blocked:false },
    { id:20583, word:'present',translate:'настоящее',checked:false,blocked:false },
    { id:20584, word:'president',translate:'президент',checked:false,blocked:false },
    { id:20585, word:'prevent',translate:'предотвратить',checked:false,blocked:false },
    { id:20586, word:'print',translate:'печать',checked:false,blocked:false },
    { id:20587, word:'printer',translate:'принтер',checked:false,blocked:false },
    { id:20588, word:'prison',translate:'тюрьма',checked:false,blocked:false },
    { id:20589, word:'prize',translate:'приз',checked:false,blocked:false },
    { id:20590, word:'process',translate:'процесс',checked:false,blocked:false },
    { id:20591, word:'produce',translate:'производство',checked:false,blocked:false },
    { id:20592, word:'professional',translate:'профессионал',checked:false,blocked:false },
    { id:20593, word:'professor',translate:'профессор',checked:false,blocked:false },
    { id:20594, word:'profile',translate:'профиль',checked:false,blocked:false },
    { id:20595, word:'program',translate:'программа',checked:false,blocked:false },
    { id:20596, word:'progress',translate:'прогресс',checked:false,blocked:false },
    { id:20597, word:'promise',translate:'обещать',checked:false,blocked:false },
    { id:20598, word:'pronounce',translate:'произносить',checked:false,blocked:false },
    { id:20599, word:'protect',translate:'защищать',checked:false,blocked:false },
    { id:20600, word:'provide',translate:'предоставлять',checked:false,blocked:false },
    { id:20601, word:'pub',translate:'паб',checked:false,blocked:false },
    { id:20602, word:'public',translate:'публика',checked:false,blocked:false },
    { id:20603, word:'publish',translate:'опубликовать',checked:false,blocked:false },
    { id:20604, word:'pull',translate:'вытащить',checked:false,blocked:false },
    { id:20605, word:'purpose',translate:'цель',checked:false,blocked:false },
    { id:20606, word:'push',translate:'толкать',checked:false,blocked:false },
    { id:20607, word:'quality',translate:'качество',checked:false,blocked:false },
    { id:20608, word:'quantity',translate:'количество',checked:false,blocked:false },
    { id:20609, word:'queen',translate:'королева',checked:false,blocked:false },
    { id:20610, word:'question',translate:'вопрос',checked:false,blocked:false },
    { id:20611, word:'quietly',translate:'тихо',checked:false,blocked:false },
    { id:20612, word:'race',translate:'гонка',checked:false,blocked:false },
    { id:20613, word:'railway',translate:'железная дорога',checked:false,blocked:false },
    { id:20614, word:'raise',translate:'поднять',checked:false,blocked:false },
    { id:20615, word:'rate',translate:'тариф',checked:false,blocked:false },
    { id:20616, word:'rather',translate:'скорее',checked:false,blocked:false },
    { id:20617, word:'reach',translate:'достичь',checked:false,blocked:false },
    { id:20618, word:'react',translate:'реагировать',checked:false,blocked:false },
    { id:20619, word:'realize',translate:'понимать',checked:false,blocked:false },
    { id:20620, word:'receive',translate:'получать',checked:false,blocked:false },
    { id:20621, word:'recent',translate:'недавно',checked:false,blocked:false },
    { id:20622, word:'recently',translate:'недавно',checked:false,blocked:false },
    { id:20623, word:'reception',translate:'прием',checked:false,blocked:false },
    { id:20624, word:'recipe',translate:'рецепт',checked:false,blocked:false },
    { id:20625, word:'recognize',translate:'узнать',checked:false,blocked:false },
    { id:20626, word:'recommend',translate:'рекомендовать',checked:false,blocked:false },
    { id:20627, word:'record',translate:'запись',checked:false,blocked:false },
    { id:20628, word:'recording',translate:'запись',checked:false,blocked:false },
    { id:20629, word:'recycle',translate:'перерабатывать',checked:false,blocked:false },
    { id:20630, word:'reduce',translate:'сократить',checked:false,blocked:false },
    { id:20631, word:'refer',translate:'направлять',checked:false,blocked:false },
    { id:20632, word:'refuse1',translate:'мусор1',checked:false,blocked:false },
    { id:20633, word:'region',translate:'регион',checked:false,blocked:false },
    { id:20634, word:'regular',translate:'регулярный',checked:false,blocked:false },
    { id:20635, word:'relationship',translate:'отношения',checked:false,blocked:false },
    { id:20636, word:'remove',translate:'удалить',checked:false,blocked:false },
    { id:20637, word:'repair',translate:'ремонт',checked:false,blocked:false },
    { id:20638, word:'replace',translate:'заменить',checked:false,blocked:false },
    { id:20639, word:'reply',translate:'ответить',checked:false,blocked:false },
    { id:20640, word:'report',translate:'отчёт',checked:false,blocked:false },
    { id:20641, word:'reporter',translate:'репортер',checked:false,blocked:false },
    { id:20642, word:'request',translate:'запрос',checked:false,blocked:false },
    { id:20643, word:'research',translate:'исследование',checked:false,blocked:false },
    { id:20644, word:'researcher',translate:'исследователь',checked:false,blocked:false },
    { id:20645, word:'respond',translate:'ответить',checked:false,blocked:false },
    { id:20646, word:'response',translate:'ответ',checked:false,blocked:false },
    { id:20647, word:'rest',translate:'отдых',checked:false,blocked:false },
    { id:20648, word:'review',translate:'обзор',checked:false,blocked:false },
    { id:20649, word:'ride',translate:'ехать',checked:false,blocked:false },
    { id:20650, word:'ring',translate:'кольцо',checked:false,blocked:false },
    { id:20651, word:'rise',translate:'rise',checked:false,blocked:false },
    { id:20652, word:'rock',translate:'скала',checked:false,blocked:false },
    { id:20653, word:'rock',translate:'скала',checked:false,blocked:false },
    { id:20654, word:'role',translate:'роль',checked:false,blocked:false },
    { id:20655, word:'roof',translate:'крыша',checked:false,blocked:false },
    { id:20656, word:'round',translate:'круглый',checked:false,blocked:false },
    { id:20657, word:'route',translate:'маршрут',checked:false,blocked:false },
    { id:20658, word:'rubbish',translate:'мусор',checked:false,blocked:false },
    { id:20659, word:'rude',translate:'грубость',checked:false,blocked:false },
    { id:20660, word:'run',translate:'бежать',checked:false,blocked:false },
    { id:20661, word:'runner',translate:'бегун',checked:false,blocked:false },
    { id:20662, word:'running',translate:'бег',checked:false,blocked:false },
    { id:20663, word:'sadly',translate:'к сожалению',checked:false,blocked:false },
    { id:20664, word:'safe',translate:'безопасный',checked:false,blocked:false },
    { id:20665, word:'sail',translate:'парус',checked:false,blocked:false },
    { id:20666, word:'sailing',translate:'плавание',checked:false,blocked:false },
    { id:20667, word:'salary',translate:'зарплата',checked:false,blocked:false },
    { id:20668, word:'sale',translate:'продажа',checked:false,blocked:false },
    { id:20669, word:'sauce',translate:'соус',checked:false,blocked:false },
    { id:20670, word:'save',translate:'сохранить',checked:false,blocked:false },
    { id:20671, word:'scared',translate:'страшно',checked:false,blocked:false },
    { id:20672, word:'scary',translate:'страшно',checked:false,blocked:false },
    { id:20673, word:'scene',translate:'сцена',checked:false,blocked:false },
    { id:20674, word:'schedule',translate:'расписание',checked:false,blocked:false },
    { id:20675, word:'score',translate:'оценка',checked:false,blocked:false },
    { id:20676, word:'screen',translate:'экран',checked:false,blocked:false },
    { id:20677, word:'search',translate:'поиск',checked:false,blocked:false },
    { id:20678, word:'season',translate:'сезон',checked:false,blocked:false },
    { id:20679, word:'seat',translate:'место',checked:false,blocked:false },
    { id:20681, word:'secondly',translate:'во-вторых',checked:false,blocked:false },
    { id:20682, word:'secret',translate:'секрет',checked:false,blocked:false },
    { id:20683, word:'secretary',translate:'секретарь',checked:false,blocked:false },
    { id:20684, word:'seem',translate:'казаться',checked:false,blocked:false },
    { id:20685, word:'sense',translate:'чувствовать',checked:false,blocked:false },
    { id:20686, word:'separate',translate:'отдельный',checked:false,blocked:false },
    { id:20687, word:'series',translate:'серия',checked:false,blocked:false },
    { id:20688, word:'serious',translate:'серьезный',checked:false,blocked:false },
    { id:20689, word:'serve',translate:'служить',checked:false,blocked:false },
    { id:20690, word:'service',translate:'обслуживание',checked:false,blocked:false },
    { id:20691, word:'several',translate:'несколько',checked:false,blocked:false },
    { id:20692, word:'shake',translate:'встряхнуть',checked:false,blocked:false },
    { id:20693, word:'shall',translate:'будет',checked:false,blocked:false },
    { id:20694, word:'shape',translate:'форма',checked:false,blocked:false },
    { id:20695, word:'sheet',translate:'лист',checked:false,blocked:false },
    { id:20696, word:'ship',translate:'корабль',checked:false,blocked:false },
    { id:20697, word:'shoulder',translate:'плечо',checked:false,blocked:false },
    { id:20698, word:'shout',translate:'кричать',checked:false,blocked:false },
    { id:20699, word:'shut',translate:'закрывать',checked:false,blocked:false },
    { id:20700, word:'side',translate:'сторона',checked:false,blocked:false },
    { id:20701, word:'sign',translate:'знак',checked:false,blocked:false },
    { id:20702, word:'silver',translate:'серебро',checked:false,blocked:false },
    { id:20703, word:'simple',translate:'просто',checked:false,blocked:false },
    { id:20704, word:'since',translate:'с',checked:false,blocked:false },
    { id:20705, word:'singing',translate:'пение',checked:false,blocked:false },
    { id:20706, word:'single',translate:'один',checked:false,blocked:false },
    { id:20707, word:'sir',translate:'сэр',checked:false,blocked:false },
    { id:20708, word:'site',translate:'сайт',checked:false,blocked:false },
    { id:20709, word:'size',translate:'размер',checked:false,blocked:false },
    { id:20710, word:'ski',translate:'лыжи',checked:false,blocked:false },
    { id:20711, word:'skiing',translate:'катание на лыжах',checked:false,blocked:false },
    { id:20712, word:'skin',translate:'кожа',checked:false,blocked:false },
    { id:20713, word:'sky',translate:'небо',checked:false,blocked:false },
    { id:20714, word:'sleep',translate:'спать',checked:false,blocked:false },
    { id:20715, word:'slowly',translate:'медленно',checked:false,blocked:false },
    { id:20716, word:'smartphone',translate:'смартфон',checked:false,blocked:false },
    { id:20717, word:'smell',translate:'запах',checked:false,blocked:false },
    { id:20718, word:'smile',translate:'улыбка',checked:false,blocked:false },
    { id:20719, word:'smoke',translate:'курить',checked:false,blocked:false },
    { id:20720, word:'smoking',translate:'курение',checked:false,blocked:false },
    { id:20721, word:'soap',translate:'мыло',checked:false,blocked:false },
    { id:20722, word:'soccer',translate:'футбол',checked:false,blocked:false },
    { id:20723, word:'social',translate:'общество',checked:false,blocked:false },
    { id:20724, word:'society',translate:'общество',checked:false,blocked:false },
    { id:20725, word:'sock',translate:'носок',checked:false,blocked:false },
    { id:20726, word:'soft',translate:'мягкий',checked:false,blocked:false },
    { id:20727, word:'soldier',translate:'солдат',checked:false,blocked:false },
    { id:20728, word:'solution',translate:'решение',checked:false,blocked:false },
    { id:20729, word:'solve',translate:'решить',checked:false,blocked:false },
    { id:20730, word:'somewhere',translate:'где-то',checked:false,blocked:false },
    { id:20731, word:'sort',translate:'сортировать',checked:false,blocked:false },
    { id:20732, word:'source',translate:'источник',checked:false,blocked:false },
    { id:20733, word:'speaker',translate:'докладчик',checked:false,blocked:false },
    { id:20734, word:'specific',translate:'конкретный',checked:false,blocked:false },
    { id:20735, word:'speech',translate:'речь',checked:false,blocked:false },
    { id:20736, word:'speed',translate:'скорость',checked:false,blocked:false },
    { id:20737, word:'spider',translate:'паук',checked:false,blocked:false },
    { id:20738, word:'spoon',translate:'ложка',checked:false,blocked:false },
    { id:20739, word:'square',translate:'квадрат',checked:false,blocked:false },
    { id:20740, word:'stage',translate:'сцена',checked:false,blocked:false },
    { id:20741, word:'stair',translate:'лестница',checked:false,blocked:false },
    { id:20742, word:'stamp',translate:'штамп',checked:false,blocked:false },
    { id:20743, word:'star',translate:'звезда',checked:false,blocked:false },
    { id:20744, word:'start',translate:'начало',checked:false,blocked:false },
    { id:20745, word:'state',translate:'состояние',checked:false,blocked:false },
    { id:20746, word:'stay',translate:'остаться',checked:false,blocked:false },
    { id:20747, word:'steal',translate:'украсть',checked:false,blocked:false },
    { id:20748, word:'step',translate:'шаг',checked:false,blocked:false },
    { id:20749, word:'stomach',translate:'желудок',checked:false,blocked:false },
    { id:20750, word:'stone',translate:'камень',checked:false,blocked:false },
    { id:20751, word:'store',translate:'магазин',checked:false,blocked:false },
    { id:20752, word:'storm',translate:'шторм',checked:false,blocked:false },
    { id:20753, word:'straight',translate:'прямой',checked:false,blocked:false },
    { id:20754, word:'strange',translate:'странный',checked:false,blocked:false },
    { id:20755, word:'strategy',translate:'стратегия',checked:false,blocked:false },
    { id:20756, word:'stress',translate:'стресс',checked:false,blocked:false },
    { id:20757, word:'structure',translate:'структура',checked:false,blocked:false },
    { id:20758, word:'stupid',translate:'глупый',checked:false,blocked:false },
    { id:20759, word:'succeed',translate:'преуспеть',checked:false,blocked:false },
    { id:20760, word:'successful',translate:'успешный',checked:false,blocked:false },
    { id:20761, word:'such',translate:'такой',checked:false,blocked:false },
    { id:20762, word:'suddenly',translate:'внезапно',checked:false,blocked:false },
    { id:20763, word:'suggest',translate:'предложение',checked:false,blocked:false },
    { id:20764, word:'suggestion',translate:'предложение',checked:false,blocked:false },
    { id:20765, word:'suit',translate:'соответствовать',checked:false,blocked:false },
    { id:20766, word:'support',translate:'поддержка',checked:false,blocked:false },
    { id:20767, word:'suppose',translate:'предположить',checked:false,blocked:false },
    { id:20768, word:'sure',translate:'уверен',checked:false,blocked:false },
    { id:20769, word:'surprise',translate:'сюрприз',checked:false,blocked:false },
    { id:20770, word:'surprised',translate:'удивленный',checked:false,blocked:false },
    { id:20771, word:'surprising',translate:'удивление',checked:false,blocked:false },
    { id:20772, word:'survey',translate:'опрос',checked:false,blocked:false },
    { id:20773, word:'sweet',translate:'сладкий',checked:false,blocked:false },
    { id:20774, word:'symbol',translate:'символ',checked:false,blocked:false },
    { id:20775, word:'system',translate:'система',checked:false,blocked:false },
    { id:20776, word:'tablet',translate:'планшет',checked:false,blocked:false },
    { id:20777, word:'talk',translate:'говорить',checked:false,blocked:false },
    { id:20778, word:'target',translate:'цель',checked:false,blocked:false },
    { id:20779, word:'task',translate:'задача',checked:false,blocked:false },
    { id:20780, word:'taste',translate:'вкус',checked:false,blocked:false },
    { id:20781, word:'teaching',translate:'обучение',checked:false,blocked:false },
    { id:20782, word:'technology',translate:'технология',checked:false,blocked:false },
    { id:20783, word:'teenage',translate:'подросток',checked:false,blocked:false },
    { id:20784, word:'temperature',translate:'температура',checked:false,blocked:false },
    { id:20785, word:'term',translate:'срок',checked:false,blocked:false },
    { id:20786, word:'text',translate:'текст',checked:false,blocked:false },
    { id:20787, word:'themselves',translate:'сами',checked:false,blocked:false },
    { id:20788, word:'thick',translate:'толстый',checked:false,blocked:false },
    { id:20789, word:'thief',translate:'вор',checked:false,blocked:false },
    { id:20790, word:'thin',translate:'тонкий',checked:false,blocked:false },
    { id:20791, word:'thinking',translate:'думать',checked:false,blocked:false },
    { id:20792, word:'third',translate:'третий',checked:false,blocked:false },
    { id:20793, word:'thought',translate:'мысль',checked:false,blocked:false },
    { id:20794, word:'throw',translate:'бросить',checked:false,blocked:false },
    { id:20795, word:'tidy',translate:'привести в порядок',checked:false,blocked:false },
    { id:20796, word:'tie',translate:'галстук',checked:false,blocked:false },
    { id:20797, word:'tip',translate:'наконечник',checked:false,blocked:false },
    { id:20798, word:'tool',translate:'инструмент',checked:false,blocked:false },
    { id:20799, word:'top',translate:'верх',checked:false,blocked:false },
    { id:20800, word:'touch',translate:'прикосновение',checked:false,blocked:false },
    { id:20801, word:'tour',translate:'тур',checked:false,blocked:false },
    { id:20802, word:'tourism',translate:'туризм',checked:false,blocked:false },
    { id:20803, word:'towards',translate:'по направлению',checked:false,blocked:false },
    { id:20804, word:'towel',translate:'полотенце',checked:false,blocked:false },
    { id:20805, word:'tower',translate:'башня',checked:false,blocked:false },
    { id:20806, word:'toy',translate:'игрушка',checked:false,blocked:false },
    { id:20807, word:'track',translate:'трек',checked:false,blocked:false },
    { id:20808, word:'tradition',translate:'традиция',checked:false,blocked:false },
    { id:20809, word:'traditional',translate:'традиционный',checked:false,blocked:false },
    { id:20810, word:'train',translate:'поезд',checked:false,blocked:false },
    { id:20811, word:'trainer',translate:'тренажер',checked:false,blocked:false },
    { id:20812, word:'training',translate:'обучение',checked:false,blocked:false },
    { id:20813, word:'transport',translate:'транспорт',checked:false,blocked:false },
    { id:20814, word:'traveller',translate:'путешественник',checked:false,blocked:false },
    { id:20815, word:'trouble',translate:'проблема',checked:false,blocked:false },
    { id:20816, word:'truck',translate:'грузовик',checked:false,blocked:false },
    { id:20817, word:'twin',translate:'близнец',checked:false,blocked:false },
    { id:20818, word:'typical',translate:'типичный',checked:false,blocked:false },
    { id:20819, word:'underground',translate:'метро',checked:false,blocked:false },
    { id:20820, word:'understanding',translate:'понимание',checked:false,blocked:false },
    { id:20821, word:'unfortunately',translate:'к сожалению',checked:false,blocked:false },
    { id:20822, word:'unhappy',translate:'несчастный',checked:false,blocked:false },
    { id:20823, word:'uniform',translate:'униформа',checked:false,blocked:false },
    { id:20824, word:'unit',translate:'подразделение',checked:false,blocked:false },
    { id:20825, word:'united',translate:'единый',checked:false,blocked:false },
    { id:20826, word:'unusual',translate:'необычный',checked:false,blocked:false },
    { id:20827, word:'upstairs',translate:'наверху',checked:false,blocked:false },
    { id:20828, word:'use',translate:'использовать',checked:false,blocked:false },
    { id:20829, word:'used',translate:'использовано',checked:false,blocked:false },
    { id:20830, word:'user',translate:'пользователь',checked:false,blocked:false },
    { id:20831, word:'usual',translate:'обычный',checked:false,blocked:false },
    { id:20832, word:'valley',translate:'долина',checked:false,blocked:false },
    { id:20833, word:'van',translate:'фургон',checked:false,blocked:false },
    { id:20834, word:'variety',translate:'разнообразие',checked:false,blocked:false },
    { id:20835, word:'vehicle',translate:'транспортное средство',checked:false,blocked:false },
    { id:20836, word:'view',translate:'вид',checked:false,blocked:false },
    { id:20837, word:'virus',translate:'вирус',checked:false,blocked:false },
    { id:20838, word:'voice',translate:'голос',checked:false,blocked:false },
    { id:20839, word:'wait',translate:'ждать',checked:false,blocked:false },
    { id:20840, word:'war',translate:'война',checked:false,blocked:false },
    { id:20841, word:'wash',translate:'мыть',checked:false,blocked:false },
    { id:20842, word:'washing',translate:'мытьё',checked:false,blocked:false },
    { id:20843, word:'wave',translate:'волна',checked:false,blocked:false },
    { id:20844, word:'weak',translate:'слабый',checked:false,blocked:false },
    { id:20845, word:'web',translate:'веб',checked:false,blocked:false },
    { id:20846, word:'wedding',translate:'свадьба',checked:false,blocked:false },
    { id:20847, word:'weight',translate:'вес',checked:false,blocked:false },
    { id:20848, word:'welcome',translate:'добро пожаловать',checked:false,blocked:false },
    { id:20849, word:'wet',translate:'мокрый',checked:false,blocked:false },
    { id:20850, word:'wheel',translate:'колесо',checked:false,blocked:false },
    { id:20851, word:'while',translate:'пока',checked:false,blocked:false },
    { id:20852, word:'whole',translate:'весь',checked:false,blocked:false },
    { id:20853, word:'whose',translate:'чей',checked:false,blocked:false },
    { id:20854, word:'wide',translate:'широкий',checked:false,blocked:false },
    { id:20855, word:'wild',translate:'дикий',checked:false,blocked:false },
    { id:20856, word:'wind',translate:'ветер',checked:false,blocked:false },
    { id:20857, word:'winner',translate:'победитель',checked:false,blocked:false },
    { id:20858, word:'wish',translate:'желание',checked:false,blocked:false },
    { id:20859, word:'wood',translate:'дерево',checked:false,blocked:false },
    { id:20860, word:'wooden',translate:'дерево',checked:false,blocked:false },
    { id:20861, word:'working',translate:'работа',checked:false,blocked:false },
    { id:20862, word:'worried',translate:'беспокойство',checked:false,blocked:false },
    { id:20863, word:'worry',translate:'беспокоиться',checked:false,blocked:false },
    { id:20864, word:'worse',translate:'хуже',checked:false,blocked:false },
    { id:20865, word:'worst',translate:'худший',checked:false,blocked:false },
    { id:20866, word:'wow',translate:'ничего себе',checked:false,blocked:false },
    { id:20867, word:'yet',translate:'пока',checked:false,blocked:false },
    { id:20868, word:'yours',translate:'ваш',checked:false,blocked:false },
    { id:20869, word:'zero',translate:'ноль',checked:false,blocked:false }
  ];


export const b1 = [
    { id:30001, word:'absolutely',translate:'абсолютно',checked:false,blocked:false },
    { id:30002, word:'academic',translate:'академический',checked:false,blocked:false },
    { id:30003, word:'access',translate:'доступ',checked:false,blocked:false },
    { id:30004, word:'accommodation',translate:'проживание',checked:false,blocked:false },
    { id:30005, word:'account',translate:'счет',checked:false,blocked:false },
    { id:30006, word:'achievement',translate:'достижение',checked:false,blocked:false },
    { id:30007, word:'act',translate:'акт',checked:false,blocked:false },
    { id:30008, word:'ad',translate:'объявление',checked:false,blocked:false },
    { id:30009, word:'addition',translate:'дополнение',checked:false,blocked:false },
    { id:30010, word:'admire',translate:'восхищаться',checked:false,blocked:false },
    { id:30011, word:'admit',translate:'признать',checked:false,blocked:false },
    { id:30012, word:'advanced',translate:'продвинутый',checked:false,blocked:false },
    { id:30013, word:'advise',translate:'советовать',checked:false,blocked:false },
    { id:30014, word:'afford',translate:'позволить себе',checked:false,blocked:false },
    { id:30015, word:'age',translate:'возраст',checked:false,blocked:false },
    { id:30016, word:'aged',translate:'пожилой',checked:false,blocked:false },
    { id:30017, word:'agent',translate:'агент',checked:false,blocked:false },
    { id:30018, word:'agreement',translate:'соглашение',checked:false,blocked:false },
    { id:30019, word:'ahead',translate:'впереди',checked:false,blocked:false },
    { id:30020, word:'aim',translate:'цель',checked:false,blocked:false },
    { id:30021, word:'alarm',translate:'сигнализация',checked:false,blocked:false },
    { id:30022, word:'album',translate:'альбом',checked:false,blocked:false },
    { id:30023, word:'alcohol',translate:'алкоголь',checked:false,blocked:false },
    { id:30024, word:'alcoholic',translate:'алкоголь',checked:false,blocked:false },
    { id:30025, word:'alternative',translate:'альтернатива',checked:false,blocked:false },
    { id:30026, word:'amazed',translate:'пораженный',checked:false,blocked:false },
    { id:30027, word:'ambition',translate:'амбиции',checked:false,blocked:false },
    { id:30028, word:'ambitious',translate:'амбициозный',checked:false,blocked:false },
    { id:30029, word:'analyse',translate:'анализировать',checked:false,blocked:false },
    { id:30030, word:'analysis',translate:'анализ',checked:false,blocked:false },
    { id:30031, word:'announce',translate:'объявление',checked:false,blocked:false },
    { id:30032, word:'announcement',translate:'объявление',checked:false,blocked:false },
    { id:30033, word:'annoy',translate:'раздражать',checked:false,blocked:false },
    { id:30034, word:'annoyed',translate:'надоедливый',checked:false,blocked:false },
    { id:30035, word:'annoying',translate:'надоедливый',checked:false,blocked:false },
    { id:30036, word:'apart',translate:'отдельно',checked:false,blocked:false },
    { id:30037, word:'apologize',translate:'извиниться',checked:false,blocked:false },
    { id:30038, word:'application',translate:'заявка',checked:false,blocked:false },
    { id:30039, word:'appointment',translate:'назначение',checked:false,blocked:false },
    { id:30040, word:'appreciate',translate:'ценить',checked:false,blocked:false },
    { id:30041, word:'approximately',translate:'примерно',checked:false,blocked:false },
    { id:30042, word:'arrest',translate:'арест',checked:false,blocked:false },
    { id:30043, word:'arrival',translate:'прибытие',checked:false,blocked:false },
    { id:30044, word:'assignment',translate:'назначение',checked:false,blocked:false },
    { id:30045, word:'assist',translate:'помощь',checked:false,blocked:false },
    { id:30046, word:'atmosphere',translate:'атмосфера',checked:false,blocked:false },
    { id:30047, word:'attach',translate:'прикрепить',checked:false,blocked:false },
    { id:30048, word:'attitude',translate:'отношение',checked:false,blocked:false },
    { id:30049, word:'attract',translate:'привлекать',checked:false,blocked:false },
    { id:30050, word:'attraction',translate:'привлечение',checked:false,blocked:false },
    { id:30051, word:'authority',translate:'авторитет',checked:false,blocked:false },
    { id:30052, word:'average',translate:'средний',checked:false,blocked:false },
    { id:30053, word:'award',translate:'награда',checked:false,blocked:false },
    { id:30054, word:'aware',translate:'знать',checked:false,blocked:false },
    { id:30055, word:'backwards',translate:'назад',checked:false,blocked:false },
    { id:30056, word:'bake',translate:'выпечка',checked:false,blocked:false },
    { id:30057, word:'balance',translate:'баланс',checked:false,blocked:false },
    { id:30058, word:'ban',translate:'запрет',checked:false,blocked:false },
    { id:30059, word:'bank',translate:'банк',checked:false,blocked:false },
    { id:30060, word:'base',translate:'база',checked:false,blocked:false },
    { id:30061, word:'basic',translate:'основной',checked:false,blocked:false },
    { id:30062, word:'basis',translate:'основа',checked:false,blocked:false },
    { id:30063, word:'battery',translate:'батарея',checked:false,blocked:false },
    { id:30064, word:'battle',translate:'битва',checked:false,blocked:false },
    { id:30065, word:'beauty',translate:'красота',checked:false,blocked:false },
    { id:30066, word:'bee',translate:'пчела',checked:false,blocked:false },
    { id:30067, word:'belief',translate:'вера',checked:false,blocked:false },
    { id:30068, word:'bell',translate:'колокол',checked:false,blocked:false },
    { id:30069, word:'bend',translate:'изгиб',checked:false,blocked:false },
    { id:30070, word:'benefit',translate:'польза',checked:false,blocked:false },
    { id:30071, word:'better',translate:'лучше',checked:false,blocked:false },
    { id:30072, word:'bite',translate:'укус',checked:false,blocked:false },
    { id:30073, word:'block',translate:'блок',checked:false,blocked:false },
    { id:30074, word:'board',translate:'доска',checked:false,blocked:false },
    { id:30075, word:'bomb',translate:'бомба',checked:false,blocked:false },
    { id:30076, word:'border',translate:'граница',checked:false,blocked:false },
    { id:30077, word:'bother',translate:'беспокоить',checked:false,blocked:false },
    { id:30078, word:'branch',translate:'ветка',checked:false,blocked:false },
    { id:30079, word:'brand',translate:'бренд',checked:false,blocked:false },
    { id:30080, word:'brave',translate:'храбрый',checked:false,blocked:false },
    { id:30081, word:'breath',translate:'дышать',checked:false,blocked:false },
    { id:30082, word:'breathe',translate:'дышать',checked:false,blocked:false },
    { id:30083, word:'breathing',translate:'дыхание',checked:false,blocked:false },
    { id:30084, word:'bride',translate:'невеста',checked:false,blocked:false },
    { id:30085, word:'bubble',translate:'пузырь',checked:false,blocked:false },
    { id:30086, word:'bury',translate:'похоронить',checked:false,blocked:false },
    { id:30087, word:'by',translate:'на',checked:false,blocked:false },
    { id:30088, word:'calm',translate:'спокойствие',checked:false,blocked:false },
    { id:30089, word:'campaign',translate:'кампания',checked:false,blocked:false },
    { id:30090, word:'campus',translate:'кампус',checked:false,blocked:false },
    { id:30091, word:'candidate',translate:'кандидат',checked:false,blocked:false },
    { id:30092, word:'cap',translate:'кэп',checked:false,blocked:false },
    { id:30093, word:'captain',translate:'капитан',checked:false,blocked:false },
    { id:30094, word:'careless',translate:'беспечный',checked:false,blocked:false },
    { id:30095, word:'category',translate:'категория',checked:false,blocked:false },
    { id:30096, word:'ceiling',translate:'потолок',checked:false,blocked:false },
    { id:30097, word:'celebration',translate:'праздник',checked:false,blocked:false },
    { id:30098, word:'central',translate:'центр',checked:false,blocked:false },
    { id:30099, word:'centre',translate:'центр',checked:false,blocked:false },
    { id:30100, word:'ceremony',translate:'церемония',checked:false,blocked:false },
    { id:30101, word:'chain',translate:'цепь',checked:false,blocked:false },
    { id:30102, word:'challenge',translate:'вызов',checked:false,blocked:false },
    { id:30103, word:'champion',translate:'чемпион',checked:false,blocked:false },
    { id:30104, word:'channel',translate:'канал',checked:false,blocked:false },
    { id:30105, word:'chapter',translate:'глава',checked:false,blocked:false },
    { id:30106, word:'charge',translate:'заряд',checked:false,blocked:false },
    { id:30107, word:'cheap',translate:'дешевый',checked:false,blocked:false },
    { id:30108, word:'cheat',translate:'чит',checked:false,blocked:false },
    { id:30109, word:'cheerful',translate:'веселый',checked:false,blocked:false },
    { id:30110, word:'chemical',translate:'химический',checked:false,blocked:false },
    { id:30111, word:'chest',translate:'грудь',checked:false,blocked:false },
    { id:30112, word:'childhood',translate:'детство',checked:false,blocked:false },
    { id:30113, word:'claim',translate:'претензия',checked:false,blocked:false },
    { id:30114, word:'clause',translate:'статья',checked:false,blocked:false },
    { id:30115, word:'clear',translate:'ясно',checked:false,blocked:false },
    { id:30116, word:'click',translate:'нажмите',checked:false,blocked:false },
    { id:30117, word:'client',translate:'клиент',checked:false,blocked:false },
    { id:30118, word:'climb',translate:'подняться',checked:false,blocked:false },
    { id:30119, word:'close',translate:'закрыть',checked:false,blocked:false },
    { id:30120, word:'cloth',translate:'ткань',checked:false,blocked:false },
    { id:30121, word:'clue',translate:'подсказка',checked:false,blocked:false },
    { id:30122, word:'coach',translate:'тренер',checked:false,blocked:false },
    { id:30123, word:'coal',translate:'уголь',checked:false,blocked:false },
    { id:30124, word:'coin',translate:'монета',checked:false,blocked:false },
    { id:30125, word:'collection',translate:'коллекция',checked:false,blocked:false },
    { id:30126, word:'coloured',translate:'цветные',checked:false,blocked:false },
    { id:30127, word:'combine',translate:'комбайн',checked:false,blocked:false },
    { id:30128, word:'comment',translate:'комментарий',checked:false,blocked:false },
    { id:30129, word:'commercial',translate:'коммерческий',checked:false,blocked:false },
    { id:30130, word:'commit',translate:'совершать',checked:false,blocked:false },
    { id:30131, word:'communication',translate:'коммуникация',checked:false,blocked:false },
    { id:30132, word:'comparison',translate:'сравнение',checked:false,blocked:false },
    { id:30133, word:'competitor',translate:'конкурент',checked:false,blocked:false },
    { id:30134, word:'competitive',translate:'конкурент',checked:false,blocked:false },
    { id:30135, word:'complaint',translate:'жалоба',checked:false,blocked:false },
    { id:30136, word:'complex',translate:'комплекс',checked:false,blocked:false },
    { id:30137, word:'concentrate',translate:'концентрат',checked:false,blocked:false },
    { id:30138, word:'conclude',translate:'заключение',checked:false,blocked:false },
    { id:30139, word:'conclusion',translate:'заключение',checked:false,blocked:false },
    { id:30140, word:'confident',translate:'уверенность',checked:false,blocked:false },
    { id:30141, word:'confirm',translate:'подтвердить',checked:false,blocked:false },
    { id:30142, word:'confuse',translate:'путать',checked:false,blocked:false },
    { id:30143, word:'confused',translate:'путать',checked:false,blocked:false },
    { id:30144, word:'connection',translate:'связь',checked:false,blocked:false },
    { id:30145, word:'consequence',translate:'следствие',checked:false,blocked:false },
    { id:30146, word:'consist',translate:'состоять',checked:false,blocked:false },
    { id:30147, word:'consume',translate:'потреблять',checked:false,blocked:false },
    { id:30148, word:'consumer',translate:'потребитель',checked:false,blocked:false },
    { id:30149, word:'contact',translate:'контакт',checked:false,blocked:false },
    { id:30150, word:'container',translate:'контейнер',checked:false,blocked:false },
    { id:30151, word:'content',translate:'содержание',checked:false,blocked:false },
    { id:30152, word:'continuous',translate:'непрерывный',checked:false,blocked:false },
    { id:30153, word:'contrast',translate:'контраст',checked:false,blocked:false },
    { id:30154, word:'convenient',translate:'удобно',checked:false,blocked:false },
    { id:30155, word:'convince',translate:'убедить',checked:false,blocked:false },
    { id:30156, word:'cool',translate:'круто',checked:false,blocked:false },
    { id:30157, word:'costume',translate:'костюм',checked:false,blocked:false },
    { id:30158, word:'cottage',translate:'коттедж',checked:false,blocked:false },
    { id:30159, word:'cotton',translate:'хлопок',checked:false,blocked:false },
    { id:30160, word:'count',translate:'считать',checked:false,blocked:false },
    { id:30161, word:'countryside',translate:'сельская местность',checked:false,blocked:false },
    { id:30162, word:'court',translate:'суд',checked:false,blocked:false },
    { id:30163, word:'cover',translate:'покрыть',checked:false,blocked:false },
    { id:30164, word:'covered',translate:'крытый',checked:false,blocked:false },
    { id:30165, word:'cream',translate:'крем',checked:false,blocked:false },
    { id:30166, word:'criminal',translate:'преступник',checked:false,blocked:false },
    { id:30167, word:'cruel',translate:'жестокий',checked:false,blocked:false },
    { id:30168, word:'cultural',translate:'культура',checked:false,blocked:false },
    { id:30169, word:'currency',translate:'валюта',checked:false,blocked:false },
    { id:30170, word:'current',translate:'настоящее время',checked:false,blocked:false },
    { id:30171, word:'currently',translate:'в настоящее время',checked:false,blocked:false },
    { id:30172, word:'curtain',translate:'занавес',checked:false,blocked:false },
    { id:30173, word:'custom',translate:'заказ',checked:false,blocked:false },
    { id:30174, word:'cut',translate:'разрез',checked:false,blocked:false },
    { id:30175, word:'daily',translate:'ежедневно',checked:false,blocked:false },
    { id:30176, word:'damage',translate:'ущерб',checked:false,blocked:false },
    { id:30177, word:'deal',translate:'сделка',checked:false,blocked:false },
    { id:30178, word:'decade',translate:'декада',checked:false,blocked:false },
    { id:30179, word:'decorate',translate:'украсить',checked:false,blocked:false },
    { id:30180, word:'deep',translate:'глубокий',checked:false,blocked:false },
    { id:30181, word:'define',translate:'определить',checked:false,blocked:false },
    { id:30182, word:'definite',translate:'определенный',checked:false,blocked:false },
    { id:30183, word:'definition',translate:'определение',checked:false,blocked:false },
    { id:30184, word:'deliver',translate:'доставить',checked:false,blocked:false },
    { id:30185, word:'departure',translate:'отъезд',checked:false,blocked:false },
    { id:30186, word:'despite',translate:'несмотря на',checked:false,blocked:false },
    { id:30187, word:'destination',translate:'место назначения',checked:false,blocked:false },
    { id:30188, word:'determine',translate:'определить',checked:false,blocked:false },
    { id:30189, word:'determined',translate:'определенный',checked:false,blocked:false },
    { id:30190, word:'development',translate:'развитие',checked:false,blocked:false },
    { id:30191, word:'diagram',translate:'диаграмма',checked:false,blocked:false },
    { id:30192, word:'diamond',translate:'бриллиант',checked:false,blocked:false },
    { id:30193, word:'difficulty',translate:'трудность',checked:false,blocked:false },
    { id:30194, word:'direct',translate:'прямой',checked:false,blocked:false },
    { id:30195, word:'directly',translate:'непосредственно',checked:false,blocked:false },
    { id:30196, word:'dirt',translate:'грязь',checked:false,blocked:false },
    { id:30197, word:'disadvantage',translate:'недостаток',checked:false,blocked:false },
    { id:30198, word:'disappointed',translate:'разочарованный',checked:false,blocked:false },
    { id:30199, word:'disappointing',translate:'разочарование',checked:false,blocked:false },
    { id:30200, word:'discount',translate:'скидка',checked:false,blocked:false },
    { id:30201, word:'dislike',translate:'не любить',checked:false,blocked:false },
    { id:30202, word:'divide',translate:'разделять',checked:false,blocked:false },
    { id:30203, word:'documentary',translate:'документальный фильм',checked:false,blocked:false },
    { id:30204, word:'donate',translate:'пожертвовать',checked:false,blocked:false },
    { id:30205, word:'double',translate:'двойной',checked:false,blocked:false },
    { id:30206, word:'doubt',translate:'сомневаться',checked:false,blocked:false },
    { id:30207, word:'dressed',translate:'одетый',checked:false,blocked:false },
    { id:30208, word:'drop',translate:'капля',checked:false,blocked:false },
    { id:30209, word:'drum',translate:'барабан',checked:false,blocked:false },
    { id:30210, word:'drunk',translate:'пьяный',checked:false,blocked:false },
    { id:30211, word:'due',translate:'должен',checked:false,blocked:false },
    { id:30212, word:'dust',translate:'пыль',checked:false,blocked:false },
    { id:30213, word:'duty',translate:'долг',checked:false,blocked:false },
    { id:30214, word:'earthquake',translate:'землетрясение',checked:false,blocked:false },
    { id:30215, word:'eastern',translate:'восток',checked:false,blocked:false },
    { id:30216, word:'economic',translate:'экономика',checked:false,blocked:false },
    { id:30217, word:'economy',translate:'экономика',checked:false,blocked:false },
    { id:30218, word:'edge',translate:'край',checked:false,blocked:false },
    { id:30219, word:'editor',translate:'редактор',checked:false,blocked:false },
    { id:30220, word:'educate',translate:'образовывать',checked:false,blocked:false },
    { id:30221, word:'educated',translate:'образованный',checked:false,blocked:false },
    { id:30222, word:'educational',translate:'образование',checked:false,blocked:false },
    { id:30223, word:'effective',translate:'эффективно',checked:false,blocked:false },
    { id:30224, word:'effectively',translate:'эффективно',checked:false,blocked:false },
    { id:30225, word:'effort',translate:'усилия',checked:false,blocked:false },
    { id:30226, word:'election',translate:'выборы',checked:false,blocked:false },
    { id:30227, word:'element',translate:'элемент',checked:false,blocked:false },
    { id:30228, word:'embarrassed',translate:'смущенный',checked:false,blocked:false },
    { id:30229, word:'embarrassing',translate:'смущение',checked:false,blocked:false },
    { id:30230, word:'emergency',translate:'чрезвычайная ситуация',checked:false,blocked:false },
    { id:30231, word:'emotion',translate:'эмоция',checked:false,blocked:false },
    { id:30232, word:'employment',translate:'занятость',checked:false,blocked:false },
    { id:30233, word:'empty',translate:'пустой',checked:false,blocked:false },
    { id:30234, word:'encourage',translate:'поощрять',checked:false,blocked:false },
    { id:30235, word:'enemy',translate:'враг',checked:false,blocked:false },
    { id:30236, word:'engaged',translate:'задействован',checked:false,blocked:false },
    { id:30237, word:'engineering',translate:'проектирование',checked:false,blocked:false },
    { id:30238, word:'entertain',translate:'развлекать',checked:false,blocked:false },
    { id:30239, word:'entertainment',translate:'развлечение',checked:false,blocked:false },
    { id:30240, word:'entrance',translate:'вход',checked:false,blocked:false },
    { id:30241, word:'entry',translate:'вход',checked:false,blocked:false },
    { id:30242, word:'environmental',translate:'экология',checked:false,blocked:false },
    { id:30243, word:'episode',translate:'эпизод',checked:false,blocked:false },
    { id:30244, word:'equal',translate:'равный',checked:false,blocked:false },
    { id:30245, word:'equally',translate:'одинаково',checked:false,blocked:false },
    { id:30246, word:'escape',translate:'побег',checked:false,blocked:false },
    { id:30247, word:'essential',translate:'важно',checked:false,blocked:false },
    { id:30248, word:'eventually',translate:'в конце концов',checked:false,blocked:false },
    { id:30249, word:'examine',translate:'изучить',checked:false,blocked:false },
    { id:30250, word:'except',translate:'кроме',checked:false,blocked:false },
    { id:30251, word:'exchange',translate:'обмен',checked:false,blocked:false },
    { id:30252, word:'excitement',translate:'волнение',checked:false,blocked:false },
    { id:30253, word:'exhibition',translate:'выставка',checked:false,blocked:false },
    { id:30254, word:'expand',translate:'расширяться',checked:false,blocked:false },
    { id:30255, word:'expected',translate:'ожидаемый',checked:false,blocked:false },
    { id:30256, word:'expedition',translate:'экспедиция',checked:false,blocked:false },
    { id:30257, word:'experience',translate:'опыт',checked:false,blocked:false },
    { id:30258, word:'experienced',translate:'опыт',checked:false,blocked:false },
    { id:30259, word:'experiment',translate:'эксперимент',checked:false,blocked:false },
    { id:30260, word:'explode',translate:'взорваться',checked:false,blocked:false },
    { id:30261, word:'explore',translate:'исследовать',checked:false,blocked:false },
    { id:30262, word:'explosion',translate:'взрыв',checked:false,blocked:false },
    { id:30263, word:'export',translate:'экспорт',checked:false,blocked:false },
    { id:30264, word:'extra',translate:'дополнительный',checked:false,blocked:false },
    { id:30265, word:'face',translate:'лицо',checked:false,blocked:false },
    { id:30266, word:'fairly',translate:'довольно',checked:false,blocked:false },
    { id:30267, word:'familiar',translate:'знакомый',checked:false,blocked:false },
    { id:30268, word:'fancy',translate:'причудливый',checked:false,blocked:false },
    { id:30269, word:'far',translate:'далеко',checked:false,blocked:false },
    { id:30270, word:'fascinating',translate:'очаровательный',checked:false,blocked:false },
    { id:30271, word:'fashionable',translate:'модный',checked:false,blocked:false },
    { id:30272, word:'fasten',translate:'застегнуть',checked:false,blocked:false },
    { id:30273, word:'favour',translate:'предпочтение',checked:false,blocked:false },
    { id:30274, word:'fear',translate:'страх',checked:false,blocked:false },
    { id:30275, word:'feature',translate:'особенность',checked:false,blocked:false },
    { id:30276, word:'fence',translate:'забор',checked:false,blocked:false },
    { id:30277, word:'fighting',translate:'борьба',checked:false,blocked:false },
    { id:30278, word:'file',translate:'файл',checked:false,blocked:false },
    { id:30279, word:'financial',translate:'финансы',checked:false,blocked:false },
    { id:30280, word:'fire',translate:'пожар',checked:false,blocked:false },
    { id:30281, word:'fitness',translate:'фитнес',checked:false,blocked:false },
    { id:30282, word:'fixed',translate:'фиксированный',checked:false,blocked:false },
    { id:30283, word:'flag',translate:'флаг',checked:false,blocked:false },
    { id:30284, word:'flood',translate:'наводнение',checked:false,blocked:false },
    { id:30285, word:'flour',translate:'мука',checked:false,blocked:false },
    { id:30286, word:'flow',translate:'поток',checked:false,blocked:false },
    { id:30287, word:'fold',translate:'складка',checked:false,blocked:false },
    { id:30288, word:'folk',translate:'народный',checked:false,blocked:false },
    { id:30289, word:'following',translate:'следующий',checked:false,blocked:false },
    { id:30290, word:'force',translate:'сила',checked:false,blocked:false },
    { id:30291, word:'forever',translate:'навсегда',checked:false,blocked:false },
    { id:30292, word:'frame',translate:'кадр',checked:false,blocked:false },
    { id:30293, word:'freeze',translate:'заморозить',checked:false,blocked:false },
    { id:30294, word:'frequently',translate:'часто',checked:false,blocked:false },
    { id:30295, word:'friendship',translate:'дружба',checked:false,blocked:false },
    { id:30296, word:'frighten',translate:'испугаться',checked:false,blocked:false },
    { id:30297, word:'frightened',translate:'испуганный',checked:false,blocked:false },
    { id:30298, word:'frightening',translate:'испуг',checked:false,blocked:false },
    { id:30299, word:'frozen',translate:'замороженный',checked:false,blocked:false },
    { id:30300, word:'fry',translate:'жарить',checked:false,blocked:false },
    { id:30301, word:'fuel',translate:'топливо',checked:false,blocked:false },
    { id:30302, word:'function',translate:'функция',checked:false,blocked:false },
    { id:30303, word:'fur',translate:'мех',checked:false,blocked:false },
    { id:30304, word:'further',translate:'далее',checked:false,blocked:false },
    { id:30305, word:'garage',translate:'гараж',checked:false,blocked:false },
    { id:30306, word:'gather',translate:'собирать',checked:false,blocked:false },
    { id:30307, word:'generally',translate:'в целом',checked:false,blocked:false },
    { id:30308, word:'generation',translate:'поколение',checked:false,blocked:false },
    { id:30309, word:'generous',translate:'щедрый',checked:false,blocked:false },
    { id:30310, word:'gentle',translate:'нежный',checked:false,blocked:false },
    { id:30311, word:'gentleman',translate:'джентльмен',checked:false,blocked:false },
    { id:30312, word:'ghost',translate:'призрак',checked:false,blocked:false },
    { id:30313, word:'giant',translate:'гигант',checked:false,blocked:false },
    { id:30314, word:'glad',translate:'радость',checked:false,blocked:false },
    { id:30315, word:'global',translate:'глобальный',checked:false,blocked:false },
    { id:30316, word:'glove',translate:'перчатка',checked:false,blocked:false },
    { id:30317, word:'go',translate:'идти',checked:false,blocked:false },
    { id:30318, word:'goods',translate:'товары',checked:false,blocked:false },
    { id:30319, word:'grade',translate:'сорт',checked:false,blocked:false },
    { id:30320, word:'graduate',translate:'выпускник',checked:false,blocked:false },
    { id:30321, word:'grain',translate:'зерно',checked:false,blocked:false },
    { id:30322, word:'grateful',translate:'благодарность',checked:false,blocked:false },
    { id:30323, word:'growth',translate:'рост',checked:false,blocked:false },
    { id:30324, word:'guard',translate:'охрана',checked:false,blocked:false },
    { id:30325, word:'guilty',translate:'виновный',checked:false,blocked:false },
    { id:30326, word:'hand',translate:'рука',checked:false,blocked:false },
    { id:30327, word:'hang',translate:'повесить',checked:false,blocked:false },
    { id:30328, word:'happiness',translate:'счастье',checked:false,blocked:false },
    { id:30329, word:'hardly',translate:'вряд ли',checked:false,blocked:false },
    { id:30330, word:'hate',translate:'ненависть',checked:false,blocked:false },
    { id:30331, word:'head',translate:'голова',checked:false,blocked:false },
    { id:30332, word:'headline',translate:'заголовок',checked:false,blocked:false },
    { id:30333, word:'heating',translate:'отопление',checked:false,blocked:false },
    { id:30334, word:'heavily',translate:'сильно',checked:false,blocked:false },
    { id:30335, word:'helicopter',translate:'вертолет',checked:false,blocked:false },
    { id:30336, word:'highlight',translate:'выделить',checked:false,blocked:false },
    { id:30337, word:'highly',translate:'очень',checked:false,blocked:false },
    { id:30338, word:'hire',translate:'прокат',checked:false,blocked:false },
    { id:30339, word:'historic',translate:'исторический',checked:false,blocked:false },
    { id:30340, word:'historical',translate:'исторический',checked:false,blocked:false },
    { id:30341, word:'honest',translate:'честный',checked:false,blocked:false },
    { id:30342, word:'horrible',translate:'ужас',checked:false,blocked:false },
    { id:30343, word:'horror',translate:'ужас',checked:false,blocked:false },
    { id:30344, word:'host',translate:'хозяин',checked:false,blocked:false },
    { id:30345, word:'hunt',translate:'охота',checked:false,blocked:false },
    { id:30346, word:'hurricane',translate:'ураган',checked:false,blocked:false },
    { id:30347, word:'hurry',translate:'спешить',checked:false,blocked:false },
    { id:30348, word:'identity',translate:'личность',checked:false,blocked:false },
    { id:30349, word:'ignore',translate:'игнорировать',checked:false,blocked:false },
    { id:30350, word:'illegal',translate:'нелегальный',checked:false,blocked:false },
    { id:30351, word:'imaginary',translate:'воображаемый',checked:false,blocked:false },
    { id:30352, word:'immediate',translate:'немедленно',checked:false,blocked:false },
    { id:30353, word:'immigrant',translate:'иммигрант',checked:false,blocked:false },
    { id:30354, word:'impact',translate:'влияние',checked:false,blocked:false },
    { id:30355, word:'import',translate:'импорт',checked:false,blocked:false },
    { id:30356, word:'importance',translate:'важность',checked:false,blocked:false },
    { id:30357, word:'impression',translate:'впечатление',checked:false,blocked:false },
    { id:30358, word:'impressive',translate:'впечатляющий',checked:false,blocked:false },
    { id:30359, word:'improvement',translate:'улучшение',checked:false,blocked:false },
    { id:30360, word:'incredibly',translate:'невероятно',checked:false,blocked:false },
    { id:30361, word:'indeed',translate:'действительно',checked:false,blocked:false },
    { id:30362, word:'indicate',translate:'указать',checked:false,blocked:false },
    { id:30363, word:'indirect',translate:'косвенный',checked:false,blocked:false },
    { id:30364, word:'indoor',translate:'в помещении',checked:false,blocked:false },
    { id:30365, word:'indoors',translate:'в помещении',checked:false,blocked:false },
    { id:30366, word:'influence',translate:'влияние',checked:false,blocked:false },
    { id:30367, word:'ingredient',translate:'ингредиент',checked:false,blocked:false },
    { id:30368, word:'injure',translate:'травма',checked:false,blocked:false },
    { id:30369, word:'injured',translate:'травма',checked:false,blocked:false },
    { id:30370, word:'innocent',translate:'невиновный',checked:false,blocked:false },
    { id:30371, word:'intelligence',translate:'разведка',checked:false,blocked:false },
    { id:30372, word:'intend',translate:'намерение',checked:false,blocked:false },
    { id:30373, word:'intention',translate:'намерение',checked:false,blocked:false },
    { id:30374, word:'invest',translate:'инвестировать',checked:false,blocked:false },
    { id:30375, word:'investigate',translate:'расследовать',checked:false,blocked:false },
    { id:30376, word:'involved',translate:'вовлеченный',checked:false,blocked:false },
    { id:30377, word:'iron',translate:'железо',checked:false,blocked:false },
    { id:30378, word:'issue',translate:'выпуск',checked:false,blocked:false },
    { id:30380, word:'journal',translate:'журнал',checked:false,blocked:false },
    { id:30381, word:'judge',translate:'судья',checked:false,blocked:false },
    { id:30382, word:'keen',translate:'увлеченный',checked:false,blocked:false },
    { id:30383, word:'key',translate:'ключ',checked:false,blocked:false },
    { id:30384, word:'keyboard',translate:'клавиатура',checked:false,blocked:false },
    { id:30385, word:'kick',translate:'удар',checked:false,blocked:false },
    { id:30386, word:'killing',translate:'убийство',checked:false,blocked:false },
    { id:30387, word:'kind',translate:'добрый',checked:false,blocked:false },
    { id:30388, word:'kiss',translate:'поцелуй',checked:false,blocked:false },
    { id:30389, word:'knock',translate:'стучать',checked:false,blocked:false },
    { id:30390, word:'label',translate:'этикетка',checked:false,blocked:false },
    { id:30391, word:'laboratory',translate:'лаборатория',checked:false,blocked:false },
    { id:30392, word:'lack',translate:'отсутствие',checked:false,blocked:false },
    { id:30393, word:'latest',translate:'последние',checked:false,blocked:false },
    { id:30394, word:'lay',translate:'проложить',checked:false,blocked:false },
    { id:30395, word:'layer',translate:'слой',checked:false,blocked:false },
    { id:30396, word:'lead',translate:'вести',checked:false,blocked:false },
    { id:30398, word:'leading',translate:'ведущий',checked:false,blocked:false },
    { id:30399, word:'leaf',translate:'лист',checked:false,blocked:false },
    { id:30400, word:'leather',translate:'кожа',checked:false,blocked:false },
    { id:30401, word:'legal',translate:'юридический',checked:false,blocked:false },
    { id:30402, word:'leisure',translate:'отдых',checked:false,blocked:false },
    { id:30403, word:'length',translate:'длина',checked:false,blocked:false },
    { id:30404, word:'level',translate:'уровень',checked:false,blocked:false },
    { id:30405, word:'like',translate:'как',checked:false,blocked:false },
    { id:30406, word:'limit',translate:'предел',checked:false,blocked:false },
    { id:30407, word:'lip',translate:'губа',checked:false,blocked:false },
    { id:30408, word:'liquid',translate:'жидкость',checked:false,blocked:false },
    { id:30409, word:'literature',translate:'литература',checked:false,blocked:false },
    { id:30410, word:'living',translate:'жизнь',checked:false,blocked:false },
    { id:30411, word:'local',translate:'местный',checked:false,blocked:false },
    { id:30412, word:'locate',translate:'найти',checked:false,blocked:false },
    { id:30413, word:'located',translate:'расположенный',checked:false,blocked:false },
    { id:30414, word:'location',translate:'местоположение',checked:false,blocked:false },
    { id:30415, word:'lonely',translate:'одинокий',checked:false,blocked:false },
    { id:30416, word:'loss',translate:'потеря',checked:false,blocked:false },
    { id:30417, word:'luxury',translate:'роскошь',checked:false,blocked:false },
    { id:30418, word:'mad',translate:'сумасшедший',checked:false,blocked:false },
    { id:30419, word:'magic',translate:'магия',checked:false,blocked:false },
    { id:30420, word:'mainly',translate:'в основном',checked:false,blocked:false },
    { id:30421, word:'mall',translate:'торговый центр',checked:false,blocked:false },
    { id:30422, word:'management',translate:'управление',checked:false,blocked:false },
    { id:30423, word:'market',translate:'рынок',checked:false,blocked:false },
    { id:30424, word:'marketing',translate:'маркетинг',checked:false,blocked:false },
    { id:30425, word:'marriage',translate:'брак',checked:false,blocked:false },
    { id:30426, word:'meanwhile',translate:'тем временем',checked:false,blocked:false },
    { id:30427, word:'measure',translate:'измерять',checked:false,blocked:false },
    { id:30428, word:'medium',translate:'среда',checked:false,blocked:false },
    { id:30429, word:'mental',translate:'умственный',checked:false,blocked:false },
    { id:30430, word:'mention',translate:'упоминание',checked:false,blocked:false },
    { id:30431, word:'mess',translate:'беспорядок',checked:false,blocked:false },
    { id:30432, word:'mild',translate:'легкий',checked:false,blocked:false },
    { id:30433, word:'mine',translate:'мой',checked:false,blocked:false },
    { id:30434, word:'mix',translate:'смешивать',checked:false,blocked:false },
    { id:30435, word:'mixture',translate:'смесь',checked:false,blocked:false },
    { id:30436, word:'mood',translate:'настроение',checked:false,blocked:false },
    { id:30437, word:'move',translate:'двигаться',checked:false,blocked:false },
    { id:30438, word:'mud',translate:'грязь',checked:false,blocked:false },
    { id:30439, word:'murder',translate:'убийство',checked:false,blocked:false },
    { id:30440, word:'muscle',translate:'мышца',checked:false,blocked:false },
    { id:30441, word:'musical',translate:'музыка',checked:false,blocked:false },
    { id:30442, word:'mystery',translate:'тайна',checked:false,blocked:false },
    { id:30443, word:'nail',translate:'гвоздь',checked:false,blocked:false },
    { id:30444, word:'narrative',translate:'повествование',checked:false,blocked:false },
    { id:30445, word:'nation',translate:'нация',checked:false,blocked:false },
    { id:30446, word:'native',translate:'родной',checked:false,blocked:false },
    { id:30447, word:'naturally',translate:'естественно',checked:false,blocked:false },
    { id:30448, word:'necessarily',translate:'обязательно',checked:false,blocked:false },
    { id:30449, word:'need',translate:'нужно',checked:false,blocked:false },
    { id:30450, word:'needle',translate:'игла',checked:false,blocked:false },
    { id:30451, word:'neighbourhood',translate:'район',checked:false,blocked:false },
    { id:30452, word:'neither',translate:'ни',checked:false,blocked:false },
    { id:30453, word:'net',translate:'сеть',checked:false,blocked:false },
    { id:30454, word:'next',translate:'следующий',checked:false,blocked:false },
    { id:30455, word:'nor',translate:'ни',checked:false,blocked:false },
    { id:30456, word:'normal',translate:'нормальный',checked:false,blocked:false },
    { id:30457, word:'northern',translate:'север',checked:false,blocked:false },
    { id:30458, word:'note',translate:'заметка',checked:false,blocked:false },
    { id:30459, word:'now',translate:'сейчас',checked:false,blocked:false },
    { id:30460, word:'nuclear',translate:'ядерный',checked:false,blocked:false },
    { id:30461, word:'obvious',translate:'очевидно',checked:false,blocked:false },
    { id:30462, word:'obviously',translate:'очевидно',checked:false,blocked:false },
    { id:30463, word:'occasion',translate:'случай',checked:false,blocked:false },
    { id:30464, word:'occur',translate:'происходить',checked:false,blocked:false },
    { id:30465, word:'odd',translate:'странный',checked:false,blocked:false },
    { id:30466, word:'official',translate:'официальный',checked:false,blocked:false },
    { id:30468, word:'once',translate:'однажды',checked:false,blocked:false },
    { id:30469, word:'operation',translate:'операция',checked:false,blocked:false },
    { id:30470, word:'organized',translate:'организованный',checked:false,blocked:false },
    { id:30471, word:'organizer',translate:'организатор',checked:false,blocked:false },
    { id:30472, word:'original',translate:'оригинал',checked:false,blocked:false },
    { id:30473, word:'originally',translate:'первоначально',checked:false,blocked:false },
    { id:30474, word:'ought',translate:'должен',checked:false,blocked:false },
    { id:30475, word:'ours',translate:'наш',checked:false,blocked:false },
    { id:30476, word:'outdoor',translate:'на открытом воздухе',checked:false,blocked:false },
    { id:30477, word:'outdoors',translate:'на открытом воздухе',checked:false,blocked:false },
    { id:30478, word:'pack',translate:'упаковать',checked:false,blocked:false },
    { id:30479, word:'package',translate:'пакет',checked:false,blocked:false },
    { id:30480, word:'painful',translate:'болезненный',checked:false,blocked:false },
    { id:30481, word:'pale',translate:'бледный',checked:false,blocked:false },
    { id:30482, word:'pan',translate:'сковорода',checked:false,blocked:false },
    { id:30483, word:'participate',translate:'участвовать',checked:false,blocked:false },
    { id:30484, word:'particularly',translate:'особенно',checked:false,blocked:false },
    { id:30485, word:'pass',translate:'пройти',checked:false,blocked:false },
    { id:30486, word:'passion',translate:'страсть',checked:false,blocked:false },
    { id:30487, word:'path',translate:'путь',checked:false,blocked:false },
    { id:30488, word:'payment',translate:'оплата',checked:false,blocked:false },
    { id:30489, word:'peaceful',translate:'мирный',checked:false,blocked:false },
    { id:30490, word:'percentage',translate:'процент',checked:false,blocked:false },
    { id:30491, word:'perfectly',translate:'идеально',checked:false,blocked:false },
    { id:30492, word:'performance',translate:'производительность',checked:false,blocked:false },
    { id:30493, word:'personally',translate:'лично',checked:false,blocked:false },
    { id:30494, word:'persuade',translate:'убедить',checked:false,blocked:false },
    { id:30495, word:'photographer',translate:'фотограф',checked:false,blocked:false },
    { id:30496, word:'photography',translate:'фотография',checked:false,blocked:false },
    { id:30497, word:'pin',translate:'булавка',checked:false,blocked:false },
    { id:30498, word:'pipe',translate:'труба',checked:false,blocked:false },
    { id:30499, word:'place',translate:'место',checked:false,blocked:false },
    { id:30500, word:'planning',translate:'планирование',checked:false,blocked:false },
    { id:30501, word:'pleasant',translate:'приятный',checked:false,blocked:false },
    { id:30502, word:'pleasure',translate:'удовольствие',checked:false,blocked:false },
    { id:30503, word:'plenty',translate:'много',checked:false,blocked:false },
    { id:30504, word:'plot',translate:'участок',checked:false,blocked:false },
    { id:30505, word:'plus1',translate:'плюс1',checked:false,blocked:false },
    { id:30506, word:'poem',translate:'стихотворение',checked:false,blocked:false },
    { id:30507, word:'poet',translate:'поэт',checked:false,blocked:false },
    { id:30508, word:'poetry',translate:'поэзия',checked:false,blocked:false },
    { id:30509, word:'point',translate:'точка',checked:false,blocked:false },
    { id:30510, word:'poison',translate:'яд',checked:false,blocked:false },
    { id:30511, word:'poisonous',translate:'ядовитый',checked:false,blocked:false },
    { id:30512, word:'policy',translate:'политика',checked:false,blocked:false },
    { id:30513, word:'political',translate:'политика',checked:false,blocked:false },
    { id:30514, word:'politician',translate:'политик',checked:false,blocked:false },
    { id:30515, word:'politics',translate:'политика',checked:false,blocked:false },
    { id:30516, word:'port',translate:'порт',checked:false,blocked:false },
    { id:30517, word:'portrait',translate:'портрет',checked:false,blocked:false },
    { id:30518, word:'possibly',translate:'возможно',checked:false,blocked:false },
    { id:30519, word:'pot',translate:'горшок',checked:false,blocked:false },
    { id:30520, word:'pour',translate:'наливать',checked:false,blocked:false },
    { id:30521, word:'poverty',translate:'бедность',checked:false,blocked:false },
    { id:30522, word:'powder',translate:'порошок',checked:false,blocked:false },
    { id:30523, word:'powerful',translate:'мощный',checked:false,blocked:false },
    { id:30524, word:'practical',translate:'практичный',checked:false,blocked:false },
    { id:30525, word:'pray',translate:'молиться',checked:false,blocked:false },
    { id:30526, word:'prayer',translate:'молитва',checked:false,blocked:false },
    { id:30527, word:'prediction',translate:'предсказание',checked:false,blocked:false },
    { id:30528, word:'prepared',translate:'подготовленный',checked:false,blocked:false },
    { id:30529, word:'presentation',translate:'презентация',checked:false,blocked:false },
    { id:30530, word:'press',translate:'пресса',checked:false,blocked:false },
    { id:30531, word:'pressure',translate:'давление',checked:false,blocked:false },
    { id:30532, word:'pretend',translate:'притворяться',checked:false,blocked:false },
    { id:30533, word:'previous',translate:'предыдущий',checked:false,blocked:false },
    { id:30534, word:'previously',translate:'ранее',checked:false,blocked:false },
    { id:30535, word:'priest',translate:'священник',checked:false,blocked:false },
    { id:30536, word:'primary',translate:'основной',checked:false,blocked:false },
    { id:30537, word:'prince',translate:'принц',checked:false,blocked:false },
    { id:30538, word:'princess',translate:'принцесса',checked:false,blocked:false },
    { id:30539, word:'printing',translate:'печать',checked:false,blocked:false },
    { id:30540, word:'prisoner',translate:'заключенный',checked:false,blocked:false },
    { id:30541, word:'private',translate:'частный',checked:false,blocked:false },
    { id:30542, word:'producer',translate:'производитель',checked:false,blocked:false },
    { id:30543, word:'production',translate:'производство',checked:false,blocked:false },
    { id:30544, word:'profession',translate:'профессия',checked:false,blocked:false },
    { id:30545, word:'profit',translate:'прибыль',checked:false,blocked:false },
    { id:30546, word:'program',translate:'программа',checked:false,blocked:false },
    { id:30547, word:'promote',translate:'продвигать',checked:false,blocked:false },
    { id:30548, word:'proper',translate:'правильно',checked:false,blocked:false },
    { id:30549, word:'properly',translate:'правильно',checked:false,blocked:false },
    { id:30550, word:'property',translate:'собственность',checked:false,blocked:false },
    { id:30551, word:'protest',translate:'протест',checked:false,blocked:false },
    { id:30552, word:'proud',translate:'гордиться',checked:false,blocked:false },
    { id:30553, word:'prove',translate:'доказать',checked:false,blocked:false },
    { id:30554, word:'pull',translate:'тянуть',checked:false,blocked:false },
    { id:30555, word:'punish',translate:'наказывать',checked:false,blocked:false },
    { id:30556, word:'punishment',translate:'наказание',checked:false,blocked:false },
    { id:30557, word:'push',translate:'толкать',checked:false,blocked:false },
    { id:30558, word:'qualification',translate:'квалификация',checked:false,blocked:false },
    { id:30559, word:'qualified',translate:'квалифицированный',checked:false,blocked:false },
    { id:30560, word:'qualify',translate:'квалифицировать',checked:false,blocked:false },
    { id:30561, word:'queue',translate:'очередь',checked:false,blocked:false },
    { id:30562, word:'quit',translate:'уволиться',checked:false,blocked:false },
    { id:30563, word:'quotation',translate:'котировка',checked:false,blocked:false },
    { id:30564, word:'quote',translate:'котировка',checked:false,blocked:false },
    { id:30565, word:'race',translate:'гонка',checked:false,blocked:false },
    { id:30566, word:'racing',translate:'гонка',checked:false,blocked:false },
    { id:30567, word:'range',translate:'полигон',checked:false,blocked:false },
    { id:30568, word:'rare',translate:'редкий',checked:false,blocked:false },
    { id:30569, word:'rarely',translate:'редко',checked:false,blocked:false },
    { id:30570, word:'reaction',translate:'реакция',checked:false,blocked:false },
    { id:30571, word:'reality',translate:'реальность',checked:false,blocked:false },
    { id:30572, word:'receipt',translate:'получение',checked:false,blocked:false },
    { id:30573, word:'recommendation',translate:'рекомендация',checked:false,blocked:false },
    { id:30574, word:'reference',translate:'ссылка',checked:false,blocked:false },
    { id:30575, word:'reflect',translate:'отражать',checked:false,blocked:false },
    { id:30576, word:'regularly',translate:'регулярно',checked:false,blocked:false },
    { id:30577, word:'reject',translate:'отклонить',checked:false,blocked:false },
    { id:30578, word:'relate',translate:'относиться',checked:false,blocked:false },
    { id:30579, word:'related',translate:'связанный',checked:false,blocked:false },
    { id:30580, word:'relation',translate:'отношение',checked:false,blocked:false },
    { id:30581, word:'relative',translate:'родственник',checked:false,blocked:false },
    { id:30582, word:'relaxed',translate:'расслабленный',checked:false,blocked:false },
    { id:30583, word:'relaxing',translate:'расслабление',checked:false,blocked:false },
    { id:30584, word:'release',translate:'освобождение',checked:false,blocked:false },
    { id:30585, word:'reliable',translate:'надежный',checked:false,blocked:false },
    { id:30586, word:'religion',translate:'религия',checked:false,blocked:false },
    { id:30587, word:'religious',translate:'религия',checked:false,blocked:false },
    { id:30588, word:'remain',translate:'оставаться',checked:false,blocked:false },
    { id:30589, word:'remind',translate:'напоминать',checked:false,blocked:false },
    { id:30590, word:'remote',translate:'удаленный',checked:false,blocked:false },
    { id:30591, word:'rent',translate:'аренда',checked:false,blocked:false },
    { id:30592, word:'repair',translate:'ремонт',checked:false,blocked:false },
    { id:30593, word:'repeat',translate:'повторить',checked:false,blocked:false },
    { id:30594, word:'repeated',translate:'повторять',checked:false,blocked:false },
    { id:30595, word:'represent',translate:'представлять',checked:false,blocked:false },
    { id:30596, word:'request',translate:'запрашивать',checked:false,blocked:false },
    { id:30597, word:'require',translate:'требовать',checked:false,blocked:false },
    { id:30598, word:'reservation',translate:'резервирование',checked:false,blocked:false },
    { id:30599, word:'resource',translate:'ресурс',checked:false,blocked:false },
    { id:30600, word:'respect',translate:'уважение',checked:false,blocked:false },
    { id:30601, word:'responsibility',translate:'ответственность',checked:false,blocked:false },
    { id:30602, word:'responsible',translate:'ответственность',checked:false,blocked:false },
    { id:30603, word:'result',translate:'результат',checked:false,blocked:false },
    { id:30604, word:'retire',translate:'на пенсию',checked:false,blocked:false },
    { id:30605, word:'retired',translate:'на пенсии',checked:false,blocked:false },
    { id:30606, word:'revise',translate:'пересмотреть',checked:false,blocked:false },
    { id:30607, word:'ring',translate:'кольцо',checked:false,blocked:false },
    { id:30608, word:'rise',translate:'подняться',checked:false,blocked:false },
    { id:30609, word:'risk',translate:'риск',checked:false,blocked:false },
    { id:30610, word:'robot',translate:'робот',checked:false,blocked:false },
    { id:30611, word:'roll',translate:'ролл',checked:false,blocked:false },
    { id:30612, word:'romantic',translate:'романтика',checked:false,blocked:false },
    { id:30613, word:'rope',translate:'канат',checked:false,blocked:false },
    { id:30614, word:'rough',translate:'грубый',checked:false,blocked:false },
    { id:30615, word:'row',translate:'ряд',checked:false,blocked:false },
    { id:30616, word:'royal',translate:'королевский',checked:false,blocked:false },
    { id:30617, word:'rugby',translate:'регби',checked:false,blocked:false },
    { id:30618, word:'rule',translate:'правило',checked:false,blocked:false },
    { id:30619, word:'safety',translate:'безопасность',checked:false,blocked:false },
    { id:30620, word:'sail',translate:'парус',checked:false,blocked:false },
    { id:30621, word:'sailor',translate:'моряк',checked:false,blocked:false },
    { id:30622, word:'sample',translate:'образец',checked:false,blocked:false },
    { id:30623, word:'sand',translate:'песок',checked:false,blocked:false },
    { id:30624, word:'scan',translate:'сканирование',checked:false,blocked:false },
    { id:30625, word:'scientific',translate:'научный',checked:false,blocked:false },
    { id:30626, word:'script',translate:'сценарий',checked:false,blocked:false },
    { id:30627, word:'sculpture',translate:'скульптура',checked:false,blocked:false },
    { id:30628, word:'secondary',translate:'вторичный',checked:false,blocked:false },
    { id:30629, word:'security',translate:'безопасность',checked:false,blocked:false },
    { id:30630, word:'seed',translate:'семя',checked:false,blocked:false },
    { id:30631, word:'sensible',translate:'разумный',checked:false,blocked:false },
    { id:30632, word:'separate',translate:'отдельный',checked:false,blocked:false },
    { id:30633, word:'seriously',translate:'серьезно',checked:false,blocked:false },
    { id:30634, word:'servant',translate:'слуга',checked:false,blocked:false },
    { id:30635, word:'set',translate:'набор',checked:false,blocked:false },
    { id:30636, word:'set',translate:'набор',checked:false,blocked:false },
    { id:30637, word:'setting',translate:'настройка',checked:false,blocked:false },
    { id:30638, word:'sex',translate:'секс',checked:false,blocked:false },
    { id:30639, word:'sexual',translate:'сексуальный',checked:false,blocked:false },
    { id:30640, word:'shake',translate:'трясти',checked:false,blocked:false },
    { id:30641, word:'share',translate:'поделиться',checked:false,blocked:false },
    { id:30642, word:'sharp',translate:'острый',checked:false,blocked:false },
    { id:30643, word:'shelf',translate:'полка',checked:false,blocked:false },
    { id:30644, word:'shell',translate:'оболочка',checked:false,blocked:false },
    { id:30645, word:'shift',translate:'сдвиг',checked:false,blocked:false },
    { id:30646, word:'shine',translate:'блеск',checked:false,blocked:false },
    { id:30647, word:'shiny',translate:'блеск',checked:false,blocked:false },
    { id:30648, word:'shoot',translate:'стрелять',checked:false,blocked:false },
    { id:30649, word:'shy',translate:'застенчивый',checked:false,blocked:false },
    { id:30650, word:'sight',translate:'зрение',checked:false,blocked:false },
    { id:30651, word:'signal',translate:'сигнал',checked:false,blocked:false },
    { id:30652, word:'silent',translate:'молчать',checked:false,blocked:false },
    { id:30653, word:'silly',translate:'глупый',checked:false,blocked:false },
    { id:30654, word:'similarity',translate:'сходство',checked:false,blocked:false },
    { id:30655, word:'similarly',translate:'аналогично',checked:false,blocked:false },
    { id:30656, word:'simply',translate:'просто',checked:false,blocked:false },
    { id:30657, word:'since',translate:'так как',checked:false,blocked:false },
    { id:30658, word:'sink',translate:'топить',checked:false,blocked:false },
    { id:30659, word:'slice',translate:'ломтик',checked:false,blocked:false },
    { id:30660, word:'slightly',translate:'немного',checked:false,blocked:false },
    { id:30661, word:'slow',translate:'медленно',checked:false,blocked:false },
    { id:30662, word:'smart',translate:'умный',checked:false,blocked:false },
    { id:30663, word:'smooth',translate:'гладкий',checked:false,blocked:false },
    { id:30664, word:'software',translate:'программное обеспечение',checked:false,blocked:false },
    { id:30665, word:'soil',translate:'почва',checked:false,blocked:false },
    { id:30666, word:'solid',translate:'твердый',checked:false,blocked:false },
    { id:30667, word:'sort',translate:'сортировать',checked:false,blocked:false },
    { id:30668, word:'southern',translate:'юг',checked:false,blocked:false },
    { id:30669, word:'specifically',translate:'специально',checked:false,blocked:false },
    { id:30670, word:'spending',translate:'расходы',checked:false,blocked:false },
    { id:30671, word:'spicy',translate:'пряный',checked:false,blocked:false },
    { id:30672, word:'spirit',translate:'дух',checked:false,blocked:false },
    { id:30673, word:'spoken',translate:'разговорный',checked:false,blocked:false },
    { id:30674, word:'spot',translate:'место',checked:false,blocked:false },
    { id:30675, word:'spread',translate:'распространение',checked:false,blocked:false },
    { id:30676, word:'spring',translate:'весна',checked:false,blocked:false },
    { id:30677, word:'stadium',translate:'стадион',checked:false,blocked:false },
    { id:30678, word:'staff',translate:'персонал',checked:false,blocked:false },
    { id:30679, word:'standard',translate:'стандарт',checked:false,blocked:false },
    { id:30680, word:'state',translate:'штат',checked:false,blocked:false },
    { id:30681, word:'statistic',translate:'статистика',checked:false,blocked:false },
    { id:30682, word:'statue',translate:'статуя',checked:false,blocked:false },
    { id:30683, word:'stick',translate:'палка',checked:false,blocked:false },
    { id:30684, word:'still',translate:'до сих пор',checked:false,blocked:false },
    { id:30685, word:'store',translate:'магазин',checked:false,blocked:false },
    { id:30686, word:'stranger',translate:'незнакомец',checked:false,blocked:false },
    { id:30687, word:'strength',translate:'сила',checked:false,blocked:false },
    { id:30688, word:'string',translate:'строка',checked:false,blocked:false },
    { id:30689, word:'strongly',translate:'сильно',checked:false,blocked:false },
    { id:30690, word:'studio',translate:'студия',checked:false,blocked:false },
    { id:30691, word:'stuff',translate:'материал',checked:false,blocked:false },
    { id:30692, word:'substance',translate:'вещество',checked:false,blocked:false },
    { id:30693, word:'successfully',translate:'успешно',checked:false,blocked:false },
    { id:30694, word:'sudden',translate:'внезапно',checked:false,blocked:false },
    { id:30695, word:'suffer',translate:'страдать',checked:false,blocked:false },
    { id:30696, word:'suit',translate:'подходящий',checked:false,blocked:false },
    { id:30697, word:'suitable',translate:'подходящий',checked:false,blocked:false },
    { id:30698, word:'summarize',translate:'обобщить',checked:false,blocked:false },
    { id:30699, word:'summary',translate:'резюме',checked:false,blocked:false },
    { id:30700, word:'supply',translate:'поставлять',checked:false,blocked:false },
    { id:30701, word:'supporter',translate:'сторонник',checked:false,blocked:false },
    { id:30702, word:'surely',translate:'конечно',checked:false,blocked:false },
    { id:30703, word:'surface',translate:'поверхность',checked:false,blocked:false },
    { id:30704, word:'survive',translate:'выжить',checked:false,blocked:false },
    { id:30705, word:'swim',translate:'плавать',checked:false,blocked:false },
    { id:30706, word:'switch',translate:'переключаться',checked:false,blocked:false },
    { id:30707, word:'symptom',translate:'симптом',checked:false,blocked:false },
    { id:30708, word:'tail',translate:'хвост',checked:false,blocked:false },
    { id:30709, word:'talent',translate:'талант',checked:false,blocked:false },
    { id:30710, word:'talented',translate:'талантливый',checked:false,blocked:false },
    { id:30711, word:'tape',translate:'лента',checked:false,blocked:false },
    { id:30712, word:'tax',translate:'налог',checked:false,blocked:false },
    { id:30713, word:'technical',translate:'технический',checked:false,blocked:false },
    { id:30714, word:'technique',translate:'техника',checked:false,blocked:false },
    { id:30715, word:'tend',translate:'тенденция',checked:false,blocked:false },
    { id:30716, word:'tent',translate:'палатка',checked:false,blocked:false },
    { id:30717, word:'that',translate:'что',checked:false,blocked:false },
    { id:30718, word:'theirs',translate:'их',checked:false,blocked:false },
    { id:30719, word:'theme',translate:'тема',checked:false,blocked:false },
    { id:30720, word:'theory',translate:'теория',checked:false,blocked:false },
    { id:30721, word:'therefore',translate:'поэтому',checked:false,blocked:false },
    { id:30722, word:'this',translate:'это',checked:false,blocked:false },
    { id:30723, word:'though',translate:'хотя',checked:false,blocked:false },
    { id:30724, word:'throat',translate:'горло',checked:false,blocked:false },
    { id:30725, word:'throughout',translate:'на протяжении',checked:false,blocked:false },
    { id:30726, word:'tight',translate:'туго',checked:false,blocked:false },
    { id:30727, word:'till',translate:'до',checked:false,blocked:false },
    { id:30728, word:'tin',translate:'олово',checked:false,blocked:false },
    { id:30729, word:'tiny',translate:'маленький',checked:false,blocked:false },
    { id:30730, word:'tip',translate:'кончик',checked:false,blocked:false },
    { id:30731, word:'toe',translate:'палец',checked:false,blocked:false },
    { id:30732, word:'tongue',translate:'язык',checked:false,blocked:false },
    { id:30733, word:'total',translate:'полностью',checked:false,blocked:false },
    { id:30734, word:'totally',translate:'полностью',checked:false,blocked:false },
    { id:30735, word:'touch',translate:'прикосновение',checked:false,blocked:false },
    { id:30736, word:'tour',translate:'тур',checked:false,blocked:false },
    { id:30737, word:'trade',translate:'торговать',checked:false,blocked:false },
    { id:30738, word:'translate',translate:'перевести',checked:false,blocked:false },
    { id:30739, word:'translation',translate:'перевод',checked:false,blocked:false },
    { id:30740, word:'transport',translate:'транспорт',checked:false,blocked:false },
    { id:30741, word:'treat',translate:'лечить',checked:false,blocked:false },
    { id:30742, word:'treatment',translate:'лечение',checked:false,blocked:false },
    { id:30743, word:'trend',translate:'тенденция',checked:false,blocked:false },
    { id:30744, word:'trick',translate:'обман',checked:false,blocked:false },
    { id:30745, word:'truth',translate:'правда',checked:false,blocked:false },
    { id:30746, word:'tube',translate:'трубка',checked:false,blocked:false },
    { id:30747, word:'type',translate:'тип',checked:false,blocked:false },
    { id:30748, word:'typically',translate:'обычно',checked:false,blocked:false },
    { id:30749, word:'tyre',translate:'шина',checked:false,blocked:false },
    { id:30750, word:'ugly',translate:'уродливый',checked:false,blocked:false },
    { id:30751, word:'unable',translate:'неспособный',checked:false,blocked:false },
    { id:30752, word:'uncomfortable',translate:'неудобный',checked:false,blocked:false },
    { id:30753, word:'underwear',translate:'нижнее белье',checked:false,blocked:false },
    { id:30754, word:'unemployed',translate:'безработный',checked:false,blocked:false },
    { id:30755, word:'unemployment',translate:'безработица',checked:false,blocked:false },
    { id:30756, word:'unfair',translate:'несправедливый',checked:false,blocked:false },
    { id:30757, word:'union',translate:'профсоюз',checked:false,blocked:false },
    { id:30758, word:'unless',translate:'если не',checked:false,blocked:false },
    { id:30759, word:'unlike',translate:'в отличие от',checked:false,blocked:false },
    { id:30760, word:'unlikely',translate:'маловероятно',checked:false,blocked:false },
    { id:30761, word:'unnecessary',translate:'ненужный',checked:false,blocked:false },
    { id:30762, word:'unpleasant',translate:'неприятный',checked:false,blocked:false },
    { id:30763, word:'update',translate:'обновить',checked:false,blocked:false },
    { id:30764, word:'upon',translate:'на',checked:false,blocked:false },
    { id:30765, word:'upset',translate:'расстроенный',checked:false,blocked:false },
    { id:30766, word:'valuable',translate:'ценный',checked:false,blocked:false },
    { id:30767, word:'value',translate:'ценность',checked:false,blocked:false },
    { id:30768, word:'various',translate:'различные',checked:false,blocked:false },
    { id:30769, word:'version',translate:'версия',checked:false,blocked:false },
    { id:30770, word:'victim',translate:'жертва',checked:false,blocked:false },
    { id:30771, word:'view',translate:'просмотр',checked:false,blocked:false },
    { id:30772, word:'viewer',translate:'зритель',checked:false,blocked:false },
    { id:30773, word:'violent',translate:'насилие',checked:false,blocked:false },
    { id:30774, word:'volunteer',translate:'доброволец',checked:false,blocked:false },
    { id:30775, word:'vote',translate:'голосовать',checked:false,blocked:false },
    { id:30776, word:'warm',translate:'тепло',checked:false,blocked:false },
    { id:30777, word:'warn',translate:'предупредить',checked:false,blocked:false },
    { id:30778, word:'warning',translate:'предупреждение',checked:false,blocked:false },
    { id:30779, word:'waste',translate:'отходы',checked:false,blocked:false },
    { id:30780, word:'water',translate:'вода',checked:false,blocked:false },
    { id:30781, word:'wave',translate:'волна',checked:false,blocked:false },
    { id:30782, word:'weapon',translate:'оружие',checked:false,blocked:false },
    { id:30783, word:'weigh',translate:'взвешивать',checked:false,blocked:false },
    { id:30784, word:'western',translate:'запад',checked:false,blocked:false },
    { id:30785, word:'whatever',translate:'что угодно',checked:false,blocked:false },
    { id:30786, word:'whenever',translate:'когда бы то ни было',checked:false,blocked:false },
    { id:30787, word:'whether',translate:'ли',checked:false,blocked:false },
    { id:30788, word:'while',translate:'пока',checked:false,blocked:false },
    { id:30789, word:'whole',translate:'весь',checked:false,blocked:false },
    { id:30790, word:'will',translate:'будет',checked:false,blocked:false },
    { id:30791, word:'win',translate:'победа',checked:false,blocked:false },
    { id:30792, word:'wing',translate:'крыло',checked:false,blocked:false },
    { id:30793, word:'within',translate:'внутри',checked:false,blocked:false },
    { id:30794, word:'wonder',translate:'чудо',checked:false,blocked:false },
    { id:30795, word:'wool',translate:'шерсть',checked:false,blocked:false },
    { id:30796, word:'worldwide',translate:'весь мир',checked:false,blocked:false },
    { id:30797, word:'worry',translate:'беспокоиться',checked:false,blocked:false },
    { id:30798, word:'worse',translate:'хуже',checked:false,blocked:false },
    { id:30799, word:'worst',translate:'худший',checked:false,blocked:false },
    { id:30800, word:'worth',translate:'стоимость',checked:false,blocked:false },
    { id:30801, word:'written',translate:'написано',checked:false,blocked:false },
    { id:30802, word:'wrong',translate:'неправильно',checked:false,blocked:false },
    { id:30803, word:'yard',translate:'двор',checked:false,blocked:false },
    { id:30804, word:'young',translate:'молодежь',checked:false,blocked:false },
    { id:30805, word:'youth',translate:'молодость',checked:false,blocked:false }
  ];


export const b2 = [
    { id:40001, word:'absorb',translate:'поглощать',checked:false,blocked:false },
    { id:40002, word:'abstract',translate:'абстракция',checked:false,blocked:false },
    { id:40003, word:'accent',translate:'акцент',checked:false,blocked:false },
    { id:40004, word:'accidentally',translate:'случайно',checked:false,blocked:false },
    { id:40005, word:'accommodate',translate:'разместить',checked:false,blocked:false },
    { id:40006, word:'accomplish',translate:'сделать',checked:false,blocked:false },
    { id:40007, word:'accountant',translate:'бухгалтер',checked:false,blocked:false },
    { id:40008, word:'accuracy',translate:'точность',checked:false,blocked:false },
    { id:40009, word:'accurately',translate:'точно',checked:false,blocked:false },
    { id:40010, word:'acid',translate:'кислота',checked:false,blocked:false },
    { id:40011, word:'activate',translate:'активировать',checked:false,blocked:false },
    { id:40012, word:'addiction',translate:'зависимость',checked:false,blocked:false },
    { id:40013, word:'additionally',translate:'дополнительно',checked:false,blocked:false },
    { id:40014, word:'adequate',translate:'адекватно',checked:false,blocked:false },
    { id:40015, word:'adequately',translate:'адекватно',checked:false,blocked:false },
    { id:40016, word:'adjust',translate:'корректировать',checked:false,blocked:false },
    { id:40017, word:'affordable',translate:'доступный',checked:false,blocked:false },
    { id:40018, word:'agriculture',translate:'сельское хозяйство',checked:false,blocked:false },
    { id:40019, word:'AIDS',translate:'СПИД',checked:false,blocked:false },
    { id:40020, word:'alien',translate:'инопланетянин',checked:false,blocked:false },
    { id:40021, word:'alongside',translate:'рядом',checked:false,blocked:false },
    { id:40022, word:'altogether',translate:'в целом',checked:false,blocked:false },
    { id:40023, word:'ambulance',translate:'скорая помощь',checked:false,blocked:false },
    { id:40024, word:'amusing',translate:'веселый',checked:false,blocked:false },
    { id:40025, word:'analyst',translate:'аналитик',checked:false,blocked:false },
    { id:40026, word:'ancestor',translate:'предок',checked:false,blocked:false },
    { id:40027, word:'animation',translate:'анимация',checked:false,blocked:false },
    { id:40028, word:'annually',translate:'ежегодно',checked:false,blocked:false },
    { id:40029, word:'anticipate',translate:'предвидеть',checked:false,blocked:false },
    { id:40030, word:'anxiety',translate:'тревога',checked:false,blocked:false },
    { id:40031, word:'apology',translate:'извинение',checked:false,blocked:false },
    { id:40032, word:'applicant',translate:'заявитель',checked:false,blocked:false },
    { id:40033, word:'appropriately',translate:'соответствующим образом',checked:false,blocked:false },
    { id:40034, word:'arrow',translate:'стрелка',checked:false,blocked:false },
    { id:40035, word:'artwork',translate:'произведение искусства',checked:false,blocked:false },
    { id:40036, word:'aside',translate:'в сторону',checked:false,blocked:false },
    { id:40037, word:'asset',translate:'актив',checked:false,blocked:false },
    { id:40038, word:'assign',translate:'назначить',checked:false,blocked:false },
    { id:40039, word:'assistance',translate:'помощь',checked:false,blocked:false },
    { id:40040, word:'assumption',translate:'предположение',checked:false,blocked:false },
    { id:40041, word:'assure',translate:'заверять',checked:false,blocked:false },
    { id:40042, word:'astonishing',translate:'поразительный',checked:false,blocked:false },
    { id:40043, word:'attachment',translate:'прикрепление',checked:false,blocked:false },
    { id:40044, word:'auction',translate:'аукцион',checked:false,blocked:false },
    { id:40045, word:'audio',translate:'аудио',checked:false,blocked:false },
    { id:40046, word:'automatic',translate:'автоматический',checked:false,blocked:false },
    { id:40047, word:'automatically',translate:'автоматически',checked:false,blocked:false },
    { id:40048, word:'awareness',translate:'осведомленность',checked:false,blocked:false },
    { id:40049, word:'awkward',translate:'неловко',checked:false,blocked:false },
    { id:40050, word:'badge',translate:'значок',checked:false,blocked:false },
    { id:40051, word:'balanced',translate:'сбалансированный',checked:false,blocked:false },
    { id:40052, word:'ballet',translate:'балет',checked:false,blocked:false },
    { id:40053, word:'balloon',translate:'воздушный шар',checked:false,blocked:false },
    { id:40054, word:'barely',translate:'едва',checked:false,blocked:false },
    { id:40055, word:'bargain',translate:'выгодная сделка',checked:false,blocked:false },
    { id:40056, word:'basement',translate:'подвал',checked:false,blocked:false },
    { id:40057, word:'basket',translate:'корзина',checked:false,blocked:false },
    { id:40058, word:'bat',translate:'летучая мышь',checked:false,blocked:false },
    { id:40059, word:'beneficial',translate:'выгодно',checked:false,blocked:false },
    { id:40060, word:'beside',translate:'рядом',checked:false,blocked:false },
    { id:40061, word:'besides',translate:'кроме того',checked:false,blocked:false },
    { id:40062, word:'bias',translate:'предубеждение',checked:false,blocked:false },
    { id:40063, word:'bid',translate:'ставка',checked:false,blocked:false },
    { id:40064, word:'biological',translate:'биологический',checked:false,blocked:false },
    { id:40065, word:'blanket',translate:'одеяло',checked:false,blocked:false },
    { id:40066, word:'blow',translate:'дуть',checked:false,blocked:false },
    { id:40067, word:'bold',translate:'смелый',checked:false,blocked:false },
    { id:40068, word:'bombing',translate:'бомбардировка',checked:false,blocked:false },
    { id:40069, word:'booking',translate:'бронирование',checked:false,blocked:false },
    { id:40070, word:'boost',translate:'увеличить',checked:false,blocked:false },
    { id:40071, word:'bound',translate:'связанный',checked:false,blocked:false },
    { id:40072, word:'brick',translate:'кирпич',checked:false,blocked:false },
    { id:40073, word:'briefly',translate:'кратко',checked:false,blocked:false },
    { id:40074, word:'broadcaster',translate:'вещатель',checked:false,blocked:false },
    { id:40075, word:'broadly',translate:'широко',checked:false,blocked:false },
    { id:40076, word:'bug',translate:'жук',checked:false,blocked:false },
    { id:40077, word:'cabin',translate:'хижина',checked:false,blocked:false },
    { id:40078, word:'canal',translate:'канал',checked:false,blocked:false },
    { id:40079, word:'candle',translate:'свеча',checked:false,blocked:false },
    { id:40080, word:'carbon',translate:'углерод',checked:false,blocked:false },
    { id:40081, word:'casual',translate:'случайный',checked:false,blocked:false },
    { id:40082, word:'cave',translate:'пещера',checked:false,blocked:false },
    { id:40083, word:'certainty',translate:'уверенность',checked:false,blocked:false },
    { id:40084, word:'certificate',translate:'сертификат',checked:false,blocked:false },
    { id:40085, word:'challenging',translate:'сложный',checked:false,blocked:false },
    { id:40086, word:'championship',translate:'чемпионат',checked:false,blocked:false },
    { id:40087, word:'charming',translate:'очаровательный',checked:false,blocked:false },
    { id:40088, word:'chase',translate:'погоня',checked:false,blocked:false },
    { id:40089, word:'cheek',translate:'щека',checked:false,blocked:false },
    { id:40090, word:'cheer',translate:'аплодисменты',checked:false,blocked:false },
    { id:40091, word:'choir',translate:'хор',checked:false,blocked:false },
    { id:40092, word:'chop',translate:'отбивная',checked:false,blocked:false },
    { id:40093, word:'circuit',translate:'схема',checked:false,blocked:false },
    { id:40094, word:'civilization',translate:'цивилизация',checked:false,blocked:false },
    { id:40095, word:'clarify',translate:'прояснить',checked:false,blocked:false },
    { id:40096, word:'classify',translate:'классифицировать',checked:false,blocked:false },
    { id:40097, word:'clerk',translate:'клерк',checked:false,blocked:false },
    { id:40098, word:'cliff',translate:'скала',checked:false,blocked:false },
    { id:40099, word:'clinic',translate:'клиника',checked:false,blocked:false },
    { id:40100, word:'clip',translate:'клип',checked:false,blocked:false },
    { id:40101, word:'coincidence',translate:'совпадение',checked:false,blocked:false },
    { id:40102, word:'collector',translate:'коллекционер',checked:false,blocked:false },
    { id:40103, word:'colony',translate:'колония',checked:false,blocked:false },
    { id:40104, word:'colourful',translate:'красочный',checked:false,blocked:false },
    { id:40105, word:'comic',translate:'комикс',checked:false,blocked:false },
    { id:40106, word:'commander',translate:'командир',checked:false,blocked:false },
    { id:40107, word:'comparative',translate:'сравнение',checked:false,blocked:false },
    { id:40108, word:'completion',translate:'завершение',checked:false,blocked:false },
    { id:40109, word:'compose',translate:'сочинить',checked:false,blocked:false },
    { id:40110, word:'composer',translate:'композитор',checked:false,blocked:false },
    { id:40111, word:'compound',translate:'соединение',checked:false,blocked:false },
    { id:40112, word:'comprehensive',translate:'всеобъемлющий',checked:false,blocked:false },
    { id:40113, word:'comprise',translate:'составлять',checked:false,blocked:false },
    { id:40114, word:'compulsory',translate:'обязательный',checked:false,blocked:false },
    { id:40115, word:'concrete',translate:'конкретный',checked:false,blocked:false },
    { id:40116, word:'confess',translate:'признаться',checked:false,blocked:false },
    { id:40117, word:'confusion',translate:'путаница',checked:false,blocked:false },
    { id:40118, word:'consequently',translate:'следовательно',checked:false,blocked:false },
    { id:40119, word:'conservation',translate:'сохранение',checked:false,blocked:false },
    { id:40120, word:'considerable',translate:'значительно',checked:false,blocked:false },
    { id:40121, word:'considerably',translate:'значительно',checked:false,blocked:false },
    { id:40122, word:'consistently',translate:'последовательно',checked:false,blocked:false },
    { id:40123, word:'conspiracy',translate:'заговор',checked:false,blocked:false },
    { id:40124, word:'consult',translate:'консультироваться',checked:false,blocked:false },
    { id:40125, word:'consultant',translate:'консультант',checked:false,blocked:false },
    { id:40126, word:'consumption',translate:'потребление',checked:false,blocked:false },
    { id:40127, word:'controversial',translate:'противоречивый',checked:false,blocked:false },
    { id:40128, word:'controversy',translate:'спор',checked:false,blocked:false },
    { id:40129, word:'convenience',translate:'удобство',checked:false,blocked:false },
    { id:40130, word:'convention',translate:'конвенция',checked:false,blocked:false },
    { id:40131, word:'conventional',translate:'конвенция',checked:false,blocked:false },
    { id:40132, word:'convey',translate:'передать',checked:false,blocked:false },
    { id:40133, word:'convincing',translate:'убеждать',checked:false,blocked:false },
    { id:40134, word:'cope',translate:'справиться',checked:false,blocked:false },
    { id:40135, word:'corporation',translate:'корпорация',checked:false,blocked:false },
    { id:40136, word:'corridor',translate:'коридор',checked:false,blocked:false },
    { id:40137, word:'counter',translate:'противодействие',checked:false,blocked:false },
    { id:40138, word:'coverage',translate:'покрытие',checked:false,blocked:false },
    { id:40139, word:'crack',translate:'трещина',checked:false,blocked:false },
    { id:40140, word:'craft',translate:'ремесло',checked:false,blocked:false },
    { id:40141, word:'creativity',translate:'творчество',checked:false,blocked:false },
    { id:40142, word:'critically',translate:'критически',checked:false,blocked:false },
    { id:40143, word:'cruise',translate:'круиз',checked:false,blocked:false },
    { id:40144, word:'cue',translate:'кий',checked:false,blocked:false },
    { id:40145, word:'curious',translate:'любопытный',checked:false,blocked:false },
    { id:40146, word:'curriculum',translate:'учебный план',checked:false,blocked:false },
    { id:40147, word:'cute',translate:'милый',checked:false,blocked:false },
    { id:40148, word:'dairy',translate:'молочный',checked:false,blocked:false },
    { id:40149, word:'dare',translate:'осмелиться',checked:false,blocked:false },
    { id:40150, word:'darkness',translate:'темнота',checked:false,blocked:false },
    { id:40151, word:'database',translate:'база данных',checked:false,blocked:false },
    { id:40152, word:'deadline',translate:'срок',checked:false,blocked:false },
    { id:40153, word:'deadly',translate:'смертельный',checked:false,blocked:false },
    { id:40154, word:'dealer',translate:'дилер',checked:false,blocked:false },
    { id:40155, word:'deck',translate:'колода',checked:false,blocked:false },
    { id:40156, word:'defender',translate:'защитник',checked:false,blocked:false },
    { id:40157, word:'delete',translate:'удалить',checked:false,blocked:false },
    { id:40158, word:'democracy',translate:'демократия',checked:false,blocked:false },
    { id:40159, word:'democratic',translate:'демократия',checked:false,blocked:false },
    { id:40160, word:'demonstration',translate:'демонстрация',checked:false,blocked:false },
    { id:40161, word:'depart',translate:'уехать',checked:false,blocked:false },
    { id:40162, word:'dependent',translate:'зависимый',checked:false,blocked:false },
    { id:40163, word:'deposit',translate:'депозит',checked:false,blocked:false },
    { id:40164, word:'depression',translate:'депрессия',checked:false,blocked:false },
    { id:40165, word:'derive',translate:'вывести',checked:false,blocked:false },
    { id:40166, word:'desperately',translate:'отчаянно',checked:false,blocked:false },
    { id:40167, word:'destruction',translate:'разрушение',checked:false,blocked:false },
    { id:40168, word:'determination',translate:'решимость',checked:false,blocked:false },
    { id:40169, word:'devote',translate:'посвятить',checked:false,blocked:false },
    { id:40170, word:'differ',translate:'отличаться',checked:false,blocked:false },
    { id:40171, word:'disability',translate:'инвалидность',checked:false,blocked:false },
    { id:40172, word:'disabled',translate:'инвалид',checked:false,blocked:false },
    { id:40173, word:'disagreement',translate:'разногласие',checked:false,blocked:false },
    { id:40174, word:'disappoint',translate:'разочарование',checked:false,blocked:false },
    { id:40175, word:'disappointment',translate:'разочарование',checked:false,blocked:false },
    { id:40176, word:'discourage',translate:'обескураживать',checked:false,blocked:false },
    { id:40177, word:'disorder',translate:'расстройство',checked:false,blocked:false },
    { id:40178, word:'distant',translate:'дальний',checked:false,blocked:false },
    { id:40179, word:'distinct',translate:'различать',checked:false,blocked:false },
    { id:40180, word:'distinguish',translate:'различать',checked:false,blocked:false },
    { id:40181, word:'distract',translate:'отвлекать',checked:false,blocked:false },
    { id:40182, word:'disturb',translate:'беспокоить',checked:false,blocked:false },
    { id:40183, word:'dive',translate:'погружаться',checked:false,blocked:false },
    { id:40184, word:'diverse',translate:'разнообразный',checked:false,blocked:false },
    { id:40185, word:'diversity',translate:'разнообразие',checked:false,blocked:false },
    { id:40186, word:'divorce',translate:'развод',checked:false,blocked:false },
    { id:40187, word:'dominant',translate:'доминанта',checked:false,blocked:false },
    { id:40188, word:'donation',translate:'пожертвование',checked:false,blocked:false },
    { id:40189, word:'dot',translate:'точка',checked:false,blocked:false },
    { id:40190, word:'downtown',translate:'центр города',checked:false,blocked:false },
    { id:40191, word:'dramatically',translate:'драматично',checked:false,blocked:false },
    { id:40192, word:'drought',translate:'засуха',checked:false,blocked:false },
    { id:40193, word:'dull',translate:'тусклый',checked:false,blocked:false },
    { id:40194, word:'dump',translate:'свалка',checked:false,blocked:false },
    { id:40195, word:'duration',translate:'продолжительность',checked:false,blocked:false },
    { id:40196, word:'dynamic',translate:'динамика',checked:false,blocked:false },
    { id:40197, word:'economics',translate:'экономика',checked:false,blocked:false },
    { id:40198, word:'economist',translate:'экономист',checked:false,blocked:false },
    { id:40199, word:'editorial',translate:'редакционная статья',checked:false,blocked:false },
    { id:40200, word:'efficiently',translate:'эффективно',checked:false,blocked:false },
    { id:40201, word:'elbow',translate:'локоть',checked:false,blocked:false },
    { id:40202, word:'electronics',translate:'электроника',checked:false,blocked:false },
    { id:40203, word:'elegant',translate:'элегантный',checked:false,blocked:false },
    { id:40204, word:'elementary',translate:'элементарно',checked:false,blocked:false },
    { id:40205, word:'eliminate',translate:'устранить',checked:false,blocked:false },
    { id:40206, word:'embrace',translate:'обнимать',checked:false,blocked:false },
    { id:40207, word:'emission',translate:'выброс',checked:false,blocked:false },
    { id:40208, word:'emotionally',translate:'эмоционально',checked:false,blocked:false },
    { id:40209, word:'empire',translate:'империя',checked:false,blocked:false },
    { id:40210, word:'enjoyable',translate:'приятный',checked:false,blocked:false },
    { id:40211, word:'entertaining',translate:'развлекательный',checked:false,blocked:false },
    { id:40212, word:'entrepreneur',translate:'предприниматель',checked:false,blocked:false },
    { id:40213, word:'envelope',translate:'конверт',checked:false,blocked:false },
    { id:40214, word:'equip',translate:'оснастить',checked:false,blocked:false },
    { id:40215, word:'equivalent',translate:'эквивалент',checked:false,blocked:false },
    { id:40216, word:'era',translate:'эпоха',checked:false,blocked:false },
    { id:40217, word:'erupt',translate:'извергаться',checked:false,blocked:false },
    { id:40218, word:'essentially',translate:'по существу',checked:false,blocked:false },
    { id:40219, word:'ethic',translate:'этнический',checked:false,blocked:false },
    { id:40220, word:'ethnic',translate:'этнический',checked:false,blocked:false },
    { id:40221, word:'evaluation',translate:'оценка',checked:false,blocked:false },
    { id:40222, word:'evident',translate:'очевидное',checked:false,blocked:false },
    { id:40223, word:'evolution',translate:'эволюция',checked:false,blocked:false },
    { id:40224, word:'evolve',translate:'развиваться',checked:false,blocked:false },
    { id:40225, word:'exceed',translate:'превышать',checked:false,blocked:false },
    { id:40226, word:'exception',translate:'исключение',checked:false,blocked:false },
    { id:40227, word:'excessive',translate:'чрезмерный',checked:false,blocked:false },
    { id:40228, word:'exclude',translate:'исключить',checked:false,blocked:false },
    { id:40229, word:'exhibit',translate:'экспонат',checked:false,blocked:false },
    { id:40230, word:'exit',translate:'выход',checked:false,blocked:false },
    { id:40231, word:'exotic',translate:'экзотика',checked:false,blocked:false },
    { id:40232, word:'expansion',translate:'расширение',checked:false,blocked:false },
    { id:40233, word:'expertise',translate:'экспертиза',checked:false,blocked:false },
    { id:40234, word:'exploit',translate:'использовать',checked:false,blocked:false },
    { id:40235, word:'exposure',translate:'экспозиция',checked:false,blocked:false },
    { id:40236, word:'extension',translate:'расширение',checked:false,blocked:false },
    { id:40237, word:'extensive',translate:'обширный',checked:false,blocked:false },
    { id:40238, word:'extensively',translate:'широко',checked:false,blocked:false },
    { id:40239, word:'extract',translate:'добывать',checked:false,blocked:false },
    { id:40240, word:'fabric',translate:'ткань',checked:false,blocked:false },
    { id:40241, word:'fabulous',translate:'сказочный',checked:false,blocked:false },
    { id:40242, word:'failed',translate:'неудачный',checked:false,blocked:false },
    { id:40243, word:'fake',translate:'подделка',checked:false,blocked:false },
    { id:40244, word:'fame',translate:'слава',checked:false,blocked:false },
    { id:40245, word:'fantasy',translate:'фантазия',checked:false,blocked:false },
    { id:40246, word:'fare',translate:'стоимость проезда',checked:false,blocked:false },
    { id:40247, word:'federal',translate:'федеральный',checked:false,blocked:false },
    { id:40248, word:'fever',translate:'лихорадка',checked:false,blocked:false },
    { id:40249, word:'firefighter',translate:'пожарный',checked:false,blocked:false },
    { id:40250, word:'firework',translate:'фейерверк',checked:false,blocked:false },
    { id:40251, word:'firm',translate:'твердо',checked:false,blocked:false },
    { id:40252, word:'firmly',translate:'твердо',checked:false,blocked:false },
    { id:40253, word:'flavour',translate:'вкус',checked:false,blocked:false },
    { id:40254, word:'fond',translate:'любимый',checked:false,blocked:false },
    { id:40255, word:'fool',translate:'дурак',checked:false,blocked:false },
    { id:40256, word:'forbid',translate:'запретить',checked:false,blocked:false },
    { id:40257, word:'forecast',translate:'прогноз',checked:false,blocked:false },
    { id:40258, word:'format',translate:'формат',checked:false,blocked:false },
    { id:40259, word:'formation',translate:'образование',checked:false,blocked:false },
    { id:40260, word:'formerly',translate:'ранее',checked:false,blocked:false },
    { id:40261, word:'fortunate',translate:'удача',checked:false,blocked:false },
    { id:40262, word:'forum',translate:'форум',checked:false,blocked:false },
    { id:40263, word:'fossil',translate:'ископаемое',checked:false,blocked:false },
    { id:40264, word:'foundation',translate:'фонд',checked:false,blocked:false },
    { id:40265, word:'founder',translate:'основатель',checked:false,blocked:false },
    { id:40266, word:'fraction',translate:'фракция',checked:false,blocked:false },
    { id:40267, word:'fragment',translate:'фрагмент',checked:false,blocked:false },
    { id:40268, word:'framework',translate:'основа',checked:false,blocked:false },
    { id:40269, word:'fraud',translate:'мошенничество',checked:false,blocked:false },
    { id:40270, word:'freely',translate:'свободно',checked:false,blocked:false },
    { id:40271, word:'frequent',translate:'часто',checked:false,blocked:false },
    { id:40272, word:'fulfil',translate:'выполнять',checked:false,blocked:false },
    { id:40273, word:'full-time',translate:'полный рабочий день',checked:false,blocked:false },
    { id:40274, word:'fundamentally',translate:'основательно',checked:false,blocked:false },
    { id:40275, word:'furious',translate:'яростный',checked:false,blocked:false },
    { id:40276, word:'gaming',translate:'игра',checked:false,blocked:false },
    { id:40277, word:'gay',translate:'гей',checked:false,blocked:false },
    { id:40278, word:'gender',translate:'пол',checked:false,blocked:false },
    { id:40279, word:'gene',translate:'ген',checked:false,blocked:false },
    { id:40280, word:'genetic',translate:'генетика',checked:false,blocked:false },
    { id:40281, word:'genius',translate:'гений',checked:false,blocked:false },
    { id:40282, word:'genuine',translate:'подлинный',checked:false,blocked:false },
    { id:40283, word:'genuinely',translate:'подлинный',checked:false,blocked:false },
    { id:40284, word:'gesture',translate:'жест',checked:false,blocked:false },
    { id:40285, word:'gig',translate:'концерт',checked:false,blocked:false },
    { id:40286, word:'globalization',translate:'глобализация',checked:false,blocked:false },
    { id:40287, word:'globe',translate:'глобус',checked:false,blocked:false },
    { id:40288, word:'golden',translate:'золотой',checked:false,blocked:false },
    { id:40289, word:'goodness',translate:'добро',checked:false,blocked:false },
    { id:40290, word:'gorgeous',translate:'великолепный',checked:false,blocked:false },
    { id:40291, word:'governor',translate:'губернатор',checked:false,blocked:false },
    { id:40292, word:'graphic',translate:'графика',checked:false,blocked:false },
    { id:40293, word:'graphics',translate:'графика',checked:false,blocked:false },
    { id:40294, word:'greatly',translate:'очень',checked:false,blocked:false },
    { id:40295, word:'greenhouse',translate:'теплица',checked:false,blocked:false },
    { id:40296, word:'grocery',translate:'продукты',checked:false,blocked:false },
    { id:40297, word:'guideline',translate:'руководство',checked:false,blocked:false },
    { id:40298, word:'habitat',translate:'среда обитания',checked:false,blocked:false },
    { id:40299, word:'harbour',translate:'гавань',checked:false,blocked:false },
    { id:40300, word:'headquarters',translate:'штаб-квартира',checked:false,blocked:false },
    { id:40301, word:'heal',translate:'исцелиться',checked:false,blocked:false },
    { id:40302, word:'healthcare',translate:'здравоохранение',checked:false,blocked:false },
    { id:40303, word:'helmet',translate:'шлем',checked:false,blocked:false },
    { id:40304, word:'hence',translate:'следовательно',checked:false,blocked:false },
    { id:40305, word:'herb',translate:'трава',checked:false,blocked:false },
    { id:40306, word:'hidden',translate:'скрытый',checked:false,blocked:false },
    { id:40307, word:'highway',translate:'шоссе',checked:false,blocked:false },
    { id:40308, word:'hilarious',translate:'уморительный',checked:false,blocked:false },
    { id:40309, word:'hip',translate:'хип',checked:false,blocked:false },
    { id:40310, word:'historian',translate:'историк',checked:false,blocked:false },
    { id:40311, word:'homeless',translate:'бездомный',checked:false,blocked:false },
    { id:40312, word:'honesty',translate:'честность',checked:false,blocked:false },
    { id:40313, word:'hook',translate:'крючок',checked:false,blocked:false },
    { id:40314, word:'hopefully',translate:'надеюсь',checked:false,blocked:false },
    { id:40315, word:'hunger',translate:'голод',checked:false,blocked:false },
    { id:40316, word:'hypothesis',translate:'гипотеза',checked:false,blocked:false },
    { id:40317, word:'icon',translate:'икона',checked:false,blocked:false },
    { id:40318, word:'ID',translate:'ID',checked:false,blocked:false },
    { id:40319, word:'identical',translate:'идентичный',checked:false,blocked:false },
    { id:40320, word:'illusion',translate:'иллюзия',checked:false,blocked:false },
    { id:40321, word:'immigration',translate:'иммиграция',checked:false,blocked:false },
    { id:40322, word:'immune',translate:'иммунитет',checked:false,blocked:false },
    { id:40323, word:'implement',translate:'внедрение',checked:false,blocked:false },
    { id:40324, word:'implication',translate:'импликация',checked:false,blocked:false },
    { id:40325, word:'incentive',translate:'стимул',checked:false,blocked:false },
    { id:40326, word:'incorporate',translate:'включить',checked:false,blocked:false },
    { id:40327, word:'incorrect',translate:'неверный',checked:false,blocked:false },
    { id:40328, word:'independence',translate:'независимость',checked:false,blocked:false },
    { id:40329, word:'index',translate:'индекс',checked:false,blocked:false },
    { id:40330, word:'indication',translate:'указание',checked:false,blocked:false },
    { id:40331, word:'inevitable',translate:'неизбежно',checked:false,blocked:false },
    { id:40332, word:'inevitably',translate:'неизбежно',checked:false,blocked:false },
    { id:40333, word:'infer',translate:'вывод',checked:false,blocked:false },
    { id:40334, word:'inflation',translate:'инфляция',checked:false,blocked:false },
    { id:40335, word:'info',translate:'информация',checked:false,blocked:false },
    { id:40336, word:'infrastructure',translate:'инфраструктура',checked:false,blocked:false },
    { id:40337, word:'inhabitant',translate:'житель',checked:false,blocked:false },
    { id:40338, word:'inherit',translate:'наследовать',checked:false,blocked:false },
    { id:40339, word:'ink',translate:'чернила',checked:false,blocked:false },
    { id:40340, word:'innovation',translate:'инновация',checked:false,blocked:false },
    { id:40341, word:'innovative',translate:'инновационный',checked:false,blocked:false },
    { id:40342, word:'input',translate:'вход',checked:false,blocked:false },
    { id:40343, word:'insert',translate:'вставка',checked:false,blocked:false },
    { id:40344, word:'inspector',translate:'инспектор',checked:false,blocked:false },
    { id:40345, word:'installation',translate:'установка',checked:false,blocked:false },
    { id:40346, word:'instant',translate:'мгновенно',checked:false,blocked:false },
    { id:40347, word:'instantly',translate:'мгновенно',checked:false,blocked:false },
    { id:40348, word:'integrate',translate:'интегрировать',checked:false,blocked:false },
    { id:40349, word:'intellectual',translate:'интеллектуальный',checked:false,blocked:false },
    { id:40350, word:'interact',translate:'взаимодействовать',checked:false,blocked:false },
    { id:40351, word:'interaction',translate:'взаимодействие',checked:false,blocked:false },
    { id:40352, word:'interpretation',translate:'интерпретация',checked:false,blocked:false },
    { id:40353, word:'interval',translate:'интервал',checked:false,blocked:false },
    { id:40354, word:'invade',translate:'вторгнуться',checked:false,blocked:false },
    { id:40355, word:'invasion',translate:'вторжение',checked:false,blocked:false },
    { id:40356, word:'investor',translate:'инвестор',checked:false,blocked:false },
    { id:40357, word:'isolate',translate:'изолировать',checked:false,blocked:false },
    { id:40358, word:'isolated',translate:'изолировать',checked:false,blocked:false },
    { id:40359, word:'jail',translate:'тюрьма',checked:false,blocked:false },
    { id:40360, word:'jet',translate:'самолет',checked:false,blocked:false },
    { id:40361, word:'joint',translate:'совместное',checked:false,blocked:false },
    { id:40362, word:'journalism',translate:'журналистика',checked:false,blocked:false },
    { id:40363, word:'jury',translate:'суд присяжных',checked:false,blocked:false },
    { id:40364, word:'kit',translate:'набор',checked:false,blocked:false },
    { id:40365, word:'ladder',translate:'лестница',checked:false,blocked:false },
    { id:40366, word:'landing',translate:'посадка',checked:false,blocked:false },
    { id:40367, word:'lane',translate:'переулок',checked:false,blocked:false },
    { id:40368, word:'lately',translate:'в последнее время',checked:false,blocked:false },
    { id:40369, word:'leaflet',translate:'листовка',checked:false,blocked:false },
    { id:40370, word:'legend',translate:'легенда',checked:false,blocked:false },
    { id:40371, word:'lens',translate:'линза',checked:false,blocked:false },
    { id:40372, word:'lifetime',translate:'срок службы',checked:false,blocked:false },
    { id:40373, word:'lighting',translate:'освещение',checked:false,blocked:false },
    { id:40374, word:'likewise',translate:'аналогично',checked:false,blocked:false },
    { id:40375, word:'limitation',translate:'ограничение',checked:false,blocked:false },
    { id:40376, word:'literally',translate:'буквально',checked:false,blocked:false },
    { id:40377, word:'literary',translate:'литературный',checked:false,blocked:false },
    { id:40378, word:'litre',translate:'литр',checked:false,blocked:false },
    { id:40379, word:'litter',translate:'мусор',checked:false,blocked:false },
    { id:40380, word:'logo',translate:'логотип',checked:false,blocked:false },
    { id:40381, word:'lottery',translate:'лотерея',checked:false,blocked:false },
    { id:40382, word:'loyal',translate:'преданный',checked:false,blocked:false },
    { id:40383, word:'lyric',translate:'лирика',checked:false,blocked:false },
    { id:40384, word:'magnificent',translate:'великолепный',checked:false,blocked:false },
    { id:40385, word:'make-up',translate:'макияж',checked:false,blocked:false },
    { id:40386, word:'making',translate:'изготовление',checked:false,blocked:false },
    { id:40387, word:'manufacture',translate:'производство',checked:false,blocked:false },
    { id:40388, word:'manufacturing',translate:'производство',checked:false,blocked:false },
    { id:40389, word:'marathon',translate:'марафон',checked:false,blocked:false },
    { id:40390, word:'margin',translate:'маржа',checked:false,blocked:false },
    { id:40391, word:'marker',translate:'маркер',checked:false,blocked:false },
    { id:40392, word:'martial',translate:'единоборство',checked:false,blocked:false },
    { id:40393, word:'mate',translate:'приятель',checked:false,blocked:false },
    { id:40394, word:'mayor',translate:'мэр',checked:false,blocked:false },
    { id:40395, word:'mechanic',translate:'механик',checked:false,blocked:false },
    { id:40396, word:'mechanical',translate:'механический',checked:false,blocked:false },
    { id:40397, word:'mechanism',translate:'механизм',checked:false,blocked:false },
    { id:40398, word:'medal',translate:'медаль',checked:false,blocked:false },
    { id:40399, word:'medication',translate:'лекарство',checked:false,blocked:false },
    { id:40400, word:'membership',translate:'членство',checked:false,blocked:false },
    { id:40401, word:'memorable',translate:'памятный',checked:false,blocked:false },
    { id:40402, word:'metaphor',translate:'метафора',checked:false,blocked:false },
    { id:40403, word:'miner',translate:'шахтер',checked:false,blocked:false },
    { id:40404, word:'miserable',translate:'несчастный',checked:false,blocked:false },
    { id:40405, word:'mode',translate:'способ',checked:false,blocked:false },
    { id:40406, word:'modest',translate:'скромный',checked:false,blocked:false },
    { id:40407, word:'monster',translate:'монстр',checked:false,blocked:false },
    { id:40408, word:'monthly',translate:'ежемесячно',checked:false,blocked:false },
    { id:40409, word:'monument',translate:'памятник',checked:false,blocked:false },
    { id:40410, word:'moreover',translate:'более того',checked:false,blocked:false },
    { id:40411, word:'mortgage',translate:'ипотека',checked:false,blocked:false },
    { id:40412, word:'mosque',translate:'мечеть',checked:false,blocked:false },
    { id:40413, word:'motion',translate:'движение',checked:false,blocked:false },
    { id:40414, word:'motivate',translate:'мотивировать',checked:false,blocked:false },
    { id:40415, word:'motivation',translate:'мотивация',checked:false,blocked:false },
    { id:40416, word:'moving',translate:'движение',checked:false,blocked:false },
    { id:40417, word:'myth',translate:'миф',checked:false,blocked:false },
    { id:40418, word:'naked',translate:'голый',checked:false,blocked:false },
    { id:40419, word:'nasty',translate:'противный',checked:false,blocked:false },
    { id:40420, word:'navigation',translate:'навигация',checked:false,blocked:false },
    { id:40421, word:'nearby',translate:'рядом',checked:false,blocked:false },
    { id:40422, word:'necessity',translate:'необходимость',checked:false,blocked:false },
    { id:40423, word:'negotiate',translate:'договориться',checked:false,blocked:false },
    { id:40424, word:'negotiation',translate:'переговоры',checked:false,blocked:false },
    { id:40425, word:'neutral',translate:'нейтральный',checked:false,blocked:false },
    { id:40426, word:'newly',translate:'недавно',checked:false,blocked:false },
    { id:40427, word:'norm',translate:'норма',checked:false,blocked:false },
    { id:40428, word:'notebook',translate:'блокнот',checked:false,blocked:false },
    { id:40429, word:'novelist',translate:'романист',checked:false,blocked:false },
    { id:40430, word:'nowadays',translate:'сегодня',checked:false,blocked:false },
    { id:40431, word:'nursing',translate:'уход за больными',checked:false,blocked:false },
    { id:40432, word:'nutrition',translate:'питание',checked:false,blocked:false },
    { id:40433, word:'obesity',translate:'ожирение',checked:false,blocked:false },
    { id:40434, word:'observer',translate:'наблюдатель',checked:false,blocked:false },
    { id:40435, word:'obstacle',translate:'препятствие',checked:false,blocked:false },
    { id:40436, word:'occupation',translate:'занятие',checked:false,blocked:false },
    { id:40437, word:'occupy',translate:'занятие',checked:false,blocked:false },
    { id:40438, word:'offender',translate:'преступник',checked:false,blocked:false },
    { id:40439, word:'ongoing',translate:'текущий',checked:false,blocked:false },
    { id:40440, word:'openly',translate:'открыто',checked:false,blocked:false },
    { id:40441, word:'opera',translate:'опера',checked:false,blocked:false },
    { id:40442, word:'operator',translate:'оператор',checked:false,blocked:false },
    { id:40443, word:'optimistic',translate:'оптимист',checked:false,blocked:false },
    { id:40444, word:'orchestra',translate:'оркестр',checked:false,blocked:false },
    { id:40445, word:'organic',translate:'органический',checked:false,blocked:false },
    { id:40446, word:'outfit',translate:'наряд',checked:false,blocked:false },
    { id:40447, word:'output',translate:'выход',checked:false,blocked:false },
    { id:40448, word:'outstanding',translate:'выдающийся',checked:false,blocked:false },
    { id:40449, word:'overcome',translate:'преодолеть',checked:false,blocked:false },
    { id:40450, word:'overnight',translate:'ночной',checked:false,blocked:false },
    { id:40451, word:'overseas',translate:'за границей',checked:false,blocked:false },
    { id:40452, word:'ownership',translate:'владение',checked:false,blocked:false },
    { id:40453, word:'oxygen',translate:'кислород',checked:false,blocked:false },
    { id:40454, word:'packet',translate:'пакет',checked:false,blocked:false },
    { id:40455, word:'palm',translate:'ладонь',checked:false,blocked:false },
    { id:40456, word:'panic',translate:'паника',checked:false,blocked:false },
    { id:40457, word:'parade',translate:'парад',checked:false,blocked:false },
    { id:40458, word:'parallel',translate:'параллель',checked:false,blocked:false },
    { id:40459, word:'participation',translate:'участие',checked:false,blocked:false },
    { id:40460, word:'partnership',translate:'партнерство',checked:false,blocked:false },
    { id:40461, word:'part-time',translate:'неполный рабочий день',checked:false,blocked:false },
    { id:40462, word:'passionate',translate:'страстный',checked:false,blocked:false },
    { id:40463, word:'password',translate:'пароль',checked:false,blocked:false },
    { id:40464, word:'patience',translate:'терпение',checked:false,blocked:false },
    { id:40465, word:'pause',translate:'пауза',checked:false,blocked:false },
    { id:40466, word:'peer',translate:'сверстник',checked:false,blocked:false },
    { id:40467, word:'penalty',translate:'штраф',checked:false,blocked:false },
    { id:40468, word:'perceive',translate:'воспринимать',checked:false,blocked:false },
    { id:40469, word:'perception',translate:'восприятие',checked:false,blocked:false },
    { id:40470, word:'permanently',translate:'постоянно',checked:false,blocked:false },
    { id:40471, word:'pill',translate:'таблетка',checked:false,blocked:false },
    { id:40472, word:'pity',translate:'жалость',checked:false,blocked:false },
    { id:40473, word:'placement',translate:'размещение',checked:false,blocked:false },
    { id:40474, word:'portion',translate:'порция',checked:false,blocked:false },
    { id:40475, word:'potentially',translate:'потенциально',checked:false,blocked:false },
    { id:40476, word:'precede',translate:'предшествовать',checked:false,blocked:false },
    { id:40477, word:'precious',translate:'драгоценный',checked:false,blocked:false },
    { id:40478, word:'precise',translate:'точный',checked:false,blocked:false },
    { id:40479, word:'precisely',translate:'точно',checked:false,blocked:false },
    { id:40480, word:'predictable',translate:'предсказуемый',checked:false,blocked:false },
    { id:40481, word:'preference',translate:'предпочтение',checked:false,blocked:false },
    { id:40482, word:'pride',translate:'гордость',checked:false,blocked:false },
    { id:40483, word:'primarily',translate:'в первую очередь',checked:false,blocked:false },
    { id:40484, word:'principal',translate:'главный',checked:false,blocked:false },
    { id:40485, word:'prior',translate:'предшествующий',checked:false,blocked:false },
    { id:40486, word:'probability',translate:'вероятность',checked:false,blocked:false },
    { id:40487, word:'probable',translate:'вероятный',checked:false,blocked:false },
    { id:40488, word:'proceed',translate:'продолжить',checked:false,blocked:false },
    { id:40489, word:'programming',translate:'программирование',checked:false,blocked:false },
    { id:40490, word:'progressive',translate:'прогрессивный',checked:false,blocked:false },
    { id:40491, word:'prohibit',translate:'запретить',checked:false,blocked:false },
    { id:40492, word:'promising',translate:'перспективный',checked:false,blocked:false },
    { id:40493, word:'promotion',translate:'продвижение',checked:false,blocked:false },
    { id:40494, word:'prompt',translate:'подсказка',checked:false,blocked:false },
    { id:40495, word:'proportion',translate:'пропорция',checked:false,blocked:false },
    { id:40496, word:'protein',translate:'протеин',checked:false,blocked:false },
    { id:40497, word:'protester',translate:'протест',checked:false,blocked:false },
    { id:40498, word:'psychological',translate:'психологический',checked:false,blocked:false },
    { id:40499, word:'publicity',translate:'реклама',checked:false,blocked:false },
    { id:40500, word:'publishing',translate:'публикация',checked:false,blocked:false },
    { id:40501, word:'punk',translate:'панк',checked:false,blocked:false },
    { id:40502, word:'purely',translate:'чисто',checked:false,blocked:false },
    { id:40503, word:'pursuit',translate:'погоня',checked:false,blocked:false },
    { id:40504, word:'puzzle',translate:'головоломка',checked:false,blocked:false },
    { id:40505, word:'questionnaire',translate:'анкета',checked:false,blocked:false },
    { id:40506, word:'racial',translate:'раса',checked:false,blocked:false },
    { id:40507, word:'racism',translate:'расизм',checked:false,blocked:false },
    { id:40508, word:'racist',translate:'расист',checked:false,blocked:false },
    { id:40509, word:'radiation',translate:'радиация',checked:false,blocked:false },
    { id:40510, word:'rail',translate:'железная дорога',checked:false,blocked:false },
    { id:40511, word:'random',translate:'случайный',checked:false,blocked:false },
    { id:40512, word:'rat',translate:'крыса',checked:false,blocked:false },
    { id:40513, word:'rating',translate:'рейтинг',checked:false,blocked:false },
    { id:40514, word:'reasonably',translate:'разумно',checked:false,blocked:false },
    { id:40515, word:'rebuild',translate:'восстановление',checked:false,blocked:false },
    { id:40516, word:'receiver',translate:'приемник',checked:false,blocked:false },
    { id:40517, word:'recession',translate:'рецессия',checked:false,blocked:false },
    { id:40518, word:'reckon',translate:'считаться',checked:false,blocked:false },
    { id:40519, word:'recognition',translate:'признание',checked:false,blocked:false },
    { id:40520, word:'recovery',translate:'восстановление',checked:false,blocked:false },
    { id:40521, word:'recruit',translate:'рекрут',checked:false,blocked:false },
    { id:40522, word:'recruitment',translate:'вербовка',checked:false,blocked:false },
    { id:40523, word:'referee',translate:'судья',checked:false,blocked:false },
    { id:40524, word:'refugee',translate:'беженец',checked:false,blocked:false },
    { id:40525, word:'registration',translate:'регистрация',checked:false,blocked:false },
    { id:40526, word:'regulate',translate:'регулировать',checked:false,blocked:false },
    { id:40527, word:'reinforce',translate:'усилить',checked:false,blocked:false },
    { id:40528, word:'relieve',translate:'облегчить',checked:false,blocked:false },
    { id:40529, word:'relieved',translate:'облегчить',checked:false,blocked:false },
    { id:40530, word:'remarkable',translate:'замечательно',checked:false,blocked:false },
    { id:40531, word:'remarkably',translate:'замечательно',checked:false,blocked:false },
    { id:40532, word:'reporting',translate:'отчитываться',checked:false,blocked:false },
    { id:40533, word:'resign',translate:'уйти в отставку',checked:false,blocked:false },
    { id:40534, word:'resolution',translate:'разрешение',checked:false,blocked:false },
    { id:40535, word:'restore',translate:'восстановить',checked:false,blocked:false },
    { id:40536, word:'restrict',translate:'ограничение',checked:false,blocked:false },
    { id:40537, word:'restriction',translate:'ограничение',checked:false,blocked:false },
    { id:40538, word:'retail',translate:'розничная торговля',checked:false,blocked:false },
    { id:40539, word:'retirement',translate:'пенсия',checked:false,blocked:false },
    { id:40540, word:'revenue',translate:'доход',checked:false,blocked:false },
    { id:40541, word:'revision',translate:'пересмотр',checked:false,blocked:false },
    { id:40542, word:'ridiculous',translate:'нелепость',checked:false,blocked:false },
    { id:40543, word:'risky',translate:'рискованный',checked:false,blocked:false },
    { id:40544, word:'rival',translate:'соперник',checked:false,blocked:false },
    { id:40545, word:'rob',translate:'ограбить',checked:false,blocked:false },
    { id:40546, word:'robbery',translate:'ограбление',checked:false,blocked:false },
    { id:40547, word:'rocket',translate:'ракета',checked:false,blocked:false },
    { id:40548, word:'romance',translate:'роман',checked:false,blocked:false },
    { id:40549, word:'rose',translate:'роза',checked:false,blocked:false },
    { id:40550, word:'roughly',translate:'примерно',checked:false,blocked:false },
    { id:40551, word:'ruin',translate:'разорение',checked:false,blocked:false },
    { id:40552, word:'satisfaction',translate:'удовлетворение',checked:false,blocked:false },
    { id:40553, word:'scandal',translate:'скандал',checked:false,blocked:false },
    { id:40554, word:'scare',translate:'испуг',checked:false,blocked:false },
    { id:40555, word:'scenario',translate:'сценарий',checked:false,blocked:false },
    { id:40556, word:'scholar',translate:'стипендиат',checked:false,blocked:false },
    { id:40557, word:'scholarship',translate:'стипендия',checked:false,blocked:false },
    { id:40558, word:'scratch',translate:'царапина',checked:false,blocked:false },
    { id:40559, word:'screening',translate:'отбор',checked:false,blocked:false },
    { id:40560, word:'seeker',translate:'искатель',checked:false,blocked:false },
    { id:40561, word:'seminar',translate:'семинар',checked:false,blocked:false },
    { id:40562, word:'settler',translate:'поселенец',checked:false,blocked:false },
    { id:40563, word:'severely',translate:'сурово',checked:false,blocked:false },
    { id:40564, word:'sexy',translate:'сексуальный',checked:false,blocked:false },
    { id:40565, word:'shaped',translate:'форма',checked:false,blocked:false },
    { id:40566, word:'shocking',translate:'шокирующий',checked:false,blocked:false },
    { id:40567, word:'shore',translate:'берег',checked:false,blocked:false },
    { id:40568, word:'shortage',translate:'дефицит',checked:false,blocked:false },
    { id:40569, word:'shortly',translate:'вскоре',checked:false,blocked:false },
    { id:40570, word:'short-term',translate:'краткосрочный',checked:false,blocked:false },
    { id:40571, word:'sibling',translate:'брат и сестра',checked:false,blocked:false },
    { id:40572, word:'signature',translate:'подпись',checked:false,blocked:false },
    { id:40573, word:'significance',translate:'значение',checked:false,blocked:false },
    { id:40574, word:'skilled',translate:'опытный',checked:false,blocked:false },
    { id:40575, word:'skull',translate:'череп',checked:false,blocked:false },
    { id:40576, word:'slogan',translate:'лозунг',checked:false,blocked:false },
    { id:40577, word:'so-called',translate:'так называемый',checked:false,blocked:false },
    { id:40578, word:'somehow',translate:'какой-нибудь',checked:false,blocked:false },
    { id:40579, word:'sometime',translate:'иногда',checked:false,blocked:false },
    { id:40580, word:'sophisticated',translate:'сложный',checked:false,blocked:false },
    { id:40581, word:'spare',translate:'запасной',checked:false,blocked:false },
    { id:40582, word:'specialize',translate:'специализироваться',checked:false,blocked:false },
    { id:40583, word:'specify',translate:'уточнить',checked:false,blocked:false },
    { id:40584, word:'spectacular',translate:'зрелищный',checked:false,blocked:false },
    { id:40585, word:'spectator',translate:'зритель',checked:false,blocked:false },
    { id:40586, word:'speculate',translate:'спекулировать',checked:false,blocked:false },
    { id:40587, word:'speculation',translate:'спекуляция',checked:false,blocked:false },
    { id:40588, word:'spice',translate:'специи',checked:false,blocked:false },
    { id:40589, word:'spill',translate:'пролить',checked:false,blocked:false },
    { id:40590, word:'spite',translate:'злить',checked:false,blocked:false },
    { id:40591, word:'spoil',translate:'испортить',checked:false,blocked:false },
    { id:40592, word:'spokesman',translate:'пресс-секретарь',checked:false,blocked:false },
    { id:40593, word:'spokesperson',translate:'пресс-секретарь',checked:false,blocked:false },
    { id:40594, word:'spokeswoman',translate:'пресс-секретарь',checked:false,blocked:false },
    { id:40595, word:'sponsorship',translate:'спонсорство',checked:false,blocked:false },
    { id:40596, word:'sporting',translate:'спорт',checked:false,blocked:false },
    { id:40597, word:'stall',translate:'ларёк',checked:false,blocked:false },
    { id:40598, word:'stance',translate:'позиция',checked:false,blocked:false },
    { id:40599, word:'starve',translate:'голодать',checked:false,blocked:false },
    { id:40600, word:'steadily',translate:'постоянно',checked:false,blocked:false },
    { id:40601, word:'steam',translate:'пар',checked:false,blocked:false },
    { id:40602, word:'stimulate',translate:'стимулировать',checked:false,blocked:false },
    { id:40603, word:'strengthen',translate:'укреплять',checked:false,blocked:false },
    { id:40604, word:'strictly',translate:'строго',checked:false,blocked:false },
    { id:40605, word:'stroke',translate:'гладить',checked:false,blocked:false },
    { id:40606, word:'stunning',translate:'потрясающий',checked:false,blocked:false },
    { id:40607, word:'subsequent',translate:'впоследствии',checked:false,blocked:false },
    { id:40608, word:'subsequently',translate:'впоследствии',checked:false,blocked:false },
    { id:40609, word:'suburb',translate:'пригород',checked:false,blocked:false },
    { id:40610, word:'suffering',translate:'страдание',checked:false,blocked:false },
    { id:40611, word:'sufficient',translate:'достаточно',checked:false,blocked:false },
    { id:40612, word:'sufficiently',translate:'достаточно',checked:false,blocked:false },
    { id:40613, word:'super',translate:'супер',checked:false,blocked:false },
    { id:40614, word:'surgeon',translate:'хирург',checked:false,blocked:false },
    { id:40615, word:'survival',translate:'выживание',checked:false,blocked:false },
    { id:40616, word:'survivor',translate:'выживший',checked:false,blocked:false },
    { id:40617, word:'suspend',translate:'приостановить',checked:false,blocked:false },
    { id:40618, word:'sustainable',translate:'устойчивый',checked:false,blocked:false },
    { id:40619, word:'swallow',translate:'глотать',checked:false,blocked:false },
    { id:40620, word:'sympathetic',translate:'сочувствующий',checked:false,blocked:false },
    { id:40621, word:'tackle',translate:'захват',checked:false,blocked:false },
    { id:40622, word:'tag',translate:'метка',checked:false,blocked:false },
    { id:40623, word:'tap',translate:'кран',checked:false,blocked:false },
    { id:40624, word:'technological',translate:'технологический',checked:false,blocked:false },
    { id:40625, word:'teens',translate:'подростки',checked:false,blocked:false },
    { id:40626, word:'temple',translate:'храм',checked:false,blocked:false },
    { id:40627, word:'temporarily',translate:'временно',checked:false,blocked:false },
    { id:40628, word:'tendency',translate:'тенденция',checked:false,blocked:false },
    { id:40629, word:'tension',translate:'напряжение',checked:false,blocked:false },
    { id:40630, word:'terminal',translate:'терминал',checked:false,blocked:false },
    { id:40631, word:'terms',translate:'условия',checked:false,blocked:false },
    { id:40632, word:'terribly',translate:'ужасно',checked:false,blocked:false },
    { id:40633, word:'terrify',translate:'ужас',checked:false,blocked:false },
    { id:40634, word:'territory',translate:'территория',checked:false,blocked:false },
    { id:40635, word:'terror',translate:'террор',checked:false,blocked:false },
    { id:40636, word:'terrorism',translate:'терроризм',checked:false,blocked:false },
    { id:40637, word:'terrorist',translate:'террорист',checked:false,blocked:false },
    { id:40638, word:'testing',translate:'тестирование',checked:false,blocked:false },
    { id:40639, word:'textbook',translate:'учебник',checked:false,blocked:false },
    { id:40640, word:'theft',translate:'кража',checked:false,blocked:false },
    { id:40641, word:'therapist',translate:'терапевт',checked:false,blocked:false },
    { id:40642, word:'thesis',translate:'диссертация',checked:false,blocked:false },
    { id:40643, word:'thorough',translate:'тщательно',checked:false,blocked:false },
    { id:40644, word:'thoroughly',translate:'тщательно',checked:false,blocked:false },
    { id:40645, word:'thumb',translate:'большой палец',checked:false,blocked:false },
    { id:40646, word:'timing',translate:'сроки',checked:false,blocked:false },
    { id:40647, word:'tissue',translate:'ткань',checked:false,blocked:false },
    { id:40648, word:'ton',translate:'тонна',checked:false,blocked:false },
    { id:40649, word:'tonne',translate:'тонна',checked:false,blocked:false },
    { id:40650, word:'tournament',translate:'турнир',checked:false,blocked:false },
    { id:40651, word:'trace',translate:'след',checked:false,blocked:false },
    { id:40652, word:'trading',translate:'торговля',checked:false,blocked:false },
    { id:40653, word:'tragedy',translate:'трагедия',checked:false,blocked:false },
    { id:40654, word:'tragic',translate:'трагедия',checked:false,blocked:false },
    { id:40655, word:'trait',translate:'черта',checked:false,blocked:false },
    { id:40656, word:'transmit',translate:'передача',checked:false,blocked:false },
    { id:40657, word:'transportation',translate:'транспортировка',checked:false,blocked:false },
    { id:40658, word:'trap',translate:'ловушка',checked:false,blocked:false },
    { id:40659, word:'treasure',translate:'сокровище',checked:false,blocked:false },
    { id:40660, word:'tribe',translate:'племя',checked:false,blocked:false },
    { id:40661, word:'trigger',translate:'триггер',checked:false,blocked:false },
    { id:40662, word:'trillion',translate:'триллион',checked:false,blocked:false },
    { id:40663, word:'troop',translate:'войска',checked:false,blocked:false },
    { id:40664, word:'tsunami',translate:'цунами',checked:false,blocked:false },
    { id:40665, word:'ultimate',translate:'окончательный',checked:false,blocked:false },
    { id:40666, word:'unacceptable',translate:'неприемлемо',checked:false,blocked:false },
    { id:40667, word:'uncertainty',translate:'неопределенность',checked:false,blocked:false },
    { id:40668, word:'undergo',translate:'пройти',checked:false,blocked:false },
    { id:40669, word:'undertake',translate:'предпринимать',checked:false,blocked:false },
    { id:40670, word:'unfold',translate:'разворачиваться',checked:false,blocked:false },
    { id:40671, word:'unfortunate',translate:'несчастье',checked:false,blocked:false },
    { id:40672, word:'unite',translate:'объединяться',checked:false,blocked:false },
    { id:40673, word:'unity',translate:'единство',checked:false,blocked:false },
    { id:40674, word:'universal',translate:'универсальный',checked:false,blocked:false },
    { id:40675, word:'urgent',translate:'срочно',checked:false,blocked:false },
    { id:40676, word:'usage',translate:'использование',checked:false,blocked:false },
    { id:40677, word:'useless',translate:'бесполезно',checked:false,blocked:false },
    { id:40678, word:'valid',translate:'действительный',checked:false,blocked:false },
    { id:40679, word:'variation',translate:'вариация',checked:false,blocked:false },
    { id:40680, word:'vertical',translate:'вертикаль',checked:false,blocked:false },
    { id:40681, word:'viewpoint',translate:'точка зрения',checked:false,blocked:false },
    { id:40682, word:'visa',translate:'виза',checked:false,blocked:false },
    { id:40683, word:'visible',translate:'видимый',checked:false,blocked:false },
    { id:40684, word:'voluntary',translate:'добровольный',checked:false,blocked:false },
    { id:40685, word:'voting',translate:'голосование',checked:false,blocked:false },
    { id:40686, word:'wander',translate:'странствовать',checked:false,blocked:false },
    { id:40687, word:'warming',translate:'согреться',checked:false,blocked:false },
    { id:40688, word:'weekly',translate:'еженедельник',checked:false,blocked:false },
    { id:40689, word:'weird',translate:'странный',checked:false,blocked:false },
    { id:40690, word:'welfare',translate:'благосостояние',checked:false,blocked:false },
    { id:40691, word:'wheat',translate:'пшеница',checked:false,blocked:false },
    { id:40692, word:'whoever',translate:'кто бы ни был',checked:false,blocked:false },
    { id:40693, word:'widespread',translate:'широкое распространение',checked:false,blocked:false },
    { id:40694, word:'wisdom',translate:'мудрость',checked:false,blocked:false },
    { id:40695, word:'withdraw',translate:'вывести',checked:false,blocked:false },
    { id:40696, word:'workforce',translate:'рабочая сила',checked:false,blocked:false },
    { id:40697, word:'workplace',translate:'рабочее место',checked:false,blocked:false },
    { id:40698, word:'workshop',translate:'мастерская',checked:false,blocked:false },
    { id:40699, word:'worm',translate:'червь',checked:false,blocked:false },
    { id:40700, word:'wrist',translate:'запястье',checked:false,blocked:false }
  ];


export const c1 = [
    { id:50001, word:'abolish',translate:'отменить',checked:false,blocked:false },
    { id:50002, word:'abortion',translate:'аборт',checked:false,blocked:false },
    { id:50003, word:'absence',translate:'отсутствие',checked:false,blocked:false },
    { id:50004, word:'absent',translate:'отсутствие',checked:false,blocked:false },
    { id:50005, word:'absurd',translate:'абсурд',checked:false,blocked:false },
    { id:50006, word:'abundance',translate:'изобилие',checked:false,blocked:false },
    { id:50007, word:'abuse',translate:'злоупотребление',checked:false,blocked:false },
    { id:50008, word:'academy',translate:'академия',checked:false,blocked:false },
    { id:50009, word:'accelerate',translate:'ускорить',checked:false,blocked:false },
    { id:50010, word:'acceptance',translate:'акцепт',checked:false,blocked:false },
    { id:50011, word:'accessible',translate:'доступный',checked:false,blocked:false },
    { id:50012, word:'accomplishment',translate:'достижение',checked:false,blocked:false },
    { id:50013, word:'accordance',translate:'в соответствии',checked:false,blocked:false },
    { id:50014, word:'accordingly',translate:'соответственно',checked:false,blocked:false },
    { id:50015, word:'accountability',translate:'подотчетность',checked:false,blocked:false },
    { id:50016, word:'accountable',translate:'подотчетный',checked:false,blocked:false },
    { id:50017, word:'accumulate',translate:'накапливать',checked:false,blocked:false },
    { id:50018, word:'accumulation',translate:'накопление',checked:false,blocked:false },
    { id:50019, word:'accusation',translate:'обвинение',checked:false,blocked:false },
    { id:50020, word:'accused',translate:'обвиняемый',checked:false,blocked:false },
    { id:50021, word:'acid',translate:'кислота',checked:false,blocked:false },
    { id:50022, word:'acquisition',translate:'приобретение',checked:false,blocked:false },
    { id:50023, word:'acre',translate:'акр',checked:false,blocked:false },
    { id:50024, word:'activation',translate:'активация',checked:false,blocked:false },
    { id:50025, word:'activist',translate:'активист',checked:false,blocked:false },
    { id:50026, word:'acute',translate:'острый',checked:false,blocked:false },
    { id:50027, word:'adaptation',translate:'адаптация',checked:false,blocked:false },
    { id:50028, word:'adhere',translate:'придерживаться',checked:false,blocked:false },
    { id:50029, word:'adjacent',translate:'соседний',checked:false,blocked:false },
    { id:50030, word:'adjustment',translate:'адаптация',checked:false,blocked:false },
    { id:50031, word:'administer',translate:'администрировать',checked:false,blocked:false },
    { id:50032, word:'administrative',translate:'администратор',checked:false,blocked:false },
    { id:50033, word:'administrator',translate:'администратор',checked:false,blocked:false },
    { id:50034, word:'admission',translate:'допуск',checked:false,blocked:false },
    { id:50035, word:'adolescent',translate:'подросток',checked:false,blocked:false },
    { id:50036, word:'adoption',translate:'усыновление',checked:false,blocked:false },
    { id:50037, word:'adverse',translate:'неблагоприятный',checked:false,blocked:false },
    { id:50038, word:'advocate',translate:'защитник',checked:false,blocked:false },
    { id:50039, word:'aesthetic',translate:'эстетика',checked:false,blocked:false },
    { id:50040, word:'affection',translate:'привязанность',checked:false,blocked:false },
    { id:50041, word:'aftermath',translate:'последствия',checked:false,blocked:false },
    { id:50042, word:'aggression',translate:'агрессия',checked:false,blocked:false },
    { id:50043, word:'agricultural',translate:'сельское хозяйство',checked:false,blocked:false },
    { id:50044, word:'aide',translate:'помощь',checked:false,blocked:false },
    { id:50045, word:'albeit',translate:'хотя',checked:false,blocked:false },
    { id:50046, word:'alert',translate:'предупреждение',checked:false,blocked:false },
    { id:50047, word:'alien',translate:'инопланетянин',checked:false,blocked:false },
    { id:50048, word:'align',translate:'выравнивание',checked:false,blocked:false },
    { id:50049, word:'alignment',translate:'выравнивание',checked:false,blocked:false },
    { id:50050, word:'alike',translate:'одинаковый',checked:false,blocked:false },
    { id:50051, word:'allegation',translate:'утверждение',checked:false,blocked:false },
    { id:50052, word:'allege',translate:'утверждать',checked:false,blocked:false },
    { id:50053, word:'allegedly',translate:'якобы',checked:false,blocked:false },
    { id:50054, word:'alliance',translate:'альянс',checked:false,blocked:false },
    { id:50055, word:'allocate',translate:'распределять',checked:false,blocked:false },
    { id:50056, word:'allocation',translate:'распределение',checked:false,blocked:false },
    { id:50057, word:'allowance',translate:'пособие',checked:false,blocked:false },
    { id:50058, word:'ally',translate:'союзник',checked:false,blocked:false },
    { id:50059, word:'aluminium',translate:'алюминий',checked:false,blocked:false },
    { id:50060, word:'amateur',translate:'любитель',checked:false,blocked:false },
    { id:50061, word:'ambassador',translate:'посол',checked:false,blocked:false },
    { id:50062, word:'amend',translate:'поправка',checked:false,blocked:false },
    { id:50063, word:'amendment',translate:'поправка',checked:false,blocked:false },
    { id:50064, word:'amid',translate:'среди',checked:false,blocked:false },
    { id:50065, word:'analogy',translate:'аналогия',checked:false,blocked:false },
    { id:50066, word:'anchor',translate:'якорь',checked:false,blocked:false },
    { id:50067, word:'angel',translate:'ангел',checked:false,blocked:false },
    { id:50068, word:'anonymous',translate:'аноним',checked:false,blocked:false },
    { id:50069, word:'apparatus',translate:'аппарат',checked:false,blocked:false },
    { id:50070, word:'appealing',translate:'привлекательный',checked:false,blocked:false },
    { id:50071, word:'appetite',translate:'аппетит',checked:false,blocked:false },
    { id:50072, word:'applaud',translate:'аплодировать',checked:false,blocked:false },
    { id:50073, word:'applicable',translate:'применимо',checked:false,blocked:false },
    { id:50074, word:'appoint',translate:'назначить',checked:false,blocked:false },
    { id:50075, word:'appreciation',translate:'признательность',checked:false,blocked:false },
    { id:50076, word:'arbitrary',translate:'произвольный',checked:false,blocked:false },
    { id:50077, word:'architectural',translate:'архитектура',checked:false,blocked:false },
    { id:50078, word:'archive',translate:'архив',checked:false,blocked:false },
    { id:50079, word:'arena',translate:'арена',checked:false,blocked:false },
    { id:50080, word:'arguably',translate:'возможно',checked:false,blocked:false },
    { id:50081, word:'arm',translate:'рука',checked:false,blocked:false },
    { id:50082, word:'array',translate:'массив',checked:false,blocked:false },
    { id:50083, word:'articulate',translate:'артикулировать',checked:false,blocked:false },
    { id:50084, word:'ash',translate:'ясень',checked:false,blocked:false },
    { id:50085, word:'aspiration',translate:'стремление',checked:false,blocked:false },
    { id:50086, word:'aspire',translate:'стремиться',checked:false,blocked:false },
    { id:50087, word:'assassination',translate:'покушение',checked:false,blocked:false },
    { id:50088, word:'assault',translate:'нападение',checked:false,blocked:false },
    { id:50089, word:'assemble',translate:'собирать',checked:false,blocked:false },
    { id:50090, word:'assembly',translate:'сборка',checked:false,blocked:false },
    { id:50091, word:'assert',translate:'утверждать',checked:false,blocked:false },
    { id:50092, word:'assertion',translate:'утверждение',checked:false,blocked:false },
    { id:50093, word:'assurance',translate:'заверение',checked:false,blocked:false },
    { id:50094, word:'asylum',translate:'убежище',checked:false,blocked:false },
    { id:50095, word:'atrocity',translate:'злодеяние',checked:false,blocked:false },
    { id:50096, word:'attain',translate:'достичь',checked:false,blocked:false },
    { id:50097, word:'attendance',translate:'присутствие',checked:false,blocked:false },
    { id:50098, word:'attorney',translate:'адвокат',checked:false,blocked:false },
    { id:50099, word:'attribute',translate:'атрибут',checked:false,blocked:false },
    { id:50100, word:'audit',translate:'аудит',checked:false,blocked:false },
    { id:50101, word:'authentic',translate:'подлинный',checked:false,blocked:false },
    { id:50102, word:'authorize',translate:'авторизация',checked:false,blocked:false },
    { id:50103, word:'auto',translate:'авто',checked:false,blocked:false },
    { id:50104, word:'autonomy',translate:'автономия',checked:false,blocked:false },
    { id:50105, word:'availability',translate:'доступность',checked:false,blocked:false },
    { id:50106, word:'await',translate:'ожидать',checked:false,blocked:false },
    { id:50107, word:'backdrop',translate:'задник',checked:false,blocked:false },
    { id:50108, word:'backing',translate:'резервное копирование',checked:false,blocked:false },
    { id:50109, word:'backup',translate:'поддержка',checked:false,blocked:false },
    { id:50110, word:'bail',translate:'поручительство',checked:false,blocked:false },
    { id:50111, word:'ballot',translate:'бюллетень',checked:false,blocked:false },
    { id:50112, word:'banner',translate:'баннер',checked:false,blocked:false },
    { id:50113, word:'bare',translate:'голый',checked:false,blocked:false },
    { id:50114, word:'barrel',translate:'бочка',checked:false,blocked:false },
    { id:50115, word:'bass',translate:'бас',checked:false,blocked:false },
    { id:50116, word:'bat',translate:'летучая мышь',checked:false,blocked:false },
    { id:50117, word:'battlefield',translate:'поле боя',checked:false,blocked:false },
    { id:50118, word:'bay',translate:'бухта',checked:false,blocked:false },
    { id:50119, word:'beam',translate:'луч',checked:false,blocked:false },
    { id:50120, word:'beast',translate:'зверь',checked:false,blocked:false },
    { id:50121, word:'behalf',translate:'имя',checked:false,blocked:false },
    { id:50122, word:'beloved',translate:'любимый',checked:false,blocked:false },
    { id:50123, word:'bench',translate:'скамейка',checked:false,blocked:false },
    { id:50124, word:'benchmark',translate:'ориентир',checked:false,blocked:false },
    { id:50125, word:'beneath',translate:'ниже',checked:false,blocked:false },
    { id:50126, word:'beneficiary',translate:'бенефициар',checked:false,blocked:false },
    { id:50127, word:'betray',translate:'предать',checked:false,blocked:false },
    { id:50128, word:'bind',translate:'связывать',checked:false,blocked:false },
    { id:50129, word:'biography',translate:'биография',checked:false,blocked:false },
    { id:50130, word:'bishop',translate:'епископ',checked:false,blocked:false },
    { id:50131, word:'bizarre',translate:'странный',checked:false,blocked:false },
    { id:50132, word:'blade',translate:'клинок',checked:false,blocked:false },
    { id:50133, word:'blast',translate:'взрыв',checked:false,blocked:false },
    { id:50134, word:'bleed',translate:'кровотечение',checked:false,blocked:false },
    { id:50135, word:'blend',translate:'смесь',checked:false,blocked:false },
    { id:50136, word:'bless',translate:'благословлять',checked:false,blocked:false },
    { id:50137, word:'blessing',translate:'благословение',checked:false,blocked:false },
    { id:50138, word:'boast',translate:'хвастаться',checked:false,blocked:false },
    { id:50139, word:'bonus',translate:'бонус',checked:false,blocked:false },
    { id:50140, word:'boom',translate:'бум',checked:false,blocked:false },
    { id:50141, word:'bounce',translate:'отскок',checked:false,blocked:false },
    { id:50142, word:'boundary',translate:'граница',checked:false,blocked:false },
    { id:50143, word:'bow',translate:'лук',checked:false,blocked:false },
    { id:50144, word:'breach',translate:'прорыв',checked:false,blocked:false },
    { id:50145, word:'breakdown',translate:'пробой',checked:false,blocked:false },
    { id:50146, word:'breakthrough',translate:'прорыв',checked:false,blocked:false },
    { id:50147, word:'breed',translate:'порода',checked:false,blocked:false },
    { id:50149, word:'browser',translate:'браузер',checked:false,blocked:false },
    { id:50150, word:'brutal',translate:'брутальный',checked:false,blocked:false },
    { id:50151, word:'buck',translate:'бакс',checked:false,blocked:false },
    { id:50152, word:'buddy',translate:'приятель',checked:false,blocked:false },
    { id:50153, word:'buffer',translate:'буфер',checked:false,blocked:false },
    { id:50154, word:'bulk',translate:'большая часть',checked:false,blocked:false },
    { id:50155, word:'burden',translate:'бремя',checked:false,blocked:false },
    { id:50156, word:'bureaucracy',translate:'бюрократия',checked:false,blocked:false },
    { id:50157, word:'burial',translate:'захоронение',checked:false,blocked:false },
    { id:50158, word:'burst',translate:'взрыв',checked:false,blocked:false },
    { id:50159, word:'cabinet',translate:'кабинет',checked:false,blocked:false },
    { id:50160, word:'calculation',translate:'расчёт',checked:false,blocked:false },
    { id:50161, word:'canvas',translate:'холст',checked:false,blocked:false },
    { id:50162, word:'capability',translate:'возможность',checked:false,blocked:false },
    { id:50163, word:'capitalism',translate:'капитализм',checked:false,blocked:false },
    { id:50164, word:'capitalist',translate:'капиталист',checked:false,blocked:false },
    { id:50165, word:'cargo',translate:'груз',checked:false,blocked:false },
    { id:50166, word:'carriage',translate:'вагон',checked:false,blocked:false },
    { id:50167, word:'carve',translate:'вырезать',checked:false,blocked:false },
    { id:50168, word:'casino',translate:'казино',checked:false,blocked:false },
    { id:50169, word:'casualty',translate:'несчастный случай',checked:false,blocked:false },
    { id:50170, word:'catalogue',translate:'каталог',checked:false,blocked:false },
    { id:50171, word:'cater',translate:'обслуживание',checked:false,blocked:false },
    { id:50172, word:'cattle',translate:'скот',checked:false,blocked:false },
    { id:50173, word:'caution',translate:'осторожность',checked:false,blocked:false },
    { id:50174, word:'cautious',translate:'осторожность',checked:false,blocked:false },
    { id:50175, word:'cease',translate:'прекратить',checked:false,blocked:false },
    { id:50176, word:'cemetery',translate:'кладбище',checked:false,blocked:false },
    { id:50177, word:'chamber',translate:'палата',checked:false,blocked:false },
    { id:50178, word:'chaos',translate:'хаос',checked:false,blocked:false },
    { id:50179, word:'characterize',translate:'характеризовать',checked:false,blocked:false },
    { id:50180, word:'charm',translate:'очарование',checked:false,blocked:false },
    { id:50181, word:'charter',translate:'устав',checked:false,blocked:false },
    { id:50182, word:'chronic',translate:'хронический',checked:false,blocked:false },
    { id:50183, word:'chunk',translate:'кусок',checked:false,blocked:false },
    { id:50184, word:'circulate',translate:'циркулировать',checked:false,blocked:false },
    { id:50185, word:'circulation',translate:'циркуляция',checked:false,blocked:false },
    { id:50186, word:'citizenship',translate:'гражданство',checked:false,blocked:false },
    { id:50187, word:'civic',translate:'гражданин',checked:false,blocked:false },
    { id:50188, word:'civilian',translate:'гражданин',checked:false,blocked:false },
    { id:50189, word:'clarity',translate:'ясность',checked:false,blocked:false },
    { id:50190, word:'clash',translate:'столкновение',checked:false,blocked:false },
    { id:50191, word:'classification',translate:'классификация',checked:false,blocked:false },
    { id:50192, word:'cling',translate:'цепляться',checked:false,blocked:false },
    { id:50193, word:'clinical',translate:'клинический',checked:false,blocked:false },
    { id:50194, word:'closure',translate:'закрытие',checked:false,blocked:false },
    { id:50195, word:'cluster',translate:'кластер',checked:false,blocked:false },
    { id:50196, word:'coalition',translate:'коалиция',checked:false,blocked:false },
    { id:50197, word:'coastal',translate:'прибрежный',checked:false,blocked:false },
    { id:50198, word:'cocktail',translate:'коктейль',checked:false,blocked:false },
    { id:50199, word:'cognitive',translate:'когнитивный',checked:false,blocked:false },
    { id:50200, word:'coincide',translate:'совпадать',checked:false,blocked:false },
    { id:50201, word:'collaborate',translate:'сотрудничать',checked:false,blocked:false },
    { id:50202, word:'collaboration',translate:'сотрудничество',checked:false,blocked:false },
    { id:50203, word:'collective',translate:'коллектив',checked:false,blocked:false },
    { id:50204, word:'collision',translate:'столкновение',checked:false,blocked:false },
    { id:50205, word:'colonial',translate:'колониальный',checked:false,blocked:false },
    { id:50206, word:'columnist',translate:'обозреватель',checked:false,blocked:false },
    { id:50207, word:'combat',translate:'бой',checked:false,blocked:false },
    { id:50208, word:'commence',translate:'начать',checked:false,blocked:false },
    { id:50209, word:'commentary',translate:'комментарий',checked:false,blocked:false },
    { id:50210, word:'commentator',translate:'комментатор',checked:false,blocked:false },
    { id:50211, word:'commerce',translate:'торговля',checked:false,blocked:false },
    { id:50212, word:'commissioner',translate:'комиссар',checked:false,blocked:false },
    { id:50213, word:'commodity',translate:'товар',checked:false,blocked:false },
    { id:50214, word:'communist',translate:'коммунист',checked:false,blocked:false },
    { id:50215, word:'companion',translate:'компаньон',checked:false,blocked:false },
    { id:50216, word:'comparable',translate:'сопоставимый',checked:false,blocked:false },
    { id:50217, word:'compassion',translate:'сострадание',checked:false,blocked:false },
    { id:50218, word:'compel',translate:'принуждать',checked:false,blocked:false },
    { id:50219, word:'compelling',translate:'принуждение',checked:false,blocked:false },
    { id:50220, word:'compensate',translate:'компенсировать',checked:false,blocked:false },
    { id:50221, word:'compensation',translate:'компенсация',checked:false,blocked:false },
    { id:50222, word:'competence',translate:'компетентность',checked:false,blocked:false },
    { id:50223, word:'competent',translate:'компетентный',checked:false,blocked:false },
    { id:50224, word:'compile',translate:'составлять',checked:false,blocked:false },
    { id:50225, word:'complement',translate:'дополнять',checked:false,blocked:false },
    { id:50226, word:'complexity',translate:'сложность',checked:false,blocked:false },
    { id:50227, word:'compliance',translate:'соответствие',checked:false,blocked:false },
    { id:50228, word:'complication',translate:'осложнение',checked:false,blocked:false },
    { id:50229, word:'comply',translate:'соблюдать',checked:false,blocked:false },
    { id:50230, word:'composition',translate:'состав',checked:false,blocked:false },
    { id:50231, word:'compromise',translate:'компромисс',checked:false,blocked:false },
    { id:50232, word:'compute',translate:'вычислять',checked:false,blocked:false },
    { id:50233, word:'conceal',translate:'скрывать',checked:false,blocked:false },
    { id:50234, word:'concede',translate:'уступать',checked:false,blocked:false },
    { id:50235, word:'conceive',translate:'задумывать',checked:false,blocked:false },
    { id:50236, word:'conception',translate:'концепция',checked:false,blocked:false },
    { id:50237, word:'concession',translate:'уступка',checked:false,blocked:false },
    { id:50238, word:'condemn',translate:'осудить',checked:false,blocked:false },
    { id:50239, word:'confer',translate:'признать',checked:false,blocked:false },
    { id:50240, word:'confession',translate:'признание',checked:false,blocked:false },
    { id:50241, word:'configuration',translate:'конфигурация',checked:false,blocked:false },
    { id:50242, word:'confine',translate:'ограничить',checked:false,blocked:false },
    { id:50243, word:'confirmation',translate:'подтверждение',checked:false,blocked:false },
    { id:50244, word:'confront',translate:'конфронтация',checked:false,blocked:false },
    { id:50245, word:'confrontation',translate:'конфронтация',checked:false,blocked:false },
    { id:50246, word:'congratulate',translate:'поздравить',checked:false,blocked:false },
    { id:50247, word:'congregation',translate:'прихожане',checked:false,blocked:false },
    { id:50248, word:'congressional',translate:'конгресс',checked:false,blocked:false },
    { id:50249, word:'conquer',translate:'победить',checked:false,blocked:false },
    { id:50250, word:'conscience',translate:'совесть',checked:false,blocked:false },
    { id:50251, word:'consciousness',translate:'сознание',checked:false,blocked:false },
    { id:50252, word:'consecutive',translate:'последовательный',checked:false,blocked:false },
    { id:50253, word:'consensus',translate:'консенсус',checked:false,blocked:false },
    { id:50254, word:'consent',translate:'согласие',checked:false,blocked:false },
    { id:50255, word:'conserve',translate:'сохранить',checked:false,blocked:false },
    { id:50256, word:'consistency',translate:'консистенция',checked:false,blocked:false },
    { id:50257, word:'consolidate',translate:'консолидировать',checked:false,blocked:false },
    { id:50258, word:'constituency',translate:'избирательный округ',checked:false,blocked:false },
    { id:50259, word:'constitute',translate:'составлять',checked:false,blocked:false },
    { id:50260, word:'constitution',translate:'конституция',checked:false,blocked:false },
    { id:50261, word:'constitutional',translate:'конституция',checked:false,blocked:false },
    { id:50262, word:'constraint',translate:'ограничение',checked:false,blocked:false },
    { id:50263, word:'consultation',translate:'консультация',checked:false,blocked:false },
    { id:50264, word:'contemplate',translate:'созерцать',checked:false,blocked:false },
    { id:50265, word:'contempt',translate:'презрение',checked:false,blocked:false },
    { id:50266, word:'contend',translate:'оспаривать',checked:false,blocked:false },
    { id:50267, word:'contender',translate:'соперник',checked:false,blocked:false },
    { id:50268, word:'content',translate:'содержание',checked:false,blocked:false },
    { id:50269, word:'contention',translate:'соперничество',checked:false,blocked:false },
    { id:50270, word:'continually',translate:'постоянно',checked:false,blocked:false },
    { id:50271, word:'contractor',translate:'подрядчик',checked:false,blocked:false },
    { id:50272, word:'contradiction',translate:'противоречие',checked:false,blocked:false },
    { id:50273, word:'contrary',translate:'противоречие',checked:false,blocked:false },
    { id:50274, word:'contributor',translate:'вкладчик',checked:false,blocked:false },
    { id:50275, word:'conversion',translate:'преобразование',checked:false,blocked:false },
    { id:50276, word:'convict',translate:'осудить',checked:false,blocked:false },
    { id:50277, word:'conviction',translate:'осуждение',checked:false,blocked:false },
    { id:50278, word:'cooperate',translate:'сотрудничать',checked:false,blocked:false },
    { id:50279, word:'cooperative',translate:'сотрудничать',checked:false,blocked:false },
    { id:50280, word:'coordinate',translate:'координировать',checked:false,blocked:false },
    { id:50281, word:'coordination',translate:'координация',checked:false,blocked:false },
    { id:50282, word:'coordinator',translate:'координатор',checked:false,blocked:false },
    { id:50283, word:'cop',translate:'коп',checked:false,blocked:false },
    { id:50284, word:'copper',translate:'медь',checked:false,blocked:false },
    { id:50286, word:'correction',translate:'коррекция',checked:false,blocked:false },
    { id:50287, word:'correlate',translate:'коррелировать',checked:false,blocked:false },
    { id:50288, word:'correlation',translate:'корреляция',checked:false,blocked:false },
    { id:50289, word:'correspond',translate:'соответствовать',checked:false,blocked:false },
    { id:50290, word:'correspondence',translate:'корреспонденция',checked:false,blocked:false },
    { id:50291, word:'correspondent',translate:'корреспондент',checked:false,blocked:false },
    { id:50292, word:'corresponding',translate:'соответствующий',checked:false,blocked:false },
    { id:50293, word:'corrupt',translate:'коррупция',checked:false,blocked:false },
    { id:50294, word:'corruption',translate:'коррупция',checked:false,blocked:false },
    { id:50295, word:'costly',translate:'дорого',checked:false,blocked:false },
    { id:50296, word:'councillor',translate:'советник',checked:false,blocked:false },
    { id:50297, word:'counselling',translate:'консультирование',checked:false,blocked:false },
    { id:50298, word:'counsellor',translate:'советник',checked:false,blocked:false },
    { id:50299, word:'counter',translate:'контрагент',checked:false,blocked:false },
    { id:50300, word:'counterpart',translate:'коллега',checked:false,blocked:false },
    { id:50301, word:'countless',translate:'бесчисленные',checked:false,blocked:false },
    { id:50302, word:'coup',translate:'переворот',checked:false,blocked:false },
    { id:50303, word:'courtesy',translate:'любезность',checked:false,blocked:false },
    { id:50304, word:'craft',translate:'ремесло',checked:false,blocked:false },
    { id:50305, word:'crawl',translate:'ползать',checked:false,blocked:false },
    { id:50306, word:'creator',translate:'создатель',checked:false,blocked:false },
    { id:50307, word:'credibility',translate:'доверие',checked:false,blocked:false },
    { id:50309, word:'creep',translate:'ползти',checked:false,blocked:false },
    { id:50310, word:'critique',translate:'критика',checked:false,blocked:false },
    { id:50311, word:'crown',translate:'корона',checked:false,blocked:false },
    { id:50312, word:'crude',translate:'грубый',checked:false,blocked:false },
    { id:50313, word:'crush',translate:'раздавить',checked:false,blocked:false },
    { id:50314, word:'crystal',translate:'кристалл',checked:false,blocked:false },
    { id:50315, word:'cult',translate:'культ',checked:false,blocked:false },
    { id:50316, word:'cultivate',translate:'культивировать',checked:false,blocked:false },
    { id:50317, word:'curiosity',translate:'любопытство',checked:false,blocked:false },
    { id:50318, word:'custody',translate:'опека',checked:false,blocked:false },
    { id:50319, word:'cutting',translate:'огранка',checked:false,blocked:false },
    { id:50320, word:'cynical',translate:'циничный',checked:false,blocked:false },
    { id:50321, word:'dam',translate:'плотина',checked:false,blocked:false },
    { id:50322, word:'damaging',translate:'повреждение',checked:false,blocked:false },
    { id:50323, word:'dawn',translate:'рассвет',checked:false,blocked:false },
    { id:50324, word:'debris',translate:'обломки',checked:false,blocked:false },
    { id:50325, word:'debut',translate:'дебют',checked:false,blocked:false },
    { id:50326, word:'decision-making',translate:'принятие решений',checked:false,blocked:false },
    { id:50327, word:'decisive',translate:'решительный',checked:false,blocked:false },
    { id:50328, word:'declaration',translate:'декларация',checked:false,blocked:false },
    { id:50329, word:'dedicated',translate:'посвящение',checked:false,blocked:false },
    { id:50330, word:'dedication',translate:'посвящение',checked:false,blocked:false },
    { id:50331, word:'deed',translate:'акт',checked:false,blocked:false },
    { id:50332, word:'deem',translate:'считать',checked:false,blocked:false },
    { id:50333, word:'default',translate:'дефолт',checked:false,blocked:false },
    { id:50334, word:'defect',translate:'дефект',checked:false,blocked:false },
    { id:50335, word:'defensive',translate:'защита',checked:false,blocked:false },
    { id:50336, word:'deficiency',translate:'дефицит',checked:false,blocked:false },
    { id:50337, word:'deficit',translate:'дефицит',checked:false,blocked:false },
    { id:50338, word:'defy',translate:'не подчиняться',checked:false,blocked:false },
    { id:50339, word:'delegate',translate:'делегировать',checked:false,blocked:false },
    { id:50340, word:'delegation',translate:'делегация',checked:false,blocked:false },
    { id:50341, word:'delicate',translate:'деликатный',checked:false,blocked:false },
    { id:50342, word:'demon',translate:'демон',checked:false,blocked:false },
    { id:50343, word:'denial',translate:'отрицание',checked:false,blocked:false },
    { id:50344, word:'denounce',translate:'осуждение',checked:false,blocked:false },
    { id:50345, word:'dense',translate:'плотный',checked:false,blocked:false },
    { id:50346, word:'density',translate:'плотность',checked:false,blocked:false },
    { id:50347, word:'dependence',translate:'зависимость',checked:false,blocked:false },
    { id:50348, word:'depict',translate:'изобразить',checked:false,blocked:false },
    { id:50349, word:'deploy',translate:'развернуть',checked:false,blocked:false },
    { id:50350, word:'deployment',translate:'развертывание',checked:false,blocked:false },
    { id:50351, word:'deposit',translate:'депонировать',checked:false,blocked:false },
    { id:50352, word:'deprive',translate:'лишить',checked:false,blocked:false },
    { id:50353, word:'deputy',translate:'заместитель',checked:false,blocked:false },
    { id:50354, word:'descend',translate:'спускаться',checked:false,blocked:false },
    { id:50355, word:'descent',translate:'спуск',checked:false,blocked:false },
    { id:50356, word:'designate',translate:'назначить',checked:false,blocked:false },
    { id:50357, word:'desirable',translate:'желаемый',checked:false,blocked:false },
    { id:50358, word:'desktop',translate:'рабочий стол',checked:false,blocked:false },
    { id:50359, word:'destructive',translate:'разрушительный',checked:false,blocked:false },
    { id:50360, word:'detain',translate:'задерживать',checked:false,blocked:false },
    { id:50361, word:'detection',translate:'обнаружение',checked:false,blocked:false },
    { id:50362, word:'detention',translate:'задержание',checked:false,blocked:false },
    { id:50363, word:'deteriorate',translate:'разрушаться',checked:false,blocked:false },
    { id:50364, word:'devastate',translate:'опустошать',checked:false,blocked:false },
    { id:50365, word:'devil',translate:'дьявол',checked:false,blocked:false },
    { id:50366, word:'devise',translate:'придумывать',checked:false,blocked:false },
    { id:50367, word:'diagnose',translate:'диагностировать',checked:false,blocked:false },
    { id:50368, word:'diagnosis',translate:'диагноз',checked:false,blocked:false },
    { id:50369, word:'dictate',translate:'диктовать',checked:false,blocked:false },
    { id:50370, word:'dictator',translate:'диктатор',checked:false,blocked:false },
    { id:50371, word:'differentiate',translate:'различать',checked:false,blocked:false },
    { id:50372, word:'dignity',translate:'достоинство',checked:false,blocked:false },
    { id:50373, word:'dilemma',translate:'дилемма',checked:false,blocked:false },
    { id:50374, word:'dimension',translate:'измерение',checked:false,blocked:false },
    { id:50375, word:'diminish',translate:'уменьшать',checked:false,blocked:false },
    { id:50376, word:'dip',translate:'окунуться',checked:false,blocked:false },
    { id:50377, word:'diplomat',translate:'дипломат',checked:false,blocked:false },
    { id:50378, word:'diplomatic',translate:'дипломат',checked:false,blocked:false },
    { id:50379, word:'directory',translate:'каталог',checked:false,blocked:false },
    { id:50380, word:'disastrous',translate:'катастрофический',checked:false,blocked:false },
    { id:50381, word:'discard',translate:'выбрасывать',checked:false,blocked:false },
    { id:50382, word:'discharge',translate:'выброс',checked:false,blocked:false },
    { id:50383, word:'disclose',translate:'раскрывать',checked:false,blocked:false },
    { id:50384, word:'disclosure',translate:'раскрытие',checked:false,blocked:false },
    { id:50385, word:'discourse',translate:'обсуждение',checked:false,blocked:false },
    { id:50386, word:'discretion',translate:'усмотрение',checked:false,blocked:false },
    { id:50387, word:'discrimination',translate:'дискриминация',checked:false,blocked:false },
    { id:50388, word:'dismissal',translate:'увольнение',checked:false,blocked:false },
    { id:50389, word:'displace',translate:'вытеснение',checked:false,blocked:false },
    { id:50390, word:'disposal',translate:'удаление',checked:false,blocked:false },
    { id:50391, word:'dispose',translate:'распоряжаться',checked:false,blocked:false },
    { id:50392, word:'dispute',translate:'спор',checked:false,blocked:false },
    { id:50393, word:'disrupt',translate:'нарушить',checked:false,blocked:false },
    { id:50394, word:'disruption',translate:'разрушение',checked:false,blocked:false },
    { id:50395, word:'dissolve',translate:'растворить',checked:false,blocked:false },
    { id:50396, word:'distinction',translate:'различие',checked:false,blocked:false },
    { id:50397, word:'distinctive',translate:'отличительный',checked:false,blocked:false },
    { id:50398, word:'distort',translate:'искажать',checked:false,blocked:false },
    { id:50399, word:'distress',translate:'бедствие',checked:false,blocked:false },
    { id:50400, word:'disturbing',translate:'беспокоить',checked:false,blocked:false },
    { id:50401, word:'divert',translate:'отвлекать',checked:false,blocked:false },
    { id:50402, word:'divine',translate:'божественный',checked:false,blocked:false },
    { id:50403, word:'doctrine',translate:'доктрина',checked:false,blocked:false },
    { id:50404, word:'documentation',translate:'документация',checked:false,blocked:false },
    { id:50405, word:'domain',translate:'домен',checked:false,blocked:false },
    { id:50406, word:'dominance',translate:'господство',checked:false,blocked:false },
    { id:50407, word:'donor',translate:'донор',checked:false,blocked:false },
    { id:50408, word:'dose',translate:'доза',checked:false,blocked:false },
    { id:50409, word:'drain',translate:'слив',checked:false,blocked:false },
    { id:50410, word:'drift',translate:'дрейф',checked:false,blocked:false },
    { id:50411, word:'driving',translate:'вождение',checked:false,blocked:false },
    { id:50412, word:'drown',translate:'утонуть',checked:false,blocked:false },
    { id:50413, word:'dual',translate:'двойной',checked:false,blocked:false },
    { id:50414, word:'dub',translate:'дублировать',checked:false,blocked:false },
    { id:50415, word:'dumb',translate:'тупой',checked:false,blocked:false },
    { id:50416, word:'duo',translate:'дуэт',checked:false,blocked:false },
    { id:50417, word:'dynamic',translate:'динамичный',checked:false,blocked:false },
    { id:50418, word:'eager',translate:'жаждущий',checked:false,blocked:false },
    { id:50419, word:'earnings',translate:'заработок',checked:false,blocked:false },
    { id:50420, word:'ease',translate:'легкость',checked:false,blocked:false },
    { id:50421, word:'echo',translate:'эхо',checked:false,blocked:false },
    { id:50422, word:'ecological',translate:'экологический',checked:false,blocked:false },
    { id:50423, word:'educator',translate:'педагог',checked:false,blocked:false },
    { id:50424, word:'effectiveness',translate:'эффективность',checked:false,blocked:false },
    { id:50425, word:'efficiency',translate:'эффективность',checked:false,blocked:false },
    { id:50426, word:'ego',translate:'эго',checked:false,blocked:false },
    { id:50427, word:'elaborate',translate:'продуманность',checked:false,blocked:false },
    { id:50428, word:'electoral',translate:'выборы',checked:false,blocked:false },
    { id:50429, word:'elevate',translate:'повышать',checked:false,blocked:false },
    { id:50430, word:'eligible',translate:'правомочный',checked:false,blocked:false },
    { id:50431, word:'elite',translate:'элита',checked:false,blocked:false },
    { id:50432, word:'embark',translate:'начать',checked:false,blocked:false },
    { id:50433, word:'embarrassment',translate:'смущение',checked:false,blocked:false },
    { id:50434, word:'embassy',translate:'посольство',checked:false,blocked:false },
    { id:50435, word:'embed',translate:'встраивать',checked:false,blocked:false },
    { id:50436, word:'embody',translate:'воплощение',checked:false,blocked:false },
    { id:50437, word:'emergence',translate:'возникновение',checked:false,blocked:false },
    { id:50438, word:'empirical',translate:'эмпирический',checked:false,blocked:false },
    { id:50440, word:'enact',translate:'внедрять',checked:false,blocked:false },
    { id:50441, word:'encompass',translate:'охватывать',checked:false,blocked:false },
    { id:50442, word:'encouragement',translate:'поощрение',checked:false,blocked:false },
    { id:50443, word:'encouraging',translate:'поощрение',checked:false,blocked:false },
    { id:50444, word:'endeavour',translate:'стараться',checked:false,blocked:false },
    { id:50445, word:'endless',translate:'бесконечный',checked:false,blocked:false },
    { id:50446, word:'endorse',translate:'одобрить',checked:false,blocked:false },
    { id:50447, word:'endorsement',translate:'одобрение',checked:false,blocked:false },
    { id:50448, word:'endure',translate:'терпеть',checked:false,blocked:false },
    { id:50450, word:'enforcement',translate:'принуждение',checked:false,blocked:false },
    { id:50451, word:'engagement',translate:'вовлечение',checked:false,blocked:false },
    { id:50452, word:'engaging',translate:'привлечение',checked:false,blocked:false },
    { id:50453, word:'enquire',translate:'интересоваться',checked:false,blocked:false },
    { id:50454, word:'enrich',translate:'обогащать',checked:false,blocked:false },
    { id:50455, word:'enrol',translate:'зачислять',checked:false,blocked:false },
    { id:50456, word:'ensue',translate:'заниматься',checked:false,blocked:false },
    { id:50457, word:'enterprise',translate:'предприятие',checked:false,blocked:false },
    { id:50458, word:'enthusiast',translate:'энтузиаст',checked:false,blocked:false },
    { id:50459, word:'entitle',translate:'право',checked:false,blocked:false },
    { id:50460, word:'entity',translate:'организация',checked:false,blocked:false },
    { id:50461, word:'epidemic',translate:'эпидемия',checked:false,blocked:false },
    { id:50462, word:'equality',translate:'равенство',checked:false,blocked:false },
    { id:50463, word:'equation',translate:'уравнение',checked:false,blocked:false },
    { id:50464, word:'erect',translate:'возводить',checked:false,blocked:false },
    { id:50465, word:'escalate',translate:'эскалация',checked:false,blocked:false },
    { id:50466, word:'essence',translate:'сущность',checked:false,blocked:false },
    { id:50467, word:'establishment',translate:'создание',checked:false,blocked:false },
    { id:50468, word:'eternal',translate:'вечный',checked:false,blocked:false },
    { id:50469, word:'evacuate',translate:'эвакуировать',checked:false,blocked:false },
    { id:50470, word:'evoke',translate:'вызывать',checked:false,blocked:false },
    { id:50471, word:'evolutionary',translate:'эволюционный',checked:false,blocked:false },
    { id:50472, word:'exaggerate',translate:'преувеличивать',checked:false,blocked:false },
    { id:50473, word:'excellence',translate:'превосходство',checked:false,blocked:false },
    { id:50474, word:'exceptional',translate:'исключительный',checked:false,blocked:false },
    { id:50475, word:'excess',translate:'избыток',checked:false,blocked:false },
    { id:50476, word:'exclusion',translate:'исключение',checked:false,blocked:false },
    { id:50477, word:'exclusive',translate:'эксклюзив',checked:false,blocked:false },
    { id:50478, word:'exclusively',translate:'исключительно',checked:false,blocked:false },
    { id:50479, word:'execute',translate:'исполнить',checked:false,blocked:false },
    { id:50480, word:'execution',translate:'исполнение',checked:false,blocked:false },
    { id:50481, word:'exert',translate:'изгнать',checked:false,blocked:false },
    { id:50482, word:'exile',translate:'изгнание',checked:false,blocked:false },
    { id:50483, word:'exit',translate:'выход',checked:false,blocked:false },
    { id:50484, word:'expenditure',translate:'расходы',checked:false,blocked:false },
    { id:50485, word:'experimental',translate:'экспериментальный',checked:false,blocked:false },
    { id:50486, word:'expire',translate:'истекать',checked:false,blocked:false },
    { id:50487, word:'explicit',translate:'явный',checked:false,blocked:false },
    { id:50488, word:'explicitly',translate:'явно',checked:false,blocked:false },
    { id:50489, word:'exploitation',translate:'эксплуатация',checked:false,blocked:false },
    { id:50490, word:'explosive',translate:'взрывчатка',checked:false,blocked:false },
    { id:50491, word:'extract',translate:'извлечь',checked:false,blocked:false },
    { id:50492, word:'extremist',translate:'экстремист',checked:false,blocked:false },
    { id:50493, word:'facilitate',translate:'способствовать',checked:false,blocked:false },
    { id:50494, word:'faction',translate:'фракция',checked:false,blocked:false },
    { id:50495, word:'faculty',translate:'факультет',checked:false,blocked:false },
    { id:50496, word:'fade',translate:'угасать',checked:false,blocked:false },
    { id:50497, word:'fairness',translate:'справедливость',checked:false,blocked:false },
    { id:50498, word:'fatal',translate:'рок',checked:false,blocked:false },
    { id:50499, word:'fate',translate:'судьба',checked:false,blocked:false },
    { id:50500, word:'favourable',translate:'благоприятный',checked:false,blocked:false },
    { id:50501, word:'feat',translate:'подвиг',checked:false,blocked:false },
    { id:50502, word:'feminist',translate:'феминистка',checked:false,blocked:false },
    { id:50503, word:'fibre',translate:'волокно',checked:false,blocked:false },
    { id:50504, word:'fierce',translate:'яростный',checked:false,blocked:false },
    { id:50505, word:'film-maker',translate:'режиссер',checked:false,blocked:false },
    { id:50506, word:'filter',translate:'фильтр',checked:false,blocked:false },
    { id:50507, word:'fine',translate:'тонкий',checked:false,blocked:false },
    { id:50509, word:'fit',translate:'соответствовать',checked:false,blocked:false },
    { id:50510, word:'fixture',translate:'приспособление',checked:false,blocked:false },
    { id:50511, word:'flaw',translate:'недостаток',checked:false,blocked:false },
    { id:50512, word:'flawed',translate:'дефектный',checked:false,blocked:false },
    { id:50513, word:'flee',translate:'бежать',checked:false,blocked:false },
    { id:50514, word:'fleet',translate:'флот',checked:false,blocked:false },
    { id:50515, word:'flesh',translate:'плоть',checked:false,blocked:false },
    { id:50516, word:'flexibility',translate:'гибкость',checked:false,blocked:false },
    { id:50517, word:'flourish',translate:'процветать',checked:false,blocked:false },
    { id:50518, word:'fluid',translate:'жидкость',checked:false,blocked:false },
    { id:50519, word:'footage',translate:'кадры',checked:false,blocked:false },
    { id:50520, word:'foreigner',translate:'иностранец',checked:false,blocked:false },
    { id:50521, word:'forge',translate:'ковать',checked:false,blocked:false },
    { id:50522, word:'formula',translate:'формула',checked:false,blocked:false },
    { id:50523, word:'formulate',translate:'формулировать',checked:false,blocked:false },
    { id:50524, word:'forth',translate:'вперёд',checked:false,blocked:false },
    { id:50525, word:'forthcoming',translate:'предстоящий',checked:false,blocked:false },
    { id:50526, word:'foster',translate:'способствовать',checked:false,blocked:false },
    { id:50527, word:'fragile',translate:'хрупкий',checked:false,blocked:false },
    { id:50528, word:'franchise',translate:'франшиза',checked:false,blocked:false },
    { id:50529, word:'frankly',translate:'откровенно',checked:false,blocked:false },
    { id:50530, word:'frustrated',translate:'разочарованный',checked:false,blocked:false },
    { id:50531, word:'frustrating',translate:'разочарование',checked:false,blocked:false },
    { id:50532, word:'frustration',translate:'разочарование',checked:false,blocked:false },
    { id:50533, word:'functional',translate:'функциональный',checked:false,blocked:false },
    { id:50534, word:'fundraising',translate:'сбор средств',checked:false,blocked:false },
    { id:50535, word:'funeral',translate:'похороны',checked:false,blocked:false },
    { id:50536, word:'gallon',translate:'галлон',checked:false,blocked:false },
    { id:50537, word:'gambling',translate:'азартные игры',checked:false,blocked:false },
    { id:50538, word:'gathering',translate:'сбор',checked:false,blocked:false },
    { id:50539, word:'gaze',translate:'взгляд',checked:false,blocked:false },
    { id:50540, word:'gear',translate:'снаряжение',checked:false,blocked:false },
    { id:50541, word:'generic',translate:'дженерик',checked:false,blocked:false },
    { id:50542, word:'genocide',translate:'геноцид',checked:false,blocked:false },
    { id:50543, word:'glance',translate:'взгляд',checked:false,blocked:false },
    { id:50544, word:'glimpse',translate:'взгляд',checked:false,blocked:false },
    { id:50545, word:'glorious',translate:'слава',checked:false,blocked:false },
    { id:50546, word:'glory',translate:'слава',checked:false,blocked:false },
    { id:50547, word:'governance',translate:'управление',checked:false,blocked:false },
    { id:50548, word:'grace',translate:'благодать',checked:false,blocked:false },
    { id:50549, word:'grasp',translate:'схватить',checked:false,blocked:false },
    { id:50550, word:'grave',translate:'могила',checked:false,blocked:false },
    { id:50552, word:'gravity',translate:'гравитация',checked:false,blocked:false },
    { id:50553, word:'grid',translate:'решётка',checked:false,blocked:false },
    { id:50554, word:'grief',translate:'горе',checked:false,blocked:false },
    { id:50555, word:'grin',translate:'ухмыляться',checked:false,blocked:false },
    { id:50556, word:'grind',translate:'измельчать',checked:false,blocked:false },
    { id:50557, word:'grip',translate:'хватка',checked:false,blocked:false },
    { id:50558, word:'gross',translate:'грубый',checked:false,blocked:false },
    { id:50559, word:'guerrilla',translate:'партизан',checked:false,blocked:false },
    { id:50560, word:'guidance',translate:'руководство',checked:false,blocked:false },
    { id:50561, word:'guilt',translate:'чувство вины',checked:false,blocked:false },
    { id:50562, word:'gut',translate:'кишечник',checked:false,blocked:false },
    { id:50563, word:'hail',translate:'град',checked:false,blocked:false },
    { id:50564, word:'halfway',translate:'половина пути',checked:false,blocked:false },
    { id:50565, word:'halt',translate:'остановка',checked:false,blocked:false },
    { id:50566, word:'handful',translate:'рука',checked:false,blocked:false },
    { id:50567, word:'handling',translate:'обработка',checked:false,blocked:false },
    { id:50568, word:'handy',translate:'удобный',checked:false,blocked:false },
    { id:50569, word:'harassment',translate:'преследование',checked:false,blocked:false },
    { id:50570, word:'hardware',translate:'оборудование',checked:false,blocked:false },
    { id:50571, word:'harmony',translate:'гармония',checked:false,blocked:false },
    { id:50572, word:'harsh',translate:'суровый',checked:false,blocked:false },
    { id:50573, word:'harvest',translate:'урожай',checked:false,blocked:false },
    { id:50574, word:'hatred',translate:'ненависть',checked:false,blocked:false },
    { id:50575, word:'haunt',translate:'преследовать',checked:false,blocked:false },
    { id:50576, word:'hazard',translate:'опасность',checked:false,blocked:false },
    { id:50577, word:'heighten',translate:'повышать',checked:false,blocked:false },
    { id:50578, word:'heritage',translate:'наследие',checked:false,blocked:false },
    { id:50579, word:'hierarchy',translate:'иерархия',checked:false,blocked:false },
    { id:50580, word:'high-profile',translate:'высокопоставленный',checked:false,blocked:false },
    { id:50581, word:'hint',translate:'намек',checked:false,blocked:false },
    { id:50582, word:'homeland',translate:'родина',checked:false,blocked:false },
    { id:50583, word:'hook',translate:'крючок',checked:false,blocked:false },
    { id:50584, word:'hopeful',translate:'надежда',checked:false,blocked:false },
    { id:50585, word:'horizon',translate:'горизонт',checked:false,blocked:false },
    { id:50586, word:'horn',translate:'рог',checked:false,blocked:false },
    { id:50587, word:'hostage',translate:'заложник',checked:false,blocked:false },
    { id:50588, word:'hostile',translate:'враждебный',checked:false,blocked:false },
    { id:50589, word:'hostility',translate:'враждебность',checked:false,blocked:false },
    { id:50590, word:'humanitarian',translate:'гуманитарный',checked:false,blocked:false },
    { id:50591, word:'humanity',translate:'человечество',checked:false,blocked:false },
    { id:50592, word:'humble',translate:'скромный',checked:false,blocked:false },
    { id:50593, word:'hydrogen',translate:'водород',checked:false,blocked:false },
    { id:50594, word:'identification',translate:'идентификация',checked:false,blocked:false },
    { id:50595, word:'ideological',translate:'идеология',checked:false,blocked:false },
    { id:50596, word:'ideology',translate:'идеология',checked:false,blocked:false },
    { id:50597, word:'idiot',translate:'идиот',checked:false,blocked:false },
    { id:50598, word:'ignorance',translate:'невежество',checked:false,blocked:false },
    { id:50599, word:'imagery',translate:'образность',checked:false,blocked:false },
    { id:50600, word:'immense',translate:'огромный',checked:false,blocked:false },
    { id:50601, word:'imminent',translate:'неминуемый',checked:false,blocked:false },
    { id:50602, word:'implementation',translate:'реализация',checked:false,blocked:false },
    { id:50603, word:'imprison',translate:'заключить в тюрьму',checked:false,blocked:false },
    { id:50604, word:'imprisonment',translate:'заключение',checked:false,blocked:false },
    { id:50605, word:'inability',translate:'неспособность',checked:false,blocked:false },
    { id:50606, word:'inadequate',translate:'неадекватный',checked:false,blocked:false },
    { id:50607, word:'inappropriate',translate:'неадекватный',checked:false,blocked:false },
    { id:50608, word:'incidence',translate:'заболеваемость',checked:false,blocked:false },
    { id:50609, word:'inclined',translate:'склонный',checked:false,blocked:false },
    { id:50610, word:'inclusion',translate:'включение',checked:false,blocked:false },
    { id:50611, word:'incur',translate:'возникнуть',checked:false,blocked:false },
    { id:50612, word:'indicator',translate:'показатель',checked:false,blocked:false },
    { id:50613, word:'indictment',translate:'обвинение',checked:false,blocked:false },
    { id:50614, word:'indigenous',translate:'коренной',checked:false,blocked:false },
    { id:50615, word:'induce',translate:'побуждать',checked:false,blocked:false },
    { id:50616, word:'indulge',translate:'потакать',checked:false,blocked:false },
    { id:50617, word:'inequality',translate:'неравенство',checked:false,blocked:false },
    { id:50618, word:'infamous',translate:'печально известный',checked:false,blocked:false },
    { id:50619, word:'infant',translate:'младенец',checked:false,blocked:false },
    { id:50620, word:'infect',translate:'заразить',checked:false,blocked:false },
    { id:50621, word:'inflict',translate:'наносить',checked:false,blocked:false },
    { id:50622, word:'influential',translate:'влиятельный',checked:false,blocked:false },
    { id:50623, word:'inherent',translate:'присущий',checked:false,blocked:false },
    { id:50624, word:'inhibit',translate:'сдерживать',checked:false,blocked:false },
    { id:50625, word:'initiate',translate:'инициировать',checked:false,blocked:false },
    { id:50626, word:'inject',translate:'инъекция',checked:false,blocked:false },
    { id:50627, word:'injection',translate:'инъекция',checked:false,blocked:false },
    { id:50628, word:'injustice',translate:'несправедливость',checked:false,blocked:false },
    { id:50629, word:'inmate',translate:'заключенный',checked:false,blocked:false },
    { id:50630, word:'insertion',translate:'введение',checked:false,blocked:false },
    { id:50631, word:'insider',translate:'инсайдер',checked:false,blocked:false },
    { id:50632, word:'inspect',translate:'осмотр',checked:false,blocked:false },
    { id:50633, word:'inspection',translate:'инспекция',checked:false,blocked:false },
    { id:50634, word:'inspiration',translate:'вдохновение',checked:false,blocked:false },
    { id:50635, word:'instinct',translate:'инстинкт',checked:false,blocked:false },
    { id:50636, word:'institutional',translate:'институциональный',checked:false,blocked:false },
    { id:50637, word:'instruct',translate:'инструктаж',checked:false,blocked:false },
    { id:50638, word:'instrumental',translate:'инструментальный',checked:false,blocked:false },
    { id:50639, word:'insufficient',translate:'недостаточный',checked:false,blocked:false },
    { id:50640, word:'insult',translate:'оскорбление',checked:false,blocked:false },
    { id:50641, word:'intact',translate:'неповрежденный',checked:false,blocked:false },
    { id:50642, word:'intake',translate:'прием',checked:false,blocked:false },
    { id:50643, word:'integral',translate:'интеграл',checked:false,blocked:false },
    { id:50644, word:'integrated',translate:'интеграция',checked:false,blocked:false },
    { id:50645, word:'integration',translate:'интеграция',checked:false,blocked:false },
    { id:50646, word:'integrity',translate:'целостность',checked:false,blocked:false },
    { id:50647, word:'intellectual',translate:'интеллект',checked:false,blocked:false },
    { id:50648, word:'intensify',translate:'интенсифицировать',checked:false,blocked:false },
    { id:50649, word:'intensity',translate:'интенсивность',checked:false,blocked:false },
    { id:50650, word:'intensive',translate:'интенсивный',checked:false,blocked:false },
    { id:50651, word:'intent',translate:'намерение',checked:false,blocked:false },
    { id:50652, word:'interactive',translate:'интерактивный',checked:false,blocked:false },
    { id:50653, word:'interface',translate:'интерфейс',checked:false,blocked:false },
    { id:50654, word:'interfere',translate:'вмешаться',checked:false,blocked:false },
    { id:50656, word:'interference',translate:'помехи',checked:false,blocked:false },
    { id:50657, word:'interim',translate:'промежуточный',checked:false,blocked:false },
    { id:50658, word:'interior',translate:'внутренний',checked:false,blocked:false },
    { id:50659, word:'intermediate',translate:'промежуточный',checked:false,blocked:false },
    { id:50660, word:'intervene',translate:'вмешаться',checked:false,blocked:false },
    { id:50661, word:'intervention',translate:'вмешательство',checked:false,blocked:false },
    { id:50662, word:'intimate',translate:'интимный',checked:false,blocked:false },
    { id:50663, word:'intriguing',translate:'интригующий',checked:false,blocked:false },
    { id:50664, word:'investigator',translate:'следователь',checked:false,blocked:false },
    { id:50665, word:'invisible',translate:'невидимый',checked:false,blocked:false },
    { id:50666, word:'invoke',translate:'вызывать',checked:false,blocked:false },
    { id:50667, word:'involvement',translate:'участие',checked:false,blocked:false },
    { id:50668, word:'ironic',translate:'ироничный',checked:false,blocked:false },
    { id:50669, word:'ironically',translate:'иронично',checked:false,blocked:false },
    { id:50670, word:'irony',translate:'ирония',checked:false,blocked:false },
    { id:50671, word:'irrelevant',translate:'неактуально',checked:false,blocked:false },
    { id:50672, word:'isolation',translate:'изоляция',checked:false,blocked:false },
    { id:50673, word:'judicial',translate:'судебный',checked:false,blocked:false },
    { id:50674, word:'junction',translate:'перекресток',checked:false,blocked:false },
    { id:50675, word:'jurisdiction',translate:'юрисдикция',checked:false,blocked:false },
    { id:50676, word:'just',translate:'просто',checked:false,blocked:false },
    { id:50677, word:'justification',translate:'оправдание',checked:false,blocked:false },
    { id:50678, word:'kidnap',translate:'похищение',checked:false,blocked:false },
    { id:50679, word:'kidney',translate:'почка',checked:false,blocked:false },
    { id:50680, word:'kingdom',translate:'королевство',checked:false,blocked:false },
    { id:50681, word:'lad',translate:'парень',checked:false,blocked:false },
    { id:50682, word:'landlord',translate:'домовладелец',checked:false,blocked:false },
    { id:50683, word:'landmark',translate:'достопримечательность',checked:false,blocked:false },
    { id:50684, word:'lap',translate:'круг',checked:false,blocked:false },
    { id:50685, word:'large-scale',translate:'масштабный',checked:false,blocked:false },
    { id:50686, word:'laser',translate:'лазер',checked:false,blocked:false },
    { id:50687, word:'latter',translate:'последний',checked:false,blocked:false },
    { id:50688, word:'lawn',translate:'газон',checked:false,blocked:false },
    { id:50689, word:'lawsuit',translate:'судебный иск',checked:false,blocked:false },
    { id:50690, word:'layout',translate:'макет',checked:false,blocked:false },
    { id:50691, word:'leak',translate:'утечка',checked:false,blocked:false },
    { id:50692, word:'leap',translate:'прыжок',checked:false,blocked:false },
    { id:50693, word:'legacy',translate:'наследие',checked:false,blocked:false },
    { id:50694, word:'legendary',translate:'легенда',checked:false,blocked:false },
    { id:50695, word:'legislation',translate:'законодательство',checked:false,blocked:false },
    { id:50696, word:'legislative',translate:'законодательство',checked:false,blocked:false },
    { id:50697, word:'legislature',translate:'законодательный орган',checked:false,blocked:false },
    { id:50698, word:'legitimate',translate:'законный',checked:false,blocked:false },
    { id:50699, word:'lengthy',translate:'длинный',checked:false,blocked:false },
    { id:50700, word:'lesbian',translate:'лесбиянка',checked:false,blocked:false },
    { id:50701, word:'lesser',translate:'меньший',checked:false,blocked:false },
    { id:50702, word:'lethal',translate:'смертельный',checked:false,blocked:false },
    { id:50703, word:'liable',translate:'ответственный',checked:false,blocked:false },
    { id:50704, word:'liberal',translate:'либерал',checked:false,blocked:false },
    { id:50705, word:'liberation',translate:'освобождение',checked:false,blocked:false },
    { id:50706, word:'liberty',translate:'свобода',checked:false,blocked:false },
    { id:50707, word:'license',translate:'лицензия',checked:false,blocked:false },
    { id:50708, word:'lifelong',translate:'пожизненный',checked:false,blocked:false },
    { id:50709, word:'likelihood',translate:'вероятность',checked:false,blocked:false },
    { id:50710, word:'limb',translate:'конечность',checked:false,blocked:false },
    { id:50711, word:'linear',translate:'линейный',checked:false,blocked:false },
    { id:50712, word:'line-up',translate:'состав',checked:false,blocked:false },
    { id:50713, word:'linger',translate:'задерживаться',checked:false,blocked:false },
    { id:50714, word:'listing',translate:'список',checked:false,blocked:false },
    { id:50715, word:'literacy',translate:'грамотность',checked:false,blocked:false },
    { id:50716, word:'liver',translate:'печень',checked:false,blocked:false },
    { id:50717, word:'lobby',translate:'вестибюль',checked:false,blocked:false },
    { id:50718, word:'log',translate:'журнал',checked:false,blocked:false },
    { id:50719, word:'logic',translate:'логика',checked:false,blocked:false },
    { id:50720, word:'long-standing',translate:'давний',checked:false,blocked:false },
    { id:50721, word:'long-time',translate:'многолетний',checked:false,blocked:false },
    { id:50722, word:'loom',translate:'ткацкий станок',checked:false,blocked:false },
    { id:50723, word:'loop',translate:'петля',checked:false,blocked:false },
    { id:50724, word:'loyalty',translate:'верность',checked:false,blocked:false },
    { id:50725, word:'machinery',translate:'оборудование',checked:false,blocked:false },
    { id:50726, word:'magical',translate:'магический',checked:false,blocked:false },
    { id:50727, word:'magistrate',translate:'магистрат',checked:false,blocked:false },
    { id:50728, word:'magnetic',translate:'магнитный',checked:false,blocked:false },
    { id:50729, word:'magnitude',translate:'величина',checked:false,blocked:false },
    { id:50730, word:'mainland',translate:'материк',checked:false,blocked:false },
    { id:50731, word:'mainstream',translate:'основное направление',checked:false,blocked:false },
    { id:50732, word:'maintenance',translate:'обслуживание',checked:false,blocked:false },
    { id:50733, word:'mandate',translate:'мандат',checked:false,blocked:false },
    { id:50734, word:'mandatory',translate:'обязательный',checked:false,blocked:false },
    { id:50735, word:'manifest',translate:'манифест',checked:false,blocked:false },
    { id:50736, word:'manipulate',translate:'манипулировать',checked:false,blocked:false },
    { id:50737, word:'manipulation',translate:'манипуляция',checked:false,blocked:false },
    { id:50738, word:'manuscript',translate:'рукопись',checked:false,blocked:false },
    { id:50739, word:'march',translate:'март',checked:false,blocked:false },
    { id:50740, word:'marginal',translate:'маргинальный',checked:false,blocked:false },
    { id:50741, word:'marine',translate:'морской',checked:false,blocked:false },
    { id:50742, word:'marketplace',translate:'рынок',checked:false,blocked:false },
    { id:50743, word:'mask',translate:'маска',checked:false,blocked:false },
    { id:50744, word:'massacre',translate:'резня',checked:false,blocked:false },
    { id:50745, word:'mathematical',translate:'математика',checked:false,blocked:false },
    { id:50746, word:'mature',translate:'зрелый',checked:false,blocked:false },
    { id:50747, word:'maximize',translate:'максимизировать',checked:false,blocked:false },
    { id:50748, word:'meaningful',translate:'значимый',checked:false,blocked:false },
    { id:50749, word:'meantime',translate:'тем временем',checked:false,blocked:false },
    { id:50750, word:'medieval',translate:'средневековье',checked:false,blocked:false },
    { id:50751, word:'meditation',translate:'медитация',checked:false,blocked:false },
    { id:50752, word:'melody',translate:'мелодия',checked:false,blocked:false },
    { id:50753, word:'memo',translate:'заметка',checked:false,blocked:false },
    { id:50754, word:'memoir',translate:'мемуары',checked:false,blocked:false },
    { id:50755, word:'memorial',translate:'мемориал',checked:false,blocked:false },
    { id:50756, word:'mentor',translate:'наставник',checked:false,blocked:false },
    { id:50757, word:'merchant',translate:'купец',checked:false,blocked:false },
    { id:50758, word:'mercy',translate:'милосердие',checked:false,blocked:false },
    { id:50759, word:'mere',translate:'просто',checked:false,blocked:false },
    { id:50760, word:'merely',translate:'просто',checked:false,blocked:false },
    { id:50761, word:'merge',translate:'слиться',checked:false,blocked:false },
    { id:50762, word:'merger',translate:'слияние',checked:false,blocked:false },
    { id:50763, word:'merit',translate:'заслуга',checked:false,blocked:false },
    { id:50764, word:'methodology',translate:'методология',checked:false,blocked:false },
    { id:50765, word:'midst',translate:'середина',checked:false,blocked:false },
    { id:50766, word:'migration',translate:'миграция',checked:false,blocked:false },
    { id:50767, word:'militant',translate:'боевик',checked:false,blocked:false },
    { id:50768, word:'militia',translate:'ополчение',checked:false,blocked:false },
    { id:50769, word:'mill',translate:'мельница',checked:false,blocked:false },
    { id:50770, word:'minimal',translate:'минимум',checked:false,blocked:false },
    { id:50771, word:'minimize',translate:'минимизировать',checked:false,blocked:false },
    { id:50772, word:'mining',translate:'добыча',checked:false,blocked:false },
    { id:50773, word:'ministry',translate:'министерство',checked:false,blocked:false },
    { id:50774, word:'minute',translate:'минута',checked:false,blocked:false },
    { id:50775, word:'miracle',translate:'чудо',checked:false,blocked:false },
    { id:50776, word:'misery',translate:'несчастье',checked:false,blocked:false },
    { id:50777, word:'misleading',translate:'введение в заблуждение',checked:false,blocked:false },
    { id:50778, word:'missile',translate:'ракета',checked:false,blocked:false },
    { id:50779, word:'mob',translate:'моб',checked:false,blocked:false },
    { id:50780, word:'mobility',translate:'мобильность',checked:false,blocked:false },
    { id:50781, word:'mobilize',translate:'мобилизовать',checked:false,blocked:false },
    { id:50782, word:'moderate',translate:'умеренный',checked:false,blocked:false },
    { id:50783, word:'modification',translate:'модификация',checked:false,blocked:false },
    { id:50784, word:'momentum',translate:'импульс',checked:false,blocked:false },
    { id:50785, word:'monk',translate:'монах',checked:false,blocked:false },
    { id:50786, word:'monopoly',translate:'монополия',checked:false,blocked:false },
    { id:50787, word:'morality',translate:'мораль',checked:false,blocked:false },
    { id:50788, word:'motive',translate:'мотив',checked:false,blocked:false },
    { id:50789, word:'motorist',translate:'автомобилист',checked:false,blocked:false },
    { id:50790, word:'municipal',translate:'муниципалитет',checked:false,blocked:false },
    { id:50791, word:'mutual',translate:'взаимный',checked:false,blocked:false },
    { id:50792, word:'namely',translate:'а именно',checked:false,blocked:false },
    { id:50793, word:'nationwide',translate:'общенациональный',checked:false,blocked:false },
    { id:50794, word:'naval',translate:'военно-морской флот',checked:false,blocked:false },
    { id:50795, word:'neglect',translate:'пренебрежение',checked:false,blocked:false },
    { id:50796, word:'neighbouring',translate:'соседство',checked:false,blocked:false },
    { id:50797, word:'nest',translate:'гнездо',checked:false,blocked:false },
    { id:50798, word:'net',translate:'сеть',checked:false,blocked:false },
    { id:50799, word:'newsletter',translate:'рассылка',checked:false,blocked:false },
    { id:50800, word:'niche',translate:'ниша',checked:false,blocked:false },
    { id:50801, word:'noble',translate:'благородный',checked:false,blocked:false },
    { id:50802, word:'nod',translate:'кивать',checked:false,blocked:false },
    { id:50803, word:'nominate',translate:'номинировать',checked:false,blocked:false },
    { id:50804, word:'nomination',translate:'номинация',checked:false,blocked:false },
    { id:50805, word:'nominee',translate:'номинировать',checked:false,blocked:false },
    { id:50806, word:'nonetheless',translate:'тем не менее',checked:false,blocked:false },
    { id:50807, word:'non-profit',translate:'некоммерческая',checked:false,blocked:false },
    { id:50808, word:'nonsense',translate:'ерунда',checked:false,blocked:false },
    { id:50809, word:'noon',translate:'полдень',checked:false,blocked:false },
    { id:50810, word:'notable',translate:'примечательно',checked:false,blocked:false },
    { id:50811, word:'notably',translate:'заметно',checked:false,blocked:false },
    { id:50812, word:'notify',translate:'уведомить',checked:false,blocked:false },
    { id:50813, word:'notorious',translate:'пресловутый',checked:false,blocked:false },
    { id:50814, word:'novel',translate:'роман',checked:false,blocked:false },
    { id:50815, word:'nursery',translate:'детский сад',checked:false,blocked:false },
    { id:50816, word:'objection',translate:'возражение',checked:false,blocked:false },
    { id:50817, word:'oblige',translate:'обязывать',checked:false,blocked:false },
    { id:50818, word:'obsess',translate:'одержимость',checked:false,blocked:false },
    { id:50819, word:'obsession',translate:'одержимость',checked:false,blocked:false },
    { id:50820, word:'occasional',translate:'случай',checked:false,blocked:false },
    { id:50821, word:'occurrence',translate:'происшествие',checked:false,blocked:false },
    { id:50822, word:'odds',translate:'шансы',checked:false,blocked:false },
    { id:50823, word:'offering',translate:'предложение',checked:false,blocked:false },
    { id:50824, word:'offspring',translate:'потомство',checked:false,blocked:false },
    { id:50825, word:'operational',translate:'оперативный',checked:false,blocked:false },
    { id:50826, word:'opt',translate:'оптический',checked:false,blocked:false },
    { id:50827, word:'optical',translate:'оптический',checked:false,blocked:false },
    { id:50828, word:'optimism',translate:'оптимизм',checked:false,blocked:false },
    { id:50829, word:'oral',translate:'устный',checked:false,blocked:false },
    { id:50830, word:'organizational',translate:'организационная',checked:false,blocked:false },
    { id:50831, word:'orientation',translate:'ориентация',checked:false,blocked:false },
    { id:50832, word:'originate',translate:'возникнуть',checked:false,blocked:false },
    { id:50833, word:'outbreak',translate:'вспышка',checked:false,blocked:false },
    { id:50834, word:'outing',translate:'выезд',checked:false,blocked:false },
    { id:50835, word:'outlet',translate:'выход',checked:false,blocked:false },
    { id:50836, word:'outlook',translate:'перспектива',checked:false,blocked:false },
    { id:50837, word:'outrage',translate:'возмущение',checked:false,blocked:false },
    { id:50838, word:'outsider',translate:'аутсайдер',checked:false,blocked:false },
    { id:50839, word:'overlook',translate:'упускать из виду',checked:false,blocked:false },
    { id:50840, word:'overly',translate:'слишком',checked:false,blocked:false },
    { id:50841, word:'oversee',translate:'наблюдать',checked:false,blocked:false },
    { id:50842, word:'overturn',translate:'опрокинуть',checked:false,blocked:false },
    { id:50843, word:'overwhelm',translate:'перегружать',checked:false,blocked:false },
    { id:50844, word:'overwhelming',translate:'перегружать',checked:false,blocked:false },
    { id:50845, word:'pad',translate:'прокладка',checked:false,blocked:false },
    { id:50846, word:'parameter',translate:'параметр',checked:false,blocked:false },
    { id:50847, word:'parental',translate:'родительский',checked:false,blocked:false },
    { id:50848, word:'parish',translate:'приход',checked:false,blocked:false },
    { id:50849, word:'parliamentary',translate:'парламент',checked:false,blocked:false },
    { id:50850, word:'partial',translate:'частичный',checked:false,blocked:false },
    { id:50851, word:'partially',translate:'частично',checked:false,blocked:false },
    { id:50852, word:'passing',translate:'проходящий',checked:false,blocked:false },
    { id:50853, word:'passive',translate:'пассивный',checked:false,blocked:false },
    { id:50854, word:'pastor',translate:'пастор',checked:false,blocked:false },
    { id:50855, word:'patch',translate:'пластырь',checked:false,blocked:false },
    { id:50856, word:'patent',translate:'патент',checked:false,blocked:false },
    { id:50857, word:'pathway',translate:'путь',checked:false,blocked:false },
    { id:50858, word:'patrol',translate:'патруль',checked:false,blocked:false },
    { id:50859, word:'patron',translate:'патрон',checked:false,blocked:false },
    { id:50860, word:'peak',translate:'пик',checked:false,blocked:false },
    { id:50861, word:'peasant',translate:'крестьянин',checked:false,blocked:false },
    { id:50862, word:'peculiar',translate:'своеобразный',checked:false,blocked:false },
    { id:50863, word:'persist',translate:'упорствовать',checked:false,blocked:false },
    { id:50864, word:'persistent',translate:'упорный',checked:false,blocked:false },
    { id:50865, word:'personnel',translate:'персонал',checked:false,blocked:false },
    { id:50866, word:'petition',translate:'петиция',checked:false,blocked:false },
    { id:50867, word:'philosopher',translate:'философ',checked:false,blocked:false },
    { id:50868, word:'philosophical',translate:'философский',checked:false,blocked:false },
    { id:50869, word:'physician',translate:'врач',checked:false,blocked:false },
    { id:50870, word:'pioneer',translate:'первопроходец',checked:false,blocked:false },
    { id:50871, word:'pipeline',translate:'трубопровод',checked:false,blocked:false },
    { id:50872, word:'pirate',translate:'пират',checked:false,blocked:false },
    { id:50873, word:'pit',translate:'яма',checked:false,blocked:false },
    { id:50874, word:'plea',translate:'признание вины',checked:false,blocked:false },
    { id:50875, word:'plead',translate:'заявление',checked:false,blocked:false },
    { id:50876, word:'pledge',translate:'обещание',checked:false,blocked:false },
    { id:50877, word:'plug',translate:'затычка',checked:false,blocked:false },
    { id:50878, word:'plunge',translate:'погружаться',checked:false,blocked:false },
    { id:50879, word:'pole',translate:'шест',checked:false,blocked:false },
    { id:50880, word:'poll',translate:'опрос',checked:false,blocked:false },
    { id:50881, word:'pond',translate:'пруд',checked:false,blocked:false },
    { id:50882, word:'pop',translate:'поп',checked:false,blocked:false },
    { id:50883, word:'portfolio',translate:'портфолио',checked:false,blocked:false },
    { id:50884, word:'portray',translate:'изображать',checked:false,blocked:false },
    { id:50885, word:'postpone',translate:'переносить',checked:false,blocked:false },
    { id:50886, word:'post-war',translate:'послевоенный',checked:false,blocked:false },
    { id:50887, word:'practitioner',translate:'практик',checked:false,blocked:false },
    { id:50888, word:'preach',translate:'проповедовать',checked:false,blocked:false },
    { id:50889, word:'precedent',translate:'прецедент',checked:false,blocked:false },
    { id:50890, word:'precision',translate:'точность',checked:false,blocked:false },
    { id:50891, word:'predator',translate:'хищник',checked:false,blocked:false },
    { id:50892, word:'predecessor',translate:'предшественник',checked:false,blocked:false },
    { id:50893, word:'predominantly',translate:'преимущественно',checked:false,blocked:false },
    { id:50894, word:'pregnancy',translate:'беременность',checked:false,blocked:false },
    { id:50895, word:'prejudice',translate:'предрассудок',checked:false,blocked:false },
    { id:50896, word:'preliminary',translate:'предварительный',checked:false,blocked:false },
    { id:50897, word:'premier',translate:'премьера',checked:false,blocked:false },
    { id:50898, word:'premise',translate:'предпосылка',checked:false,blocked:false },
    { id:50899, word:'premium',translate:'премиум',checked:false,blocked:false },
    { id:50900, word:'prescribe',translate:'прописать',checked:false,blocked:false },
    { id:50901, word:'prescription',translate:'рецепт',checked:false,blocked:false },
    { id:50902, word:'presently',translate:'в настоящее время',checked:false,blocked:false },
    { id:50903, word:'preservation',translate:'сохранение',checked:false,blocked:false },
    { id:50904, word:'preside',translate:'председательствовать',checked:false,blocked:false },
    { id:50905, word:'presidency',translate:'президентство',checked:false,blocked:false },
    { id:50906, word:'presidential',translate:'президент',checked:false,blocked:false },
    { id:50907, word:'prestigious',translate:'престижный',checked:false,blocked:false },
    { id:50908, word:'presumably',translate:'предположительно',checked:false,blocked:false },
    { id:50909, word:'presume',translate:'предполагать',checked:false,blocked:false },
    { id:50910, word:'prevail',translate:'преобладать',checked:false,blocked:false },
    { id:50911, word:'prevalence',translate:'распространенность',checked:false,blocked:false },
    { id:50912, word:'prevention',translate:'профилактика',checked:false,blocked:false },
    { id:50913, word:'prey',translate:'добыча',checked:false,blocked:false },
    { id:50914, word:'principal',translate:'главный',checked:false,blocked:false },
    { id:50915, word:'privatization',translate:'приватизация',checked:false,blocked:false },
    { id:50916, word:'privilege',translate:'привилегия',checked:false,blocked:false },
    { id:50917, word:'probe',translate:'зонд',checked:false,blocked:false },
    { id:50918, word:'problematic',translate:'проблемный',checked:false,blocked:false },
    { id:50919, word:'proceedings',translate:'разбирательство',checked:false,blocked:false },
    { id:50920, word:'proceeds',translate:'выручка',checked:false,blocked:false },
    { id:50921, word:'processing',translate:'обработка',checked:false,blocked:false },
    { id:50922, word:'processor',translate:'процессор',checked:false,blocked:false },
    { id:50923, word:'proclaim',translate:'провозгласить',checked:false,blocked:false },
    { id:50924, word:'productive',translate:'продуктивный',checked:false,blocked:false },
    { id:50925, word:'productivity',translate:'продуктивность',checked:false,blocked:false },
    { id:50926, word:'profitable',translate:'прибыльный',checked:false,blocked:false },
    { id:50927, word:'profound',translate:'глубокий',checked:false,blocked:false },
    { id:50928, word:'projection',translate:'проекция',checked:false,blocked:false },
    { id:50929, word:'prominent',translate:'выдающийся',checked:false,blocked:false },
    { id:50930, word:'pronounced',translate:'выраженный',checked:false,blocked:false },
    { id:50931, word:'propaganda',translate:'пропаганда',checked:false,blocked:false },
    { id:50932, word:'proposition',translate:'предложение',checked:false,blocked:false },
    { id:50933, word:'prosecute',translate:'преследовать',checked:false,blocked:false },
    { id:50934, word:'prosecution',translate:'обвинение',checked:false,blocked:false },
    { id:50935, word:'prosecutor',translate:'прокурор',checked:false,blocked:false },
    { id:50936, word:'prospective',translate:'перспектива',checked:false,blocked:false },
    { id:50937, word:'prosperity',translate:'процветание',checked:false,blocked:false },
    { id:50938, word:'protective',translate:'защита',checked:false,blocked:false },
    { id:50939, word:'protocol',translate:'протокол',checked:false,blocked:false },
    { id:50940, word:'province',translate:'провинция',checked:false,blocked:false },
    { id:50941, word:'provincial',translate:'провинция',checked:false,blocked:false },
    { id:50942, word:'provision',translate:'обеспечение',checked:false,blocked:false },
    { id:50943, word:'provoke',translate:'провоцировать',checked:false,blocked:false },
    { id:50944, word:'psychiatric',translate:'психиатрический',checked:false,blocked:false },
    { id:50945, word:'pulse',translate:'пульс',checked:false,blocked:false },
    { id:50946, word:'pump',translate:'насос',checked:false,blocked:false },
    { id:50947, word:'punch',translate:'удар',checked:false,blocked:false },
    { id:50948, word:'query',translate:'запрос',checked:false,blocked:false },
    { id:50949, word:'quest',translate:'квест',checked:false,blocked:false },
    { id:50950, word:'quota',translate:'квота',checked:false,blocked:false },
    { id:50951, word:'radar',translate:'радар',checked:false,blocked:false },
    { id:50952, word:'radical',translate:'радикал',checked:false,blocked:false },
    { id:50953, word:'rage',translate:'ярость',checked:false,blocked:false },
    { id:50954, word:'raid',translate:'рейд',checked:false,blocked:false },
    { id:50955, word:'rally',translate:'ралли',checked:false,blocked:false },
    { id:50956, word:'ranking',translate:'рейтинг',checked:false,blocked:false },
    { id:50957, word:'rape',translate:'изнасилование',checked:false,blocked:false },
    { id:50958, word:'ratio',translate:'соотношение',checked:false,blocked:false },
    { id:50959, word:'rational',translate:'рациональный',checked:false,blocked:false },
    { id:50960, word:'ray',translate:'луч',checked:false,blocked:false },
    { id:50961, word:'readily',translate:'легко',checked:false,blocked:false },
    { id:50962, word:'realization',translate:'реализация',checked:false,blocked:false },
    { id:50963, word:'realm',translate:'царство',checked:false,blocked:false },
    { id:50964, word:'rear',translate:'тыл',checked:false,blocked:false },
    { id:50965, word:'reasoning',translate:'рассуждение',checked:false,blocked:false },
    { id:50966, word:'reassure',translate:'успокоить',checked:false,blocked:false },
    { id:50967, word:'rebel',translate:'восстание',checked:false,blocked:false },
    { id:50968, word:'rebellion',translate:'восстание',checked:false,blocked:false },
    { id:50969, word:'recipient',translate:'получатель',checked:false,blocked:false },
    { id:50970, word:'reconstruction',translate:'реконструкция',checked:false,blocked:false },
    { id:50971, word:'recount1',translate:'пересчёт1',checked:false,blocked:false },
    { id:50972, word:'referendum',translate:'референдум',checked:false,blocked:false },
    { id:50973, word:'reflection',translate:'отражение',checked:false,blocked:false },
    { id:50974, word:'reform',translate:'реформа',checked:false,blocked:false },
    { id:50975, word:'refuge',translate:'убежище',checked:false,blocked:false },
    { id:50976, word:'refusal',translate:'отказ',checked:false,blocked:false },
    { id:50977, word:'regain',translate:'вернуть',checked:false,blocked:false },
    { id:50978, word:'regardless',translate:'независимо',checked:false,blocked:false },
    { id:50979, word:'regime',translate:'режим',checked:false,blocked:false },
    { id:50980, word:'regulator',translate:'регулятор',checked:false,blocked:false },
    { id:50981, word:'regulatory',translate:'регулятор',checked:false,blocked:false },
    { id:50982, word:'rehabilitation',translate:'реабилитация',checked:false,blocked:false },
    { id:50983, word:'reign',translate:'править',checked:false,blocked:false },
    { id:50984, word:'rejection',translate:'отказ',checked:false,blocked:false },
    { id:50985, word:'relevance',translate:'актуальность',checked:false,blocked:false },
    { id:50986, word:'reliability',translate:'надежность',checked:false,blocked:false },
    { id:50987, word:'reluctant',translate:'нежелание',checked:false,blocked:false },
    { id:50988, word:'remainder',translate:'остаток',checked:false,blocked:false },
    { id:50989, word:'remains',translate:'остаётся',checked:false,blocked:false },
    { id:50990, word:'remedy',translate:'средство',checked:false,blocked:false },
    { id:50991, word:'reminder',translate:'напоминание',checked:false,blocked:false },
    { id:50992, word:'removal',translate:'удаление',checked:false,blocked:false },
    { id:50993, word:'render',translate:'сделать',checked:false,blocked:false },
    { id:50994, word:'renew',translate:'обновить',checked:false,blocked:false },
    { id:50995, word:'renowned',translate:'известный',checked:false,blocked:false },
    { id:50996, word:'rental',translate:'аренда',checked:false,blocked:false },
    { id:50997, word:'replacement',translate:'замена',checked:false,blocked:false },
    { id:50998, word:'reportedly',translate:'по сообщениям',checked:false,blocked:false },
    { id:50999, word:'representation',translate:'представление',checked:false,blocked:false },
    { id:51000, word:'reproduce',translate:'воспроизводить',checked:false,blocked:false },
    { id:51001, word:'reproduction',translate:'воспроизводство',checked:false,blocked:false },
    { id:51002, word:'republic',translate:'республика',checked:false,blocked:false },
    { id:51003, word:'resemble',translate:'напоминать',checked:false,blocked:false },
    { id:51004, word:'reside',translate:'проживать',checked:false,blocked:false },
    { id:51005, word:'residence',translate:'проживание',checked:false,blocked:false },
    { id:51006, word:'residential',translate:'жилой',checked:false,blocked:false },
    { id:51007, word:'residue',translate:'остаток',checked:false,blocked:false },
    { id:51008, word:'resignation',translate:'отставка',checked:false,blocked:false },
    { id:51009, word:'resistance',translate:'сопротивление',checked:false,blocked:false },
    { id:51010, word:'respective',translate:'соответственно',checked:false,blocked:false },
    { id:51011, word:'respectively',translate:'соответственно',checked:false,blocked:false },
    { id:51012, word:'restoration',translate:'восстановление',checked:false,blocked:false },
    { id:51013, word:'restraint',translate:'сдерживание',checked:false,blocked:false },
    { id:51014, word:'resume',translate:'возобновить',checked:false,blocked:false },
    { id:51015, word:'retreat',translate:'отступление',checked:false,blocked:false },
    { id:51016, word:'retrieve',translate:'восстановить',checked:false,blocked:false },
    { id:51017, word:'revelation',translate:'откровение',checked:false,blocked:false },
    { id:51018, word:'revenge',translate:'месть',checked:false,blocked:false },
    { id:51019, word:'reverse',translate:'реверс',checked:false,blocked:false },
    { id:51020, word:'revival',translate:'возрождение',checked:false,blocked:false },
    { id:51021, word:'revive',translate:'возродить',checked:false,blocked:false },
    { id:51022, word:'revolutionary',translate:'революция',checked:false,blocked:false },
    { id:51023, word:'rhetoric',translate:'риторика',checked:false,blocked:false },
    { id:51024, word:'rifle',translate:'винтовка',checked:false,blocked:false },
    { id:51025, word:'riot',translate:'бунт',checked:false,blocked:false },
    { id:51026, word:'rip',translate:'разрыв',checked:false,blocked:false },
    { id:51027, word:'ritual',translate:'ритуал',checked:false,blocked:false },
    { id:51028, word:'robust',translate:'крепкий',checked:false,blocked:false },
    { id:51029, word:'rock',translate:'рок',checked:false,blocked:false },
    { id:51030, word:'rod',translate:'стержень',checked:false,blocked:false },
    { id:51031, word:'rotate',translate:'вращаться',checked:false,blocked:false },
    { id:51032, word:'rotation',translate:'вращение',checked:false,blocked:false },
    { id:51033, word:'ruling',translate:'править',checked:false,blocked:false },
    { id:51034, word:'rumour',translate:'слухи',checked:false,blocked:false },
    { id:51035, word:'sack',translate:'мешок',checked:false,blocked:false },
    { id:51036, word:'sacred',translate:'священный',checked:false,blocked:false },
    { id:51037, word:'sacrifice',translate:'жертва',checked:false,blocked:false },
    { id:51038, word:'saint',translate:'святой',checked:false,blocked:false },
    { id:51039, word:'sake',translate:'сакэ',checked:false,blocked:false },
    { id:51040, word:'sanction',translate:'санкция',checked:false,blocked:false },
    { id:51041, word:'say',translate:'сказать',checked:false,blocked:false },
    { id:51042, word:'scattered',translate:'рассеянный',checked:false,blocked:false },
    { id:51043, word:'sceptical',translate:'скептик',checked:false,blocked:false },
    { id:51044, word:'scope',translate:'сфера',checked:false,blocked:false },
    { id:51045, word:'screw',translate:'винт',checked:false,blocked:false },
    { id:51046, word:'scrutiny',translate:'проверка',checked:false,blocked:false },
    { id:51047, word:'seal',translate:'печать',checked:false,blocked:false },
    { id:51048, word:'secular',translate:'светский',checked:false,blocked:false },
    { id:51049, word:'seemingly',translate:'на первый взгляд',checked:false,blocked:false },
    { id:51050, word:'segment',translate:'сегмент',checked:false,blocked:false },
    { id:51051, word:'seize',translate:'захватить',checked:false,blocked:false },
    { id:51052, word:'seldom',translate:'редко',checked:false,blocked:false },
    { id:51053, word:'selective',translate:'выборочно',checked:false,blocked:false },
    { id:51054, word:'senator',translate:'сенатор',checked:false,blocked:false },
    { id:51055, word:'sensation',translate:'ощущение',checked:false,blocked:false },
    { id:51056, word:'sensitivity',translate:'чувствительность',checked:false,blocked:false },
    { id:51057, word:'sentiment',translate:'чувство',checked:false,blocked:false },
    { id:51058, word:'separation',translate:'разделение',checked:false,blocked:false },
    { id:51059, word:'serial',translate:'сериал',checked:false,blocked:false },
    { id:51060, word:'settlement',translate:'поселение',checked:false,blocked:false },
    { id:51061, word:'set-up',translate:'установка',checked:false,blocked:false },
    { id:51062, word:'sexuality',translate:'сексуальность',checked:false,blocked:false },
    { id:51063, word:'shareholder',translate:'акционер',checked:false,blocked:false },
    { id:51064, word:'shatter',translate:'разбиться',checked:false,blocked:false },
    { id:51065, word:'shed',translate:'пролить',checked:false,blocked:false },
    { id:51066, word:'sheer',translate:'просто',checked:false,blocked:false },
    { id:51067, word:'shipping',translate:'доставка',checked:false,blocked:false },
    { id:51068, word:'shoot',translate:'стрелять',checked:false,blocked:false },
    { id:51069, word:'shrink',translate:'сократить',checked:false,blocked:false },
    { id:51070, word:'shrug',translate:'пожать плечами',checked:false,blocked:false },
    { id:51071, word:'sigh',translate:'вздыхать',checked:false,blocked:false },
    { id:51072, word:'simulate',translate:'имитировать',checked:false,blocked:false },
    { id:51073, word:'simulation',translate:'моделирование',checked:false,blocked:false },
    { id:51074, word:'simultaneously',translate:'одновременно',checked:false,blocked:false },
    { id:51075, word:'sin',translate:'грешить',checked:false,blocked:false },
    { id:51076, word:'situated',translate:'расположенный',checked:false,blocked:false },
    { id:51077, word:'sketch',translate:'набросок',checked:false,blocked:false },
    { id:51078, word:'skip',translate:'пропустить',checked:false,blocked:false },
    { id:51079, word:'slam',translate:'хлопнуть',checked:false,blocked:false },
    { id:51080, word:'slap',translate:'пощёчина',checked:false,blocked:false },
    { id:51081, word:'slash',translate:'пощёчина',checked:false,blocked:false },
    { id:51082, word:'slavery',translate:'рабство',checked:false,blocked:false },
    { id:51083, word:'slot',translate:'слот',checked:false,blocked:false },
    { id:51084, word:'smash',translate:'разбить',checked:false,blocked:false },
    { id:51085, word:'snap',translate:'щелчок',checked:false,blocked:false },
    { id:51086, word:'soak',translate:'впитывать',checked:false,blocked:false },
    { id:51087, word:'soar',translate:'парить',checked:false,blocked:false },
    { id:51088, word:'socialist',translate:'социалист',checked:false,blocked:false },
    { id:51089, word:'sole',translate:'единственный',checked:false,blocked:false },
    { id:51090, word:'solely',translate:'исключительно',checked:false,blocked:false },
    { id:51091, word:'solicitor',translate:'солиситор',checked:false,blocked:false },
    { id:51092, word:'solidarity',translate:'солидарность',checked:false,blocked:false },
    { id:51093, word:'solo',translate:'соло',checked:false,blocked:false },
    { id:51094, word:'sound',translate:'звук',checked:false,blocked:false },
    { id:51095, word:'sovereignty',translate:'суверенитет',checked:false,blocked:false },
    { id:51096, word:'spam',translate:'спам',checked:false,blocked:false },
    { id:51097, word:'span',translate:'пролет',checked:false,blocked:false },
    { id:51098, word:'spare',translate:'запасной',checked:false,blocked:false },
    { id:51099, word:'spark',translate:'искра',checked:false,blocked:false },
    { id:51100, word:'specialized',translate:'специализированный',checked:false,blocked:false },
    { id:51101, word:'specification',translate:'спецификация',checked:false,blocked:false },
    { id:51102, word:'specimen',translate:'образец',checked:false,blocked:false },
    { id:51103, word:'spectacle',translate:'очки',checked:false,blocked:false },
    { id:51104, word:'spectrum',translate:'спектр',checked:false,blocked:false },
    { id:51105, word:'spell',translate:'заклинание',checked:false,blocked:false },
    { id:51106, word:'sphere',translate:'сфера',checked:false,blocked:false },
    { id:51107, word:'spin',translate:'спин',checked:false,blocked:false },
    { id:51108, word:'spine',translate:'позвоночник',checked:false,blocked:false },
    { id:51109, word:'spotlight',translate:'прожектор',checked:false,blocked:false },
    { id:51110, word:'spouse',translate:'супруг',checked:false,blocked:false },
    { id:51111, word:'spy',translate:'шпион',checked:false,blocked:false },
    { id:51112, word:'squad',translate:'отряд',checked:false,blocked:false },
    { id:51113, word:'squeeze',translate:'сжимать',checked:false,blocked:false },
    { id:51114, word:'stab',translate:'колоть',checked:false,blocked:false },
    { id:51115, word:'stability',translate:'стабильность',checked:false,blocked:false },
    { id:51116, word:'stabilize',translate:'стабилизировать',checked:false,blocked:false },
    { id:51117, word:'stake',translate:'кол',checked:false,blocked:false },
    { id:51118, word:'standing',translate:'стоять',checked:false,blocked:false },
    { id:51119, word:'stark',translate:'звёздный',checked:false,blocked:false },
    { id:51120, word:'statistical',translate:'статистика',checked:false,blocked:false },
    { id:51121, word:'steer',translate:'рулить',checked:false,blocked:false },
    { id:51122, word:'stem',translate:'стебель',checked:false,blocked:false },
    { id:51123, word:'stereotype',translate:'стереотип',checked:false,blocked:false },
    { id:51124, word:'stimulus',translate:'стимул',checked:false,blocked:false },
    { id:51125, word:'stir',translate:'перемешать',checked:false,blocked:false },
    { id:51126, word:'storage',translate:'хранение',checked:false,blocked:false },
    { id:51127, word:'straightforward',translate:'прямой',checked:false,blocked:false },
    { id:51128, word:'strain',translate:'напряжение',checked:false,blocked:false },
    { id:51129, word:'strand',translate:'прядь',checked:false,blocked:false },
    { id:51130, word:'strategic',translate:'стратегический',checked:false,blocked:false },
    { id:51131, word:'striking',translate:'удар',checked:false,blocked:false },
    { id:51132, word:'strip',translate:'полоса',checked:false,blocked:false },
    { id:51133, word:'strip',translate:'полоса',checked:false,blocked:false },
    { id:51134, word:'strive',translate:'стремиться',checked:false,blocked:false },
    { id:51135, word:'structural',translate:'структурный',checked:false,blocked:false },
    { id:51136, word:'stumble',translate:'споткнуться',checked:false,blocked:false },
    { id:51137, word:'stun',translate:'ошеломлять',checked:false,blocked:false },
    { id:51138, word:'submission',translate:'подчинение',checked:false,blocked:false },
    { id:51139, word:'subscriber',translate:'подписчик',checked:false,blocked:false },
    { id:51140, word:'subscription',translate:'подписка',checked:false,blocked:false },
    { id:51141, word:'subsidy',translate:'субсидия',checked:false,blocked:false },
    { id:51142, word:'substantial',translate:'существенный',checked:false,blocked:false },
    { id:51143, word:'substantially',translate:'существенно',checked:false,blocked:false },
    { id:51144, word:'substitute',translate:'абоненты',checked:false,blocked:false },
    { id:51145, word:'substitution',translate:'замена',checked:false,blocked:false },
    { id:51146, word:'subtle',translate:'тонкий',checked:false,blocked:false },
    { id:51147, word:'suburban',translate:'пригород',checked:false,blocked:false },
    { id:51148, word:'succession',translate:'преемственность',checked:false,blocked:false },
    { id:51149, word:'successive',translate:'преемник',checked:false,blocked:false },
    { id:51150, word:'successor',translate:'преемник',checked:false,blocked:false },
    { id:51151, word:'suck',translate:'сосать',checked:false,blocked:false },
    { id:51152, word:'sue',translate:'подать в суд',checked:false,blocked:false },
    { id:51153, word:'suicide',translate:'самоубийство',checked:false,blocked:false },
    { id:51154, word:'suite',translate:'люкс',checked:false,blocked:false },
    { id:51155, word:'summit',translate:'саммит',checked:false,blocked:false },
    { id:51156, word:'superb',translate:'превосходный',checked:false,blocked:false },
    { id:51157, word:'superior',translate:'превосходный',checked:false,blocked:false },
    { id:51158, word:'supervise',translate:'надзор',checked:false,blocked:false },
    { id:51159, word:'supervision',translate:'надзор',checked:false,blocked:false },
    { id:51160, word:'supervisor',translate:'начальник',checked:false,blocked:false },
    { id:51161, word:'supplement',translate:'дополнение',checked:false,blocked:false },
    { id:51162, word:'supportive',translate:'поддерживающий',checked:false,blocked:false },
    { id:51163, word:'supposedly',translate:'предположительно',checked:false,blocked:false },
    { id:51164, word:'suppress',translate:'подавлять',checked:false,blocked:false },
    { id:51165, word:'supreme',translate:'верховный',checked:false,blocked:false },
    { id:51166, word:'surge',translate:'всплеск',checked:false,blocked:false },
    { id:51167, word:'surgical',translate:'хирургический',checked:false,blocked:false },
    { id:51168, word:'surplus',translate:'излишки',checked:false,blocked:false },
    { id:51169, word:'surrender',translate:'сдаваться',checked:false,blocked:false },
    { id:51170, word:'surveillance',translate:'наблюдение',checked:false,blocked:false },
    { id:51171, word:'suspension',translate:'приостановка',checked:false,blocked:false },
    { id:51172, word:'suspicion',translate:'подозрение',checked:false,blocked:false },
    { id:51173, word:'suspicious',translate:'подозрительный',checked:false,blocked:false },
    { id:51174, word:'sustain',translate:'поддерживать',checked:false,blocked:false },
    { id:51175, word:'swing',translate:'замах',checked:false,blocked:false },
    { id:51176, word:'sword',translate:'меч',checked:false,blocked:false },
    { id:51177, word:'symbolic',translate:'символический',checked:false,blocked:false },
    { id:51178, word:'syndrome',translate:'синдром',checked:false,blocked:false },
    { id:51179, word:'synthesis',translate:'синтез',checked:false,blocked:false },
    { id:51180, word:'systematic',translate:'систематический',checked:false,blocked:false },
    { id:51181, word:'tackle',translate:'борьба',checked:false,blocked:false },
    { id:51182, word:'tactic',translate:'тактика',checked:false,blocked:false },
    { id:51183, word:'tactical',translate:'тактика',checked:false,blocked:false },
    { id:51184, word:'taxpayer',translate:'налогоплательщик',checked:false,blocked:false },
    { id:51185, word:'tempt',translate:'искушать',checked:false,blocked:false },
    { id:51186, word:'tenant',translate:'арендатор',checked:false,blocked:false },
    { id:51187, word:'tender',translate:'тендер',checked:false,blocked:false },
    { id:51188, word:'tenure',translate:'срок аренды',checked:false,blocked:false },
    { id:51189, word:'terminal',translate:'терминал',checked:false,blocked:false },
    { id:51190, word:'terminate',translate:'прекратить',checked:false,blocked:false },
    { id:51191, word:'terrain',translate:'местность',checked:false,blocked:false },
    { id:51192, word:'terrific',translate:'потрясающий',checked:false,blocked:false },
    { id:51193, word:'testify',translate:'свидетельствовать',checked:false,blocked:false },
    { id:51194, word:'testimony',translate:'свидетельство',checked:false,blocked:false },
    { id:51195, word:'texture',translate:'текстура',checked:false,blocked:false },
    { id:51196, word:'thankfully',translate:'к счастью',checked:false,blocked:false },
    { id:51197, word:'theatrical',translate:'театральный',checked:false,blocked:false },
    { id:51198, word:'theology',translate:'теология',checked:false,blocked:false },
    { id:51199, word:'theoretical',translate:'теоретическая',checked:false,blocked:false },
    { id:51200, word:'thereafter',translate:'далее',checked:false,blocked:false },
    { id:51201, word:'thereby',translate:'таким образом',checked:false,blocked:false },
    { id:51202, word:'thoughtful',translate:'вдумчивый',checked:false,blocked:false },
    { id:51203, word:'thought-provoking',translate:'наводящий на размышления',checked:false,blocked:false },
    { id:51204, word:'thread',translate:'нить',checked:false,blocked:false },
    { id:51205, word:'threshold',translate:'порог',checked:false,blocked:false },
    { id:51206, word:'thrilled',translate:'в восторге',checked:false,blocked:false },
    { id:51207, word:'thrive',translate:'процветать',checked:false,blocked:false },
    { id:51208, word:'tide',translate:'прилив',checked:false,blocked:false },
    { id:51209, word:'tighten',translate:'затянуть',checked:false,blocked:false },
    { id:51210, word:'timber',translate:'древесина',checked:false,blocked:false },
    { id:51211, word:'timely',translate:'вовремя',checked:false,blocked:false },
    { id:51212, word:'tobacco',translate:'табак',checked:false,blocked:false },
    { id:51213, word:'tolerance',translate:'терпимость',checked:false,blocked:false },
    { id:51214, word:'tolerate',translate:'терпеть',checked:false,blocked:false },
    { id:51215, word:'toll',translate:'плата',checked:false,blocked:false },
    { id:51216, word:'top',translate:'топ',checked:false,blocked:false },
    { id:51217, word:'torture',translate:'пытать',checked:false,blocked:false },
    { id:51218, word:'toss',translate:'бросать',checked:false,blocked:false },
    { id:51219, word:'total',translate:'полный',checked:false,blocked:false },
    { id:51220, word:'toxic',translate:'токсичный',checked:false,blocked:false },
    { id:51221, word:'trace',translate:'след',checked:false,blocked:false },
    { id:51222, word:'trademark',translate:'торговая марка',checked:false,blocked:false },
    { id:51223, word:'trail',translate:'след',checked:false,blocked:false },
    { id:51224, word:'trailer',translate:'трейлер',checked:false,blocked:false },
    { id:51225, word:'transaction',translate:'транзакция',checked:false,blocked:false },
    { id:51226, word:'transcript',translate:'транскрипт',checked:false,blocked:false },
    { id:51227, word:'transformation',translate:'трансформация',checked:false,blocked:false },
    { id:51228, word:'transit',translate:'транзит',checked:false,blocked:false },
    { id:51229, word:'transmission',translate:'передача',checked:false,blocked:false },
    { id:51230, word:'transparency',translate:'прозрачность',checked:false,blocked:false },
    { id:51231, word:'transparent',translate:'прозрачный',checked:false,blocked:false },
    { id:51232, word:'trauma',translate:'травма',checked:false,blocked:false },
    { id:51233, word:'treaty',translate:'договор',checked:false,blocked:false },
    { id:51234, word:'tremendous',translate:'огромный',checked:false,blocked:false },
    { id:51235, word:'tribal',translate:'племя',checked:false,blocked:false },
    { id:51236, word:'tribunal',translate:'трибунал',checked:false,blocked:false },
    { id:51237, word:'tribute',translate:'дань',checked:false,blocked:false },
    { id:51238, word:'trigger',translate:'триггер',checked:false,blocked:false },
    { id:51239, word:'trio',translate:'трио',checked:false,blocked:false },
    { id:51240, word:'triumph',translate:'триумф',checked:false,blocked:false },
    { id:51241, word:'trophy',translate:'трофей',checked:false,blocked:false },
    { id:51242, word:'troubled',translate:'проблемный',checked:false,blocked:false },
    { id:51243, word:'trustee',translate:'попечитель',checked:false,blocked:false },
    { id:51244, word:'tuition',translate:'плата за обучение',checked:false,blocked:false },
    { id:51245, word:'turnout',translate:'явка',checked:false,blocked:false },
    { id:51246, word:'turnover',translate:'оборот',checked:false,blocked:false },
    { id:51247, word:'twist',translate:'поворот',checked:false,blocked:false },
    { id:51248, word:'undergraduate',translate:'бакалавриат',checked:false,blocked:false },
    { id:51249, word:'underlying',translate:'основа',checked:false,blocked:false },
    { id:51250, word:'undermine',translate:'подрывать',checked:false,blocked:false },
    { id:51251, word:'undoubtedly',translate:'несомненно',checked:false,blocked:false },
    { id:51252, word:'unify',translate:'объединить',checked:false,blocked:false },
    { id:51253, word:'unprecedented',translate:'беспрецедентный',checked:false,blocked:false },
    { id:51254, word:'unveil',translate:'раскрыть',checked:false,blocked:false },
    { id:51255, word:'upcoming',translate:'предстоящий',checked:false,blocked:false },
    { id:51256, word:'upgrade',translate:'модернизировать',checked:false,blocked:false },
    { id:51257, word:'uphold',translate:'поддерживать',checked:false,blocked:false },
    { id:51258, word:'utility',translate:'использовать',checked:false,blocked:false },
    { id:51259, word:'utilize',translate:'использовать',checked:false,blocked:false },
    { id:51260, word:'utterly',translate:'полностью',checked:false,blocked:false },
    { id:51261, word:'vacuum',translate:'пылесос',checked:false,blocked:false },
    { id:51262, word:'vague',translate:'неясный',checked:false,blocked:false },
    { id:51263, word:'validity',translate:'действительность',checked:false,blocked:false },
    { id:51264, word:'vanish',translate:'исчезнуть',checked:false,blocked:false },
    { id:51265, word:'variable',translate:'переменный',checked:false,blocked:false },
    { id:51266, word:'varied',translate:'разнообразный',checked:false,blocked:false },
    { id:51267, word:'vein',translate:'жила',checked:false,blocked:false },
    { id:51268, word:'venture',translate:'авантюра',checked:false,blocked:false },
    { id:51269, word:'verbal',translate:'словесный',checked:false,blocked:false },
    { id:51270, word:'verdict',translate:'вердикт',checked:false,blocked:false },
    { id:51271, word:'verify',translate:'проверить',checked:false,blocked:false },
    { id:51272, word:'verse',translate:'стих',checked:false,blocked:false },
    { id:51273, word:'versus',translate:'против',checked:false,blocked:false },
    { id:51274, word:'vessel',translate:'судно',checked:false,blocked:false },
    { id:51275, word:'veteran',translate:'ветеран',checked:false,blocked:false },
    { id:51276, word:'viable',translate:'жизнеспособный',checked:false,blocked:false },
    { id:51277, word:'vibrant',translate:'яркий',checked:false,blocked:false },
    { id:51278, word:'vice',translate:'порок',checked:false,blocked:false },
    { id:51279, word:'vicious',translate:'порочный',checked:false,blocked:false },
    { id:51280, word:'villager',translate:'житель деревни',checked:false,blocked:false },
    { id:51281, word:'violate',translate:'нарушать',checked:false,blocked:false },
    { id:51282, word:'violation',translate:'нарушение',checked:false,blocked:false },
    { id:51283, word:'virtue',translate:'добродетель',checked:false,blocked:false },
    { id:51284, word:'vocal',translate:'вокал',checked:false,blocked:false },
    { id:51285, word:'vow',translate:'обет',checked:false,blocked:false },
    { id:51286, word:'vulnerability',translate:'уязвимость',checked:false,blocked:false },
    { id:51287, word:'vulnerable',translate:'уязвимость',checked:false,blocked:false },
    { id:51288, word:'ward',translate:'палата',checked:false,blocked:false },
    { id:51289, word:'warehouse',translate:'склад',checked:false,blocked:false },
    { id:51290, word:'warfare',translate:'война',checked:false,blocked:false },
    { id:51291, word:'warrant',translate:'прапорщик',checked:false,blocked:false },
    { id:51292, word:'warrior',translate:'воин',checked:false,blocked:false },
    { id:51293, word:'weaken',translate:'ослабить',checked:false,blocked:false },
    { id:51294, word:'weave',translate:'ткать',checked:false,blocked:false },
    { id:51295, word:'weed',translate:'сорняк',checked:false,blocked:false },
    { id:51296, word:'well',translate:'хорошо',checked:false,blocked:false },
    { id:51297, word:'well-being',translate:'благополучие',checked:false,blocked:false },
    { id:51298, word:'whatsoever',translate:'что бы то ни было',checked:false,blocked:false },
    { id:51299, word:'whereby',translate:'таким образом',checked:false,blocked:false },
    { id:51300, word:'whilst',translate:'в то время как',checked:false,blocked:false },
    { id:51301, word:'whip',translate:'плеть',checked:false,blocked:false },
    { id:51302, word:'wholly',translate:'полностью',checked:false,blocked:false },
    { id:51303, word:'widen',translate:'расширять',checked:false,blocked:false },
    { id:51304, word:'widow',translate:'вдова',checked:false,blocked:false },
    { id:51305, word:'width',translate:'ширина',checked:false,blocked:false },
    { id:51306, word:'willingness',translate:'готовность',checked:false,blocked:false },
    { id:51307, word:'wipe',translate:'вытирать',checked:false,blocked:false },
    { id:51308, word:'wit',translate:'остроумие',checked:false,blocked:false },
    { id:51309, word:'withdrawal',translate:'вывод',checked:false,blocked:false },
    { id:51310, word:'workout',translate:'тренировка',checked:false,blocked:false },
    { id:51311, word:'worship',translate:'поклонение',checked:false,blocked:false },
    { id:51312, word:'worthwhile',translate:'достойный',checked:false,blocked:false },
    { id:51313, word:'worthy',translate:'достойный',checked:false,blocked:false },
    { id:51314, word:'yell',translate:'кричать',checked:false,blocked:false },
    { id:51315, word:'yield',translate:'уступать',checked:false,blocked:false },
    { id:51316, word:'youngster',translate:'подросток',checked:false,blocked:false }
  ];

export const c2 = [
  { id:60001, word:'Capricious',translate:'Капризная',checked:false,blocked:false },
  { id:60002, word:'Cogent',translate:'Неоспоримый',checked:false,blocked:false },
  { id:60003, word:'Didactic',translate:'Дидактика',checked:false,blocked:false },
  { id:60004, word:'Equanimity',translate:'Невозмутимость',checked:false,blocked:false },
  { id:60005, word:'Fastidious',translate:'Прихотливый',checked:false,blocked:false },
  { id:60006, word:'Gratuitous',translate:'Добровольный',checked:false,blocked:false },
  { id:60007, word:'Inscrutable',translate:'Непостижимый',checked:false,blocked:false },
  { id:60008, word:'Meticulous',translate:'Тщательный',checked:false,blocked:false },
  { id:60009, word:'Nebulous',translate:'Расплывчатый',checked:false,blocked:false },
  { id:60010, word:'Ostracize',translate:'Изгонять',checked:false,blocked:false },
  { id:60012, word:'Rancor',translate:'Злопамятность',checked:false,blocked:false },
  { id:60013, word:'Sagacious',translate:'Проницательный',checked:false,blocked:false },
  { id:60014, word:'Tenacious',translate:'Упорный',checked:false,blocked:false },
  { id:60015, word:'Ubiquitous',translate:'Вездесущий',checked:false,blocked:false },
  { id:60016, word:'Vex',translate:'Досаждать',checked:false,blocked:false },
  { id:60017, word:'Wistful',translate:'Задумчивый',checked:false,blocked:false },
  { id:60018, word:'Zealot',translate:'Фанатик',checked:false,blocked:false },
  { id:60019, word:'Ostensible',translate:'Мнимый',checked:false,blocked:false },
  { id:60020, word:'Pedantic',translate:'Педантичный',checked:false,blocked:false },
  { id:60021, word:'Quotidian',translate:'Банальный',checked:false,blocked:false },
  { id:60022, word:'Recalcitrant',translate:'Непримиримый',checked:false,blocked:false },
  { id:60023, word:'Sanguine',translate:'Сангвиник',checked:false,blocked:false },
  { id:60024, word:'Tenuous',translate:'Непрочный',checked:false,blocked:false },
  { id:60025, word:'Ubiquitous',translate:'Вездесущий',checked:false,blocked:false },
  { id:60026, word:'Vacillate',translate:'Колебаться',checked:false,blocked:false },
  { id:60027, word:'Wistfule',translate:'Задумчивый',checked:false,blocked:false },
  { id:60028, word:'Zealous',translate:'Ревностный',checked:false,blocked:false },
  { id:60029, word:'Conundrum ',translate:'Загадка ',checked:false,blocked:false },
  { id:60030, word:'Ephemeral',translate:'Эфемерный',checked:false,blocked:false },
  { id:60031, word:'Inscrutable',translate:'Непостижимый',checked:false,blocked:false },
  { id:60032, word:'Myriad',translate:'Множество',checked:false,blocked:false },
  { id:60033, word:'Nefarious',translate:'Гнусный',checked:false,blocked:false },
  { id:60034, word:'Querulous',translate:'Ворчливый',checked:false,blocked:false },
  { id:60035, word:'Salient',translate:'Важный',checked:false,blocked:false },
  { id:60036, word:'Ubiquitous',translate:'Вездесущий',checked:false,blocked:false },
  { id:60037, word:'Vexatious',translate:'Досадный',checked:false,blocked:false },
  { id:60038, word:'Aberration ',translate:'Аберрация',checked:false,blocked:false },
  { id:60039, word:'Bombastic',translate:'Напыщенный',checked:false,blocked:false },
  { id:60040, word:'Cacophony',translate:'Какофония',checked:false,blocked:false },
  { id:60041, word:'Disparage',translate:'Унижать',checked:false,blocked:false },
  { id:60042, word:'Enigma',translate:'Загадка',checked:false,blocked:false },
  { id:60043, word:'Furtive',translate:'Скрытный',checked:false,blocked:false },
  { id:60044, word:'Gregarious',translate:'Стадный',checked:false,blocked:false },
  { id:60045, word:'Harbinger',translate:'Предвестник',checked:false,blocked:false },
  { id:60046, word:'Axiomatic',translate:'Аксиоматика',checked:false,blocked:false },
  { id:60047, word:'Equanimity',translate:'Невозмутимость',checked:false,blocked:false },
  { id:60048, word:'Genuflect',translate:'Преклониться',checked:false,blocked:false },
  { id:60049, word:'Intransigent',translate:'Непримеримый',checked:false,blocked:false },
  { id:60050, word:'Juxtapose',translate:'Сопоставить',checked:false,blocked:false },
  { id:60051, word:'Limpid',translate:'Прозрачный',checked:false,blocked:false },
  { id:60052, word:'Metamorphosis',translate:'Метаморфозы',checked:false,blocked:false },
  { id:60053, word:'Perspicacious',translate:'Проницательный',checked:false,blocked:false },
  { id:60054, word:'Vituperate',translate:'Бранить',checked:false,blocked:false },
  { id:60055, word:'Nonplussed',translate:'Невозмутимый',checked:false,blocked:false },
  { id:60056, word:'Obdurate',translate:'Упрямый',checked:false,blocked:false }
];

export const rus1 = [
  { id:90001, word:'функция' },
  { id:90002, word:'войско' },
  { id:90003, word:'комиссия' },
  { id:90004, word:'применение' },
  { id:90005, word:'капитан' },
  { id:90006, word:'работник' },
  { id:90007, word:'улыбнуться' },
  { id:90008, word:'холодный' },
  { id:90009, word:'обеспечение' },
  { id:90010, word:'офицер' },
  { id:90011, word:'появляться' },
  { id:90012, word:'конкретный' },
  { id:90013, word:'фамилия' },
  { id:90014, word:'предел' },
  { id:90015, word:'прямо' },
  { id:90016, word:'смеяться' },
  { id:90017, word:'выборы' },
  { id:90018, word:'иностранный' },
  { id:90019, word:'уехать' },
  { id:90020, word:'учёный' },
  { id:90021, word:'вроде' },
  { id:90022, word:'левый' },
  { id:90023, word:'счастливый' },
  { id:90024, word:'бутылка' },
  { id:90025, word:'бой' },
  { id:90026, word:'подходить' },
  { id:90027, word:'судить' },
  { id:90028, word:'родиться' },
  { id:90029, word:'теория' },
  { id:90030, word:'зона' },
  { id:90031, word:'отдел' },
  { id:90032, word:'зуб' },
  { id:90033, word:'разработка' },
  { id:90034, word:'отказаться' },
  { id:90035, word:'святой' },
  { id:90036, word:'считаться' },
  { id:90037, word:'точный' },
  { id:90038, word:'занять' },
  { id:90039, word:'личность' },
  { id:90040, word:'гора' },
  { id:90041, word:'добавить' },
  { id:90042, word:'товар' },
  { id:90043, word:'звонить' },
  { id:90044, word:'медленно' },
  { id:90045, word:'носить' },
  { id:90046, word:'метр' },
  { id:90047, word:'начинаться' },
  { id:90048, word:'зависеть' },
  { id:90049, word:'праздник' },
  { id:90050, word:'влияние' },
  { id:90051, word:'построить' },
  { id:90052, word:'читатель' },
  { id:90053, word:'частый' },
  { id:90054, word:'удовольствие' },
  { id:90055, word:'актёр' },
  { id:90056, word:'слеза' },
  { id:90057, word:'создавать' },
  { id:90058, word:'значительный' },
  { id:90059, word:'ответственность' },
  { id:90060, word:'учитель' },
  { id:90061, word:'акт' },
  { id:90062, word:'встретить' },
  { id:90063, word:'принадлежать' },
  { id:90064, word:'произнести' },
  { id:90065, word:'боль' },
  { id:90066, word:'множество' },
  { id:90067, word:'связанный' },
  { id:90068, word:'особенность' },
  { id:90069, word:'участвовать' },
  { id:90070, word:'показатель' },
  { id:90071, word:'занимать' },
  { id:90072, word:'корабль' },
  { id:90073, word:'уверенный' },
  { id:90074, word:'звук' },
  { id:90075, word:'тонкий' },
  { id:90076, word:'центральный' },
  { id:90077, word:'впечатление' },
  { id:90078, word:'спокойно' },
  { id:90079, word:'частность' },
  { id:90080, word:'детство' },
  { id:90081, word:'вывод' },
  { id:90082, word:'профессор' },
  { id:90083, word:'будущий' },
  { id:90084, word:'доля' },
  { id:90085, word:'норма' },
  { id:90086, word:'улыбаться' },
  { id:90087, word:'прошлое' },
  { id:90088, word:'возле' },
  { id:90089, word:'командир' },
  { id:90090, word:'коридор' },
  { id:90091, word:'поддержка' },
  { id:90092, word:'рамка' },
  { id:90093, word:'вскоре' },
  { id:90094, word:'лучше' },
  { id:90095, word:'сквозь' },
  { id:90096, word:'враг' },
  { id:90097, word:'ладно' },
  { id:90098, word:'этап' },
  { id:90099, word:'чёрт' },
  { id:90100, word:'дед' },
  { id:90101, word:'направить' },
  { id:90102, word:'собрание' },
  { id:90103, word:'приём' },
  { id:90104, word:'физический' },
  { id:90105, word:'болезнь' },
  { id:90106, word:'клетка' },
  { id:90107, word:'кожа' },
  { id:90108, word:'заявление' },
  { id:90109, word:'ради' },
  { id:90110, word:'попытка' },
  { id:90111, word:'сравнение' },
  { id:90112, word:'только' },
  { id:90113, word:'расчёт' },
  { id:90114, word:'когда-то' },
  { id:90115, word:'депутат' },
  { id:90116, word:'то' },
  { id:90117, word:'частный' },
  { id:90118, word:'обратиться' },
  { id:90119, word:'мелкий' },
  { id:90120, word:'невозможно' },
  { id:90121, word:'английский' },
  { id:90122, word:'постоянный' },
  { id:90123, word:'комитет' },
  { id:90124, word:'знак' },
  { id:90125, word:'выбрать' },
  { id:90126, word:'примерно' },
  { id:90127, word:'бить' },
  { id:90128, word:'дядя' },
  { id:90129, word:'учёт' },
  { id:90130, word:'хлеб' },
  { id:90131, word:'тихий' },
  { id:90132, word:'хватить' },
  { id:90133, word:'обещать' },
  { id:90134, word:'ли' },
  { id:90135, word:'встречаться' },
  { id:90136, word:'чай' },
  { id:90137, word:'режим' },
  { id:90138, word:'целое' },
  { id:90139, word:'вирус' },
  { id:90140, word:'гораздо' },
  { id:90141, word:'тридцать' },
  { id:90142, word:'выражение' },
  { id:90143, word:'напоминать' },
  { id:90144, word:'уж' },
  { id:90145, word:'неожиданно' },
  { id:90146, word:'здоровье' },
  { id:90147, word:'зима' },
  { id:90148, word:'упасть' },
  { id:90149, word:'перестать' },
  { id:90150, word:'десяток' },
  { id:90151, word:'глубина' },
  { id:90152, word:'сеть' },
  { id:90153, word:'студент' },
  { id:90154, word:'обладать' },
  { id:90155, word:'секунда' },
  { id:90156, word:'скорость' },
  { id:90157, word:'поиск' },
  { id:90158, word:'европейский' },
  { id:90159, word:'суть' },
  { id:90160, word:'налог' },
  { id:90161, word:'ошибка' },
  { id:90162, word:'ближайший' },
  { id:90163, word:'отечественный' },
  { id:90164, word:'тёплый' },
  { id:90165, word:'плакать' },
  { id:90166, word:'духовный' },
  { id:90167, word:'доход' },
  { id:90168, word:'прежний' },
  { id:90169, word:'режиссёр' },
  { id:90170, word:'поверхность' },
  { id:90171, word:'ощущение' },
  { id:90172, word:'состояться' },
  { id:90173, word:'карта' },
  { id:90174, word:'клуб' },
  { id:90175, word:'семь' },
  { id:90176, word:'станция' },
  { id:90177, word:'революция' },
  { id:90178, word:'колено' },
  { id:90179, word:'министерство' },
  { id:90180, word:'стекло' },
  { id:90181, word:'этаж' },
  { id:90182, word:'остальной' },
  { id:90183, word:'высота' },
  { id:90184, word:'наоборот' },
  { id:90185, word:'бабушка' },
  { id:90186, word:'двое' },
  { id:90187, word:'поверить' },
  { id:90188, word:'трубка' },
  { id:90189, word:'собрать' },
  { id:90190, word:'профессиональный' },
  { id:90191, word:'французский' },
  { id:90192, word:'женский' },
  { id:90193, word:'крайний' },
  { id:90194, word:'простить' },
  { id:90195, word:'газ' },
  { id:90196, word:'естественно' },
  { id:90197, word:'мастер' },
  { id:90198, word:'вниз' },
  { id:90199, word:'главное' },
  { id:90200, word:'поведение' },
  { id:90201, word:'рассматривать' },
  { id:90202, word:'перейти' },
  { id:90203, word:'мешать' },
  { id:90204, word:'давай' },
  { id:90205, word:'столица' },
  { id:90206, word:'механизм' },
  { id:90207, word:'передача' },
  { id:90208, word:'божий' },
  { id:90209, word:'исчезнуть' },
  { id:90210, word:'способность' },
  { id:90211, word:'подход' },
  { id:90212, word:'разумеется' },
  { id:90213, word:'столько' },
  { id:90214, word:'энергия' },
  { id:90215, word:'дальнейший' },
  { id:90216, word:'существование' },
  { id:90217, word:'исполнение' },
  { id:90218, word:'сорок' },
  { id:90219, word:'кино' },
  { id:90220, word:'сожаление' },
  { id:90221, word:'заместитель' },
  { id:90222, word:'мол' },
  { id:90223, word:'объявить' },
  { id:90224, word:'отличаться' },
  { id:90225, word:'естественный' },
  { id:90226, word:'ресурс' },
  { id:90227, word:'обращаться' },
  { id:90228, word:'акция' },
  { id:90229, word:'информационный' },
  { id:90230, word:'рождение' },
  { id:90231, word:'снимать' },
  { id:90232, word:'явно' },
  { id:90233, word:'администрация' },
  { id:90234, word:'железный' },
  { id:90235, word:'пригласить' },
  { id:90236, word:'соответствовать' },
  { id:90237, word:'стоимость' },
  { id:90238, word:'улыбка' },
  { id:90239, word:'артист' },
  { id:90240, word:'горячий' },
  { id:90241, word:'закрыть' },
  { id:90242, word:'придумать' },
  { id:90243, word:'пожалуйста' },
  { id:90244, word:'попробовать' },
  { id:90245, word:'приводить' },
  { id:90246, word:'сосед' },
  { id:90247, word:'фраза' },
  { id:90248, word:'весёлый' },
  { id:90249, word:'фигура' },
  { id:90250, word:'достигнуть' },
  { id:90251, word:'субъект' },
  { id:90252, word:'утверждать' },
  { id:90253, word:'реакция' },
  { id:90254, word:'список' },
  { id:90255, word:'фотография' },
  { id:90256, word:'журналист' },
  { id:90257, word:'май' },
  { id:90258, word:'означать' },
  { id:90259, word:'платить' },
  { id:90260, word:'нарушение' },
  { id:90261, word:'заседание' },
  { id:90262, word:'толпа' },
  { id:90263, word:'больница' },
  { id:90264, word:'существо' },
  { id:90265, word:'решать' },
  { id:90266, word:'интересно' },
  { id:90267, word:'официальный' },
  { id:90268, word:'свойство' },
  { id:90269, word:'долг' },
  { id:90270, word:'поколение' },
  { id:90271, word:'серый' },
  { id:90272, word:'животное' },
  { id:90273, word:'схема' },
  { id:90274, word:'усилие' },
  { id:90275, word:'отличие' },
  { id:90276, word:'опасный' },
  { id:90277, word:'определять' },
  { id:90278, word:'остров' },
  { id:90279, word:'наблюдать' },
  { id:90280, word:'противник' },
  { id:90281, word:'волна' },
  { id:90282, word:'погибнуть' },
  { id:90283, word:'разговаривать' },
  { id:90284, word:'прочий' },
  { id:90285, word:'реализация' },
  { id:90286, word:'страница' },
  { id:90287, word:'формирование' },
  { id:90288, word:'житель' },
  { id:90289, word:'устроить' },
  { id:90290, word:'есть' },
  { id:90291, word:'красота' },
  { id:90292, word:'правильно' },
  { id:90293, word:'благодаря' },
  { id:90294, word:'птица' },
  { id:90295, word:'собраться' },
  { id:90296, word:'достать' },
  { id:90297, word:'растение' },
  { id:90298, word:'тень' },
  { id:90299, word:'явление' },
  { id:90300, word:'храм' },
  { id:90301, word:'запах' },
  { id:90302, word:'слабый' },
  { id:90303, word:'водка' },
  { id:90304, word:'наличие' },
  { id:90305, word:'пожалуй' },
  { id:90306, word:'яркий' },
  { id:90307, word:'ужас' },
  { id:90308, word:'одежда' },
  { id:90309, word:'ездить' },
  { id:90310, word:'кресло' },
  { id:90311, word:'больной' },
  { id:90312, word:'поезд' },
  { id:90313, word:'университет' },
  { id:90314, word:'летний' },
  { id:90315, word:'дополнительный' },
  { id:90316, word:'понравиться' },
  { id:90317, word:'традиция' },
  { id:90318, word:'адрес' },
  { id:90319, word:'декабрь' },
  { id:90320, word:'ладонь' },
  { id:90321, word:'поздно' },
  { id:90322, word:'сведение' },
  { id:90323, word:'правильный' },
  { id:90324, word:'цветок' },
  { id:90325, word:'выполнять' },
  { id:90326, word:'лесной' },
  { id:90327, word:'лидер' },
  { id:90328, word:'октябрь' },
  { id:90329, word:'занятие' },
  { id:90330, word:'заставить' },
  { id:90331, word:'объяснять' },
  { id:90332, word:'сентябрь' },
  { id:90333, word:'помещение' },
  { id:90334, word:'прочее' },
  { id:90335, word:'согласно' },
  { id:90336, word:'январь' },
  { id:90337, word:'выполнить' },
  { id:90338, word:'зритель' },
  { id:90339, word:'постепенно' },
  { id:90340, word:'резко' },
  { id:90341, word:'редакция' },
  { id:90342, word:'указать' },
  { id:90343, word:'умный' },
  { id:90344, word:'стиль' },
  { id:90345, word:'весна' },
  { id:90346, word:'северный' },
  { id:90347, word:'фактор' },
  { id:90348, word:'август' },
  { id:90349, word:'известие' },
  { id:90350, word:'зависимость' },
  { id:90351, word:'охрана' },
  { id:90352, word:'ясный' },
  { id:90353, word:'милый' },
  { id:90354, word:'оборудование' },
  { id:90355, word:'светлый' },
  { id:90356, word:'концерт' },
  { id:90357, word:'отделение' },
  { id:90358, word:'расход' },
  { id:90359, word:'редкий' },
  { id:90360, word:'выставка' },
  { id:90361, word:'милиция' },
  { id:90362, word:'верный' },
  { id:90363, word:'вздохнуть' },
  { id:90364, word:'юридический' },
  { id:90365, word:'находить' },
  { id:90366, word:'переход' },
  { id:90367, word:'закончить' },
  { id:90368, word:'эпоха' },
  { id:90369, word:'запад' },
  { id:90370, word:'признать' },
  { id:90371, word:'произведение' },
  { id:90372, word:'родина' },
  { id:90373, word:'собственность' },
  { id:90374, word:'тайна' },
  { id:90375, word:'хотя' },
  { id:90376, word:'четвёртый' },
  { id:90377, word:'административный' },
  { id:90378, word:'трава' },
  { id:90379, word:'дойти' },
  { id:90380, word:'лагерь' },
  { id:90381, word:'во-первых' },
  { id:90382, word:'имущество' },
  { id:90383, word:'кивнуть' },
  { id:90384, word:'кровать' },
  { id:90385, word:'понятно' },
  { id:90386, word:'настолько' },
  { id:90387, word:'обратить' },
  { id:90388, word:'узкий' },
  { id:90389, word:'абсолютно' },
  { id:90390, word:'аппарат' },
  { id:90391, word:'несколько' },
  { id:90392, word:'очевидно' },
  { id:90393, word:'середина' },
  { id:90394, word:'узнавать' },
  { id:90395, word:'встретиться' },
  { id:90396, word:'март' },
  { id:90397, word:'художественный' },
  { id:90398, word:'клиент' },
  { id:90399, word:'лично' },
  { id:90400, word:'дама' },
  { id:90401, word:'обратно' },
  { id:90402, word:'предусмотреть' },
  { id:90403, word:'фронт' },
  { id:90404, word:'древний' },
  { id:90405, word:'отрасль' },
  { id:90406, word:'стул' },
  { id:90407, word:'беседа' },
  { id:90408, word:'массовый' },
  { id:90409, word:'обеспечивать' },
  { id:90410, word:'генеральный' },
  { id:90411, word:'замечательный' },
  { id:90412, word:'хоть' },
  { id:90413, word:'двигаться' },
  { id:90414, word:'законодательство' },
  { id:90415, word:'продажа' },
  { id:90416, word:'повышение' },
  { id:90417, word:'содержать' },
  { id:90418, word:'страшно' },
  { id:90419, word:'прежде' },
  { id:90420, word:'музей' },
  { id:90421, word:'задний' },
  { id:90422, word:'региональный' },
  { id:90423, word:'след' },
  { id:90424, word:'из-под' },
  { id:90425, word:'полковник' },
  { id:90426, word:'приезжать' },
  { id:90427, word:'сомнение' },
  { id:90428, word:'понимание' },
  { id:90429, word:'здоровый' },
  { id:90430, word:'держаться' },
  { id:90431, word:'обеспечить' },
  { id:90432, word:'слегка' },
  { id:90433, word:'апрель' },
  { id:90434, word:'князь' },
  { id:90435, word:'поступить' },
  { id:90436, word:'рыба' },
  { id:90437, word:'привыкнуть' },
  { id:90438, word:'активный' },
  { id:90439, word:'литературный' },
  { id:90440, word:'открывать' },
  { id:90441, word:'собственно' },
  { id:90442, word:'дума' },
  { id:90443, word:'ещё' },
  { id:90444, word:'неужели' },
  { id:90445, word:'кодекс' },
  { id:90446, word:'сутки' },
  { id:90447, word:'чудо' },
  { id:90448, word:'вырасти' },
  { id:90449, word:'шея' },
  { id:90450, word:'зайти' },
  { id:90451, word:'судья' },
  { id:90452, word:'острый' },
  { id:90453, word:'посвятить' },
  { id:90454, word:'стремиться' },
  { id:90455, word:'богатый' },
  { id:90456, word:'крыша' },
  { id:90457, word:'настроение' },
  { id:90458, word:'отсюда' },
  { id:90459, word:'творческий' },
  { id:90460, word:'нечто' },
  { id:90461, word:'поток' },
  { id:90462, word:'мягкий' },
  { id:90463, word:'ночной' },
  { id:90464, word:'должность' },
  { id:90465, word:'преступление' },
  { id:90466, word:'измениться' },
  { id:90467, word:'мозг' },
  { id:90468, word:'налоговый' },
  { id:90469, word:'толстый' },
  { id:90470, word:'честь' },
  { id:90471, word:'пост' },
  { id:90472, word:'удивиться' },
  { id:90473, word:'падать' },
  { id:90474, word:'еврей' },
  { id:90475, word:'звучать' },
  { id:90476, word:'бедный' },
  { id:90477, word:'июнь' },
  { id:90478, word:'много' },
  { id:90479, word:'сотня' },
  { id:90480, word:'верхний' },
  { id:90481, word:'суметь' },
  { id:90482, word:'вечный' },
  { id:90483, word:'восемь' },
  { id:90484, word:'лишний' },
  { id:90485, word:'морской' },
  { id:90486, word:'разработать' },
  { id:90487, word:'нижний' },
  { id:90488, word:'спокойный' },
  { id:90489, word:'поговорить' },
  { id:90490, word:'дождь' },
  { id:90491, word:'меньше' },
  { id:90492, word:'лестница' },
  { id:90493, word:'сухой' },
  { id:90494, word:'ах' },
  { id:90495, word:'лететь' },
  { id:90496, word:'совершить' },
  { id:90497, word:'дача' },
  { id:90498, word:'синий' },
  { id:90499, word:'просто' },
  { id:90500, word:'установка' },
  { id:90501, word:'появление' },
  { id:90502, word:'получение' },
  { id:90503, word:'сегодняшний' },
  { id:90504, word:'строить' },
  { id:90505, word:'образец' },
  { id:90506, word:'труба' },
  { id:90507, word:'главное' },
  { id:90508, word:'кончиться' },
  { id:90509, word:'медицинский' },
  { id:90510, word:'привезти' },
  { id:90511, word:'многие' },
  { id:90512, word:'осень' },
  { id:90513, word:'вряд' },
  { id:90514, word:'сложиться' },
  { id:90515, word:'оставлять' },
  { id:90516, word:'полагать' },
  { id:90517, word:'висеть' },
  { id:90518, word:'костюм' },
  { id:90519, word:'свежий' },
  { id:90520, word:'трудный' },
  { id:90521, word:'уголовный' },
  { id:90522, word:'баба' },
  { id:90523, word:'ценность' },
  { id:90524, word:'обязанность' },
  { id:90525, word:'пьеса' },
  { id:90526, word:'чей' },
  { id:90527, word:'таблица' },
  { id:90528, word:'вино' },
  { id:90529, word:'воспоминание' },
  { id:90530, word:'лошадь' },
  { id:90531, word:'повторить' },
  { id:90532, word:'кто-нибудь' },
  { id:90533, word:'сыграть' },
  { id:90534, word:'коллега' },
  { id:90535, word:'организм' },
  { id:90536, word:'случайно' },
  { id:90537, word:'ученик' },
  { id:90538, word:'учреждение' },
  { id:90539, word:'высоко' },
  { id:90540, word:'открытие' },
  { id:90541, word:'спасти' },
  { id:90542, word:'том' },
  { id:90543, word:'черта' },
  { id:90544, word:'изменить' },
  { id:90545, word:'повторять' },
  { id:90546, word:'характеристика' },
  { id:90547, word:'по-прежнему' },
  { id:90548, word:'явиться' },
  { id:90549, word:'выполнение' },
  { id:90550, word:'оборона' },
  { id:90551, word:'выступление' },
  { id:90552, word:'температура' },
  { id:90553, word:'замечать' },
  { id:90554, word:'перспектива' },
  { id:90555, word:'подать' },
  { id:90556, word:'подруга' },
  { id:90557, word:'приказ' },
  { id:90558, word:'вверх' },
  { id:90559, word:'жертва' },
  { id:90560, word:'назначить' },
  { id:90561, word:'протянуть' },
  { id:90562, word:'ресторан' },
  { id:90563, word:'километр' },
  { id:90564, word:'сохранить' },
  { id:90565, word:'спор' },
  { id:90566, word:'попытаться' },
  { id:90567, word:'расположить' },
  { id:90568, word:'использоваться' },
  { id:90569, word:'вкус' },
  { id:90570, word:'насколько' },
  { id:90571, word:'признак' },
  { id:90572, word:'промышленность' },
  { id:90573, word:'странно' },
  { id:90574, word:'американец' },
  { id:90575, word:'лоб' },
  { id:90576, word:'заключение' },
  { id:90577, word:'вероятно' },
  { id:90578, word:'восток' },
  { id:90579, word:'вроде' },
  { id:90580, word:'исключение' },
  { id:90581, word:'отправиться' },
  { id:90582, word:'жёлтый' },
  { id:90583, word:'молча' },
  { id:90584, word:'ключ' },
  { id:90585, word:'постановление' },
  { id:90586, word:'садиться' },
  { id:90587, word:'же' },
  { id:90588, word:'немедленно' },
  { id:90589, word:'слой' },
  { id:90590, word:'бок' },
  { id:90591, word:'встречать' },
  { id:90592, word:'говориться' },
  { id:90593, word:'крикнуть' },
  { id:90594, word:'спустя' },
  { id:90595, word:'июль' },
  { id:90596, word:'мощный' },
  { id:90597, word:'перевод' },
  { id:90598, word:'секретарь' },
  { id:90599, word:'кусок' },
  { id:90600, word:'готовить' },
  { id:90601, word:'слух' },
  { id:90602, word:'учить' },
  { id:90603, word:'гореть' },
  { id:90604, word:'испытывать' },
  { id:90605, word:'польза' },
  { id:90606, word:'звонок' },
  { id:90607, word:'выделить' },
  { id:90608, word:'обстановка' },
  { id:90609, word:'оттуда' },
  { id:90610, word:'мимо' },
  { id:90611, word:'поддерживать' },
  { id:90612, word:'подниматься' },
  { id:90613, word:'чиновник' },
  { id:90614, word:'следить' },
  { id:90615, word:'соглашение' },
  { id:90616, word:'деталь' },
  { id:90617, word:'русский' },
  { id:90618, word:'деревянный' },
  { id:90619, word:'тишина' },
  { id:90620, word:'зарплата' },
  { id:90621, word:'требоваться' },
  { id:90622, word:'билет' },
  { id:90623, word:'включать' },
  { id:90624, word:'подарок' },
  { id:90625, word:'тюрьма' },
  { id:90626, word:'ящик' },
  { id:90627, word:'конкурс' },
  { id:90628, word:'полезный' },
  { id:90629, word:'книжка' },
  { id:90630, word:'собирать' },
  { id:90631, word:'изучение' },
  { id:90632, word:'дальний' },
  { id:90633, word:'менять' },
  { id:90634, word:'скажем' },
  { id:90635, word:'делаться' },
  { id:90636, word:'просьба' },
  { id:90637, word:'царь' },
  { id:90638, word:'публика' },
  { id:90639, word:'смех' },
  { id:90640, word:'покупать' },
  { id:90641, word:'сообщение' },
  { id:90642, word:'угроза' },
  { id:90643, word:'беда' },
  { id:90644, word:'домашний' },
  { id:90645, word:'должно' },
  { id:90646, word:'предполагать' },
  { id:90647, word:'традиционный' },
  { id:90648, word:'терять' },
  { id:90649, word:'специально' },
  { id:90650, word:'блок' },
  { id:90651, word:'достижение' },
  { id:90652, word:'назначение' },
  { id:90653, word:'реклама' },
  { id:90654, word:'жёсткий' },
  { id:90655, word:'крепкий' },
  { id:90656, word:'портрет' },
  { id:90657, word:'послать' },
  { id:90658, word:'буквально' },
  { id:90659, word:'виноватый' },
  { id:90660, word:'допустить' },
  { id:90661, word:'значительно' },
  { id:90662, word:'масло' },
  { id:90663, word:'культурный' },
  { id:90664, word:'стакан' },
  { id:90665, word:'вокруг' },
  { id:90666, word:'приятный' },
  { id:90667, word:'прочитать' },
  { id:90668, word:'урок' },
  { id:90669, word:'присутствовать' },
  { id:90670, word:'часы' },
  { id:90671, word:'взглянуть' },
  { id:90672, word:'крик' },
  { id:90673, word:'мечтать' },
  { id:90674, word:'подписать' },
  { id:90675, word:'во-вторых' },
  { id:90676, word:'творчество' },
  { id:90677, word:'телевизор' },
  { id:90678, word:'осуществлять' },
  { id:90679, word:'инструмент' },
  { id:90680, word:'поступать' },
  { id:90681, word:'концепция' },
  { id:90682, word:'круглый' },
  { id:90683, word:'лейтенант' },
  { id:90684, word:'экран' },
  { id:90685, word:'дно' },
  { id:90686, word:'реальность' },
  { id:90687, word:'понятный' },
  { id:90688, word:'голубой' },
  { id:90689, word:'канал' },
  { id:90690, word:'удивительный' },
  { id:90691, word:'мясо' },
  { id:90692, word:'знакомый' },
  { id:90693, word:'щека' },
  { id:90694, word:'мужской' },
  { id:90695, word:'о' },
  { id:90696, word:'правовой' },
  { id:90697, word:'конфликт' },
  { id:90698, word:'переговоры' },
  { id:90699, word:'пятьдесят' },
  { id:90700, word:'больной' },
  { id:90701, word:'запись' },
  { id:90702, word:'пора' },
  { id:90703, word:'вагон' },
  { id:90704, word:'готовиться' },
  { id:90705, word:'мёртвый' },
  { id:90706, word:'площадка' },
  { id:90707, word:'последствие' },
  { id:90708, word:'пятый' },
  { id:90709, word:'сотрудничество' },
  { id:90710, word:'б' },
  { id:90711, word:'зеркало' },
  { id:90712, word:'тон' },
  { id:90713, word:'захотеть' },
  { id:90714, word:'совместный' },
  { id:90715, word:'академия' },
  { id:90716, word:'бороться' },
  { id:90717, word:'отдавать' },
  { id:90718, word:'семейный' },
  { id:90719, word:'тяжело' },
  { id:90720, word:'остановить' },
  { id:90721, word:'палата' },
  { id:90722, word:'потребность' },
  { id:90723, word:'приобрести' },
  { id:90724, word:'включить' },
  { id:90725, word:'ноябрь' },
  { id:90726, word:'увеличение' },
  { id:90727, word:'продолжаться' },
  { id:90728, word:'дурак' },
  { id:90729, word:'осторожно' },
  { id:90730, word:'поездка' },
  { id:90731, word:'обед' },
  { id:90732, word:'подтвердить' },
  { id:90733, word:'потеря' },
  { id:90734, word:'природный' },
  { id:90735, word:'спешить' },
  { id:90736, word:'февраль' },
  { id:90737, word:'добиться' },
  { id:90738, word:'мероприятие' },
  { id:90739, word:'парк' },
  { id:90740, word:'принятие' },
  { id:90741, word:'устройство' },
  { id:90742, word:'вещество' },
  { id:90743, word:'впереди' },
  { id:90744, word:'навсегда' },
  { id:90745, word:'отправить' },
  { id:90746, word:'повернуться' },
  { id:90747, word:'категория' },
  { id:90748, word:'православный' },
  { id:90749, word:'сезон' },
  { id:90750, word:'учебный' },
  { id:90751, word:'вставать' },
  { id:90752, word:'подготовить' },
  { id:90753, word:'исходить' },
  { id:90754, word:'гостиница' },
  { id:90755, word:'издание' },
  { id:90756, word:'эффективный' },
  { id:90757, word:'нечего' },
  { id:90758, word:'свидетельствовать' },
  { id:90759, word:'ввести' },
  { id:90760, word:'взяться' },
  { id:90761, word:'объединение' },
  { id:90762, word:'прочесть' },
  { id:90763, word:'темнота' },
  { id:90764, word:'человечество' },
  { id:90765, word:'болеть' },
  { id:90766, word:'вернуть' },
  { id:90767, word:'тянуть' },
  { id:90768, word:'колесо' },
  { id:90769, word:'опасность' },
  { id:90770, word:'продать' },
  { id:90771, word:'разрешение' },
  { id:90772, word:'воздействие' },
  { id:90773, word:'коллектив' },
  { id:90774, word:'камера' },
  { id:90775, word:'внимательно' },
  { id:90776, word:'запас' },
  { id:90777, word:'представляться' },
  { id:90778, word:'следствие' },
  { id:90779, word:'длина' },
  { id:90780, word:'материальный' },
  { id:90781, word:'невозможный' },
  { id:90782, word:'крыло' },
  { id:90783, word:'округ' },
  { id:90784, word:'произвести' },
  { id:90785, word:'фон' },
  { id:90786, word:'кандидат' },
  { id:90787, word:'родственник' },
  { id:90788, word:'полтора' },
  { id:90789, word:'прекрасно' },
  { id:90790, word:'выступить' },
  { id:90791, word:'давление' },
  { id:90792, word:'пахнуть' },
  { id:90793, word:'познакомиться' },
  { id:90794, word:'присутствие' },
  { id:90795, word:'взаимодействие' },
  { id:90796, word:'громко' },
  { id:90797, word:'доска' },
  { id:90798, word:'наступить' },
  { id:90799, word:'партнёр' },
  { id:90800, word:'двигатель' },
  { id:90801, word:'шум' },
  { id:90802, word:'достоинство' },
  { id:90803, word:'предстоять' },
  { id:90804, word:'вдоль' },
  { id:90805, word:'грех' },
  { id:90806, word:'господь' },
  { id:90807, word:'нож' },
  { id:90808, word:'полёт' },
  { id:90809, word:'производить' },
  { id:90810, word:'ранее' },
  { id:90811, word:'страсть' },
  { id:90812, word:'перевести' },
  { id:90813, word:'разрешить' },
  { id:90814, word:'соседний' },
  { id:90815, word:'психологический' },
  { id:90816, word:'испытание' },
  { id:90817, word:'проверить' }
];