// SpeachSynt API
const synth = window.speechSynthesis;
export function speak(text:string) {
    if (synth.speaking) {
      console.error('speechSynthesis.speaking');
      return;
    }
      const utterThis = new SpeechSynthesisUtterance(text);
      utterThis.lang = 'en-US';
      utterThis.pitch = 0.9;
      utterThis.rate = 0.9;
      synth.speak(utterThis);
    
  }