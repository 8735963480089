import React from 'react';
import { isMobile } from 'react-device-detect';
import bannerresultdesk from './desk.webp';
import bannerresultmobile from './mobile.webp';
import style from './Bannerresult.module.css';


const RESULT_MOB_URL = 'https://thevospad.com/g/tqcmqmjujob7f03aadbd86cd98cf0aa58ac8e377/?erid=LatgC5u4W?subid=mobile';
const RESULT_WEB_URL = 'https://thevospad.com/g/tqcmqmjujob7f03aadbd86cd98cf0aa58ac8e377/?erid=LatgC5u4W?subid=desktop'; // Full Result URL

function BannerResult() {
  if (isMobile) {
    return (
      <div className="result-banner-container">
        <a
          className="result-banner-container"
          href={RESULT_MOB_URL}
          target="_blank"
        >
          <img src={bannerresultmobile} alt="тест английского языка" className="result-bannerimage" />
        </a>
      </div>
    );
  }
  return (
    <div className="result-banner-container">
      <a
        className="result-banner-container"
        href={RESULT_WEB_URL}
        target="_blank"
      >
        <img src={bannerresultdesk} alt="тест английского языка" className="result-bannerimage" />
      </a>
    </div>
  );
}

export default BannerResult;
