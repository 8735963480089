import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import About from '../pages/about/about';
import UserAgreemend from '../pages/agreemend/agreemend';
import Hero from '../pages/hero/hero';
import {Result} from '../pages/result/result';
import { Container } from '../features/container/Container';
import Notfound from '../pages/404/notfound';
import Contacts from '../pages/contacts/contacts';
import Attribution from '../pages/attribution/attribution';

//  -- this is example for redirect to unprotected component
// function ProtectedRoute({ children }) {
//   const localtoken = JSON.parse(localStorage.getItem('protected'));
//   if (localtoken) {
//     return <Navigate to="/loginpage" replace />;
//   }

//   return children;
// }
//  --

function WebRouter() {
  return (
    <Routes>
      <Route path="/">
        {/* <Route path="/contentsub" element={<Contenttable />} /> */}
        <Route path="/" element={<Hero />} />
        <Route path="/about" element={<About />} />
        <Route path="/test" element={<Container />} />
        <Route path="/agreemend" element={<UserAgreemend />} />
        <Route path="/result" element={<Result />} />
        <Route path="/contacts" element={<Contacts />} />
        <Route path="/attribution" element={<Attribution />} />
        {/* <Route path="/destricted" element={<ProtectedRoute><DestrictedArea /></ProtectedRoute>} /> */}
      </Route>
      <Route path="/loginpage" />
      <Route path="*" element={<Notfound />} />
    </Routes>
  );
}

export default WebRouter;