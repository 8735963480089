/* eslint-disable @typescript-eslint/no-explicit-any */
import styles from './Container.module.css';
import { useState,createRef,LegacyRef } from 'react';
import { PiPlayCircleThin } from 'react-icons/pi';
import { randomArray,randomRusArray } from './logic/random';
import { speak } from './logic/voicesynt';
import Modal from './Modal';
import { Link } from 'react-router-dom';
import { getChecked } from '../../pages/result/calculateresult';

export function Container() {
    const [modalOpen, setModalOpen] = useState(false);
    const [wordObject, setWordObject] = useState({ id:30001, word:'absolutely',translate:'абсолютно',checked:false,blocked:false });
    const [wordIndex, setWordIndex] = useState(0);

    const [blockState, setBlockState] = useState(randomArray());
    const myRefs:string | any = blockState.map(() => createRef() as LegacyRef<HTMLDivElement> | null);  

    // Update logic for UI CSS change
    const handleSetBlocked = (e:any,i:any) => {
        setBlockState(blockState.map(obj => {
            if (obj.id == e.id) {
                myRefs[i]?.current.classList.add(styles.maincontainer_blocked);
               return {...obj, blocked: true};
            } else {
               return obj;
            }
         }));};

    // Set Checked status
    const handleSetChecked = (e:any,i:any) => {
        setBlockState(blockState.map(obj => {
            if (obj.id == e.id) {
                return {...obj, checked: !obj.checked};
            } else {
                return obj;
            }
        }));};

    // Open modal if condition check
    const handleSetWordCheck = (e:any,i:any) => {
        setModalOpen(false);
        const lastDigit = e.id % 10;
        if (lastDigit <= 5) {
            handleSetChecked(e, i),
            setModalOpen(true),
            setWordObject(e),
            setWordIndex(i);
        }
        else {
            handleSetChecked(e, i);
        }
    };
    const data = getChecked(blockState);
  return (
    <>
    <Modal
        handleSetChecked={()=>handleSetBlocked(wordObject, wordIndex)}
        hasCloseBtn={true}
        isOpen={modalOpen}
        wordObject={wordObject}
        falseWord={randomRusArray()}
        >
    </Modal>

          <div className={styles.main}>
          <div className={styles.textheaderblock}>
          <div className={styles.textheader}>
          Отметьте слова, у которых вы знаете как минимум один перевод.
          </div>
          <div className={styles.textheader}> 
          Вы можете узнать перевод слова, нажав на кнопку.
          При этом слово будет отмечено и не попадет в результат.  
          </div>
          <div className={styles.textheader}> 
          Отвечайте честно, мы иногда будем вас проверять
          </div>
          </div>
              {blockState.map((e:any, i:any) => <div key={e.id}
                  ref={myRefs[i] as LegacyRef<HTMLDivElement> | undefined}
                  className={e?.checked ? styles.maincontainer_good : styles.maincontainer}>

                  <label className={styles.container}>
                      <input
                          type="checkbox"
                          onChange={() => {handleSetWordCheck(e,i);}
                            }
                          disabled={e?.blocked ? true : false} />
                      <span className={(e?.checked && e?.blocked) ? styles.checkmarkwrong : styles.checkmark}></span>
                  </label>

                  <div className={styles.wordcontainer}>
                      <div className={styles.englishword}>
                          {e?.blocked ?
                          <div className={styles.englishwordblocked}>
                           <div className={styles.blockedtranslate}>{e.translate.toLowerCase()}</div>
                           <div className={styles.blockedword}>{e.word.toLowerCase()}</div>
                           </div> : e.word.toLowerCase()}
                          {/* {e?.blocked ? e.word: e.translate}  TODO this is for reverse translate*/}
                      </div>
                      <div className={styles.playsound_container}>
                          <PiPlayCircleThin id="circleplayicon"
                              className={styles.playsound}
                              onClick={() => speak(e.word)} />
                          <label
                              className={styles.playsound_text}
                              // ref={mainref}
                              htmlFor="circleplayicon"
                          ></label>
                      </div>
                  </div>
                  <button
                      className={e?.blocked || e?.checked ? styles.translatebutton_disabled : styles.translatebutton}
                      // onClick={()=>setBlockState(!blockState)}
                      // onClick={()=>myRefs[i].current.classList.add(styles.maincontainer_blocked)}
                      onClick={() => handleSetBlocked(e, i)}
                      disabled={e?.blocked ? true : false || e?.checked ? true : false}
                      type="button"
                      id={e.word}>узнать перевод</button>
              </div>
              )}
          </div>
          <div className={styles.resultbuttonblock}>
          <Link to={{
       pathname: '/result',
       search: data.getDataforUrl(),
    //    hash: '#the-hash',
     }} state={{ state: blockState }} className={styles.resultbutton}>
          ПОЛУЧИТЬ РЕЗУЛЬТАТ
          </Link>
        </div>
          </>
  );
}
