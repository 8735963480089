import React from 'react';
import styles from './notfound.module.css';
import image from './notfound.jpg';
import { Link } from 'react-router-dom';

export default function Notfound() {
  return (
<div className={styles.main}>
      <div className={styles.error}>
        <div className={styles.text}>Упс! Страница не найдена :(</div>
        <Link to="/" className={styles.button}>
        <div>ПЕРЕЙТИ НА ГЛАВНУЮ</div>
        </Link>
        <img className={styles.image} src={image} alt="Тест словарного запаса английского языкa"/>
        <h1>error 404</h1>
      </div>
</div>
    
  );
}
