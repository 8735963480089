import { useSearchParams } from 'react-router-dom';

import Urlresult from './urlresult';
import CommonResult from './commonresult';
   
export function Result() {
  const [queryParameters] = useSearchParams();
    if (queryParameters.size !== 0)
    return ( <Urlresult queryParameters = {queryParameters}/> );
    return ( <CommonResult/>);
}