import { BrowserRouter } from 'react-router-dom';
import Layout from './pages/layout/layout';

function App() {
  return (
    <BrowserRouter>
    <Layout>
    </Layout>
    </BrowserRouter>
  );
}

export default App;
