import React from 'react';
import styles from './contacts.module.css';

const onSubmit = (e: { preventDefault: () => void; }) => {
    e.preventDefault();};

export default function Contacts() {
  return (
    <div className={styles.formblock}>
      <div className="email-form">
        <h3>Напишите нам</h3>
        <div className="email-form-text">По вопросам контента, сотрудничества, найденных ошибок и любым другим.</div>
        <form className={styles.mainform} onSubmit={onSubmit}>
          <input
            className={styles.nameinput}
            id="outlined-required"
            type="text"
            name="from_name"
            placeholder="Ваше имя"
            required
          />
          <textarea
            className={styles.textinput}
            id="outlined-required"
            name="message"
            placeholder="Текст сообщения"
            required
          />
          <input
            className={styles.emailinput}
            id="outlined-required"
            type="email"
            name="reply_to"
            placeholder="Ваша почта"
            required
          />
          <button type="submit" className={styles.sendbutton} >
            ОТПРАВИТЬ
          </button>
        </form>
      </div>
    </div>
  );
}
