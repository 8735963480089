import styles from './herosecond.module.css';
import animatedstyles from './animated.module.css';
import { Link } from 'react-router-dom';
import screens from './screens.webp';


function HeroSecond() {
  return (
    <div className={animatedstyles.heromainsecond}>
        <div className={styles.leftblock}>
        {/* <span className={styles.h1text}><span className={styles.h1textunderline}>Тест</span> словарного запаса</span> */}
        <h1 className={styles.h1text}> Как работает проверка словарного запаса английского языка</h1>
        <span className={styles.h2text}>Тестирование в своей основе использует Oxford Advanced Learner's Dictionary,
        и расширенный список основных слов Oxford 5000.
        </span>
        <span className={styles.h2text}>
        Это 5000 самых важных и полезных английских слов, которые нужно выучить в первую очередь.
        </span>
        <span className={styles.h2text}>
        <h1 className={styles.h1text}>Как увеличить словарный запас английского языка?</h1>

        Тест при старте подберет 80 английских слов из разных тематических направлений и категорий сложности.
        </span>
        <span className={styles.h2text}>
        Каждый раз вы будете получать уникальный набор для проверки ваших знаний и изучения новых слов.
        </span>
        <span className={styles.h2text}>
        Данного уровня знаний достаточно для понимания 80-90% текстов на общую тематику.
        </span>
        <div className={styles.rightblock_mobile}>
        <img className={styles.rightimage} src={screens} alt="Тест словарного запаса английского языкa"  />
        </div>
        </div>
        <div className={styles.rightblock}>
        <img className={styles.rightimage} src={screens} alt="Тест словарного запаса английского языкa"  />
        </div>
    </div>
  );
}

export default HeroSecond;