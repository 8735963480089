import { Line, Circle } from 'rc-progress';
import styles from './result.module.css';
import { useLocation } from 'react-router-dom';
import ShareBlock from '../../features/shareblock/Shareblock';
import toast, { Toaster } from 'react-hot-toast';
import BannerResult from '../../features/banner/Bannerresult';

function Urlresult({ queryParameters }:any) {
  const location = useLocation();
  const state = location?.state?.state || [];

  const url = window.location.href;

  const result = queryParameters.get('r');
  const a1Percent = queryParameters.get('a1');
  const a2Percent = queryParameters.get('a2');
  const b1Percent = queryParameters.get('b1');
  const b2Percent = queryParameters.get('b2');
  const c1Percent = queryParameters.get('c1');
  const c2Percent = queryParameters.get('c2');
  const allPercent = queryParameters.get('all');


  const notify = () => toast.success('Ссылка скопирована! Можете поделиться ей с кем угодно!');
  return (
    <div className={styles.result}>
    <div className={styles.textheader}>{'Отлично! Вот ваш результат!'}</div>
    <div className={styles.text1}>{'Ваш примерный словарный запас'}</div>
    <div className={styles.text2block}>
      <div className={styles.text2}>{`${result}`}</div>
    </div>
    <div className={styles.text1}>{'Ваш примерный уровень языка по стандарту CEFR'}</div>
    <div className={styles.resultcontainer}>
        <div className={styles.circlecontainer}>
        <div className={styles.circleContaineritem}>
        <div className={styles.circletext}>A1</div>
        <div className={styles.circletext2}>{`${a1Percent}`}%</div>
        <div className={styles.circletext3}>Beginner</div>
            <Circle
            percent={a1Percent}
            strokeWidth={6}
            strokeLinecap="round"
            strokeColor={{
                '0%': '#108ee9',
                '100%': '#87d068',
            }}
            />
        </div>
        <div className={styles.circleContaineritem}>
        <div className={styles.circletext}>A2</div>
        <div className={styles.circletext2}>{`${a2Percent}`}%</div>
        <div className={styles.circletext3}>Elementary</div>
        <Circle
          percent={a2Percent}
          strokeWidth={6}
          strokeLinecap="round"
          strokeColor={{
            '0%': '#108ee9',
            '100%': '#87d068',
          }}
        />
      </div>
      <div className={styles.circleContaineritem}>
      <div className={styles.circletext}>B1</div>
      <div className={styles.circletext2}>{`${b1Percent}`}%</div>
      <div className={styles.circletext3}>Intermediate</div>
        <Circle
          percent={b1Percent}
          strokeWidth={6}
          strokeLinecap="round"
          strokeColor={{
            '0%': '#108ee9',
            '100%': '#87d068',
          }}
        />
      </div>
      <div className={styles.circleContaineritem}>
      <div className={styles.circletext}>B2</div>
      <div className={styles.circletext2}>{`${b2Percent}`}%</div>
      <div className={styles.circletext3}>Upper Intermediate</div>
        <Circle
          percent={b2Percent}
          strokeWidth={6}
          strokeLinecap="round"
          strokeColor={{
            '0%': '#108ee9',
            '100%': '#87d068',
          }}
        />
      </div>
      <div className={styles.circleContaineritem}>
      <div className={styles.circletext}>C1</div>
      <div className={styles.circletext2}>{`${c1Percent}`}%</div>
      <div className={styles.circletext3}>Advanced</div>
        <Circle
          percent={c1Percent}
          strokeWidth={6}
          strokeLinecap="round"
          strokeColor={{
            '0%': '#108ee9',
            '100%': '#87d068',
          }}
        />
      </div>
      <div className={styles.circleContaineritem}>
      <div className={styles.circletext}>C2</div>
      <div className={styles.circletext2}>{`${c2Percent}`}%</div>
      <div className={styles.circletext3}>Proficiency</div>
        <Circle
          percent={c2Percent}
          strokeWidth={6}
          strokeLinecap="round"
          strokeColor={{
            '0%': '#108ee9',
            '100%': '#87d068',
          }}
        />
      </div>
      </div>
      <div className={styles.lineContainerStyle}>
      <Line percent={allPercent} strokeWidth={2} strokeColor={'#87d068'} />
      </div>
    </div>
    <div className={styles.shareblock}>
        <ShareBlock />
        <button
        type="button"
        className={styles.shareblockbutton}
        onClick={() => {
            navigator.clipboard.writeText(url);
            notify();
          }}
        >СОХРАНИТЬ ССЫЛКУ</button>
    </div>
    <BannerResult />
    {(state == !![] )&& <div>Слова для изучения. </div>}
    <div className={styles.testblock}>
    {state.map((e:any, i:any) => e?.blocked == true && <div key={e.id} className={styles.wordblock} >
      <div className={styles.wordcontainer}>
          <div className={styles.translate}>{e.translate.toLowerCase()}</div>
          <div className={styles.word}>{e.word.toLowerCase()}</div>
      </div>
    </div>
    )}</div>
        <Toaster 
        position="bottom-center"/>
    </div>
  );
}

export default Urlresult;