import React from 'react';
import styles from './attribution.module.css';
import licimg from './lic.webp';
import { Link } from 'react-router-dom';

export default function Attribution() {
  return (
    <main className={styles.attmain}>
        <article className={styles.attarticle}>
          <h2 className={styles.atth2}>АТРИБУЦИЯ</h2>
          <br />
          <div className={styles.textfirst}>
            Часть контента представлена freepik.com по Free for commercial use WITH ATTRIBUTION license.
          </div>
          <span className={styles.textmiddle}>Пример лицензии:</span>
          <div className={styles.attimageblock}>
            <img src={licimg} alt="тест английского языка" className={styles.attimage}/>
          </div>
          <div className={styles.textsecond}>
            Если Контент нарушает ваши авторские права, то,
            пожалуйста, сообщите нам об этом, используя <a href = "mailto: at@engdom.ru">at@engdom.ru</a> или
            {' '}
            {' '}
            <Link
              to="/contacts"
            >
              <b>ФОРМУ ОБРАТНОЙ СВЯЗИ</b>
            </Link>
          </div>
        </article>
    </main>
  );
}