import React, { useRef, useEffect, useState, ReactNode } from 'react';
import styles from './Modal.module.css';

interface ModalProps {
  handleSetChecked:any|object;
  wordObject: any|object;
  falseWord: string[];
  isOpen: boolean;
  hasCloseBtn?: boolean;
  onClose?: () => void;
  children?: React.ReactNode;
}

const Modal: React.FC<ModalProps> = ({
  handleSetChecked,
  wordObject,
  falseWord,
  isOpen,
  // hasCloseBtn = true,
  // onClose
}) => {
  const [isModalOpen, setModalOpen] = useState(isOpen);
  const modalRef = useRef<HTMLDialogElement | null>(null);

  // const handleCloseModal = () => {
  //   if (onClose) {
  //     onClose();
  //   }
  //   setModalOpen(false);
  // };


  useEffect(() => {
    setModalOpen(isOpen);
  }, [isOpen]);

  useEffect(() => {
    const modalElement = modalRef.current;

    if (modalElement) {
      if (isModalOpen) {
        modalElement.showModal();
      } else {
        modalElement.close();
      }
    }
  }, [isModalOpen]);

  function isWrongAnswer() {
    handleSetChecked();
    setModalOpen(false);
  }
  function randomBlock(){
    const divArray:any[] = [<button
      onClick={()=>setModalOpen(false)}
      className={styles.wordbutton}>{wordObject?.translate.toUpperCase()}
      </button>,
      <button onClick={()=>isWrongAnswer()} className={styles.wordbutton}>{falseWord[0]}</button>,
      <button onClick={()=>isWrongAnswer()} className={styles.wordbutton}>{falseWord[1]}</button>,
      <button onClick={()=>isWrongAnswer()} className={styles.wordbutton}>{falseWord[2]}</button>,
      <button onClick={()=>isWrongAnswer()} className={styles.wordbutton}>{falseWord[3]}</button>];

      const result = divArray
      .map(value => ({ value, sort: Math.random() }))
      .sort((a, b) => a.sort - b.sort)
      .map(({ value }) => value);
    return result;
  }
  return (
    <dialog
    ref={modalRef}
    className={styles.modal}>
        <div className={styles.headertext}>ВЫБЕРИТЕ ВЕРНЫЙ ПЕРЕВОД СЛОВА </div>
        <div className={styles.wordtext}>{wordObject?.word}</div>
      <div >
      {randomBlock().map((e:any,i:any) =>
        <div key={i} className={styles.wordbuttonblock}>
          {e}
        </div>)}
      </div>
    </dialog>
  );
};

export default Modal;