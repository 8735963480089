import React from 'react';
import Header from '../../components/header/header';
import WebRouter from '../../routing/webrouter';
import styles from './layout.module.css';
import Footer from '../../components/footer/footer';

function Layout({children}:any) {
  return (
    <div className={styles.app}>
      <Header />
      {children}
      <WebRouter />
      {/* <Footer /> */}

    </div>
  );
}
export default Layout;