import { useClickAway } from 'react-use';
import { useRef } from 'react';
import { useState } from 'react';
import { Squash as Hamburger } from 'hamburger-react';
import { AnimatePresence, motion } from 'framer-motion';
import styles from './burgermenu.module.css';
import { routes } from './routes';
import { Link } from 'react-router-dom';


export function BurgerMenu () {
  const [isOpen, setOpen] = useState(false);
  const ref = useRef(null);
  useClickAway(ref, () => setOpen(false));
  return (
    <div ref={ref} className={styles.burgermenublock}>
      <Hamburger toggled={isOpen} size={20} toggle={setOpen} />
      <AnimatePresence>
        {isOpen && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.2 }}
            className={styles.burgermenu}
          >
            <ul className={styles.ul}>
              {routes.map((route, idx) => {
                const { Icon } = route;
                return (
                  <motion.li
                    initial={{ scale: 0, opacity: 0 }}
                    animate={{ scale: 1, opacity: 1 }}
                    transition={{
                      type:'spring',
                      stiffness: 260,
                      damping: 20,
                      delay: 0.1 + idx / 10,
                    }}
                    key={route.title}
                    className={styles.li}
                  >
                    <Link
                      onClick={() => setOpen((prev) => !prev)}
                      className={styles.a}
                      to={route.href}
                      >
                      <span className={styles.span}>
                      <Icon className={styles.icon} />
                        {route.title}</span>
                    </Link>
                  </motion.li>
                );
              })}
            </ul>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}