import { a1,a2,b1,b2,c1,c2,rus1 } from '../../../data/data';

// Makes random words new array

export function randomArray(){
    const result: any[] = [];
    function randomIntFromInterval(min: number, max: number) {
    return Math.floor(Math.random() * (max - min + 1) + min);
    }

// This 5 cycles is for original words position
 for(let i=0;i<15;i++){
    const rndInt1 = randomIntFromInterval(1, a1.length-1);
    result.push(a1[rndInt1]);
 }
 for(let i=0;i<15;i++){
    const rndInt2 = randomIntFromInterval(1, a2.length-1);
    result.push(a2[rndInt2]);
 }
 for(let i=0;i<15;i++){
    const rndInt3 = randomIntFromInterval(1, b1.length-1);
    result.push(b1[rndInt3]);
 }
 for(let i=0;i<15;i++){
    const rndInt4 = randomIntFromInterval(1, b2.length-1);
    result.push(b2[rndInt4]);
 }
 for(let i=0;i<15;i++){
    const rndInt5 = randomIntFromInterval(1, c1.length-1);
    result.push(c1[rndInt5]);
 }
 for(let i=0;i<10;i++){
   const rndInt5 = randomIntFromInterval(1, c2.length-1);
   result.push(c2[rndInt5]);
}

 // Filter array from repeat id
 const filteredArray:any[] = [];
 result.filter((item) => {
   if (!filteredArray.some((element) => element.id === item.id)) {
     filteredArray.push(item);
   }
 });

 return filteredArray;
}


// Makes random array or Russian words to modal check

export function randomRusArray(){
   const result: string[] = [];
   function randomIntFromInterval(min: number, max: number) {
   return Math.floor(Math.random() * (max - min + 1) + min);
   }

for(let i=0;i<4;i++){
   const rndInt1 = randomIntFromInterval(1, rus1.length-1);
   result.push(rus1[rndInt1].word.toUpperCase());
}
return result;
}