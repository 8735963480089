import {
    MailruIcon,
    MailruShareButton,
    OKIcon,
    OKShareButton,
    TelegramIcon,
    TelegramShareButton,
    VKIcon,
    VKShareButton,
    WhatsappIcon,
    WhatsappShareButton,
  } from 'react-share';

  import style from './Shareblock.module.css';
  
  function ShareBlock() {
    const shareUrl = window.location.href;
    const title = 'Тест словарного запаса английского языка - У вас отличный результат!';
  
    return (
      <div className={style.mainshareblock}>
        <div className={style.shareblock}>
          <div className={style.mainsharedivbutton}>
            <TelegramShareButton
              aria-label="TelegramShareButton"
              url={shareUrl}
              title={title}
            >
              <TelegramIcon size={32} round />
            </TelegramShareButton>
          </div>
  
          <div className={style.mainsharedivbutton}>
            <WhatsappShareButton
              aria-label="WhatsappShareButton"
              url={shareUrl}
              title={title}
              separator=":: "
            >
            <WhatsappIcon size={32} round />
            </WhatsappShareButton>
          </div>
  
          <div className={style.mainsharedivbutton}>
            <VKShareButton
              aria-label="VKShareButton"
              url={shareUrl}
                    //   image={`${String(window.location)}/src/${image}`}
              image="https://engdom.ru/englishgirl.jpg"
            >
            <VKIcon size={32} round />
            </VKShareButton>
          </div>
  
          <div className={style.mainsharedivbutton}>
            <OKShareButton
              aria-label="OKShareButton"
              url={shareUrl}
              image="https://engdom.ru/englishgirl.jpg"
            >
            <OKIcon size={32} round />
            </OKShareButton>
          </div>
  
          <div className={style.mainsharedivbutton}>
            <MailruShareButton
              aria-label="MailruShareButton"
              url={shareUrl}
              title={title}
            >
            <MailruIcon size={32} round />
            </MailruShareButton>
          </div>
        </div>
      </div>
    );
  }
  
  export default ShareBlock;
  