import { TfiHome } from 'react-icons/tfi';
import { TfiClipboard } from 'react-icons/tfi';
import { TfiEmail } from 'react-icons/tfi';
import { TfiLayoutCtaBtnRight } from 'react-icons/tfi';
import { TfiInfoAlt } from 'react-icons/tfi';

export const routes = [
  {
    title: 'Главная страница',
    href: '/',
    Icon: TfiHome,
  },
  {
    title: 'Информация о тесте',
    href: '/about',
    Icon: TfiInfoAlt,
  },
  {
    title: 'Атрибуция',
    href: '/attribution',
    Icon: TfiLayoutCtaBtnRight,
  },
  {
    title: 'Соглашение',
    href: '/agreemend',
    Icon: TfiClipboard,
  },
  {
    title: 'Контакты',
    href: '/contacts',
    Icon: TfiEmail,
  },
];