import React from 'react';
import cefr from './cefr.webp';
import girl1 from './girl1.webp';
import widegirl from './widegirl.webp';
import styles from './about.module.css';
import { Link } from 'react-router-dom';

export default function About() {
  return (
    <div className={styles.maincontainer}>
    <h1 className={styles.h1}>
      Что вам нужно знать о методике тестирования 
    </h1>
    <div className={styles.text}>
    <span className={styles.span}>Перед началом изучения английского языка, нужно обязательно выбрать точку старта, определив ваш текущий уровень знаний.</span>
    <div className={styles.girlimgblock}>
      <img src={girl1} className={styles.girlimg}/>
    </div>
     <span className={styles.span}>Зная, насколько хорошо вы владеете иностранным языком, можно разработать эффективную программу обучения и следовать заявленным срокам с комфортом.
     <span className={styles.span}>
     Знание английского включает знание грамматики, умение говорить, читать и писать, а также способность воспринимать речь на слух и увеличивать словарный запас.</span>
     </span>
     <span className={styles.span}>На каждом уровне предполагается определенный объем знаний, который вы должны освоить. </span>
    </div>
    <h2>Какие уровни английского существуют? Что такое CEFR?</h2>
    <div className={styles.text}>
    <span className={styles.span}>
    Для систематизации образовательного процесса создана общеевропейская шкала CEFR (Common European Framework of Reference),
     которая постепенно становится мировым стандартом.
     <span className={styles.span}>
     Согласно данной классификации,
     языковая подготовка оценивается по следующим уровням владения: A1, A2, B1, B2, C1, C2.
     </span>
     </span>
     <span className={styles.span}>
    Результат теста словарного запаса ENGDOM формируется исходя из результатов обработки по данным уровням классификации.  
    </span>
    <div>
      <img src={cefr} className={styles.cefrimg}/>
    </div>
    <span className={styles.span}>
     CEFR - это система оценки уровня владения иностранным языком и понимания на нем.
     <span className={styles.span}>
     Кроме того, существуют и другие стандарты, такие как ACTFL (American Council on the Teaching of Foreign Languages),
     CLB (Canadian Language Benchmarks) и ILR (Interagency Language Roundtable),
     которые определяют уровень знаний языка с различных точек зрения.
     </span>
     </span>
    <span className={styles.span}>
     Система CEFR не связана с конкретным языковым тестом.
     <span className={styles.span}>
     Это общеевропейская система, специально разработанная для глобального тестирования,
     поэтому можно использовать ее для оценки ваших знаний английского, немецкого и других, не менее популярных языков.
     </span>
    </span>
    <h2>Английская лексика по уровням CEFR</h2>
    <div className={styles.cefrtable}>
    <div className={styles.tablecell}>
      <div className={styles.tableup}>А1 Beginner (начальный) 50-400 слов</div>
      <div className={styles.tabledown}>Моя семья, мой день, мои привычки, мои друзья, мои вкусовые предпочтения, покупки в магазине.</div>
    </div>
    <div className={styles.tablecell}>
    <div className={styles.tableup}>А1 Elementary (выше начального)	400-1200 слов</div>
    <div className={styles.tabledown}>Мой дом, мои развлечения и планы, рецепты, мое образование, мои любимые книги и фильмы.</div>
    </div>
    <div className={styles.tablecell}>
    <div className={styles.tableup}>A2 Pre-Intermediate (средний начальный)	1200-2000 слов</div>
    <div className={styles.tabledown}>Моя профессия и рабочие обязанности, моя биография, мои взгляды и мнения, простая история из жизни.</div>
      </div>
    <div className={styles.tablecell}>
      <div className={styles.tableup}>B1 Intermediate (средний)	2000-3000 слов</div>
      <div className={styles.tabledown}>Темы, посвящённые бизнесу и специальным направлениям, бытовые и рабочие обсуждения,информационные технологии</div>
     </div>
     <div className={styles.tablecell}>
      <div className={styles.tableup}>B2 Upper-Intermediate (высокий Средний)	3000-4000 слов</div>
      <div className={styles.tabledown}>Человек может общаться на любую тему без затруднений.</div>
      </div>
     <div className={styles.tablecell}>
      <div className={styles.tableup}>C1 Advanced(продвинутый)	4000-5000 слов</div>
      <div className={styles.tabledown}>Человек может общаться на любую тему без затруднений.</div>
      </div>
     <div className={styles.tablecell}>
     <div className={styles.tableup}>C2 Procient / Mastery (профессиональный)	5000+	слов</div>
      <div className={styles.tabledown}>Человек может общаться на любую тему без затруднений, в том числе, и на узкопрофильную</div>
     </div>
    </div>
    <h2>
    Размер словарного запаса: сколько английских слов нужно знать для свободного владения языком
    </h2>
    <span className={styles.span}>
    Оксфордский словарь (англ. Oxford English Dictionary, OED) - главный исторический словарь английского языка,
     выпускаемый издательством Оксфордского университета.
     <span className={styles.span}>
    Лексикон Оксфордского словаря включает все слова в английском литературном и разговорном языках начиная с 1150 года. 
    Оксфордский словарь английского языка содержит 172 000 общеупотребительных слов. Это очень много.
    </span>
    <span className={styles.span}>
     Так сколько же слов вам нужно знать, что бы свободно общаться?
     </span>
    </span>
    <span className={styles.span}>Взрослый носитель английского языка в среднем обладает активным словарным запасом около 22000 слов.</span>
    <span className={styles.span}>
    Чтобы нам было немного легче осваивать язык, составители «Оксфордского словаря английского языка» собрали ТОП-3000 самых важных слов,
     которые нужно выучить в первую очередь — The Oxford 3000.
    </span>
    <div>
      <img src={widegirl} className={styles.widegirl}/>
    </div>
    <span className={styles.span}>
    Oxford 3000 — это список из 3000 основных слов, которые должен знать каждый изучающий английский язык.
    <span className={styles.span}>
    Слова были включены в список на основе их частотности и актуальности для изучающих.
    </span>
    Каждое слово из Oxford 3000 соответствует CEFR примерно на уровне A1–B2.
    </span>
    <span className={styles.span}>
    Для более углубленного изучения и тестирования рекомендуется использовать расширенный список Oxford 5000.
    <span className={styles.span}>
    Помимо основного списка слов, он включает в себя дополнительные 2000 слов, соответствующих CEFR на уровне B2-C1, что позволит
    ролучить знания более высокого уровня.
    </span>
     </span>
     <div className={styles.infoheader}>
      А вы знали?
     </div>
     <span className={styles.span}>
      <ul className={styles.infocontainer}>
  <li className={styles.info}>"Большинство взрослых носителей языка, прошедших тест, обладают общим словарным запасом в 20000—30000 слов"</li>
  <li className={styles.info}>"В возрасте 6 лет носитель английского знает примерно 5000 слов, а в возрасте 10 лет 10000 слов"</li>
  <li className={styles.info}>"Для людей, у которых английский язык не является родным, cредний размер словарного запаса составил 5000 слов"</li>
  <li className={styles.info}>"Проживание в англоязычной стране помогает расширить словарный запас до 10000 и более слов"</li>
  <li className={styles.info}>"Иностранцы узнают и усваивают около 2,5 новых слова в день, находясь в англоязычной стране"</li>
    </ul>
     </span>
     <span className={styles.span}>
     Несмотря на то, что активный словарный запас носителя английского языка примерно 22000 слов,
      а пассивный в пределах 42000, обучение английскому языку будет проходить быстрее и приносить вам удовольствие,
      если вы будете регулярно проходить тест и учить новые слова!
      </span>
      <span className={styles.span}>
     Вам станут понятны 90% текстов общей тематики, а остальное придет во время дальнейшего обучения. 
     </span>
    </div>
    <Link to="/test" className={styles.button} onClick={()=>window.scrollTo(0, 0)}>
        <div>НАЧАТЬ ТЕСТИРОВАНИЕ</div>
        </Link>
    </div>
  );
}
