import React, { useState } from 'react';
import styles from './hero.module.css';
import animatedstyles from './animated.module.css';
import { Link } from 'react-router-dom';
import girl20 from './girl20.webp';
import girl21 from './girl21.webp';
import usericons from './icons.jpg';
import logo1 from './logos/1.webp';
import logo2 from './logos/2.webp';
import logo3 from './logos/3.webp';
import logo4 from './logos/4.webp';
import logo5 from './logos/5.webp';
import logo6 from './logos/6.webp';
import logo8 from './logos/8.webp';
import HeroSecond from './herosecond';


// fix TS error in fetchpriority IMG block
declare module 'react' {
  interface ImgHTMLAttributes<T> extends HTMLAttributes<T> {
    fetchPriority?: 'high' | 'low' | 'auto';
  }
}

function Hero() {
  const [randomLogo] = useState(Math.random()<0.5?0:1);
  return (
    // <div className={animatedstyles.heromain}>
    <main className={styles.container}>
    <div className={animatedstyles.heromain}>
        <div className={animatedstyles.li}></div>
        <div className={animatedstyles.li}></div>
        <div className={animatedstyles.li}></div>
        <div className={animatedstyles.li}></div>
        <div className={animatedstyles.li}></div>
        <div className={animatedstyles.li}></div>
        <div className={animatedstyles.li}></div>
        <div className={animatedstyles.li}></div>
        <div className={animatedstyles.li}></div>
        <div className={animatedstyles.li}></div>
        <div className={styles.leftblock}>
        <h1 className={styles.h1text}><span className={styles.h1textunderline}>Тест</span> словарного запаса
        <span className={styles.h1text}> английского языка</span></h1>
        <h2 className={styles.h2text}>
        Наш бесплатный тренажер создан для онлайн оценки лексического запаса английского языка.
        </h2>
        <span className={styles.h2text}>
        Тестирование поможет вам учить новые английские слова и продуктивнее готовиться к любому экзамену.
        </span>
        <span className={styles.h2text}>
        После прохождения вы узнаете ваш словарный запас, и приблизительный уровень владения языком по стандарту CEFR.
        </span>
        <div className={styles.rightblock_mobile}>
        {randomLogo?<img className={styles.rightimage} src={girl21} alt="Тест словарного запаса английского языкa"  />
        :<img className={styles.rightimage} src={girl20} alt="Тест словарного запаса английского языкa" />}
        </div>
        <div className={styles.buttonblock}>
        <Link to="/test" className={styles.button} onClick={()=>window.scrollTo(0, 0)}>
        <div>НАЧИНАЕМ</div>
        </Link>
        <div className={styles.peoplesblock}>
          <img className={styles.peoplesimage} src={usericons} alt="Тест словарного запаса английского языкa" />
          <div className={styles.peopletext}> + 11 тестов сегодня</div>
        </div>
        </div>

        </div>
        <div className={styles.rightblock}>
        {randomLogo?<img className={styles.rightimage} src={girl21} alt="Тест словарного запаса английского языкa" />
        :<img className={styles.rightimage} src={girl20} alt="Тест словарного запаса английского языкa" />}
        </div>
        <HeroSecond />
    </div>
    <div className={styles.logoblock}>
    <img className={styles.logoimage} src={logo1} alt="Cambridge logo" />
    <img className={styles.logoimage} src={logo2} alt="ELC logo" />
    <img className={styles.logoimage} src={logo3} alt="ETS logo" />
    <img className={styles.logoimage} src={logo4} alt="FCE logo" />
    <img className={styles.logoimage} src={logo5} alt="ILTS logo" />
    <img className={styles.logoimage} src={logo6} alt="IELTS logo" />
    <img className={styles.logoimage} src={logo8} alt="Oxford logo" />
    </div>
    </main>
  );
}

export default Hero;