export function getChecked(blockState: any[]) {
    const WORDS = 4660;
    let a1Checked = 0;
    let a1Blocked = 0;
    let a1Wrong = 0;
   
    let a2Checked = 0;
    let a2Blocked = 0;
    let a2Wrong = 0;
   
    let b1Checked = 0;
    let b1Blocked = 0;
    let b1Wrong = 0;
   
    let b2Checked = 0;
    let b2Blocked = 0;
    let b2Wrong = 0;
   
    let c1Checked = 0;
    let c1Blocked = 0;
    let c1Wrong = 0;
   
    let c2Checked = 0;
    let c2Blocked = 0;
    let c2Wrong = 0;
   
    for (let i=0;i<blockState.length;i++)
    {
       const checked = blockState[i].checked;
       const blocked = blockState[i].blocked;
       const id = blockState[i].id;
   
       const a1true = (id >= 10000 && id <= 20000);
       const a2true = (id >= 20000 && id <= 30000);
       const b1true = (id >= 30000 && id <= 40000);
       const b2true = (id >= 40000 && id <= 50000);
       const c1true = (id >= 50000 && id <= 60000);
       const c2true = (id >= 60000 && id <= 70000);
   
       if(checked && (a1true)) a1Checked=a1Checked+1;
       if(blocked && (a1true)) a1Blocked=a1Blocked+1;
       if(blocked && checked && (a1true)) a1Wrong=a1Wrong+1;
   
       if(checked && (a2true)) a2Checked=a2Checked+1;
       if(blocked && (a2true)) a2Blocked=a2Blocked+1;
       if(blocked && checked && (a2true)) a2Wrong=a2Wrong+1;
   
       if(checked && (b1true)) b1Checked=b1Checked+1;
       if(blocked && (b1true)) b1Blocked=b1Blocked+1;
       if(blocked && checked && (b1true)) b1Wrong=b1Wrong+1;
   
       if(checked && (b2true)) b2Checked=b2Checked+1;
       if(blocked && (b2true)) b2Blocked=b2Blocked+1;
       if(blocked && checked && (b2true)) b2Wrong=b2Wrong+1;
   
       if(checked && (c1true)) c1Checked=c1Checked+1;
       if(blocked && (c1true)) c1Blocked=c1Blocked+1;
       if(blocked && checked && (c1true)) c1Wrong=c1Wrong+1;
   
       if(checked && (c2true)) c2Checked=c2Checked+1;
       if(blocked && (c2true)) c2Blocked=c2Blocked+1;
       if(blocked && checked && (c2true)) c2Wrong=c2Wrong+1;
   
    }
   const a1Words = (a1Checked-a1Wrong) * 60;
   const a2Words = (a2Checked-a2Wrong) * 60;
   const b1Words = (b1Checked-b1Wrong) * 53;
   const b2Words = (b2Checked-b2Wrong) * 46;
   const c1Words = (c1Checked-c1Wrong) * 86;
   const c2Words = (c2Checked-c2Wrong) * 4;
   
   const result = a1Words + a2Words + b1Words + b2Words + c1Words + c2Words;
   
   const a1Percent = Math.round((a1Checked-a1Wrong) * 100 / 15);
   const a2Percent = Math.round((a2Checked-a2Wrong) * 100 / 15);
   const b1Percent = Math.round((b1Checked-b1Wrong) * 100 / 15);
   const b2Percent = Math.round((b2Checked-b2Wrong) * 100 / 15);
   const c1Percent = Math.round((c1Checked-c1Wrong) * 100 / 15);
   const c2Percent = Math.round((c2Checked-c2Wrong) * 100 / 15);
   
   const allPercent = (result * 100) / WORDS;

    function getDataforUrl() {
    const textresult = `r=${result}&a1=${a1Percent}&a2=${a2Percent}&b1=${b1Percent}&b2=${b2Percent}&c1=${c1Percent}&c2=${c2Percent}&all=${allPercent}`;
    return textresult;
    }

   return {result,a1Percent,a2Percent,b1Percent,b2Percent,c1Percent,c2Percent,allPercent,getDataforUrl};
}

export function makeObjectFromUrl (data:any) {
   return {
     'result': data.get('r'),
     'a1Percent': data.get('a1'),
     'a2Percent': data.get('a2'),
     'b1Percent': data.get('b1'),
     'b2Percent': data.get('b2'),
     'c1Percent': data.get('c1'),
     'c2Percent': data.get('c2'),
     'allPercent': data.get('r')
   };
 }