import { Line, Circle } from 'rc-progress';
import styles from './result.module.css';
import { useLocation } from 'react-router-dom';
import { getChecked } from './calculateresult';
import BannerResult from '../../features/banner/Bannerresult';

function CommonResult({ queryParameters }:any) {
  const location = useLocation();
  const state = location?.state?.state || [];
  getChecked(state);

  return (
    <div className={styles.result}>
    <div className={styles.textheader}>{'Отлично! Вот ваш результат!'}</div>
    <div className={styles.text1}>{'Ваш примерный словарный запас'}</div>
    <div className={styles.text2block}>
      <div className={styles.text2}>{`${getChecked(state).result}`}</div>
    </div>
    <div className={styles.text1}>{'Ваш примерный уровень языка по стандарту CEFR'}</div>
    <div className={styles.resultcontainer}>
        <div className={styles.circlecontainer}>
        <div className={styles.circleContaineritem}>
        <div className={styles.circletext}>A1</div>
        <div className={styles.circletext2}>{`${getChecked(state).a1Percent}`}%</div>
        <div className={styles.circletext3}>Beginner</div>
            <Circle
            percent={getChecked(state).a1Percent}
            strokeWidth={6}
            strokeLinecap="round"
            strokeColor={{
                '0%': '#108ee9',
                '100%': '#87d068',
            }}
            />
        </div>
        <div className={styles.circleContaineritem}>
        <div className={styles.circletext}>A2</div>
        <div className={styles.circletext2}>{`${getChecked(state).a2Percent}`}%</div>
        <div className={styles.circletext3}>Elementary</div>
        <Circle
          percent={getChecked(state).a2Percent}
          strokeWidth={6}
          strokeLinecap="round"
          strokeColor={{
            '0%': '#108ee9',
            '100%': '#87d068',
          }}
        />
      </div>
      <div className={styles.circleContaineritem}>
      <div className={styles.circletext}>B1</div>
      <div className={styles.circletext2}>{`${getChecked(state).b1Percent}`}%</div>
      <div className={styles.circletext3}>Intermediate</div>
        <Circle
          percent={getChecked(state).b1Percent}
          strokeWidth={6}
          strokeLinecap="round"
          strokeColor={{
            '0%': '#108ee9',
            '100%': '#87d068',
          }}
        />
      </div>
      <div className={styles.circleContaineritem}>
      <div className={styles.circletext}>B2</div>
      <div className={styles.circletext2}>{`${getChecked(state).b2Percent}`}%</div>
      <div className={styles.circletext3}>Upper Intermediate</div>
        <Circle
          percent={getChecked(state).b2Percent}
          strokeWidth={6}
          strokeLinecap="round"
          strokeColor={{
            '0%': '#108ee9',
            '100%': '#87d068',
          }}
        />
      </div>
      <div className={styles.circleContaineritem}>
      <div className={styles.circletext}>C1</div>
      <div className={styles.circletext2}>{`${getChecked(state).c1Percent}`}%</div>
      <div className={styles.circletext3}>Advanced</div>
        <Circle
          percent={getChecked(state).c1Percent}
          strokeWidth={6}
          strokeLinecap="round"
          strokeColor={{
            '0%': '#108ee9',
            '100%': '#87d068',
          }}
        />
      </div>
      <div className={styles.circleContaineritem}>
      <div className={styles.circletext}>C2</div>
      <div className={styles.circletext2}>{`${getChecked(state).c2Percent}`}%</div>
      <div className={styles.circletext3}>Proficiency</div>
        <Circle
          percent={getChecked(state).c2Percent}
          strokeWidth={6}
          strokeLinecap="round"
          strokeColor={{
            '0%': '#108ee9',
            '100%': '#87d068',
          }}
        />
      </div>
      </div>
      <div className={styles.lineContainerStyle}>
      <Line percent={getChecked(state).allPercent} strokeWidth={2} strokeColor={'#87d068'} />
      </div>
    </div>
    <BannerResult />
    {(state == !![] )&& <div>Слова для изучения. </div>}
    <div className={styles.testblock}>
    {state.map((e:any, i:any) => e?.blocked == true && <div key={e.id} className={styles.wordblock} >
      <div className={styles.wordcontainer}>
          <div className={styles.translate}>{e.translate.toLowerCase()}</div>
          <div className={styles.word}>{e.word.toLowerCase()}</div>
      </div>
    </div>
    )}</div>
    </div>
  );
}

export default CommonResult;